
import axios from "axios";
import ContactHelper from "./Helper";

const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/container`
const dealRootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/deal`
const contactRootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/contacts`
const marketingRootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/marketing`
const inboxRootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/inbox`
// const apiKey = ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN)

/* get contact other email info */
const getOtherEmailForAContact = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN) }
    }
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/get-other-email-by-contact',
        data: data
    });
}
/* get user info */
const getProfileData = () => {
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/single/profile',
        data: {
            'api-key': ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN)
        },
    });
}
/* get personalized */
const getPersonalizedTag = () => {
    return axios.request({
        method: 'POST',
        url: rootUrl + '/get-custom-fields',
        data: {
            'api-key': ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN),
            'for_custom_field_update': true
        },
    });
}
/* file upload in editor */
const image_upload_handler = (blobInfo, success, failure, progress) => {
    const emailUploadFileApi = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/calendar/upload-email-file`;
    var xhr, formData;

    xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open('POST', emailUploadFileApi);

    xhr.upload.onprogress = function (e) {
        progress(e.loaded / e.total * 100);
    };

    xhr.onload = function () {
        var json;

        if (xhr.status === 403) {
            failure('HTTP Error: ' + xhr.status, { remove: true });
            return;
        }

        if (xhr.status < 200 || xhr.status >= 300) {
            failure('HTTP Error: ' + xhr.status);
            return;
        }

        json = JSON.parse(xhr.responseText);

        if (!json || typeof json.location != 'string') {
            failure('Invalid JSON: ' + xhr.responseText);
            return;
        }

        success(json.location);
    };

    xhr.onerror = function () {
        failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
    };
    formData = new FormData();
    formData.append('file', blobInfo.blob(), blobInfo.filename());
    formData.append('api-key', ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN))
    xhr.send(formData);
}
/* get email templates */
const getEmailTemplates = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN) }
    }
    return axios.request({
        method: 'POST',
        url: marketingRootUrl + '/message-templates',
        data: data
    });
}
/* send email */
const sendEmail = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN) }
    }
    return axios.request({
        method: 'POST',
        url: inboxRootUrl + '/make-conversation',
        data: data,
        timeout: 120000
    });
}

/* send sms from map */
const sendSmsFromMap = (params, formData = false) => {
    let data = {
        ...params,
        ...{ 'api-key': ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN) }
    }
    if (formData) {
        params.append('api-key', ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN))
    }
    return axios.request({
        method: 'POST',
        url: inboxRootUrl + '/make-conversation-from-map',
        data: formData ? params : data
    });
}

/* get contact details by id */
// const getContactDetailsById = (params) => {
//     let data = {
//         ...params,
//         ...{ 'api-key': ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN) }
//     }
//     return axios.request({
//         method: 'POST',
//         url: contactRootUrl + '/get-contact-details-v2',
//         data: data
//     });
// }
// /* get user custom field */
// const getUserCustomField = () => {
//     return axios.request({
//         method: 'POST',
//         url: dealRootUrl + '/get-custom-fields',
//         data: {
//             'api-key': ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN)
//         },
//     });
// }

const ApiList = {
    getOtherEmailForAContact,
    getProfileData,
    getPersonalizedTag,
    image_upload_handler,
    getEmailTemplates,
    sendEmail,
    sendSmsFromMap
}
export default ApiList;