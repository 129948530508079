import { APPOINTMENT_SCHEDULE_TODAY } from "../constants/CoreConstants";
import * as ACTION from "./floatingSidebarTypes";

const initialState = {
    isLoading: false,
    scheduleType: APPOINTMENT_SCHEDULE_TODAY,
    activityList: [],
    inboxList: [],
    notificationList: [],
    page: 1,
    hasMore: false,
    error: null,
};

const floatingSidebarReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.FETCH_USER_ACTIVITY:
            return {
                ...state,
                isLoading: true,
            };
        case ACTION.FETCH_USER_ACTIVITY_SUCCESS:
            const newActivities = action.payload || [];
            return {
                ...state,
                isLoading: false,
                activityList: [...state.activityList, ...newActivities],
                hasMore: newActivities.length > 0,
            };
        case ACTION.FETCH_USER_ACTIVITY_FAIL:
            return {
                ...state,
                isLoading: false,
                activityList: [],
                hasMore: false,
                error: action.payload,
            };

        case ACTION.SET_SIDEBAR_SCHEDULE_TYPE:
            return {
                ...state,
                isLoading: true,                // Set loading to true
                activityList: [],               // Clear activity list
                scheduleType: action.payload,   // Update scheduleType
            };
        case ACTION.SET_SIDEBAR_PAGE:
            return {
                ...state,
                page: action.payload,           // Update the page number
            };
        case ACTION.RESET_SIDEBAR_PAGE:
            return {
                ...state,
                page: 1,                        // Reset page to 1
                activityList: [],               // Clear activity list
                inboxList: [],                  // Clear inbox list
                notificationList: []            // Clear notification list
            };
        case ACTION.FETCH_USER_INBOX:
            return {
                ...state,
                isLoading: true,
            };
        case ACTION.FETCH_USER_INBOX_SUCCESS:
            return {
                ...state,
                isLoading: false,
                inboxList: [...state.inboxList, ...action.payload],
                hasMore: action.payload.length > 0,
            };
        case ACTION.FETCH_USER_INBOX_FAIL:
            return {
                ...state,
                isLoading: false,
                inboxList: [],
                error: action.payload,
            };
        case ACTION.FETCH_SIDEBAR_NOTIFICATION:
            return {
                ...state,
                isLoading: true,
            };
        case ACTION.FETCH_SIDEBAR_NOTIFICATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                notificationList: [...state.notificationList, ...action.payload],
                hasMore: action.payload.length > 0,
            };
        case ACTION.FETCH_SIDEBAR_NOTIFICATION_FAIL:
            return {
                ...state,
                isLoading: false,
                notificationList: [],
                error: action.payload,
            };
        default:
            return state;
    }
};

export default floatingSidebarReducer;
