import {
    Modal,
    Paper,
} from "@material-ui/core";
import React from "react";
import Utils from "../../helpers/Utils";
import useStyles from "./useStyles";
import WarningIcon from "./WarningIcon";

const SmsRestriction = ({ isOpen, onClose }) => {
    const classes = useStyles();

      return(
          <Modal
              open={isOpen}
              onClose={()=>{}}
              aria-labelledby="multi-stepper-modal-title"
              aria-describedby="multi-stepper-modal-description"
              className={`${classes.modalWrapper} global-xs-modal add_list_modal`}
          >
              <Paper className={classes.modalInner}>
                  <div className={classes.modalBody}>
                      <div className={classes.bodyContainer}>
                          <div className={classes.bodyTextContainer}>
                              <div className={classes.imageContainer}>
                                  <WarningIcon/>
                              </div>
                              <div className={classes.reasonTitle}>
                                  {
                                      Utils.getAccountData("userIsOwner") ?
                                          "You haven't completed your A2P/10DLC Registration!" :
                                          "Your team didn't completed A2P Campaign Registration! Please contact with team owner."
                                  }
                              </div>
                              <span className={classes.messageTitle}>
                                  All phone numbers in the United States used to send text messages must be fully registered to an approved campaign under your brand.
                              </span>
                              <span className={classes.messageTitle} style={{ marginTop: "15px" }}>
                                  This requirement applies to all texting platforms in the United States.
                              </span>
                              <div className={classes.actionContainer}>
                                  {
                                      Utils.getAccountData("userIsOwner") ?
                                          <a className={classes.actionButton}
                                             href="/user/telephony-service/trust-center">
                                              Complete My Registration
                                          </a> :
                                          <a className={classes.actionButton} onClick={onClose}>
                                              Close
                                          </a>
                                  }
                              </div>
                          </div>
                      </div>
                  </div>
              </Paper>
          </Modal>
      );
};

export default SmsRestriction;