import React from "react";
import {storeMultipleReducers} from "../../../actions/globalDialerAction";
import {connect} from "react-redux";

const Stage = props => {
    // const [stage, setStage] = useState(props.stage);

    // useEffect(() => {
    //     setStage(props.stage);
    // }, [props.stage]);


    const changeStoreStage = (stage) => {
        if ((!props.isCallInProgress || props.isCallReceived) && props.stage !== 'stage') {
            console.log('changing stage')
            props.storeMultipleReducers({stage: stage});
        }
    }

    return (
        <ul className="tabs d-flex justify-content-around">
            <li className="tab">
                <a className={props.stage === 'contact' ? 'active' : ''} href="#!" onClick={(e) => {e.preventDefault();changeStoreStage('contact');}}>
                    <div
                        className="people-icon d-flex justify-content-center align-items-center">
                        <svg width="21" height="22" viewBox="0 0 21 22" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M5.25 5.21053C5.25 8.08326 7.6055 10.4211 10.5 10.4211C13.3945 10.4211 15.75 8.08326 15.75 5.21053C15.75 2.33779 13.3945 0 10.5 0C7.6055 0 5.25 2.33779 5.25 5.21053ZM19.8333 22H21V20.8421C21 16.3738 17.3355 12.7368 12.8333 12.7368H8.16667C3.66333 12.7368 0 16.3738 0 20.8421V22H19.8333Z"
                                fill="#3C7EF3"/>
                        </svg>
                    </div>
                    <span>contact</span>
                </a>
            </li>
            <li className="tab">
                <a href='#!' onClick={(e) => {e.preventDefault();changeStoreStage('dial');}} className={props.stage === 'dial' ? 'active' : ''}>
                    <div className="dialpad-icon d-flex justify-content-center align-items-center">
                        <svg width="21" height="25" viewBox="0 0 21 25" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M5.02761 0.300781H1.93629C1.0841 0.300781 0.390625 0.994253 0.390625 1.84644V3.90734C0.390625 4.75954 1.0841 5.45301 1.93629 5.45301H5.02761C5.87981 5.45301 6.57328 4.75954 6.57328 3.90734V1.84644C6.57328 0.994253 5.87981 0.300781 5.02761 0.300781Z"
                                fill="#3C7EF3"/>
                            <path
                                d="M12.2425 0.300781H9.15113C8.29894 0.300781 7.60547 0.994253 7.60547 1.84644V3.90734C7.60547 4.75954 8.29894 5.45301 9.15113 5.45301H12.2425C13.0946 5.45301 13.7881 4.75954 13.7881 3.90734V1.84644C13.7881 0.994253 13.0946 0.300781 12.2425 0.300781Z"
                                fill="#3C7EF3"/>
                            <path
                                d="M19.4534 0.300781H16.3621C15.5099 0.300781 14.8164 0.994253 14.8164 1.84644V3.90734C14.8164 4.75954 15.5099 5.45301 16.3621 5.45301H19.4534C20.3056 5.45301 20.9991 4.75954 20.9991 3.90734V1.84644C20.9991 0.994253 20.3055 0.300781 19.4534 0.300781Z"
                                fill="#3C7EF3"/>
                            <path
                                d="M5.02761 6.48438H1.93629C1.0841 6.48438 0.390625 7.17785 0.390625 8.03004V10.0909C0.390625 10.9431 1.0841 11.6366 1.93629 11.6366H5.02761C5.87981 11.6366 6.57328 10.9431 6.57328 10.0909V8.03004C6.57328 7.17785 5.87981 6.48438 5.02761 6.48438Z"
                                fill="#3C7EF3"/>
                            <path
                                d="M12.2425 6.48438H9.15113C8.29894 6.48438 7.60547 7.17785 7.60547 8.03004V10.0909C7.60547 10.9431 8.29894 11.6366 9.15113 11.6366H12.2425C13.0946 11.6366 13.7881 10.9431 13.7881 10.0909V8.03004C13.7881 7.17785 13.0946 6.48438 12.2425 6.48438Z"
                                fill="#3C7EF3"/>
                            <path
                                d="M19.4534 6.48438H16.3621C15.5099 6.48438 14.8164 7.17785 14.8164 8.03004V10.0909C14.8164 10.9431 15.5099 11.6366 16.3621 11.6366H19.4534C20.3056 11.6366 20.9991 10.9431 20.9991 10.0909V8.03004C20.9991 7.17785 20.3055 6.48438 19.4534 6.48438Z"
                                fill="#3C7EF3"/>
                            <path
                                d="M5.02761 12.6641H1.93629C1.0841 12.6641 0.390625 13.3575 0.390625 14.2097V16.2706C0.390625 17.1228 1.0841 17.8163 1.93629 17.8163H5.02761C5.87981 17.8163 6.57328 17.1228 6.57328 16.2706V14.2097C6.57328 13.3575 5.87981 12.6641 5.02761 12.6641Z"
                                fill="#3C7EF3"/>
                            <path
                                d="M12.2425 12.6641H9.15113C8.29894 12.6641 7.60547 13.3575 7.60547 14.2097V16.2706C7.60547 17.1228 8.29894 17.8163 9.15113 17.8163H12.2425C13.0946 17.8163 13.7881 17.1228 13.7881 16.2706V14.2097C13.7881 13.3575 13.0946 12.6641 12.2425 12.6641Z"
                                fill="#3C7EF3"/>
                            <path
                                d="M19.4534 12.6641H16.3621C15.5099 12.6641 14.8164 13.3575 14.8164 14.2097V16.2706C14.8164 17.1228 15.5099 17.8163 16.3621 17.8163H19.4534C20.3056 17.8163 20.9991 17.1228 20.9991 16.2706V14.2097C20.9991 13.3575 20.3055 12.6641 19.4534 12.6641Z"
                                fill="#3C7EF3"/>
                            <path
                                d="M12.2425 18.8477H9.15113C8.29894 18.8477 7.60547 19.5411 7.60547 20.3933V22.4542C7.60547 23.3064 8.29894 23.9999 9.15113 23.9999H12.2425C13.0946 23.9999 13.7881 23.3064 13.7881 22.4542V20.3933C13.7881 19.5411 13.0946 18.8477 12.2425 18.8477Z"
                                fill="#3C7EF3"/>
                        </svg>
                    </div>
                    <span>Dialpad</span>
                </a>
            </li>
        </ul>
    );
}


const mapStateToProps = state => {
    return {
        stage: state.globalDialerReducer.stage,
        isCallInProgress: state.globalDialerReducer.isCallInProgress,
        isCallReceived: state.globalDialerReducer.isCallReceived,
        enableWarmTransfer: state.globalDialerReducer.enableWarmTransfer,

    };
};

const mapDispatchToProps = dispatch => {
    return {
		storeMultipleReducers: (params) => dispatch(storeMultipleReducers(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Stage);
