import React from "react";
import { Divider, Typography, styled } from "@material-ui/core";

const WrapperStyle = styled("div")(({theme}) => ({
  display: "flex", 
  flex: 1, 
  alignItems: "center",
  "& .title": {
    fontSize: 16,
    fontWeight: 600,
    color: "var(--dark_blue)",
  },
  "& .divider": {
    flex: 1, 
    marginLeft: theme.spacing(1),
    marginBottom:"0px !important",
    paddingBottom:"0px !important"
  }
}));



const CustomTitleAddDeal = ({ title = "Default Title" }) => {
    return (
      <WrapperStyle>
         <Typography style={{ height: 'unset !important' }} variant="body2" className={"title"}>
          {title}
        </Typography>
        <div className="divider">
          <Divider width="100%"/>
        </div>
      </WrapperStyle>
    );
};

export default CustomTitleAddDeal;