import React, {createContext, useReducer, useContext} from 'react'
import MultipleNotification from './MultipleNotification';
import './notification.css'
import '../components/layouts/notification/notification.css'
import SingleToasterView from './SingleNotification';
import {DefaultIcons, ErrorIcons, SuccessIcons, WarningIcons} from "../components/layouts/NotificationIcons";
import {NotificationHtmlTemplate} from "../helpers/Utils";
import {renderToString} from "react-dom/server";

export const ToasterContext = createContext();

export const ToasterProvider = (props) => {

    /* 
    state = [
        multiple: [{},{}],
        single: {}
    ]

    -----
    id, type, title, message, timer
    {
        id: Math.floor(Math.random() * (999999999 - 9 + 1)) + 9,
        title: 'This is title',
        message: 'This is message. here simple discription will be placed',
        type: 'SUCCESS' //ERROR, WARNING, INFO
    }
    */

    const notificationsState = {
        multiple: [],
        single: {}
    }

    const [state, dispatch] = useReducer((state = notificationsState, action) => {
        switch(action.type){
            case 'ADD_MULTIPLE_NOTIFICATIONS':
                return {...state, multiple: [...state.multiple, {...action.payload}]};
            case 'ADD_SINGLE_NOTIFICATION':
                return {...state, single: action.payload};
            case 'DELETE_MULTIPLE_NOTIFICATION':
                return {...state, multiple: state.multiple.filter(el => el.id !== action.id)};
            case 'DELETE_SINGLE_NOTIFICATION':
                if(action.id+"" === state.single.id+""){
                    return {...state, single: {}};
                }
                break;
            default:
                return state;
        }
    }, notificationsState)

    window.showNotification = (type = 'INFO', message = 'Information', des="", closeText='Dismiss') => {
        /* 'SUCCESS' //ERROR, WARNING, INFO */
        let icon, classText;
        type = type.toUpperCase();
        if(type === 'SUCCESS'){
            icon = <SuccessIcons />
            classText="success"
        }
        else if(type === 'ERROR'){
            icon = <ErrorIcons />
            classText="error"
        }
        else if(type === 'WARNING'){
            icon = <WarningIcons />
            classText="warning"
        }else{
            icon = <DefaultIcons />
            classText="default"
        }
        NotificationHtmlTemplate({
            leftIcon: renderToString(icon),
            text: message,
            des: des,
            closeText: closeText,
            timer: 3000,
            classText: classText
        })
    }

    if(state!== undefined && state!= null){
        return(
            <ToasterContext.Provider value={{state, dispatch}}>
                <div className={`notification-wrapper`}>
                    <SingleToasterView />
                </div>
                
                <div className={`notification-wrapper ${state.multiple.length > 0 ? 'topRight' : ''}`}>
                    <MultipleNotification />
                </div>
                {props.children}
            </ToasterContext.Provider>
        )
    }
    else return null
}

export const useSingleNotification = () =>{
    const {dispatch} = useContext(ToasterContext);
    return (props) => {
        dispatch({
            type: "ADD_SINGLE_NOTIFICATION",
            payload: {
                id: Math.floor(Math.random() * (999999999 - 9 + 1)) + 9,
                ...props
            }
        })
    }
}

export const useMultipleNotification = () =>{
    const {dispatch} = useContext(ToasterContext);
    return (props) => {
        dispatch({
            type: "ADD_MULTIPLE_NOTIFICATIONS",
            payload: {
                id: Math.floor(Math.random() * (999999999 - 9 + 1)) + 9,
                ...props
            }
        })
    }
}