import React, { useEffect, useState } from "react";
import {
    Backdrop,
    Button,
    Fade,
    Grid,
    Modal,
    Typography,
    makeStyles,
} from "@material-ui/core";
import {
    BasicInput,
    ContentWrapper
} from "../GlobalAddDealModalV3/GlobalAddDealModalStyles";
import BasicSelect from "../GlobalAddDealModalV3/components/BasicSelect";
import Loading from "./Loading";
import {CloseIcon} from "./Icons";
import {lostDealFormData, lostDealRecycle} from "../../api/tenantApi";
import {stagesByPipeline} from "../../api/dealApi";

const modalStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        width: 930,
        backgroundColor: theme.palette.background.paper,
        borderRadius: "8px",
        boxShadow: theme.shadows[5],
    },
    modalHeader: {
        padding: "16px",
        background: "#346fef",
        color: "#fff",
        borderTopRightRadius: "8px",
        borderTopLeftRadius: "8px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        "& .closeIcon": {
            display: "flex",
            cursor: "pointer",
            "& svg": {
                color: "#fff",
                backgroundColor: "var(--red)",
                width: "25px",
                height: "25px",
                borderRadius: "50px"
            },
        },
    },
    modalBody: {
        padding: "16px",
        "& .productValueSignDisabled": {
            color: "rgba(0,0,0,0.42)"
        }
    },
    modalBodyInput: {
        display: "flex",
        alignItems: "center",
        gap: "20px",
    },

    modalFooter: {
        background: "rgb(24, 31, 72)",
        borderEndEndRadius: "8px",
        borderEndStartRadius: "8px",
        padding: "16px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",

        "& .title": {
            color: "#fff",
            fontWeight: "600",
        },

        "& .btnSubmit": {
            background: "#346fef !important",
            fontWeight: "600"
        },
        "& .skipSubmit": {
            color: "white",
            borderColor: "white",
            fontWeight: "600",
            marginRight: ".5rem"
        }
    },
    errorText: {
        color: "red",
        marginTop: "4px",
        fontSize: "0.875rem",
    },
}));

const formatDateForInput = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
};

const isDateFuture = (inputDate) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const input = new Date(inputDate);
    input.setHours(0, 0, 0, 0);

    return input > today && input > yesterday;
}

const GlobalInsuranceDealStatusLost = ({ open, onClose, data }) => {
    const classes = modalStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        lost_deal_reason_id: "",
        winner_company_id: "",
        recycle_event_id: "",
        date_of_recycle: "",
        recycle_to_pypeline_id: "",
        recycle_to_stage_id: "",
        recycle_lead_source_id: "",
        recycle_tag_id: "",
        note: ""
    });
    const [errors, setErrors] = useState({
        lost_deal_reason_id: "",
        winner_company_id: "",
        recycle_event_id: "",
        date_of_recycle: "",
        recycle_to_pypeline_id: "",
        recycle_to_stage_id: "",
        recycle_lead_source_id: "",
        recycle_tag_id: "",
        note: ""
    });
    const [assetData, setAssetData] = useState(null);
    const [pipelineStages, setPipelineStages] = useState([]);

    useEffect(()=>{
        if (open){
            callApi();
        }
    },[open]);

    const callApi = async () => {
        try {
            setIsLoading(true);
            const resp = await lostDealFormData();

            if (resp?.success){
                setAssetData(resp.data);
            }
        }catch (err){

        }finally {
            setIsLoading(false);
        }
    };

    const handleFormData = async (event) => {
        const name = event.target.name;
        const value = event.target.value;

        let oldFormData = {...formData};
        oldFormData[name] = value;
        setFormData(oldFormData);

        let oldErrors = {...errors};
        if (oldErrors[name]){
            oldErrors[name] = "";
        }
        setErrors(oldErrors);

        if (name === "recycle_to_pypeline_id"){
            const resp = await stagesByPipeline({ pipelineId: value });

            if (resp?.data?.dealStage){
                setPipelineStages(resp?.data?.dealStage);
            }
        }
    };

    const validateForm = () => {
        let formValid = true;
        let errors = {
            lost_deal_reason_id: "",
            winner_company_id: "",
            recycle_event_id: "",
            date_of_recycle: "",
            recycle_to_pypeline_id: "",
            recycle_to_stage_id: "",
            recycle_lead_source_id: "",
            recycle_tag_id: "",
            note: ""
        };

        if (formData.lost_deal_reason_id === ""){
            errors.lost_deal_reason_id = "Lost reason is required";
            formValid = false;
        }

        if (formData.recycle_event_id === ""){
            errors.recycle_event_id = "Recycle event is required";
            formValid = false;
        }

        if (formData.winner_company_id === ""){
            errors.winner_company_id = "Insurance company is required";
            formValid = false;
        }

        if (formData.recycle_to_pypeline_id === ""){
            errors.recycle_to_pypeline_id = "Pipeline is required";
            formValid = false;
        }

        if (formData.recycle_to_stage_id === ""){
            errors.recycle_to_stage_id = "Pipeline stage is required";
            formValid = false;
        }

        if (formData.date_of_recycle === ""){
            errors.date_of_recycle = "Recycle date is required";
            formValid = false;
        }

        if (formData.date_of_recycle !== "" && !isDateFuture(formData.date_of_recycle)){
            errors.date_of_recycle = "Recycle date must be future date";
            formValid = false;
        }

        setErrors(errors);
        return formValid;
    };

    const handleSubmit = async () => {
        if (!validateForm()) {
            return;
        }

        let payload = {...formData};

        payload.contact_id = data.contact_id;
        payload.contact_stage_id = data.contact_stages_id;

        try{
            setIsSubmitting(true);
            const resp = await lostDealRecycle(payload);

            if (resp?.success){
                window.showNotification("success", resp.message);
                handleClose();
            }
        }catch (err){
            window.showNotification("error", "Something went wrong!");
        }finally {
            setIsSubmitting(false);
        }
    };

    const handleClose = () => {
      setErrors({
          lost_deal_reason_id: "",
          winner_company_id: "",
          recycle_event_id: "",
          date_of_recycle: "",
          recycle_to_pypeline_id: "",
          recycle_to_stage_id: "",
          recycle_lead_source_id: "",
          recycle_tag_id: "",
          note: ""
      });
      setFormData({
          lost_deal_reason_id: "",
          winner_company_id: "",
          recycle_event_id: "",
          date_of_recycle: "",
          recycle_to_pypeline_id: "",
          recycle_to_stage_id: "",
          recycle_lead_source_id: "",
          recycle_tag_id: "",
          note: ""
      });

      onClose();
    };

    return (
        <Modal
            open={open}
            onClose={()=>{}}
            className={classes.modal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <div className={classes.modalHeader}>
                        <div>
                            <Typography style={{
                                height: 'unset !important', color: 'white', fontWeight: 600,
                            }} variant="h5">LOST DEAL?</Typography>
                            <Typography style={{
                                height: 'unset !important', color: 'white', fontWeight: 500, fontSize: "15px"
                            }} variant="h5">
                                Complete the steps below so you can win the business in the future.
                            </Typography>
                        </div>

                        <div className="closeIcon" onClick={handleClose}>
                            <CloseIcon />
                        </div>
                    </div>

                    <div className={classes.modalBody}>
                        {
                            isLoading ?
                                <Loading /> :
                                <ContentWrapper>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <Typography style={{ height: 'unset !important', fontWeight: 600, color: "#133159" }} className={errors.title ? classes.errorText : ""}>
                                                Why did you lose the deal?
                                            </Typography>
                                            <div>
                                                <BasicSelect
                                                    name="lost_deal_reason_id"
                                                    options={assetData?.lostReasons || []}
                                                    mapping={{
                                                        label: "title",
                                                        value: "id",
                                                    }}
                                                    deletedMapping={{
                                                        field: "status",
                                                        value: "TRASH",
                                                    }}
                                                    defaultText="Select lost reason"
                                                    value={formData.lost_deal_reason_id}
                                                    onChange={handleFormData}
                                                />
                                                {errors.lost_deal_reason_id && (
                                                    <Typography style={{ height: 'unset !important' }} variant="body2" className={classes.errorText}>
                                                        {errors.lost_deal_reason_id}
                                                    </Typography>
                                                )}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography style={{ height: 'unset !important', fontWeight: 600, color: "#133159" }} className={errors.title ? classes.errorText : ""}>
                                                Carrier prospect went with?
                                            </Typography>
                                            <div>
                                                <BasicSelect
                                                    name="winner_company_id"
                                                    options={assetData?.insuranceCompanies || []}
                                                    mapping={{
                                                        label: "title",
                                                        value: "id",
                                                    }}
                                                    deletedMapping={{
                                                        field: "status",
                                                        value: "TRASH",
                                                    }}
                                                    defaultText="Select insurance company"
                                                    value={formData.winner_company_id}
                                                    onChange={handleFormData}
                                                />
                                                {errors.winner_company_id && (
                                                    <Typography style={{ height: 'unset !important' }} variant="body2" className={classes.errorText}>
                                                        {errors.winner_company_id}
                                                    </Typography>
                                                )}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography style={{ height: 'unset !important', fontWeight: 600, color: "#133159" }} className={errors.title ? classes.errorText : ""}>
                                                Recycle event
                                            </Typography>
                                            <div>
                                                <BasicSelect
                                                    name="recycle_event_id"
                                                    options={assetData?.recycleEvents || []}
                                                    mapping={{
                                                        label: "title",
                                                        value: "id",
                                                    }}
                                                    deletedMapping={{
                                                        field: "status",
                                                        value: "TRASH",
                                                    }}
                                                    defaultText="Select recycle event"
                                                    value={formData.recycle_event_id}
                                                    onChange={handleFormData}
                                                />
                                                {errors.recycle_event_id && (
                                                    <Typography style={{ height: 'unset !important' }} variant="body2" className={classes.errorText}>
                                                        {errors.recycle_event_id}
                                                    </Typography>
                                                )}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography style={{ height: 'unset !important', fontWeight: 600, color: "#133159" }} className={errors.title ? classes.errorText : ""}>
                                                Date to recycle lead?
                                            </Typography>
                                            <div>
                                                <BasicInput
                                                    name="date_of_recycle"
                                                    type="date"
                                                    fullWidth
                                                    value={formData.date_of_recycle}
                                                    onChange={handleFormData}
                                                />
                                                {errors.date_of_recycle && (
                                                    <Typography style={{ height: 'unset !important' }} variant="body2" className={classes.errorText}>
                                                        {errors.date_of_recycle}
                                                    </Typography>
                                                )}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography style={{ height: 'unset !important', fontWeight: 600, color: "#133159" }} className={errors.title ? classes.errorText : ""}>
                                                Recycle to pipeline
                                            </Typography>
                                            <div>
                                                <BasicSelect
                                                    name="recycle_to_pypeline_id"
                                                    options={assetData?.pipelines || []}
                                                    mapping={{
                                                        label: "title",
                                                        value: "id",
                                                    }}
                                                    deletedMapping={{
                                                        field: "status",
                                                        value: "TRASH",
                                                    }}
                                                    defaultText="Select pipeline"
                                                    value={formData.recycle_to_pypeline_id}
                                                    onChange={handleFormData}
                                                />
                                                {errors.recycle_to_pypeline_id && (
                                                    <Typography style={{ height: 'unset !important' }} variant="body2" className={classes.errorText}>
                                                        {errors.recycle_to_pypeline_id}
                                                    </Typography>
                                                )}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography style={{ height: 'unset !important', fontWeight: 600, color: "#133159" }} className={errors.title ? classes.errorText : ""}>
                                                Recycle to stage
                                            </Typography>
                                            <div>
                                                <BasicSelect
                                                    name="recycle_to_stage_id"
                                                    options={pipelineStages}
                                                    mapping={{
                                                        label: "label",
                                                        value: "value",
                                                    }}
                                                    deletedMapping={{
                                                        field: "status",
                                                        value: "TRASH",
                                                    }}
                                                    disabled={!formData.recycle_to_pypeline_id}
                                                    defaultText="Select stage"
                                                    value={formData.recycle_to_stage_id}
                                                    onChange={handleFormData}
                                                />
                                                {errors.recycle_to_stage_id && (
                                                    <Typography style={{ height: 'unset !important' }} variant="body2" className={classes.errorText}>
                                                        {errors.recycle_to_stage_id}
                                                    </Typography>
                                                )}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography style={{ height: 'unset !important', fontWeight: 600, color: "#133159" }} className={errors.title ? classes.errorText : ""}>
                                                When recycled, change lead source to
                                            </Typography>
                                            <div>
                                                <BasicSelect
                                                    name="recycle_lead_source_id"
                                                    options={assetData?.leadSources || []}
                                                    mapping={{
                                                        label: "title",
                                                        value: "id",
                                                    }}
                                                    deletedMapping={{
                                                        field: "status",
                                                        value: "TRASH",
                                                    }}
                                                    defaultText="Select lead source"
                                                    value={formData.recycle_lead_source_id}
                                                    onChange={handleFormData}
                                                />
                                                {errors.recycle_lead_source_id && (
                                                    <Typography style={{ height: 'unset !important' }} variant="body2" className={classes.errorText}>
                                                        {errors.recycle_lead_source_id}
                                                    </Typography>
                                                )}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography style={{ height: 'unset !important', fontWeight: 600, color: "#133159" }} className={errors.title ? classes.errorText : ""}>
                                                When recycled, add the following tag
                                            </Typography>
                                            <div>
                                                <BasicSelect
                                                    name="recycle_tag_id"
                                                    options={assetData?.tags || []}
                                                    mapping={{
                                                        label: "title",
                                                        value: "id",
                                                    }}
                                                    deletedMapping={{
                                                        field: "status",
                                                        value: "TRASH",
                                                    }}
                                                    defaultText="Select tag"
                                                    value={formData.recycle_tag_id}
                                                    onChange={handleFormData}
                                                />
                                                {errors.recycle_tag_id && (
                                                    <Typography style={{ height: 'unset !important' }} variant="body2" className={classes.errorText}>
                                                        {errors.recycle_tag_id}
                                                    </Typography>
                                                )}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Typography style={{ height: 'unset !important', fontWeight: 600, color: "#133159" }} className={errors.title ? classes.errorText : ""}>
                                                Notes
                                            </Typography>
                                            <BasicInput
                                                name="note"
                                                type="text"
                                                fullWidth
                                                value={formData.note}
                                                onChange={handleFormData}
                                                multiline
                                            />
                                            {errors.note && (
                                                <Typography style={{ height: 'unset !important' }} variant="body2" className={classes.errorText}>
                                                    {errors.note}
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                </ContentWrapper>
                        }
                    </div>

                    <div className={classes.modalFooter}>
                        <Button
                            disabled={isSubmitting}
                            className="skipSubmit"
                            variant="outlined"
                            color="primary"
                            onClick={handleClose}
                        >
                            Skip
                        </Button>
                        <Button
                            disabled={isSubmitting}
                            className="btnSubmit #346fef"
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default GlobalInsuranceDealStatusLost;