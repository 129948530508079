import React, {useState} from "react";
import {styled} from "@material-ui/core/styles";
import {Button, Modal} from "@material-ui/core";
import {OpenAiIcon} from "./utils/icons";
import BasicInput from "../../custom/BasicInput";
import {saveOpenApiKey} from "./utils/helpers";

const StyledModal = styled(Modal)(({ theme })=>({
    "& .open-ai-wrapper": {
        position: "absolute",
        width: "600px",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        top: "50%",
        left: "50%",
        transform: `translate(-50%, -50%)`,
        borderRadius: "10px",

        "&:focus-visible": {
            outline: "none",
        }
    },
    "& .open-ai-header": {
        display: "flex",
        justifyContent: "center",
        padding: "16px",
        paddingBottom: "0px"
    },
    "& .open-ai-icon": {
        width: "75px",
        height: "75px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        border: "1px solid #ddd"
    },
    "& .open-ai-body": {
        display: "flex",
        justifyContent: "center",
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingTop: "16px",
        paddingBottom: "16px"
    },
    "& .open-ai-inner-body": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    "& .open-ai-body-header": {
        margin: "0px",
        fontWeight: "600",
        fontSize: "24px",
        lineHeight: "30px",
        letterSpacing: "-0.85px",
        textAlign: "center"
    },
    "& .open-ai-body-sub": {
        margin: "8px 0px 0px",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "150%",
        letterSpacing: "-0.25px",
        textAlign: "center"
    },
    "& .open-ai-input-label": {
        margin: "8px 0px",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "150%",
        letterSpacing: "-0.25px",
        textAlign: "center"
    },
    "& .open-ai-form": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "16px",
        width: "100%"
    },
    "& .open-ai-footer": {
        display: "flex",
        flexDirection: "column",
        width: "100%"
    },
    "& .open-ai-footer-hr": {
        margin: "0px",
        flexShrink: "0",
        borderWidth: "0px 0px thin",
        borderStyle: "solid",
        borderColor: "rgba(0, 0, 0, 0.12)"
    },
    "& .open-ai-footer-actions": {
        display: "flex",
        flexDirection: "row",
        padding: "16px",
        justifyContent: "flex-end",
        gap: "8px"
    },
    "& .open-ai-submit": {
        backgroundColor: "#3c7ef3",
        textTransform: "capitalize",
        "&.MuiButton-containedPrimary:hover": {
            backgroundColor: "#3c7ef3"
        }
    },
    "& .open-ai-cancel": {
        textTransform: "capitalize"
    }
}));

const OpenAiKeyPopup = ({ open, onClose, isUpdate, setIsUpdate }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [apiKey, setApiKey] = useState("");
    const [errorMessage,setErrorMessage] =useState("");
    const [errorModalOpen,setErrorModalOpen] = useState(false);

    const handleSubmit = async () => {
        if (apiKey) {
            try {
                setIsSubmitting(true);
                const resp = await saveOpenApiKey({ apiKey: apiKey });
                if (resp?.success){
                    setApiKey("");
                    setIsUpdate(false);
                    onClose();
                    window.showNotification("success", `${resp.message}`);
                }else {
                    setErrorMessage(resp?.data.message);
                    setErrorModalOpen(true);
                    window.showNotification("error", resp.message);
                }
            } catch (error) {
                console.error(error);
                window.showNotification("error", "Failed to save api key");
            }finally {
                setIsSubmitting(false);
            }
        }else {
            window.showNotification("error", "Api key is required");
        }
    };

    const handleChange = (event) => {
        setIsUpdate(false);
        setApiKey(event.target.value);
    };

    return(
        <StyledModal open={open} onClose={()=>{}}>
            <div className="open-ai-wrapper">
                <div className="open-ai-header">
                    <div className="open-ai-icon">
                        <OpenAiIcon/>
                    </div>
                </div>
                <div className="open-ai-body">
                    <div className="open-ai-inner-body">
                        <h5 className="open-ai-body-header">OpenAI Integration</h5>
                        <p className="open-ai-body-sub">
                            Visit <a href="https://platform.openai.com/account/api-keys" target="_blank">platform.openai.com/account/api-keys</a> to get your API Key.
                        </p>
                        <div className="open-ai-form">
                            <p className="open-ai-input-label">Enter Your OpenAI Api key</p>
                            <BasicInput
                                fullWidth
                                value={apiKey}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="open-ai-footer">
                    <hr className="open-ai-footer-hr"/>
                    <div className="open-ai-footer-actions">
                        <Button
                            className="open-ai-cancel"
                            color="inherit"
                            size="large"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="open-ai-submit"
                            disabled={isSubmitting}
                            color="primary"
                            variant="contained"
                            size="large"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </div>
        </StyledModal>
    );
};

export default OpenAiKeyPopup;