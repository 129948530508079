import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { DashboardModuleSkeleton } from './skeletonUI/DashboardModuleSkeleton';
import { DealModuleSkeleton } from './skeletonUI/DealModuleSkeleton';
import { InboxModuleSkeleton } from './skeletonUI/InboxModuleSkeleton';
import { ActivityCalendarModule } from './skeletonUI/ActivityCalendarModule';
import { ContactModuleSkeleton } from './skeletonUI/ContactModuleSkeleton';
import "./skeletonUI/SkeletonUI.css"
import { MapFinderModuleSkeleton } from './skeletonUI/MapFinderModuleSkeleton';
import { TriggerModuleSkeleton } from './skeletonUI/TriggerModuleSkeleton';
import { ReportModuleSkeleton } from './skeletonUI/ReportModuleSkeleton';
import { ProfileModuleSkeleton } from './skeletonUI/ProfileModuleSkeleton';
import MarketingModule from './skeletonUI/MarketingModule';
import Utils from './helpers/Utils';
import InsuranceDashboardSkeleton from './skeletonUI/InsuranceDashboardSkeleton';
import { AGENCY_BUSINESS_TYPE } from './helpers/CoreConstant';

export const ModuleSkeleton = ({ moduleName }) => {

    const defaultSkeleton = () => {
        let skeletons = [];
        for (let i = 0; i < 15; i++) {
            skeletons.push(
                <div key={i} className='default-skeleton d-flex'>
                    <Skeleton variant="rect" height={50} />
                </div>
            );
        }
        return skeletons;
    };

    const moduleLinkWithSkeleton = {
        default: defaultSkeleton(),
        DashboardModule: Utils.getAccountData("agencyBusinessType") !== AGENCY_BUSINESS_TYPE.INSURANCE ? <DashboardModuleSkeleton /> : <InsuranceDashboardSkeleton />,
        InboxModule: <InboxModuleSkeleton />,
        DealModule: <DealModuleSkeleton />,
        //CalendarModule:(<ActivityCalendarModule/>)||(<CalendarWidgetModule/>),
        CalendarModule: <ActivityCalendarModule />,
        ContactModule: <ContactModuleSkeleton />,
        MarketingModule: <MarketingModule />,
        MapFinderModule: <MapFinderModuleSkeleton />,
        TriggerModule: <TriggerModuleSkeleton />,
        ReportModule: <ReportModuleSkeleton />,
        ProfileModule: <ProfileModuleSkeleton />
    };

    const loadingSkeleton = () => {

        if (moduleLinkWithSkeleton[moduleName]) {
            return moduleLinkWithSkeleton[moduleName];
        }

        return moduleLinkWithSkeleton['default'];
    };

    return <>{loadingSkeleton()}</>;
};

