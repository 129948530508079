import { Skeleton } from "@material-ui/lab";
import React from "react";

export const ProfileModuleSkeleton = () => {
  return (
    <div className="profile-module-skeleton-parent">

      {Array.from(Array(3)).map((_, i) => (
        <div className="profile-module-child-one p-2 m-3" key={i}>
          <div className="pl-5">
            <Skeleton variant="text" width={150} height={50} />
          </div>
          <div className="d-flex gap-15 pl-4">
            {Array.from(Array(5)).map((_, i) => (
              <div style={{ width: "180px" }} className="p-4 my-3" key={i}>
                <div className="d-flex justify-content-center">
                  <Skeleton variant="circle" width={50} height={50} />
                </div>
                <div className="d-flex justify-content-center" >
                  <Skeleton variant="text" width={100} height={30} />
                </div>
              </div>
            ))}
          </div>

        </div>
      ))}

    </div>
  );
};
