import { FormControl, FormHelperText, InputBase, withStyles } from "@material-ui/core";
import React from "react";
import Styles from "./Styles.module.css";

const CustomInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
            color: "black !important",
            fontSize: "20px!important"
        },
    },
    input: {
        fontSize: "14px !important",
        height: "auto !important",
        color: "#707070 !important",
        borderRadius: "4px !important",
        position: "relative",
        border: "1px solid #ced4da!important",
        padding: "10px 12px !important",
        marginBottom: "0!important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            color: "#707070 !important",
            boxShadow: "none!important",
            borderColor: "black !important",
        },
        "&::placeholder": {
            fontSize: "11px !important"
        }
    },
}))(InputBase);

const GeneralInformation = ({ inputScrollToViewRef, formData, callback, errorCallback, a2pDlcError }) => {
    const handleFormDataChange = (name, value) => {
        if (name === "customer_name"){
            if (value.trim() === ""){
                errorCallback("customer_name","Legal Business Name is required!");
            }else{
                errorCallback("customer_name","");
            }
        }else if (name === "street"){
            if (value.trim() === ""){
                errorCallback("street","Street Address is required!");
            }else {
                errorCallback("street","");
            }
        }else if (name === "city"){
            if (value.trim() === ""){
                errorCallback("city","City is required!");
            }else {
                errorCallback("city","");
            }
        }else if (name === "region"){
            if (value.trim() === ""){
                errorCallback("region","State is required!");
            }else {
                errorCallback("region","");
            }
        }else if (name === "postal_code"){
            if (value.trim() === ""){
                errorCallback("postal_code","Postal/Zip Code is required!");
            }else {
                errorCallback("postal_code","");
            }
        }
        callback(name, value);
    };

    const renderErrorMessage = (fieldName) => {
        return <FormHelperText error>{a2pDlcError[fieldName]}</FormHelperText>;
    };

    return (
        <div className={Styles.contentWrapper}>
            <div className={Styles.contentHeader}>
                <h1 className={Styles.contentTitle}>Read before answering questions:</h1>
                <p className={Styles.contentDescription}>When completing this page please reference your official IRS documentation. (EIN documents, W2, W9, or CP575). Answer the questions carefully to ensure the info you provide matches your official IRS documents <u>EXACTLY</u>. Inaccuracies can lead to manual reviews and long delays in getting your business registered.</p>
            </div>
            <div className={Styles.contentBody}>
                <div className={Styles.contentBodyLeft}>
                    <FormControl ref={el => inputScrollToViewRef.current[0] = el} className={Styles.formControl}>
                      <span className={Styles.label}>
                        Legal Business Name
                        <span className={Styles.required}> *</span>
                      </span>
                        <CustomInput
                            placeholder="Enter your legal business name exactly as it appears on your IRS documents (Ex. Joe's Marketing Agency LLC rather than Joe's Marketing Agency."
                            name="legalBusinessName"
                            value={formData.customer_name}
                            onChange={(e)=>{handleFormDataChange("customer_name", e.target.value)}}
                        />
                        {renderErrorMessage("customer_name")}
                    </FormControl>
                    <FormControl ref={el => inputScrollToViewRef.current[1] = el} className={Styles.formControl}>
                      <span className={Styles.label}>
                        Street Address
                        <span className={Styles.required}> *</span>
                      </span>
                        <CustomInput
                            placeholder="Enter your business street address here. Must be a valid US address."
                            name="streetAddress"
                            value={formData.street}
                            onChange={(e)=>{handleFormDataChange("street", e.target.value)}}
                        />
                        {renderErrorMessage("street")}
                    </FormControl>
                    <FormControl ref={el => inputScrollToViewRef.current[2] = el} className={Styles.formControl}>
                      <span className={Styles.label}>
                        City
                        <span className={Styles.required}> *</span>
                      </span>
                        <CustomInput
                            placeholder="Enter your city name."
                            name="city"
                            value={formData.city}
                            onChange={(e)=>{handleFormDataChange("city", e.target.value)}}
                        />
                        {renderErrorMessage("city")}
                    </FormControl>
                    <FormControl ref={el => inputScrollToViewRef.current[3] = el} className={Styles.formControl}>
                      <span className={Styles.label}>
                        State
                        <span className={Styles.required}> *</span>
                      </span>
                        <CustomInput
                            placeholder="Enter your state name."
                            name="region"
                            value={formData.region}
                            onChange={(e)=>{handleFormDataChange("region", e.target.value)}}
                        />
                        {renderErrorMessage("region")}
                    </FormControl>
                    <FormControl ref={el => inputScrollToViewRef.current[4] = el} className={Styles.formControl}>
                      <span className={Styles.label}>
                        Postal/Zip Code
                        <span className={Styles.required}> *</span>
                      </span>
                        <CustomInput
                            placeholder="Enter your postal or zip code."
                            name="postal_code"
                            value={formData.postal_code}
                            onChange={(e)=>{handleFormDataChange("postal_code", e.target.value)}}
                        />
                        {renderErrorMessage("postal_code")}
                    </FormControl>
                </div>
            </div>
        </div>
    );
}

export default GeneralInformation;