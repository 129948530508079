import { CircularProgress } from '@material-ui/core';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { redirectNylasHosted } from '../../api/globalApi';
import './sharePage.css';

const NylasActivatedPage = () => {

    const history = useHistory();
    const codeObj = useLocation().search;
    const code = new URLSearchParams(codeObj).get('code');

    useEffect(() => {
        redirectNylasHosted({code : code}).then(res => {
            if(res.data.success) {
                window.showNotification('SUCCESS','You’ve successfully connected your email!');
            } else {
                window.showNotification('ERROR',res.data.message);
            }
            history.push('/user/profile/email-settings');
        });
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    return ( 
        <div className="share_page__wrapper trigger_share">
            <div className="share_page__inner_wrapper">
                {
                    <h5><CircularProgress/> Activating Email</h5>
                }
            </div>
        </div> 
    );
}
 
export default NylasActivatedPage;