import React, { useState, useEffect, useRef } from "react";
import moment from "moment-timezone";
import EmptyInbox from "./EmptyInbox";
import { fetchConversation, formBuilderAWSPreSignedUrl } from "./inboxApi";
import {
  storeConversation,
  storeLastContactedNumberId,
  updateSelectedContact,
} from "./inboxAction";
import Utils from "../Helpers/Utils";
import { connect } from "react-redux";
import CoreConstants from "./constants/CoreConstants";
import Skeleton from "@material-ui/lab/Skeleton";
import Helper from "../Helpers/Helper";
import axis from "axis.js";
import BootstrapTooltip from "../../common/BootstrapTooltip";
import { icons } from "../icons";
import PreviewImageFile from "./imageLoadComponent/PreviewImageFile";
import { Avatar } from "@material-ui/core";
import { RightSideStyle } from "../RightSide/RightSideStyle";
import "./inbox.css";

const getHtmlText = (message) => {
  let regix = /\@.*?\)/g; // eslint-disable-line
  let matchStrign = message.match(regix);
  if (!axis.isNull(matchStrign) && axis.isArray(matchStrign)) {
    matchStrign.map((str) => {
      // eslint-disable-line array-callback-return
      let eachStr = str.match(/@\[(.*)\]\(.*\)/);
      if (eachStr != null)
        message = message.replace(str, "<b>" + "@" + eachStr[1] + "</b>");
    });
  }
  return message.replace(/\n/g, "<br />");
};

const getErrorMessage = (contentType, error) => {
  if (contentType === CoreConstants.Timeline.CONTENT_TYPE_VOICE) {
    return (
      <div>
        <p style={{ fontWeight: "bold", textAlign: "center", margin: "2px" }}>
          Reason for Failure!
        </p>
        <p style={{ textAlign: "center", margin: "2px" }}>
          {error ? (
            <>
              <span style={{ fontWeight: "bold" }}>Reason :</span>{" "}
              {error.error_message}
            </>
          ) : (
            "Error"
          )}
        </p>
      </div>
    );
  } else if (
    contentType === CoreConstants.Timeline.CONTENT_TYPE_EMAIL ||
    contentType === CoreConstants.Timeline.CONTENT_TYPE_CHAT ||
    contentType === CoreConstants.Timeline.CONTENT_TYPE_SMS ||
    contentType === CoreConstants.Timeline.CONTENT_TYPE_MMS ||
    contentType === CoreConstants.Timeline.CONTENT_TYPE_VIDEO_EMAIL ||
    contentType === CoreConstants.Timeline.CONTENT_TYPE_POST_CARD ||
    contentType === CoreConstants.Timeline.CONTENT_TYPE_GREETINGS_CARD ||
    contentType === CoreConstants.Timeline.CONTENT_TYPE_LETTER ||
    contentType === CoreConstants.Timeline.CONTENT_TYPE_GIFT
  ) {
    return (
      <div>
        {error ? (
          <>
            <p
              style={{ fontWeight: "bold", textAlign: "center", margin: "2px" }}
            >
              <span> Message Failed!</span> Error Code: {error.error_code}
            </p>
            <p style={{ textAlign: "center", margin: "2px" }}>
              <span style={{ fontWeight: "bold" }}>Reason:</span>{" "}
              {error.error_message}
            </p>
          </>
        ) : (
          <>
            <p
              style={{ fontWeight: "bold", textAlign: "center", margin: "2px" }}
            >
              Error
            </p>
          </>
        )}
      </div>
    );
  } else {
    return (
      <div>
        {error ? (
          <>
            <p
              style={{ fontWeight: "bold", textAlign: "center", margin: "2px" }}
            >
              <span style={{ fontWeight: "bold" }}>Reason :</span>{" "}
              {error.error_message}
            </p>
          </>
        ) : (
          <p style={{ fontWeight: "bold", textAlign: "center", margin: "2px" }}>
            Error
          </p>
        )}
      </div>
    );
  }
};

const ConversationBox = (props) => {
  const conversationContainer = useRef(null);
  const [activeContact, setActiveContact] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [loadMore, setLoadMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  let count = 1;
  let scrollPosition = "";
  let lastScrollTop = 0;
  const { customScrollBar } = RightSideStyle();
  useEffect(() => {
    if (activeContact != null) {
      if (props.selectedContact?.id != activeContact.id) {
        setActiveContact(props.selectedContact);
        if (props.selectedContact?.id) {
          setPageNo(1);
          setLoadMore(false);
          setLoadingMore(false);
          fetchConversationData(props.selectedContact.id, false, 1, true);
        }
      }
    } else {
      setActiveContact(props?.selectedContact);
      if (props?.selectedContact?.id) {
        setPageNo(1);
        setLoadMore(false);
        setLoadingMore(false);
        fetchConversationData(props?.selectedContact.id, false, 1);
      }
    }
  }, [props.selectedContact]);

  const getContactEmail = () => {
    let contact = props.selectedContact;
    if (contact.email !== undefined && contact.email !== null) {
      return contact.email;
    }
    return "";
  };

  const sendEmailToThread = (threadData) => {
    if (window.sendCommunication !== undefined) {
      window.sendCommunication(
        {
          open: true,
          onClose: () => {},
          id: props.selectedContact.id,
          from: "inbox",
          primaryEmail: getContactEmail(),
          fetchContactDetails: false,
          threadData: threadData,
          sendSuccessCallBack: (response) => {
            if (response.conversation !== undefined) {
              window.appendConversation(response.conversation);
            }
          },
        },
        "email"
      );
    }
  };

  window.refreshConersation = (contact_id = null) => {
    console.log("from param ", contact_id);
    console.log("active contact id ", activeContact.id);
    console.log("from props ", props.selectedContact.id);

    if (contact_id) {
      if (contact_id != activeContact.id) {
        return;
      }
    }
    setPageNo(1);
    setLoadMore(false);
    setLoadingMore(false);
    fetchConversationData(props.selectedContact.id, false, 1);
  };

  const fetchConversationData = async (
    conact_id,
    moreLoading = false,
    page,
    callApi = false
  ) => {
    if (loading && !callApi) {
      return false;
    }
    if (!moreLoading) {
      setLoading(true);
    }
    await fetchConversation({ contact_id: conact_id, page_no: page })
      .then((response) => {
        if (page === 1) {
          // setConversations(response.data.data.reverse())
          setConversations(response.data.data);
          try {
            let lengths = response.data.data.length;
            if (lengths > 0) {
              for (let i = lengths - 1; i >= 0; i--) {
                if (
                  response.data.data[i].virtual_number !== undefined &&
                  response.data.data[i].virtual_number != null &&
                  response.data.data[i].virtual_number.id !== undefined &&
                  response.data.data[i].virtual_number.id != null
                ) {
                  props.storeLastContactedNumberId(
                    response.data.data[i].virtual_number.id
                  );
                  break;
                }
              }
            }
          } catch (e) {
            console.log("VN select error");
          }
        } else {
          // setConversations([...response.data.data.reverse(), ...conversations]);
          setConversations([...response.data.data, ...conversations]);
        }
        // props.storeConversation(response.data.data.reverse());
        props.storeConversation(response.data.data);

        let newResLen = response.data.data.length;
        if (newResLen < 10) {
          setLoadMore(false);
        } else {
          setLoadMore(true);
          setPageNo(page + 1);
        }
        setLoading(false);
        if (!moreLoading) {
          if (conversationContainer.current) {
            conversationContainer.current.scrollTop =
              conversationContainer.current.scrollHeight;
          }
        } else {
          if (page > 1) {
            document
              .getElementById("customClass" + 5)
              ?.scrollIntoView({ behavior: "smooth" });
          }
        }
        setLoadingMore(false);
        if (window.updateNotificationCount !== undefined) {
          window.updateNotificationCount(false, true);
        }
      })
      .catch((error) => {
        Utils.handleException(error);
        setLoading(false);
        setLoadingMore(false);
      });
  };

  window.appendConversation = (newConversation) => {
    setConversations([...conversations, newConversation]);
    if (conversationContainer.current) {
      conversationContainer.current.scrollTop =
        conversationContainer.current.scrollHeight;
    }
  };

  const getContentTypeIcon = (contentType, message = "") => {
    if (contentType === CoreConstants.inboxThread.CONTENT_TYPE_SMS) {
      return "sms";
    } else if (contentType === CoreConstants.inboxThread.CONTENT_TYPE_EMAIL) {
      return "mail";
    } else if (contentType === CoreConstants.inboxThread.CONTENT_TYPE_MMS) {
      return "sms";
    } else if (contentType === CoreConstants.inboxThread.CONTENT_TYPE_CALL) {
      if (message === "Missed Call") {
        return "phone_missed";
      }
      return "call";
      // return 'voicemail';
    } else if (
      contentType === CoreConstants.inboxThread.CONTENT_TYPE_VOICE_MAIL
    ) {
      return "voicemail";
    }
  };

  const showFormResponse = (message) => {
    let messageValues = JSON.parse(message);
    let formValues = "";
    for (const [key, value] of Object.entries(messageValues)) {
      formValues += `${key}: ${value}<br />`;
    }
    return formValues;
  };

  const showAttachments = (timeline) => {
    let messageUrl = "";
    try {
      messageUrl = !Helper.isEmpty(timeline.message_url)
        ? timeline.message_url
        : "";
      if (messageUrl.includes("[") && messageUrl.includes("]")) {
        messageUrl = messageUrl.replace("[", "");
        messageUrl = messageUrl.replace("]", "");
        messageUrl = messageUrl.split(",");
      }
    } catch (e) {}

    return (
      <>
        {!Helper.isEmpty(messageUrl) &&
          Array.isArray(messageUrl) &&
          messageUrl.length > 0 &&
          messageUrl[0].trim() !== "" && (
            <div className="email-attachments-inboxmodule">
              <p className="no-margin-p">Attachments : </p>
              <ul>
                {messageUrl.map((item, index) => {
                  return (
                    <li>
                      <a
                        href={item.trim()}
                        rel="noreferrer"
                        className="text-decoration-none"
                        target="_blank"
                      >{`attachment(${index + 1})`}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
      </>
    );
  };

  const renderAppointment = (data) => {
    let message_body = JSON.parse(data.message);
    return (
      <div className="each_conversation_appointment_container">
        <div className="each_conversation_appointment_title">
          <p>New Appointment Booked</p>
        </div>
        <div className="each_conversation_appointment_body">
          <div className="each_conversation_appointment_date">
            <p>
              <span className="each_conversation_appointment_date__name">
                Date:
              </span>{" "}
              {window.globalTimezoneConversionToDifferentTimezone(
                message_body["date"],
                "UTC",
                "",
                "MM-DD-yyyy"
              )}
            </p>
            <p>
              <span className="each_conversation_appointment_date__name">
                Time:
              </span>{" "}
              :{" "}
              {moment(message_body["date"] + " " + message_body["time"]).format(
                "hh:mm:ss A"
              )}
            </p>
            <p>
              <span className="each_conversation_appointment_date__name">
                Duration:
              </span>{" "}
              : {message_body["duration"]}
            </p>
          </div>
          <div className="each_conversation_appointment_with">
            <p>
              Have a{" "}
              <span>
                <strong> {message_body["appointment_title"]}</strong>
              </span>
              with{" "}
              <span>
                <strong>{message_body["with_whom_title"]}</strong>
              </span>
              {message_body["with_whom_id"] == Utils.getAccountData("userId") &&
                "(you)"}
            </p>
          </div>
          <div className="each_conversation_appointment_contact">
            <div>
              <span className="earch__conversation__appointment__name">
                Name:{" "}
              </span>
              <span>{message_body["contact_name"]}</span>
            </div>
            <div>
              <span className="earch__conversation__appointment__name">
                Email:{" "}
              </span>
              <span>{message_body["contact_email"]}</span>
            </div>
            <div>
              <span className="earch__conversation__appointment__name">
                Number:{" "}
              </span>
              <span>{message_body["contact_number"]}</span>
            </div>
            <div>
              <span className="earch__conversation__appointment__name">
                Timezone:{" "}
              </span>
              <span>{message_body["timezone"]}</span>
            </div>
            {message_body["location"] !== null && (
              <div>
                <span className="earch__conversation__appointment__name">
                  Location:{" "}
                </span>
                <span>{message_body["location"]}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const showToNumberOrEmnail = (data) => {
    try {
      const modifyData = data.replace(/\\/g, "");
      const obj = JSON.parse(modifyData);
      if (obj.to !== undefined) {
        return (
          <p
            style={{
              margin: 0,
              fontSize: "14px",
              fontWeight: 500,
              marginBottom: "5px",
              color: "#7d7b7b",
            }}
          >
            Sent to: {obj.to}
          </p>
        );
      }
    } catch (error) {}
    return "";
  };

  const processFileUrl = (messageUrl) => {
    let fileDataOutput = [];
    try {
      let fileData = JSON.parse(messageUrl);
      fileDataOutput.push(
        <label style={{ fontSize: "18px" }}>Attachments: </label>
      );
      for (let i = 0; i < fileData.length; i++) {
        fileDataOutput.push(
          <p>
            <a href={fileData[i].file_url} className="cursor" target="_blank">
              {fileData[i].file_name}
            </a>
          </p>
        );
      }
    } catch (error) {
      console.log(error);
    }

    return fileDataOutput;
  };

  const renderMessageText = (type, message) => {
    // eslint-disable-next-line eqeqeq
    if (type == CoreConstants.Timeline.CONTENT_TYPE_NOTE) {
      return (
        <p
          class=""
          dangerouslySetInnerHTML={{ __html: getHtmlText(message) }}
        />
      );
    } else if (type == CoreConstants.Timeline.CONTENT_TYPE_EMAIL) {
      return <ViewEmail message={message} />;
    } else {
      let styleData = 'type="text/css">';
      let messageBody = message ? message : "";

      let iof = messageBody.indexOf(styleData);
      try {
        if (iof > 0) {
          do {
            let anotherof = messageBody.indexOf("</style>", iof);
            if (anotherof > 0) {
              messageBody =
                messageBody.slice(0, iof) + " " + messageBody.slice(anotherof);
            }
            iof = messageBody.indexOf(styleData);
          } while (iof > 0);
        }
      } catch (e) {
        console.log(e);
        messageBody = "";
      }

      return (
        <div
          style={{ wordWrap: "break-word" }}
          className=" "
          dangerouslySetInnerHTML={{ __html: messageBody }}
        />
      );
    }
  };

  const replaceHelper = (text) => {
    let check_head = text.match(/(?<=<head>)(.*)(?=<\/head>)/s);
    if (check_head != null) {
      let regex = new RegExp(check_head[0], "g");
      text = text.replace(regex, "");
      text = text.replace(/<head><\/head>/, "");
    }

    let check_style = text.match(/(?<=<style>)(.*)(?=<\/style>)/s);
    if (check_style != null) {
      let regex = new RegExp(check_style[0], "g");
      text = text.replace(regex, "");
      text = text.replace(/<style><\/style>/, "");
    }

    check_style = text.match(/(?<=style type="text\/css">)(.*)(?=<\/style>)/s);
    if (check_style != null) {
      let regex = new RegExp(check_style[0], "g");
      text = text.replace(regex, "");
      text = text.replace(/<style type="text\/css"><\/style>/, "");
    }

    check_style = text.match(/(?<=style>)(.*)(?=<\/style>)/s);
    if (check_style != null) {
      let regex = new RegExp(check_style[0], "g");
      text = text.replace(regex, "");
      text = text.replace(/<style type="text\/css"><\/style>/, "");
    }

    let check_script = text.match(/(?<=<script>)(.*)(?=<\/script>)/s);
    if (check_script != null) {
      let regex = new RegExp(check_script[0], "g");
      text = text.replace(regex, "");
      text = text.replace(/<script><\/script>/, "");
    }
    text = text.replace(/\n/g, "");
    return text;
  };

  const replaceAllHtmlTag = (text) => {
    let styleData = 'type="text/css">';
    let messageBody = text ? text : "";

    let iof = messageBody.indexOf(styleData);
    try {
      if (iof > 0) {
        do {
          let anotherof = messageBody.indexOf("</style>", iof);
          if (anotherof > 0) {
            messageBody =
              messageBody.slice(0, iof) + " " + messageBody.slice(anotherof);
          }
          iof = messageBody.indexOf(styleData);
        } while (iof > 0);
      }
    } catch (e) {
      console.log(e);
      messageBody = "";
    }

    return messageBody;
  };

  const submitPreSignedUrl = (payload) => {
    formBuilderAWSPreSignedUrl(payload).then((res) => {
      if (res.status == 200 && res.data) {
        let data = res.data;
        let bucketUrl = JSON.parse(data.bucketUrl);
        if (bucketUrl[0]) {
          window.open(bucketUrl[0].bucketUrl, "_blank");
        }
      }
    });
  };

  const smartFormResponseHtml = (response) => {
    let formResponse = JSON.parse(response);
    let messageBody = [];

    try {
      if (formResponse.submission) {
        formResponse.submission.forEach((item, index) => {
          const innerMessageBody = [];
          if (
            item.value &&
            item.type !=
              CoreConstants.FORM_BUILDER_V3_COMPONENT_TYPE.HIDDEN_FIELD
          ) {
            innerMessageBody.push(
              <span
                dangerouslySetInnerHTML={{ __html: Utils.urlify(item.value) }}
              />
            );
          } else if (item.firstName) {
            if (item.middleName) {
              innerMessageBody.push(
                <span>
                  {item.firstName + " " + item.middleName + " " + item.lastName}
                </span>
              );
            } else {
              innerMessageBody.push(
                <span>{item.firstName + " " + item.lastName}</span>
              );
            }
          } else if (item.city || item.zip || item.state) {
            if (item.streetAddress1) {
              innerMessageBody.push(<span>{item.streetAddress1}</span>);
              if (item.streetAddress2 || item.city || item.state || item.zip) {
                innerMessageBody.push(<span>, </span>);
              }
            }
            if (item.streetAddress2) {
              innerMessageBody.push(<span>{item.streetAddress2}</span>);
              if (item.city || item.state || item.zip) {
                innerMessageBody.push(<span>, </span>);
              }
            }
            if (item.city) {
              innerMessageBody.push(<span>{item.city}</span>);
              if (item.state || item.zip) {
                innerMessageBody.push(<span>, </span>);
              }
            }
            if (item.state) {
              innerMessageBody.push(<span>{item.state}</span>);
              if (item.zip) {
                innerMessageBody.push(<span>, </span>);
              }
            }
            if (item.zip) {
              innerMessageBody.push(<span>{item.zip}</span>);
            }
          } else if (item.files) {
            const fileMessageBody = [];
            const files = item.files;
            if (files && files.length > 0) {
              files.forEach((file, index) => {
                let payload = {
                  fileId: [file.id],
                  action: 0, // 0 for form file view from s3 pre-signed
                  formApiKey: formResponse.apiKey,
                  agencyId: formResponse.agencyId,
                };
                fileMessageBody.push(
                  <div>
                    <span
                      onClick={() => {
                        submitPreSignedUrl(payload);
                      }}
                      style={{ color: "#6631e7", cursor: "pointer" }}
                    >
                      {file.name}
                    </span>
                  </div>
                );
              });
            }
            innerMessageBody.push(<span>{fileMessageBody}</span>);
          }

          messageBody.push(
            <div>
              {item.type !=
                CoreConstants.FORM_BUILDER_V3_COMPONENT_TYPE.HIDDEN_FIELD && (
                <div style={{ fontWeight: 700, marginTop: "8px" }}>
                  {item.label}
                </div>
              )}
              <div style={{ marginBottom: "8px" }}>{innerMessageBody}</div>
              {index !== formResponse.submission.length - 1 && <hr />}
            </div>
          );
        });
      }
    } catch (e) {
      messageBody = [];
    }

    return messageBody;
  };

  const renderDirectMailView = (row) => {
    const cardPdf = row.file_url;
    let front_image =
      row.template_thumbnail_path !== undefined &&
      row.template_thumbnail_path != null &&
      row.template_thumbnail_path !== ""
        ? row.template_thumbnail_path
        : "";
    if (
      cardPdf.split(".").pop() === "pdf" ||
      cardPdf.split(".").pop() === "PDF"
    ) {
      return (
        <iframe
          src={row.file_url}
          frameborder="0"
          title="PDF"
          loading={"eager"}
          name={"PDF"}
          allowFullScreen
        ></iframe>
      );
    } else {
      return (
        <div class="inbox__timeline__card__view__v3">
          <div style={{ display: "flex", flexDirection: "column" }}>
            {front_image !== "" && (
              <img
                alt={front_image}
                src={front_image}
                height={200}
                width={200}
                style={{ marginBottom: "5px", marginTop: "5px" }}
              />
            )}
            <img
              alt={cardPdf}
              src={cardPdf}
              height={200}
              width={200}
              style={{
                background:
                  row.content_type ===
                  CoreConstants.Timeline.CONTENT_TYPE_GREETINGS_CARD
                    ? "#fff"
                    : "none",
              }}
            />
          </div>
        </div>
      );
    }
  };

  const renderConversation = () => {
    let user_image = Utils.getAccountData("profileImage");
    let user_name = Utils.getAccountData("fullName");
    let contactConversations = [];
    if (conversations.length) {
      let todayDate = moment().tz(props.user.timezone).format("Y-MM-D");
      let currentDate = "";
      let timelineDate = "";
      let newDate = true;
      let mainDate = "";

      conversations.map((row, index) => {
        if (row !== undefined) {
          if (row.scroll_render_id != undefined) {
            return <div id={row.scroll_render_id} className="d-none"></div>;
          }
          if (typeof row.created_at !== "undefined") {
            // timelineDate = moment(row.local_created_at.date_time).tz(props.user.timezone).format('Y-MM-D')
            timelineDate = window.globalTimezoneConversionToDifferentTimezone(
              row.created_at,
              "UTC",
              props.user.timezone,
              "Y-MM-D"
            );
            if (currentDate != timelineDate) {
              currentDate = timelineDate;
              newDate = true;
            } else {
              newDate = false;
            }

            mainDate =
              todayDate == currentDate
                ? "Today"
                : moment(currentDate).format("ll");

            let contentType = getContentTypeIcon(row.content_type, row.message);

            contactConversations.push(
              <React.Fragment>
                {row.in_out == CoreConstants.Timeline.INOUT_TYPE_IN && (
                  <div
                    className="col l11 offset-l0 reply-messages p-relative mt-4"
                    id={"customClass" + index}
                    data-date={
                      typeof row.local_created_at.date_time !== "undefined"
                        ? moment(row.local_created_at.date_time).format("ll")
                        : ""
                    }
                  >
                    {newDate && (
                      <div className="" style={{ position: "relative" }}>
                        <div
                          style={{
                            width: "100%",
                            height: "1px",
                            background: "lightgray",
                          }}
                        ></div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            position: "absolute",
                            left: 0,
                            right: 0,
                            top: "-11px",
                          }}
                        >
                          <div
                            className=""
                            style={{
                              width: "150px",
                              background: "#E5E8EF",
                              display: "flex",
                              justifyContent: "center",
                              borderRadius: "20px",
                            }}
                          >
                            {mainDate}
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className="d-flex align-items-end"
                      style={{
                        gap: "24px",
                        marginTop: "32px",
                        paddingLeft: "10px",
                      }}
                    >
                      <div className="user-short-name">
                        <Avatar
                          style={{
                            background: "#133159",
                            height: "50px",
                            width: "50px",
                          }}
                        >
                          {Utils.getInitial(
                            activeContact.first_name +
                              " " +
                              activeContact.last_name
                          )}
                        </Avatar>
                      </div>
                      <div className="chat__text-wrap">
                        {row.nylas_thread_id && (
                          <div
                            className="chat__reply"
                            title="Reply"
                            onClick={() => {
                              sendEmailToThread({
                                message_subject: row.message_subject,
                                from: row.from,
                              });
                            }}
                          >
                            {icons.reply}
                            <span>Reply</span>
                          </div>
                        )}
                        <div
                          className={` inbox-chat-text ${
                            row.content_type ===
                            CoreConstants.Timeline
                              .CONTENT_TYPE_SMART_FORM_RESPONSE
                              ? "inbox-chat-text-rm-padding"
                              : ""
                          }`}
                          style={{fontSize:'16px !important'}}
                        >
                          {row.content_type ==
                          CoreConstants.Timeline.CONTENT_TYPE_APPOINTMENT ? (
                            <>
                              {Utils.isJsonString(row.message) ? (
                                renderAppointment(row)
                              ) : (
                                <p
                                  className="S"
                                  dangerouslySetInnerHTML={{
                                    __html: row.message,
                                  }}
                                />
                              )}
                            </>
                          ) : (
                            <>
                              {row.content_type ==
                                CoreConstants.Timeline
                                  .CONTENT_TYPE_SMART_FORM_RESPONSE && (
                                <div>
                                  <p className="accent--bg--text--color inbox-chat-text-header">
                                    <span className="inbox-chat-message" >
                                      {row.message_subject}
                                    </span>
                                  </p>
                                  <div className=" inbox-chat-text-email">
                                    {smartFormResponseHtml(row.message)}
                                  </div>
                                </div>
                              )}
                              {row.message_url != undefined &&
                                row.message_url != null &&
                                (row.content_type ==
                                  CoreConstants.Timeline.CONTENT_TYPE_VOICE ||
                                  row.content_type ==
                                    CoreConstants.Timeline
                                      .CONTENT_TYPE_CALL) && (
                                  <>
                                    {row.content_type ==
                                      CoreConstants.Timeline
                                        .CONTENT_TYPE_VOICE && (
                                      <p>New Voicemail Message!</p>
                                    )}
                                    {row.content_type ==
                                      CoreConstants.Timeline
                                        .CONTENT_TYPE_CALL &&
                                      (row.message === "Missed Call" ? (
                                        <p>You have a Missed Call</p>
                                      ) : (
                                        <p>Incoming Call</p>
                                      ))}
                                    <audio controls src={row.message_url} />
                                  </>
                                )}
                              {row.message_url !== undefined &&
                                row.message_url == null &&
                                (row.content_type ==
                                  CoreConstants.Timeline.CONTENT_TYPE_VOICE ||
                                  row.content_type ==
                                    CoreConstants.Timeline
                                      .CONTENT_TYPE_CALL) && (
                                  <>
                                    {row.content_type ==
                                      CoreConstants.Timeline
                                        .CONTENT_TYPE_VOICE && (
                                      <p>New Voicemail Message!</p>
                                    )}
                                    {row.content_type ==
                                      CoreConstants.Timeline
                                        .CONTENT_TYPE_CALL &&
                                      row.message === "Missed Call" && (
                                        <p>You have a Missed Call</p>
                                      )}
                                    {row.content_type ==
                                      CoreConstants.Timeline
                                        .CONTENT_TYPE_CALL &&
                                      row.message !== "Missed Call" && (
                                        <p>Incoming Call</p>
                                      )}
                                  </>
                                )}

                              {row.content_type ===
                                CoreConstants.Timeline.CONTENT_TYPE_EMAIL && (
                                <p style={{fontSize:'16px'}}
                                  className=" "
                                  dangerouslySetInnerHTML={{
                                    __html: replaceAllHtmlTag(
                                      row.message_subject
                                    ),
                                  }}
                                />
                              )}

                              {row.content_type !==
                                CoreConstants.Timeline.CONTENT_TYPE_EMAIL &&
                                row.content_type !=
                                  CoreConstants.Timeline.CONTENT_TYPE_VOICE &&
                                row.content_type !=
                                  CoreConstants.Timeline
                                    .CONTENT_TYPE_FORM_RESPONSE &&
                                row.content_type !=
                                  CoreConstants.Timeline.CONTENT_TYPE_CALL &&
                                row.content_type !=
                                  CoreConstants.Timeline
                                    .CONTENT_TYPE_SMART_FORM_RESPONSE && (
                                  <p
                                    className=" "
                                    dangerouslySetInnerHTML={{
                                      __html: replaceAllHtmlTag(row.message),
                                    }}
                                  />
                                )}
                              {row.content_type ===
                                CoreConstants.Timeline.CONTENT_TYPE_EMAIL && (
                                <ViewEmail message={row.message} />
                              )}
                              {row.content_type ===
                                CoreConstants.Timeline.CONTENT_TYPE_EMAIL &&
                                showAttachments(row)}

                              {parseInt(row.content_type) ===
                                CoreConstants.Timeline
                                  .CONTENT_TYPE_FORM_RESPONSE && (
                                <div>
                                  <p>
                                    A form submission from{" "}
                                    <a
                                      target="_blank"
                                      href={"https://" + row.message_url}
                                    >
                                      {row.message_subject}
                                    </a>
                                  </p>
                                  <hr />
                                  <p
                                    className=" "
                                    dangerouslySetInnerHTML={{
                                      __html: showFormResponse(row.message),
                                    }}
                                  />
                                </div>
                              )}
                              {row.message_url != null &&
                                row.content_type ==
                                  CoreConstants.Timeline.CONTENT_TYPE_MMS && (
                                  <div>
                                    <PreviewImageFile link={row.message_url} />
                                  </div>
                                )}
                            </>
                          )}
                        </div>
                        <div className="activation-time mt-2">
                          <span
                            className="left"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {typeof row.created_at !== "undefined"
                              ? window.globalTimezoneConversionToDifferentTimezone(
                                  row.created_at,
                                  "UTC",
                                  props.user.timezone,
                                  "ll h:mm A"
                                )
                              : ""}
                            <i
                              class="material-icons bg-icons"
                              style={{
                                marginLeft: 10,
                                fontSize: "15px",
                                color: "#F2994A",
                                marginRight:'8px'
                              }}
                            >
                              {contentType}
                            </i>
                            {row.virtual_number !== undefined &&
                              row.virtual_number != null &&
                              row.virtual_number.virtual_number !== undefined &&
                              row.virtual_number.virtual_number != null && (
                                <p style={{ margin: 0 }}>
                                  sent to : {row.virtual_number.virtual_number}
                                </p>
                              )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {row.in_out == CoreConstants.Timeline.INOUT_TYPE_OUT && (
                  <div
                    className="col l11 offset-l2 text-messages p-relative mt-4 p-0"
                    id={"customClass" + index}
                    data-date={
                      typeof row.local_created_at.date_time !== "undefined"
                        ? moment(row.local_created_at.date_time).format("ll")
                        : ""
                    }
                  >
                    {newDate && (
                      <div className="" style={{ position: "relative" }}>
                        <div
                          style={{
                            width: "100%",
                            height: "1px",
                            background: "lightgray",
                          }}
                        ></div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            position: "absolute",
                            left: 0,
                            right: 0,
                            top: "-11px",
                          }}
                        >
                          <div
                            className=""
                            style={{
                              width: "150px",
                              background: "#E5E8EF",
                              display: "flex",
                              justifyContent: "center",
                              borderRadius: "20px",
                            }}
                          >
                            {mainDate}
                          </div>
                        </div>
                      </div>
                    )}
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <div
                        className="d-flex"
                        style={{
                          background: "",
                          paddingBottom: "16px",
                          gap: "24px",
                          marginTop: "32px",
                        }}
                      >
                        <div className="">
                          <div
                            style={{
                              background: "rgba(60, 126, 243, 0.1)",
                              maxWidth: "500px",
                              minHeight: "40px",
                              borderRadius: "10px",
                              padding: "8px 16px",
                              fontSize:'16px'
                            }}
                            className={` d-block  ${
                              row.content_type ==
                              CoreConstants.Timeline.CONTENT_TYPE_NOTE
                                ? "inbox-note-bg"
                                : ""
                            }`}
                          >
                            {showToNumberOrEmnail(row.user_info)}

                            {row.message_url != null &&
                              (row.content_type ==
                                CoreConstants.Timeline.CONTENT_TYPE_VOICE ||
                                row.content_type ==
                                  CoreConstants.Timeline.CONTENT_TYPE_CALL) && (
                                <>
                                  {(row.content_type ==
                                    CoreConstants.Timeline.CONTENT_TYPE_VOICE ||
                                    row.content_type ==
                                      CoreConstants.Timeline
                                        .CONTENT_TYPE_CALL) && (
                                    <p>
                                      {row.content_type ==
                                      CoreConstants.Timeline.CONTENT_TYPE_VOICE
                                        ? "You sent a ringless voicemail!"
                                        : "Outgoing Call"}
                                    </p>
                                  )}
                                  <audio controls src={row.message_url} />
                                </>
                              )}
                            {(row.content_type ==
                              CoreConstants.Timeline.CONTENT_TYPE_EMAIL ||
                              row.content_type ==
                                CoreConstants.Timeline
                                  .CONTENT_TYPE_VIDEO_EMAIL) && (
                              <p>Subject: {row.message_subject}</p>
                            )}

                            {row.content_type !=
                              CoreConstants.Timeline.CONTENT_TYPE_VOICE &&
                              row.content_type !=
                                CoreConstants.Timeline.CONTENT_TYPE_CALL &&
                              renderMessageText(row.content_type, row.message)}
                            {row.content_type ==
                              CoreConstants.Timeline.CONTENT_TYPE_VIDEO_EMAIL &&
                              row.video_link != null && (
                                <video width="225" height="180" controls>
                                  <source
                                    src={row.video_link}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              )}
                            {row.message_url != null &&
                              row.content_type ==
                                CoreConstants.Timeline.CONTENT_TYPE_MMS && (
                                <img
                                  src={row.message_url}
                                  style={{ width: 200, height: 200 }}
                                  alt=""
                                />
                              )}

                            {row.file_url != null &&
                              row.file_url !== "" &&
                              (row.content_type ==
                                CoreConstants.Timeline.CONTENT_TYPE_POST_CARD ||
                                row.content_type ==
                                  CoreConstants.Timeline
                                    .CONTENT_TYPE_GREETINGS_CARD ||
                                row.content_type ==
                                  CoreConstants.Timeline.CONTENT_TYPE_GIFT ||
                                row.content_type ==
                                  CoreConstants.Timeline.CONTENT_TYPE_LETTER) &&
                              renderDirectMailView(row)}
                            {((row.content_type ==
                              CoreConstants.Timeline.CONTENT_TYPE_EMAIL &&
                              row.message_url != null) ||
                              (row.content_type ==
                                CoreConstants.Timeline
                                  .CONTENT_TYPE_VIDEO_EMAIL &&
                                row.message_url != null)) &&
                              processFileUrl(row.message_url)}
                          </div>
                          <div
                            className="mt-2"
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <i
                                class="material-icons bg-icons"
                                style={{ fontSize: "15px", color: "#27AE60" }}
                              >
                                {contentType}
                              </i>

                              {typeof row.created_at !== "undefined"
                                ? window.globalTimezoneConversionToDifferentTimezone(
                                    row.created_at,
                                    "UTC",
                                    props.user.timezone,
                                    "ll h:mm A"
                                  )
                                : ""}
                              {(row.status ===
                                CoreConstants.Timeline.STATUS_PENDING ||
                                row.status ===
                                  CoreConstants.Timeline.STATUS_FAIL ||
                                row.status ===
                                  CoreConstants.Timeline.STATUS_UNDELIVERED) &&
                              row.error_message ? (
                                <BootstrapTooltip
                                  arrow
                                  placement={"top"}
                                  title={getErrorMessage(
                                    parseInt(row.content_type),
                                    row.error_message ?? null
                                  )}
                                >
                                  <span>{icons.warningIcon}</span>
                                </BootstrapTooltip>
                              ) : (
                                icons.checkMarkIcon
                              )}
                            </span>
                            <div
                            className=""
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            {row.virtual_number !== undefined &&
                              row.virtual_number != null &&
                              row.virtual_number.virtual_number !== undefined &&
                              row.virtual_number.virtual_number != null && (
                                <p style={{ margin: 0 }}>
                                  sent from :{" "}
                                  {row.virtual_number.virtual_number}
                                </p>
                              )}
                          </div>
                          </div>
                         
                        </div>

                        <div
                          className=""
                          style={{ display: "flex", alignItems: "end" }}
                        >
                          {user_image !== undefined &&
                          user_image != null &&
                          user_image.trim() !== "" ? (
                            <img
                              style={{ width: "50px",height:'50px', borderRadius: "50%" }}
                              className="responsive-img__alt__inbox"
                              src={user_image}
                              alt=""
                            />
                          ) : (
                            <span className="responsive-img__alt__inbox">
                              {Utils.getInitial(user_name)}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            );
          }
        }
      });

      return contactConversations;
    } else {
      return <EmptyInbox />;
    }
  };

  const scrollConversation = (e) => {
    if (activeContact === null || activeContact.id === undefined) {
      return;
    }
    if (loadingMore) {
      return;
    }
    if (e.target.scrollTop < 10) {
      if (loadMore) {
        setLoadingMore(true);
        fetchConversationData(props.selectedContact.id, true, pageNo);
      }
    }
  };

  const loadingSkeleton = () => {
    let skeletons = [];
    for (let i = 0; i < 5; i++) {
      skeletons.push(
        <div
          key={i}
          style={{paddingLeft:'16px'}}
          className={`skeleton_each_conversation ${
            i % 2 === 0 ? "skeleton_right" : "skeleton_left"
          }`}
        >
          <Skeleton
            className="skeleton_conversation_avater"
            variant="circle"
            width={50}
            height={50}
          />
          <div className="skeleton_conversation_details" style={{marginTop:'6px'}}>
            <Skeleton variant="rect" width={300} height={80} />
            <Skeleton variant="text" width={200} />
            <Skeleton variant="text" width={200} />
          </div>
        </div>
      );
    }
    return skeletons;
  };

  return (
    <div
      style={{ paddingRight: "4px" }}
      ref={conversationContainer}
      className={`chatbox-area inbox-conv-wrap inbox-email-active-1 ${
        props.bottomBoxVisible
          ? " props.tabOptionInBottomBox"
          : "full_area_chat_box"
      }`}
      id="cutomScrollbar2"
      onScroll={(e) => scrollConversation(e)}
    >
      {loading && loadingSkeleton()}
      {!loading && (
        <React.Fragment>
          {loadingMore && (
            <div className={"moreLoadingInbox"}>
              <span>Loading more data...</span>
            </div>
          )}
          {renderConversation()}
        </React.Fragment>
      )}
    </div>
  );
};

const ViewEmail = (props) => {
  const [sanitizedHtml, setSanitizedHtml] = useState("");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // Sanitize and render the HTML content with dangerouslySetInnerHTML
      const sanitizedMessage = replaceAllHtmlTag(props.message);
      setSanitizedHtml(sanitizedMessage);
    }, 100); // Adjust the timeout value as needed
    return () => clearTimeout(timeoutId);
  }, [props.message]);

  const replaceAllHtmlTag = (message) => {
    let messageBody = message ?? "";

    // Remove <style> tags and their content
    messageBody = messageBody.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, "");

    // Replace all instances of 'div' and 'html' with '.contact_timeline_email_body div' and '.contact_timeline_email_body html'
    messageBody = messageBody.replace(
      /<div/gi,
      '<div class="contact_timeline_email_body div"'
    );
    messageBody = messageBody.replace(
      /<html/gi,
      '<html class="contact_timeline_email_body html"'
    );

    return messageBody;
  };

  return (
    <div>
      <p
        style={{ wordBreak: "break-word",fontSize:'16px' }}
        className=" contact_timeline_email_body"
        dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedContact: state.inboxReducer.selectedContact,
    user: state.inboxReducer.user,
    bottomBoxVisible: state.inboxReducer.bottomBoxVisible,
    tabOptionInBottomBox: state.inboxReducer.tabOptionInBottomBox,
    isShowSendOption: state.inboxReducer.isShowSendOption,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    storeConversation: (params) => dispatch(storeConversation(params)),
    updateSelectedContact: (params) => dispatch(updateSelectedContact(params)),
    storeLastContactedNumberId: (params) => dispatch(storeLastContactedNumberId(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ConversationBox);
