export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ATTEMPT = 'SIGNUP_ATTEMPT';
export const FETCH_SIGNUP_DATA = 'FETCH_SIGNUP_DATA';
export const FETCH_SIGNUP_DATA_SUCCESS = 'FETCH_SIGNUP_DATA_SUCCESS';
export const UPDATE_VALIDATION_CHECK = 'UPDATE_VALIDATION_CHECK';
export const UPDATE_LOADING = 'UPDATE_LOADING';
export const SELECT_PACKAGE = 'SELECT_PACKAGE';
export const SIGN_UP_PROCESS = 'SIGN_UP_PROCESS';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const OTP_VERIFY_PROCESS = 'OTP_VERIFY_PROCESS';
export const UPDATE_SUBMITTING = 'UPDATE_SUBMITTING';
export const HANDLE_STATE_CHANGE = 'HANDLE_STATE_CHANGE';
export const UPDATE_ERROR_MESSAGE = 'UPDATE_ERROR_MESSAGE';