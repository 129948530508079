/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Drawer, IconButton, styled } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Utils from "../../../addContact/Utils";
import { CustomScrollbar } from "../common/CustomScrollbar";
import RightListSkeleton from "../common/RightListSkeleton";
import SubTitle from "../common/SubTitle";
import Title from "../common/Title";
import InboxContentTypeIcon from "./InboxContentTypeIcon";
import { fetchUserInbox, resetSidebarPage, setSidebarPage } from "../../../../actions/floatingSidebarAction";

const DrawerWrapper = styled(Drawer)(({ theme }) => ({
  "& .wrapper": {
    width: 500,
  },

  "& .headerWrapper": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "18px 22px",
    borderBottom: "1px solid #E9EAEC",

    "& .headerTitle": {
      margin: 0,
      fontWeight: 600,
      fontSize: "20px ",
    },
  },

  "& .bodyWrapper": {
    padding: "12px 16px",
  },

  "& .list": {
    display: "flex",
    flexDirection: "column",
    gap: 12,
  },

  "& .listItem": {
    padding: "8px 14px",
    borderRadius: 12,
    border: "1px solid #E9EAEC",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px !important",
    "&.unseenItem": {
      backgroundColor: "rgba(60,126,243,.1)",

      "& .message": {
        fontWeight: 700,
      },
    },

    "& .titleWrapper": {
      display: "flex",
      justifyContent: "space-between",

      "& .title": {
        fontSize: "16px",
        cursor: "pointer",
        lineHeight: "21px",
        fontWeight: 400,

        width: 265,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",

        "&:hover": {
          color: "#346FEF",
        },
      },
    },

    "& .message": {
      color: "#141928cc",
      whiteSpace: "break-spaces",

      display: "-webkit-box",
      maxWidth: 438,
      "-webkit-line-clamp": 3,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      cursor: "pointer",
      margin: 0,
      fontSize: "14px",

      "&:hover": {
        color: "#346FEF",
      },
    },
  },
}));

const InboxDrawer = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const { inboxList, isLoading, page, hasMore } = useSelector(
    (state) => state.floatingSidebarReducer
  );

  useEffect(() => {
    dispatch(fetchUserInbox({ page }));
  }, [page]);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (
      scrollHeight - scrollTop <= clientHeight + 50 &&
      hasMore &&
      !isLoading
    ) {
      dispatch(setSidebarPage(page + 1));
    }
  };

  const renderInbox = () => {
    if (isLoading && inboxList.length === 0) {
      return <RightListSkeleton itemCount={7} />;
    } else if (inboxList?.length === 0) {
      return <p className="d-flex justify-content-center">NO MESSAGES</p>;
    } else {
      return inboxList.map((timeline) => {
        const fullName = timeline.first_name + " " + timeline.last_name;
        const redirectContactUri = `/contacts/${timeline.contact_id}`;
        const redirectInboxUri = `/inbox?contactId=${timeline.contact_id}`;
        const timeAgo = window.globalTimezoneConversionToDifferentTimezone(
          timeline.last_message_time,
          "UTC",
          "",
          "from_now"
        );

        return (
          <div
            className={`listItem ${timeline.Unread ? "unseenItem" : ""}`}
            key={timeline.id}
          >
            <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
              <InboxContentTypeIcon contentType={timeline.content_type} />
              <div style={{ flex: 1 }}>
                <div className="titleWrapper">
                  <Link
                    to={redirectContactUri}
                    onClick={onClose}
                  >
                    <Title
                      className="title"
                      text={Utils.getContactDisplayName(
                        fullName,
                        timeline.number,
                        timeline.email
                      )}
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   history.push(redirectContactUri);
                      //   onClose();
                      // }}
                    />
                  </Link>
                  <SubTitle text={timeAgo || ""} className="subTitle" />
                </div>
                <Link
                  to={redirectInboxUri}
                  onClick={onClose}
                >
                  <p
                    className="message"
                    dangerouslySetInnerHTML={{ __html: timeline.last_message }}
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   history.push(redirectInboxUri);
                    //   onClose();
                    // }}
                  />
                </Link>
              </div>
            </div>
          </div>
        );
      });
    }
  };

  const closeDrawer = () => {
    onClose();
    dispatch(resetSidebarPage());
  };

  return (
    <DrawerWrapper anchor={"right"} open={open} onClose={closeDrawer}>
      <div className="wrapper">
        <div className="headerWrapper">
          <h6 className="headerTitle">Inbox</h6>

          <IconButton
            className="closeButton"
            size="small"
            onClick={closeDrawer}
          >
            <HighlightOffIcon style={{ color: "#ff0055" }} />
          </IconButton>
        </div>

        <div className="bodyWrapper">
          <CustomScrollbar
            onScroll={handleScroll}
            style={{ maxHeight: "calc(100vh - 125px)", overflow: "auto" }}
          >
            <div className="list">{renderInbox()}</div>
            {isLoading && <RightListSkeleton itemCount={3} />}
          </CustomScrollbar>
        </div>
      </div>
    </DrawerWrapper>
  );
};

export default InboxDrawer;
