import React, { useState, useContext } from "react";
import { ContactDetailContext } from "../../reducer/GlobalReduers";
import ApiList from "../../ApiList";
import DeleteIcon from '@material-ui/icons/Delete';
import NewConfirmAlert from "../../../custom/NewConfirmAlert";

const Delete = (props) => {
    const {detailStates} = useContext(ContactDetailContext)
    const [deleting, setDeleting] = useState(false)

    const removeDealFromStage = () => {
        NewConfirmAlert({
            title: 'Confirm to delete',
            description: 'Are you sure to delete this contact?',
            cancelText: "Cancel",
            submitText: "Yes, Delete!",
            onSubmit: async () => {
                if(deleting) return;
                    setDeleting(true)
                    ApiList.deleteContact({id: detailStates.contactId}).then(res => {
                        setDeleting(false)
                        if(detailStates.from === 'deal-details'){
                            if(detailStates.dealCallback !== undefined && detailStates.dealCallback !== null){
                                detailStates.dealCallback(true)
                            }
                        }else if (detailStates.from === 'contact-details'){
                            window.showNotification('SUCCESS', 'Contact deleted successfully');
                            window.location.href = '/contacts';
                        }
                        props.onClose()
                    })
                }
            }
        );
    };

    return(
        <div className="g-more-info-left g-more-info-column mt-4">
            <div onClick={removeDealFromStage} className="g-contact-delete-content">
                <DeleteIcon />
                Delete Contact              
            </div>
        </div> 
    )
}
export default Delete;