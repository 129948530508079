import {useEffect, useState} from 'react'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import moment from 'moment'
import axis from 'axis.js';
import Utils from "../../../../helpers/Utils";

const getHtmlText = (message) => {
    let regix = /\@.*?\)/g; // eslint-disable-line 
    let matchStrign = message.match(regix);
    if (!axis.isNull(matchStrign) && axis.isArray(matchStrign)) {
        matchStrign.map((str) => { // eslint-disable-line array-callback-return
            let eachStr = str.match(/@\[(.*)\]\(.*\)/);
            if(eachStr != null)
                message = message.replace(str, "<b>" + "@" + eachStr[1] + "</b>")
        });
    }
    return message.replace(/\n/g, "<br />");
}

const NoteList = (props) => {

    const [isShowDetails, setIsShowDetails] = useState(false)
    const [noteText, setNoteText] = useState('')
    const [htmlText, setHtmlText] = useState('')

    useEffect(() => {
        let text = '';
        if(props.data !== undefined && props.data != null && props.data.message!== undefined && props.data.message!= null){
            text = props.data.message
        }
        if(text!== undefined && text!= null && text!=='' && text[text.length-1] === ')'){
            text = text+' ';
        }
        setNoteText(text)
        setHtmlText(getHtmlText(props.data.message))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const toggleShowDetais = () => {
        setIsShowDetails(!isShowDetails)
    }

    const getHeaderText = (text) => {
        if(text.length > 20){
            return text.substring(0, 17) + '...';
        }
        return text;
    }

    return (
        <div className="right-side-each-note-content" key={props.data.id} style={{padding:'16px',marginTop:'16px',background:'#f3eed6',borderRadius:'8px'}}>
            <div className={`${isShowDetails ? 'boder-bottom' : ''}`} style={{display:'flex',justifyContent:'space-between'}}>
                <div className="info-content">
                    {/* <span className="header-title truncate">{getHeaderText(props.data.message)}</span> */}
                    <span className="header-title truncate" dangerouslySetInnerHTML={{__html: getHeaderText(htmlText)}} />
                    <span className="header-date">{window.globalTimezoneConversionToDifferentTimezone(props.data.created_at, 'UTC', '', "MM-DD-YYYY")}</span>
                </div>
                <div className="header-option">
                    <span onClick={toggleShowDetais} className="option-icon">{isShowDetails ? <UnfoldLessIcon fontSize="small" /> : <UnfoldMoreIcon fontSize="small" />}</span>
                    {
                        Utils.getAccountData("note_edit_access") == "1" ? (
                            <>
                                <span onClick={() => props.storeEditData(props.data, props.index)} className="option-icon"><EditIcon fontSize="small"/></span>
                                <span onClick={() => props.deleteItem(props.data.id, props.index)} className="option-icon"><DeleteIcon fontSize="small"/></span>
                            </>
                        ) : null
                    }
                </div>
            </div>
            {isShowDetails &&
            <div className="each-note-details" style={{borderTop:'1px solid #e0df9f',paddingTop:'12px',marginTop:'8px'}}>
                {/* <span className="each-note-details-text">{props.data.message}</span> */}
                <span className="each-note-details-text">
                    <p class="text-gray m-0 mb-2" dangerouslySetInnerHTML={{__html: htmlText}}/>
                </span>
            </div>
            }
        </div>
    )
} 
export default NoteList;