import React from "react";
import {
    Backdrop,
    Button,
    Fade,
    makeStyles,
    Modal,
} from "@material-ui/core";
import {WarningIcon} from "./Icons";
import Utils from "../../helpers/Utils";

const modalStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        width: 640,
        backgroundColor: theme.palette.background.paper,
        borderRadius: "4px",
        boxShadow: theme.shadows[5],
        "&:focus-visible": {
            outline: "none"
        }
    },
    modalHeader: {
        paddingTop: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    modalBody: {
        padding: "16px",
        "& .reasonTitle": {
            fontSize: "20px",
            fontWeight: "600",
            textAlign: "center",
            color: "#3a414a",
        },
        "& .message": {
          color: "#3a414a",
          textAlign: "center",
          marginTop: "10px"
        },
        "& .errorMessage": {
            color: "#3a414a",
            textAlign: "center"
        },
        "& .code": {
          fontWeight: "600"
        },
        "& .contactAgency": {
            color: "#6a737e",
            textAlign: "center",
            marginTop: "20px"
        },
        "& .save": {
            paddingLeft: "8px",
            paddingRight: "8px",
            marginTop: "15px",
            "& .saveButton": {
                backgroundColor: "#3C7EF3 !important",
                fontWeight: "600 !important",
                color: "white !important",
                minWidth: "fit-content !important",
                "&:hover, &:focus": {
                    backgroundColor: "#3C7EF3 !important",
                    color: "white !important",
                },
                width: "100%"
            }
        }
    },
    modalFooter: {
        padding: "16px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop: "15px",
        "& .btnSubmit": {
            background: "#3c7ef3 !important",
            fontWeight: "600",
            color: "white !important",
            padding: "10px 50px",
            borderRadius: "10px"
        },
    },
}));

const CallEnvironmentSetupAlertPop = ({ open, onClose, data = null }) =>{
    const classes = modalStyles();

    const handleClose = () => {
        //1440 min -> 24 hours
      Utils.setPlainCookie("call-env-closed", "true", 1440);
      onClose();
    };

    return(
        <Modal
            open={open}
            onClose={()=>{}}
            className={classes.modal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <div className={classes.modalHeader}>
                        <WarningIcon/>
                    </div>
                    <div className={classes.modalBody}>
                        <div className="reasonTitle">
                            Phone & Texting Features Are Currently Disabled
                        </div>
                        <div className="message">
                            Please contact out support team { data && data.reason ? "and reference the following" : "" }
                        </div>
                        {
                            data && data.reason &&
                            <div className="errorMessage">
                                error code: <span className="code">{data.reason}</span>
                            </div>
                        }

                        {
                            Utils.getAccountData("AuthUserAgencyEmail") &&
                            <div className="contactAgency">
                                Contact: {Utils.getAccountData("AuthUserAgencyEmail")}
                            </div>
                        }
                    </div>
                    <div className={classes.modalFooter}>
                        <Button className="btnSubmit" onClick={handleClose}>
                            Close
                        </Button>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
}

export default CallEnvironmentSetupAlertPop;