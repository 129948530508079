import { all, takeEvery, call, put } from 'redux-saga/effects';
import * as ACTION from '../constants/signupActionTypes'
import {signupRequest, signupDataRequest, signup, verifyOtp} from "../api/signupApi";
import Utils from "../helpers/Utils";

function* signupWatcher() {
    yield takeEvery(ACTION.SIGNUP_ATTEMPT, attemptSignupFlow)
    yield takeEvery(ACTION.FETCH_SIGNUP_DATA, fetchSignUpDataFlow)
    yield takeEvery(ACTION.SIGN_UP_PROCESS, signupFlow)
    yield takeEvery(ACTION.OTP_VERIFY_PROCESS, verifyOTPFlow)
}

function* attemptSignupFlow(action) {
    try {
        const response = yield call(signupRequest, action.payload);
        let responseData = response.data;
        if (responseData.status) {
            yield put({type: ACTION.SIGNUP_SUCCESS, payload: true});
        } else {
            console.log('not ok');
        }

    } catch (error) {
        console.log(error);
    }

}

function* fetchSignUpDataFlow (action) {
    const { payload } = action;
    try {
        const response = yield call(signupDataRequest, payload.payload);
        let responseData = response.data;
        if (responseData.status) {
            yield put({type: ACTION.FETCH_SIGNUP_DATA_SUCCESS, payload: responseData.data});
        } else {
            Utils.handleException(responseData.message);
        }
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* signupFlow (action) {
    try {
        const response = yield call(signup, action.payload?.payload ? action.payload?.payload : action.payload);
        let responseData = response.data;

        if (responseData.status === 'success') {
            if(responseData.userOtp)
            {
                let apiKey = responseData.api_key;
                yield put({type: ACTION.SIGN_UP_SUCCESS, payload: {apiKey}});
            }else{
                yield put({type: ACTION.SIGN_UP_SUCCESS, payload: {}});
            }
            if(action.payload?.callback !== undefined){
                action.payload.callback() 
            }
        } else {
            yield put({type: ACTION.UPDATE_SUBMITTING, payload: false});
            yield put({type: ACTION.UPDATE_ERROR_MESSAGE, payload: responseData.html});
            window.showNotification("error", responseData.html);
            Utils.handleException(responseData.html);
        }
    } catch (error) {
        Utils.handleException(error);
    }
}

function* verifyOTPFlow (action) {
    try {
        const response = yield call(verifyOtp, action.payload.payload);
        let responseData = response.data;
        if (responseData.status === 'success') {
            yield put({type: ACTION.SIGN_UP_SUCCESS, payload: {}});
        } else if (responseData.status === 'otp-not-matched'){
            let apiKey = responseData.api_key;
            let otpError = true;
            yield put({type: ACTION.SIGN_UP_SUCCESS, payload: {apiKey:apiKey, otpError:otpError}});
        } else {
            yield put({type: ACTION.UPDATE_SUBMITTING, payload: false});

            Utils.handleException(responseData.message);
        }
        if(action.payload.callback){
            action.payload.callback()
        }
    } catch (error) {
        Utils.handleException(error);
    }
}

export default function* signupMiddleware() {
    yield all([
        signupWatcher()
    ])
}