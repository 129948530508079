import {
    Button,
    makeStyles,
    Typography
} from "@material-ui/core";
import React, { Fragment } from "react";
import { closeA2pModalApi } from "../../api/globalApi";
import Utils from "../../helpers/Utils";
import MessageBox from "../messageBox/MessageBox";
import Styles from "./Styles.module.css";
import { a2pOnBoard } from "./index";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            "&.MuiButton-root": {
                backgroundColor: "#9670ff",
                padding: "5px 32px",
                borderRadius: "4px",
                fontWeight: 700,
            },
            "&.MuiButton-text": {
                color: "#9670ff"
            },
            "&.MuiButton-contained": {
                color: "white",
                padding: "7px 32px",
            },
            "&.MuiButton-outlined": {
                backgroundColor: "#ffffff",
                color: "#9670ff",
                borderColor: "#9670ff",
                border: "2px solid !important",
            }
        }
    };
});

const WelcomeDlc = ({ data, onClose, setActiveStep }) => {
    const classes = useStyles();

    const handleCloseForOneDay = async () => {
        onClose();
        await closeA2pModalApi();
    }

    const handleRedirectHelp = () => {

        if (Utils.getAccountData('agencyId') === 1) {
            return `https://softwarehelp.freshdesk.com/support/solutions/articles/66000517994-understanding-a2p-10dlc-registration`;
        }
        return `${process.env.REACT_APP_CDN_LINK}asset/files/releases/1.0.0/10dlc-help.pdf`;
    }

    const getReasonMessage = (message) => {
        if (message !== undefined && message !== null && message !== "") {
            try {
                let str = message.split("Reason")
                str = str[1];
                str = str.replace(":", "")
                str = str.trim()
                return (
                    <MessageBox
                        message="Thank you for submitting your information. Unfortunately, we were unable to process your registration successfully due to the reason stated below. If you have further questions please reach out to our team."
                        reason={str}
                    />
                )
            } catch (error) {
                return (
                    <MessageBox
                        message={message}
                    />
                )
            }
        }
    }

    return (
        <Fragment>
            <div className={Styles.headerWrapper}>
                <Typography className={Styles.headerTitle} variant="h5">Action Required: A2P/10DLC Registration</Typography>
                <Button size="small" onClick={handleCloseForOneDay} className={`${classes.root} ${Styles.redButton}`} variant="outlined">
                    EXIT
                </Button>
            </div>

            <div className={Styles.modalBody}>
                <div style={{ height: "400px" }} className={Styles.bodyContainer}>
                    {getReasonMessage(data?.standard_reg_failed_reason)}
                    <div className={Styles.welcomeMessage}>
                        <p>
                            In order to send text messages in the United States you are now required to register your business.
                            This new regulation is called A2P 10DLC.
                        </p>
                        <p>
                            A2P (Application to Person) 10DLC (10 digit long code) is the standard U.S. telecom carriers have put in
                            place to ensure text messages being sent are verified and consensual.
                        </p>
                    </div>
                    <div className={Styles.videoWrapperv2}>
                        <iframe
                            src="https://player.vimeo.com/video/861754624?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                            frameBorder="0"
                            allowFullScreen
                            title="Vimeo Video Player"
                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                        ></iframe>
                    </div>
                </div>
            </div>
            <div className={`${Styles.modalFooter} ${Styles.modalFooterCenter}`}>
                <Button target="_blank" href={handleRedirectHelp()} size="large" className={`${classes.root} ${Styles.mr1}`} variant="outlined">
                    ACCESS HELP GUIDE
                </Button>
                <Button size="large" onClick={() => {
                    setActiveStep(a2pOnBoard.ASK_REGION)
                }} className={`${classes.root} ${Styles.overwriteButton}`} variant="contained">
                    REGISTER MY BUSINESS
                </Button>
            </div>
        </Fragment>
    );
};

export default WelcomeDlc;