import React from "react";
import { Skeleton } from "@material-ui/lab";

const InsuranceCommonSkeleton = () => {
  return (
    <div style={{ width: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <div className="headerWrapper" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: "16px" }}>
        <Skeleton variant="text" width={100} height={30} />

        <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
          <Skeleton variant="rect" width={80} height={20} />
          <Skeleton variant="circle" width={40} height={40} />
        </div>
      </div>

      <div className="countWrapper" style={{ paddingBottom: "16px", display: 'flex', flexDirection: 'column' }}>
        <Skeleton variant="text" width={80} height={30} />
        <Skeleton variant="text" width={100} height={40} />
      </div>

      <div>
        <Skeleton variant="rect" width="100%" height={40} style={{ borderRadius: '4px' }} />
      </div>
    </div>
  );
};

export default InsuranceCommonSkeleton;
