import {useContext, useEffect} from 'react';
import { SmsSendContext } from "../reducers/GlobalReduers"
import { updateSelectedVn } from '../reducers/Action';
import BasicSelect from '../../../custom/BasicSelect'
import {useSelector} from "react-redux";

const FromNumber = (props) => {
    const { virtualNumbers } = useSelector((state) => (state.globalDialerReducer));
    const {states, dispatch} = useContext(SmsSendContext)

    useEffect(()=>{
        if (virtualNumbers && !virtualNumbers[0]){
            window.buyVirtualNumberModal(true);
        }
    },[virtualNumbers]);

    const hanldeVirtualNumber = (value) => {
        dispatch(updateSelectedVn(value))
    }

    return(
        <div className="sms__email__wrapper__alt">
            <div className="sms__email__wrapper__top">
                <h3 className="sms__email__wrapper__top__title">From Number: </h3>
                <div className="sms__email__wrapper__top__select">
               
                   <BasicSelect
                        fullWidth
                        name="virtual_number"
                        options={virtualNumbers}
                        defaultText="Select a virtual number "
                        value={states.selctedVn}
                        onChange={(event) => hanldeVirtualNumber(event.target.value)}
                        mapping={{
                          label: "label",
                          value: "value"
                      }}
                      />
                </div>
            </div>
            
        </div>
    )
}
export default FromNumber;