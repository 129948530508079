import { IconButton, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import ActivityDrawer from "./drawer/ActivityDrawer";
import InboxDrawer from "./drawer/InboxDrawer";
import NotificationDrawer from "./drawer/NotificationDrawer";
import { activityIcon, inboxIcon, notificationIcon } from "./common/Icons";

const useStyles = makeStyles((theme) => ({
  dashboardActionsWrapper: {
    position: "fixed",
    top: "30%",
    right: "-110px",
    zIndex: 9,
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: 10,
  },
  actionButton: {
    position: "relative",
    backgroundColor: "#202020",
    display: "flex",
    alignItems: "center",
    gap: 10,
    borderRadius: "50px 0 0 50px",
    padding: "8px",
    cursor: "pointer",
    transition: "all 350ms ease-in-out",

    "&:hover": {
      right: 108,
    },
  },
  activityButton: {
    right: 40,
  },
  inboxButton: {
    right: 55,
  },
  notificationButton: {
    right: 0,
  },
  buttonText: {
    color: "white",
  },
}));

const FloatingActions = () => {
  const classes = useStyles(); // Apply styles
  const [activityDrawer, setActivityDrawer] = useState(false);
  const [inboxDrawer, setInboxDrawer] = useState(false);
  const [notificationDrawer, setNotificationDrawer] = useState(false);

  return (
    <div className={classes.dashboardActionsWrapper}>
      <div className={classes.actionsContainer}>
        <div
          className={`${classes.actionButton} ${classes.activityButton}`}
          onClick={() => setActivityDrawer(true)}
        >
          <IconButton size="small" style={{ backgroundColor: "#202020" }}>
            {activityIcon}
          </IconButton>
          <div className={classes.buttonText}>Activity</div>
        </div>
        <div
          className={`${classes.actionButton} ${classes.inboxButton}`}
          onClick={() => setInboxDrawer(true)}
        >
          <IconButton size="small" style={{ backgroundColor: "#202020" }}>
            {inboxIcon}
          </IconButton>
          <div className={classes.buttonText}>Inbox</div>
        </div>
        <div
          className={`${classes.actionButton} ${classes.notificationButton}`}
          onClick={() => setNotificationDrawer(true)}
        >
          <IconButton size="small" style={{ backgroundColor: "#202020" }}>
            {notificationIcon}
          </IconButton>
          <div className={classes.buttonText}>Notifications</div>
        </div>
      </div>

      {activityDrawer && (
        <ActivityDrawer
          open={activityDrawer}
          onClose={() => setActivityDrawer(false)}
        />
      )}
      {inboxDrawer && (
        <InboxDrawer open={inboxDrawer} onClose={() => setInboxDrawer(false)} />
      )}
      {notificationDrawer && (
        <NotificationDrawer
          open={notificationDrawer}
          onClose={() => setNotificationDrawer(false)}
        />
      )}
    </div>
  );
};

export default FloatingActions;
