import React, { useState, useContext } from "react";
import { ContactDetailContext } from "../../reducer/GlobalReduers";
import DeleteIcon from '@material-ui/icons/Delete';
import { Box, Button, makeStyles } from "@material-ui/core";
import NewConfirmAlert from "./NewConfirmAlert";
import { removeBulkContact } from "../../../../api/contactApi";
const useStyles=makeStyles({
    deleteButton:{
        background:'red !important',
        color:'white !important',
        width:'100% !important',
        fontSize:'.9vw !important',
        fontWeight:'600 !important'
    }
})

const Delete = (props) => {
    const {detailStates} = useContext(ContactDetailContext)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [deleting, setDeleting] = useState(false)

    const removeContact = () => {
        NewConfirmAlert({
            title: 'Confirm to delete',
            description: 'Are you sure to delete contact?',
            cancelText: "Cancel",
            submitText: "Yes, Delete!",
            onSubmit: async () => {
                setDeleting(true);
                try{
                    let response = await removeBulkContact({ 'ids': [props.contact_id] });
                    if (response.data.success) {
                        window.showNotification('SUCCESS', 'Contact deleted successfully');
                        setTimeout(function() {
                            window.location.reload();
                        }, 1000);
                    } else {
                        window.showNotification('ERROR', 'Something went wrong')
                    }
                }catch (err){
                    window.showNotification('ERROR', 'Something went wrong')
                }
                setDeleting(false);
            }
        })
    }
    const classes=useStyles()
    return (
      <Box display={"flex"} justifyContent={"center"} mt={"14px"}>
        <Button
          onClick={() => removeContact()}
          variant="contained"
          startIcon={<DeleteIcon style={{ marginTop: "-3px" }} />}
          className={classes.deleteButton}
        >
          Delete Contact
        </Button>
      </Box>
    );
}
export default Delete;