import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Email from "./Email";
import DirectMail from "./DirectMail";
import Chat from "./Chat";
import Note from "./Note";
import Text from "./Text"
import { changeTabInBottomBox, updateBottomBoxVisibility } from "./inboxAction";
import Utils from "../Helpers/Utils";
import Helper from "../Helpers/Helper";
import './inbox-new.css'
import Voice from "./Voice";

const thanksIoActive =
  Utils.getAccountData("customMenu")["63"] &&
  Utils.getAccountData("customMenu")["63"] &&
  Utils.getAccountData("customMenu")["63"] !== null &&
  Utils.getAccountData("customMenu")["63"] !== ""
    ? true
    : false;

const zenDirectActive =
  Utils.getAccountData("customMenu")["61"] &&
  Utils.getAccountData("customMenu")["61"] &&
  Utils.getAccountData("customMenu")["61"] !== null &&
  Utils.getAccountData("customMenu")["61"] !== ""
    ? true
    : false;

const BottomBox = (props) => {
  const [activeTab, setActiveTab] = useState("reply");
  const [bottomBoxVisibility, setBottomBoxVisibility] = useState(false);
  const [isOpenEmailModal, setIsOpenEmailModal] = useState(false);
  const [hasEmail, setHasEmail] = useState(true);
  const [hasNumber, setHasNumber] = useState(true);
  const [hasAddress, setHasAddress] = useState(true);

  useEffect(() => {
    props.toggleBottomBoxVisibility(false);
    if (
      props.selectedContact?.number !== undefined &&
      props.selectedContact?.number !== null &&
      props.selectedContact?.number !== ""
    ) {
      setActiveTab("reply");
      setHasNumber(true);
    } else {
      setActiveTab("chat");
      setHasNumber(false);
    }
    if (
      props.selectedContact?.email !== undefined &&
      props.selectedContact?.email !== null &&
      props.selectedContact?.email !== ""
    ) {
      setHasEmail(true);
    } else {
      setHasEmail(false);
    }
    if (!Helper.isEmpty(props.selectedContact?.address)) {
      setHasAddress(true);
    } else {
      setHasAddress(false);
    }
    setBottomBoxVisibility(false);
  }, [props.selectedContact]);

  const changeConversationTab = (e, activeTab) => {
    e.preventDefault();
    if (
      activeTab === "reply" ||
      activeTab === "voice" ||
      activeTab === "reply"
    ) {
      if (!hasNumber) return;
    } else if (activeTab === "email") {
      if (!hasEmail) return;
    } else if (activeTab === "direct_mail") {
      if (!hasAddress) return;
    }
    if (activeTab === "email") {

      if (window.sendCommunication !== undefined) {
        window.sendCommunication(
          {
            open: true,
            onClose: () => setIsOpenEmailModal(false),
            id: props.selectedContact?.id,
            from: "inbox",
            primaryEmail: getContactEmail(),
            fetchContactDetails: false,
            sendSuccessCallBack: (response) => {
              if (response.conversation !== undefined) {
                window.appendConversation(response.conversation);
              }
              setIsOpenEmailModal(false);
            },
          },
          "email"
        );
      }
      return;
    }
    localStorage.setItem("conversationTab", activeTab);
    setActiveTab(activeTab);
  };

  const toggleBottomBox = (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    props.toggleBottomBoxVisibility(!bottomBoxVisibility);
    setBottomBoxVisibility(!bottomBoxVisibility);
  };
  const renderTabs = () => {
    switch (activeTab) {
        case 'reply':
            return <Text closeTab={toggleBottomBox} />
        case 'email':
            return <Email closeTab={toggleBottomBox} />
        case 'voice':
            return <Voice closeTab={toggleBottomBox} />
        case 'direct_mail':
            return <DirectMail updateActiveMailItemType={updateActiveMailItemType} closeTab={toggleBottomBox} 
            zenDirectActive={zenDirectActive} thanksIoActive={thanksIoActive} contactId={props?.selectedContact?.id} handleClose={props.handleClose}/>
        case 'chat':
            return <Chat closeTab={toggleBottomBox} />
        case 'note':
            return <Note closeTab={toggleBottomBox} />
        default:
            return <Text closeTab={toggleBottomBox} />
    }
}

  const updateActiveMailItemType = (type) => {
    window.openGlobalDirectMailModal({
      messageType: type,
      contactId: props.selectedContact?.id,
      contactDetails: props.selectedContact,
      from: "inbox",
    });
  };

  const getContactEmail = () => {
    let contact = props.selectedContact;
    if (contact.email !== undefined && contact.email !== null) {
      return contact.email;
    }
    return "";
  };
  
  return (
    <React.Fragment>
      {bottomBoxVisibility && (
       <div className="InboxModule-container-parent-class chat__bottom_wrapper chat__wrapper-main " >
       <div className="info-links">
         <div className="mb-0 row">
           <div className="col s12 d-flex align-items-center">
             <span onClick={(e) => toggleBottomBox(e)}>
               <i className="material-icons pulse close">clear</i>
             </span>
             <ul className="tabs tabs-fixed-width">
               <li className="tab">
                 <a
                   inbox-expand="inbox-text-active"
                   className={
                     "inbox-tab" +
                     (activeTab === "reply" ? " active" : "") +
                     (hasNumber ? "" : " not_allowed_send_option")
                   }
                   onClick={(e) => changeConversationTab(e, "reply")}
                   href="#"
                 >
                   <svg
                     width="17"
                     height="16"
                     viewBox="0 0 17 16"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                   >
                     <ellipse
                       cx="8.14453"
                       cy="7.6"
                       rx="8"
                       ry="7.6"
                       fill="#133159"
                     />
                     <path
                       d="M8.14456 0C3.73335 0 0.144561 3.31169 0.144561 7.38226C0.144561 8.91225 0.638935 10.3624 1.57878 11.6023L0.196425 15.0222C0.0878816 15.2906 0.152897 15.5979 0.360722 15.7994C0.496124 15.9309 0.674684 16 0.856022 16C0.952896 16 1.05051 15.9802 1.14276 15.9396L5.08089 14.2037C6.05204 14.5762 7.08117 14.7647 8.14456 14.7647C9.49209 14.7647 10.8246 14.4496 11.998 13.8538C12.3483 13.6758 12.488 13.2477 12.3102 12.8974C12.1321 12.5472 11.7041 12.4075 11.3538 12.5853C10.3792 13.0804 9.26945 13.3422 8.14456 13.3422C7.16711 13.3422 6.22634 13.1521 5.34799 12.7774C5.1672 12.7002 4.96216 12.7013 4.78193 12.7807L2.1717 13.9314L3.05394 11.7488C3.15341 11.503 3.10784 11.2226 2.93595 11.0207C2.04037 9.97027 1.56711 8.71202 1.56711 7.38245C1.56711 4.09613 4.5178 1.42255 8.14456 1.42255C11.7713 1.42255 14.722 4.09613 14.722 7.38245C14.722 8.51253 14.3712 9.61222 13.7073 10.5628C13.4825 10.8849 13.5612 11.3284 13.8833 11.5532C14.2052 11.7781 14.6488 11.6994 14.8737 11.3773C15.705 10.1866 16.1446 8.80519 16.1446 7.38245C16.1446 3.31169 12.5558 0 8.14456 0Z"
                       fill="#133159"
                     />
                     <path
                       d="M13.5609 7.66355L10.7663 4.42076C10.627 4.25906 10.4244 4.16885 10.2094 4.17404C9.99616 4.17941 9.79648 4.28036 9.66571 4.44892L7.07771 7.78247L6.22214 6.7726C6.08674 6.61294 5.88836 6.52106 5.67942 6.52106C5.67738 6.52106 5.67535 6.52106 5.67331 6.52106C5.46215 6.52273 5.26266 6.61831 5.12892 6.78186L3.30925 9.00553C3.06048 9.30949 3.10531 9.75774 3.40927 10.0065C3.71323 10.2553 4.16148 10.2104 4.41006 9.90648L5.68887 8.34389L6.46794 9.26355C6.51313 9.34653 6.57518 9.42192 6.65428 9.4836L8.77329 11.9849C8.91406 12.1511 9.11448 12.2366 9.31637 12.2365C9.47882 12.2365 9.64219 12.1813 9.77574 12.0679C10.0754 11.814 10.1127 11.3651 9.85872 11.0654L8.01737 8.8918L10.2562 6.00798L12.4834 8.59228C12.7398 8.88976 13.1889 8.9231 13.4864 8.66674C13.7841 8.4102 13.8174 7.96121 13.5609 7.66355Z"
                       fill="#133159"
                     />
                     <path
                       d="M6.38427 4.38842C6.38427 4.90002 5.96936 5.31493 5.45776 5.31493C4.94598 5.31493 4.53125 4.90002 4.53125 4.38842C4.53125 3.87664 4.94598 3.46191 5.45776 3.46191C5.96936 3.46191 6.38427 3.87664 6.38427 4.38842Z"
                       fill="#133159"
                     />
                     <path
                       d="M1.74219 14.8043L2.54219 12.4043L4.94219 13.6043L1.74219 14.8043Z"
                       fill="#133159"
                     />
                   </svg>
                   Text
                 </a>
               </li>
               <li className="tab">
                 <a
                   inbox-expand="inbox-email-active"
                   className={
                     "inbox-tab" +
                     (activeTab === "email" ? " active" : "") +
                     (hasEmail ? "" : " not_allowed_send_option")
                   }
                   onClick={(e) => changeConversationTab(e, "email")}
                   href="#"
                 >
                   <svg
                     width="16"
                     height="14"
                     viewBox="0 0 16 14"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                   >
                     <path
                       d="M2.66666 0C1.95942 0 1.28114 0.280951 0.781048 0.781048C0.280951 1.28114 0 1.95942 0 2.66666V2.93466L7.99999 7.24266L16 2.936V2.66666C16 1.95942 15.719 1.28114 15.2189 0.781048C14.7188 0.280951 14.0406 0 13.3333 0H2.66666Z"
                       fill="#133159"
                     />
                     <path
                       d="M16 4.44922L8.31599 8.58655C8.21887 8.63883 8.11029 8.6662 7.99999 8.6662C7.88969 8.6662 7.78112 8.63883 7.68399 8.58655L0 4.44922V10.6665C0 11.3738 0.280951 12.0521 0.781048 12.5522C1.28114 13.0523 1.95942 13.3332 2.66666 13.3332H13.3333C14.0406 13.3332 14.7188 13.0523 15.2189 12.5522C15.719 12.0521 16 11.3738 16 10.6665V4.44922Z"
                       fill="#133159"
                     />
                   </svg>
                   Email
                 </a>
               </li>
               <li className="tab">
                 <a
                   inbox-expand={`inbox-video-email-active`}
                   className={`inbox-tab ${
                     hasEmail ? "" : " not_allowed_send_option"
                   }`}
                   href="#"
                   onClick={(e) => {
                     e.preventDefault();
                     // setOpenVideoEmailModal(true)
                     window.sendCommunication(
                       {
                         open: true,
                         contactId: props.selectedContact?.id,
                         contactDetails: props.selectedContact,
                         from: "inbox",
                         responseCallback: (response) => {
                           if (window.appendConversation !== undefined) {
                             window.appendConversation(
                               response.conversation
                             );
                           }
                         },
                       },
                       "videoEmail"
                     );
                   }}
                 >
                   <svg
                     width="16"
                     height="10"
                     viewBox="0 0 16 10"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                   >
                     <path
                       d="M9.05039 0H1.75056C0.787752 0 0 0.787752 0 1.75056V7.64994C0 8.61275 0.787752 9.4005 1.75056 9.4005H9.05039C10.0132 9.4005 10.8009 8.61275 10.8009 7.64994V1.75056C10.8009 0.770246 10.0132 0 9.05039 0Z"
                       fill="#133159"
                     />
                     <path
                       d="M14.7042 0.963255C14.5992 0.980761 14.4942 1.03328 14.4066 1.08579L11.6758 2.6613V6.7226L14.4242 8.2981C14.9318 8.5957 15.562 8.42064 15.8596 7.91298C15.9471 7.75543 15.9997 7.58037 15.9997 7.38781V1.97858C15.9997 1.33087 15.387 0.805705 14.7042 0.963255Z"
                       fill="#133159"
                     />
                   </svg>
                   Video Email
                 </a>

                 {/*{openVideoEmailModal &&*/}
                 {/*    <VideoEmailModal closeTab={toggleBottomBox} openVideoEmailModal={openVideoEmailModal} setOpenVideoEmailModal={setOpenVideoEmailModal} showAllTimeSettings={true} />*/}
                 {/*}*/}
               </li>
               <li className="tab">
                 <a
                   inbox-expand="inbox-voice-active"
                   className={
                     "inbox-tab" +
                     (activeTab === "voice" ? " active" : "") +
                     (hasNumber ? "" : " not_allowed_send_option")
                   }
                   onClick={(e) => changeConversationTab(e, "voice")}
                   href="#"
                 >
                   <svg
                     width="11"
                     height="16"
                     viewBox="0 0 11 16"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                   >
                     <path
                       d="M10.2392 8.26388C10.2392 7.94391 9.99456 7.69922 9.67458 7.69922C9.35461 7.69922 9.10992 7.94391 9.10992 8.26388C9.10992 10.4661 7.32182 12.2542 5.11962 12.2542C2.91743 12.2542 1.12933 10.4661 1.12933 8.26388C1.12933 7.94391 0.884641 7.69922 0.564664 7.69922C0.244688 7.69922 0 7.94391 0 8.26388C0 10.8802 1.9575 13.0824 4.55496 13.3647V14.8705H2.50335C2.18337 14.8705 1.93868 15.1151 1.93868 15.4351C1.93868 15.7551 2.18337 15.9998 2.50335 15.9998H7.7359C8.05588 15.9998 8.30057 15.7551 8.30057 15.4351C8.30057 15.1151 8.05588 14.8705 7.7359 14.8705H5.68429V13.3647C8.28174 13.0824 10.2392 10.8802 10.2392 8.26388Z"
                       fill="#133159"
                     />
                     <path
                       d="M5.11986 0C3.38822 0 1.97656 1.41166 1.97656 3.1433V8.2441C1.97656 9.99456 3.38822 11.3874 5.11986 11.4062C6.8515 11.4062 8.26316 9.99456 8.26316 8.26292V3.1433C8.26316 1.41166 6.8515 0 5.11986 0Z"
                       fill="#133159"
                     />
                   </svg>
                   Voice
                 </a>
               </li>
               {(thanksIoActive || zenDirectActive) && (
                 <li className="tab">
                   <a
                     inbox-expand="inbox-directmail-active"
                     className={`inbox-tab ${
                       hasAddress ? "" : " not_allowed_send_option"
                     }`}
                     onClick={(e) => changeConversationTab(e, "direct_mail")}
                     href="#"
                   >
                     <svg
                       width="16"
                       height="16"
                       viewBox="0 0 16 16"
                       fill="none"
                       xmlns="http://www.w3.org/2000/svg"
                     >
                       <path
                         d="M16 0L0 8.99999L5.1133 10.8938L13 3.5L7.00112 11.593L7.006 11.5948L7.00002 11.593V16L9.86753 12.6546L13.5 14L16 0Z"
                         fill="#133159"
                       />
                     </svg>
                     Direct Mail
                   </a>
                 </li>
               )}
               <li className="tab">
                 <a
                   inbox-expand="inbox-chat-active"
                   className={
                     "inbox-tab" + (activeTab === "chat" ? " active" : "")
                   }
                   onClick={(e) => changeConversationTab(e, "chat")}
                   href="#"
                 >
                   <svg
                     width="16"
                     height="16"
                     viewBox="0 0 16 16"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                   >
                     <path
                       fillRule="evenodd"
                       clipRule="evenodd"
                       d="M0 2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0L14 0C14.5304 0 15.0391 0.210714 15.4142 0.585786C15.7893 0.960859 16 1.46957 16 2V10C16 10.5304 15.7893 11.0391 15.4142 11.4142C15.0391 11.7893 14.5304 12 14 12H11.5C11.3448 12 11.1916 12.0361 11.0528 12.1056C10.9139 12.175 10.7931 12.2758 10.7 12.4L8.8 14.933C8.70685 15.0572 8.58607 15.158 8.44721 15.2274C8.30836 15.2969 8.15524 15.333 8 15.333C7.84475 15.333 7.69164 15.2969 7.55279 15.2274C7.41393 15.158 7.29315 15.0572 7.2 14.933L5.3 12.4C5.20685 12.2758 5.08607 12.175 4.94721 12.1056C4.80836 12.0361 4.65525 12 4.5 12H2C1.46957 12 0.960859 11.7893 0.585786 11.4142C0.210714 11.0391 0 10.5304 0 10V2ZM5 6C5 6.26522 4.89464 6.51957 4.70711 6.70711C4.51957 6.89464 4.26522 7 4 7C3.73478 7 3.48043 6.89464 3.29289 6.70711C3.10536 6.51957 3 6.26522 3 6C3 5.73478 3.10536 5.48043 3.29289 5.29289C3.48043 5.10536 3.73478 5 4 5C4.26522 5 4.51957 5.10536 4.70711 5.29289C4.89464 5.48043 5 5.73478 5 6ZM9 6C9 6.26522 8.89464 6.51957 8.70711 6.70711C8.51957 6.89464 8.26522 7 8 7C7.73478 7 7.48043 6.89464 7.29289 6.70711C7.10536 6.51957 7 6.26522 7 6C7 5.73478 7.10536 5.48043 7.29289 5.29289C7.48043 5.10536 7.73478 5 8 5C8.26522 5 8.51957 5.10536 8.70711 5.29289C8.89464 5.48043 9 5.73478 9 6ZM12 7C12.2652 7 12.5196 6.89464 12.7071 6.70711C12.8946 6.51957 13 6.26522 13 6C13 5.73478 12.8946 5.48043 12.7071 5.29289C12.5196 5.10536 12.2652 5 12 5C11.7348 5 11.4804 5.10536 11.2929 5.29289C11.1054 5.48043 11 5.73478 11 6C11 6.26522 11.1054 6.51957 11.2929 6.70711C11.4804 6.89464 11.7348 7 12 7Z"
                       fill="#133159"
                     />
                   </svg>
                   Chat
                 </a>
               </li>
               <li className="tab">
                 <a
                   inbox-expand="inbox-note-active"
                   className={
                     "inbox-tab" + (activeTab === "note" ? " active" : "")
                   }
                   onClick={(e) => changeConversationTab(e, "note")}
                   href="#"
                 >
                   <svg
                     width="16"
                     height="16"
                     viewBox="0 0 16 16"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                   >
                     <path
                       d="M15.5026 5.92818C14.92 5.43835 14.0286 5.4967 13.5227 6.09984L13.2188 6.45948L15.3734 8.26733L15.6636 7.90901C16.1629 7.31523 16.0997 6.42845 15.5026 5.92818Z"
                       fill="#133159"
                     />
                     <path
                       d="M9.41433 10.9536L9.25398 11.1434C9.11482 11.31 9.01457 11.5091 8.96422 11.7202L8.52842 13.544C8.48724 13.7165 8.54721 13.8978 8.68315 14.0118C8.81547 14.123 9.0021 14.1553 9.1707 14.0832L10.8915 13.337C11.0906 13.2505 11.2692 13.1173 11.4083 12.9521L11.5692 12.7617L9.41433 10.9536Z"
                       fill="#133159"
                     />
                     <path
                       d="M12.6149 7.17041L10.0234 10.2366L12.1782 12.0447L14.7695 8.97826L12.6149 7.17041Z"
                       fill="#133159"
                     />
                     <path
                       d="M11.7495 2.075L9.96874 0.383027C9.80039 0.223428 9.59329 0.129424 9.375 0.0683594V2.80927H12.158C12.0998 2.53083 11.9579 2.27288 11.7495 2.075Z"
                       fill="#133159"
                     />
                     <path
                       d="M8.05511 11.5033C8.13889 11.1526 8.30596 10.8207 8.5376 10.5433L12.1879 6.22395V3.75014H8.90658C8.64747 3.75014 8.43781 3.54047 8.43781 3.28137V0H1.4063C0.630836 0 0 0.630836 0 1.4063V14.5943C0 15.3698 0.630836 16.0006 1.4063 16.0006H10.7816C11.5571 16.0006 12.1879 15.3698 12.1879 14.5943V13.4862L12.1271 13.5582C11.8968 13.8324 11.5997 14.0535 11.2687 14.1977L9.54657 14.9444C9.06118 15.154 8.49931 15.0797 8.08352 14.7315C7.67288 14.3868 7.49481 13.8489 7.61931 13.3271L8.05511 11.5033ZM2.34384 2.8126H7.03151C7.29061 2.8126 7.50028 3.02227 7.50028 3.28137C7.50028 3.54047 7.29061 3.75014 7.03151 3.75014H2.34384C2.08473 3.75014 1.87507 3.54047 1.87507 3.28137C1.87507 3.02227 2.08473 2.8126 2.34384 2.8126ZM2.34384 5.65646H9.84411C10.1032 5.65646 10.3129 5.86612 10.3129 6.12523C10.3129 6.38433 10.1032 6.59399 9.84411 6.59399H2.34384C2.08473 6.59399 1.87507 6.38433 1.87507 6.12523C1.87507 5.86612 2.08473 5.65646 2.34384 5.65646ZM6.09397 12.2192H2.34384C2.08473 12.2192 1.87507 12.0095 1.87507 11.7504C1.87507 11.4913 2.08473 11.2817 2.34384 11.2817H6.09397C6.35308 11.2817 6.56274 11.4913 6.56274 11.7504C6.56274 12.0095 6.35308 12.2192 6.09397 12.2192ZM2.34384 9.4066C2.08473 9.4066 1.87507 9.19693 1.87507 8.93783C1.87507 8.67873 2.08473 8.46906 2.34384 8.46906H7.96904C8.22815 8.46906 8.43781 8.67873 8.43781 8.93783C8.43781 9.19693 8.22815 9.4066 7.96904 9.4066H2.34384Z"
                       fill="#133159"
                     />
                   </svg>
                   Note
                 </a>
               </li>
             </ul>
           </div>
         </div>
       </div>
       {renderTabs()}
     </div>
      )}

      {!bottomBoxVisibility && (
        <div className="chat__bottom_wrapper chat__wrapper-main">
          <div className="row m-0">
            <div className="col s12" style={{padding:'10px 0px'}}>
             <div style={{position:'relative'}}>
             <div  onClick={(e) => toggleBottomBox(e)}
                className="mr-3 send-messagetabValue-btn right p-relative p-2 "
                style={{ background: "#3C7EF3", borderRadius: "10px",display:'flex',alignItems:'center',fontSize: "16px !important",padding:'4px' }}
              >
                 <i
                    className="material-icons left"
                    style={{
                      width: "26px",
                      height: "29px",
                      background: "white",
                      color: "#3C7EF3",
                      borderRadius: "50%",
                      padding: "12px 15px",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "16px !important",
                    }}
                  >
                    near_me
                  </i>
                <a
                  style={{ color: "white" ,}}
                  href="#"
                 
                >
                  Send a message
                </a>
              </div>
             </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedContact: state.inboxReducer.selectedContact,
    bottomBoxVisible: state.inboxReducer.bottomBoxVisible,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    toggleBottomBoxVisibility: (params) =>dispatch(updateBottomBoxVisibility(params)),
    changeTabInBottomBox: (params) => dispatch(changeTabInBottomBox(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BottomBox);
