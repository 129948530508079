import React from "react";
const POSTCARD = 6;
const GREETING_CARD = 7;
const GIFT = 8;
const LETTER = 9;
const THANKSIO_GREETING_CARD = 10;

const DirectMail = (props) => {
    const handleNewPostcard = (type = 'postcard') => {
        window.sendGlobalDirectMailV2({
            contactId: props.contactId,
            from: '/inbox',
            card_type: type,
            for: 'inbox-send'
        })
        props.handleClose()
    }

    return (
        <div id="directMmailTab" className="col s12 active inbox__direct__mail__wr">
            
            <div className="chat__bottom_wrapper">
            <div className="direct-mail">
                <div className="mb-0 row">
                    <div className="col s12">
                        <div className="card direct-mail-box-inbox" style={{height:'250px',marginTop:'22px'}}>
                            <p className="center-align">
                                Pick a Card Type
                            </p>
                            <div
                                className="d-flex align-items-center justify-content-center card-listing">
                                {props.thanksIoActive &&
                                <>
                                <a href="#!" onClick={(e)=>{e.preventDefault(); handleNewPostcard('postcard')}}>Postcard</a>
                                <a href="#!" onClick={(e)=>{e.preventDefault(); handleNewPostcard('letter')}}>Letter</a>
                                <a href="#!" onClick={(e)=>{e.preventDefault(); handleNewPostcard('notecard')}}>Notecard</a>
                                </>
                                }
                                {props.zenDirectActive &&
                                <a href="#!" onClick={(e)=>{e.preventDefault(); handleNewPostcard('zendirect_gift')}}>Gift</a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default DirectMail;