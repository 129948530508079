import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import GiphySearch from "./GiphySearch";
import {chatCompletions, imageUploadHandler} from "./utils/helpers";
import OpenAiKeyPopup from "./OpenAiKeyPopup";
import {styled} from "@material-ui/core/styles";

const StyledEditor = styled("div")({
    "& .tox.tox-tinymce": {
        border: "0 !important",
        background: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px"
    },
    "& .tox .tox-editor-container > .tox-editor-header": {
        borderBottom: "unset !important",
        paddingTop: "unset !important",
        paddingBottom: "unset !important"
    },
    "& .tox .tox-toolbar-overlord": {
        width: "100% !important",
        maxWidth: "unset !important",
        marginLeft: "unset !important"
    },
    "& .tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary": {
        border: "none !important"
    },
    "& .tox-editor-container .tox-editor-header .tox-toolbar__primary": {
        borderRadius: "0 !important",
        backgroundColor: "#fff !important"
    },
    "& .tox-editor-container .tox-editor-header": {
        width: "100% !important",
        borderRadius: "0 !important"
    },
    "& .tox .tox-edit-area": {
        borderRadius: "0 !important"
    },
    "& .tox:not([dir=\"rtl\"]) .tox-toolbar__group:first-of-type": {
        borderLeft: "none !important",
        borderRadius: "0 !important"
    },
    "& .tox:not([dir=\"rtl\"]) .tox-toolbar__group": {
        borderRight: "none !important",
        borderTop: "none !important",
        background: "transparent !important"
    },
    "& .tox .tox-tbtn svg": {
        transform: "none !important"
    },
    "& .tox .tox-tbtn--select span": {
        fontFamily: "unset !important",
        fontWeight: "400 !important",
        height: "initial !important",
        fontSize: "initial !important",
        lineHeight: "inherit !important"
    },
});

const TEditor = ({ body, onChangeBody, placeholder = "", ...rest }, ref) => {
    const [giphyModalOpen, setGiphyModalOpen] = useState(false);
    const [openAIApiKeyModalOpen, setOpenAIApiKeyModalOpen] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    const handleGiphySelect = (gifUrl) => {
        onChangeBody(body + `<img src="${gifUrl}" alt="Giphy GIF" />`);
        setGiphyModalOpen(false);
    };

    const aiRequest = async (request, respondWith) => {
        respondWith.string(async () => await chatCompletions({
                content: request.prompt
            }).then((resp) => {
                if (resp) {
                    if (resp.success && resp.data && resp.data.message) {
                        return resp.data.message;
                    } else {
                        if (resp.data.responseCode && resp.data.responseCode === 12401) {
                            setOpenAIApiKeyModalOpen(true);
                            if (isUpdate) {
                                setIsUpdate(false);
                            }
                            throw new Error("Please add your OpenAi api-key");
                        } else if (resp.data.responseCode && resp.data.responseCode === 12403) {
                            setOpenAIApiKeyModalOpen(true);
                            setIsUpdate(true);
                            throw new Error("Your OpenAI Api key is Invalid");
                        } else if (resp.data.message) {
                            throw new Error(resp.data.message);
                        } else {
                            throw new Error("Failed to communicate with the ChatGPT API");
                        }
                    }
                } else {
                    throw new Error("Failed to communicate with the ChatGPT API");
                }
            })
        );
    };

    return (
        <StyledEditor>
            <Editor
                ref={ref}
                apiKey="f2zuqw07g06pnduwafiv7wehs2nc44j426n4nq0rjdhs8w1x"
                value={body}
                init={{
                    height: 300,
                    auto_focus: undefined,
                    menubar: false,
                    browser_spellcheck: true,
                    menu: {
                        favs: {
                            title: "My Favourites",
                            items: "code visualaid | searchreplace | emoticons",
                        },
                    },
                    plugins: "advlist anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker permanentpen powerpaste advtable advcode editimage advtemplate ai mentions tableofcontents footnotes mergetags autocorrect typography inlinecss markdown preview",
                    convert_urls: false,
                    toolbar: "insertfile undo redo | styleselect | blocks fontsize | bold italic backcolor |  giphy emoticons media |aidialog aishortcuts| link image | alignleft aligncenter alignright alignjustify| fontfamily | bullist numlist outdent indent | removeformat | preview fullpage | checklist | footnotes ",
                    automatic_uploads: true,
                    target_list: [
                        { title: "Same page", value: "_self" },
                        { title: "New page", value: "_blank" },
                    ],
                    content_style: "p { margin: 0px; }",
                    placeholder: placeholder,
                    forced_root_block: false,
                    indent: false,
                    ai_shortcuts: [
                        { title: "Screenplay", prompt: "Convert this to screenplay format.", selection: true },
                        { title: "Stage play", prompt: "Convert this to stage play format.", selection: true },
                        {
                            title: "Classical", subprompts:
                                [
                                    { title: "Dialogue", prompt: "Convert this to a Socratic dialogue.", selection: true },
                                    { title: "Homeric", prompt: "Convert this to a Classical Epic.", selection: true }
                                ]
                        },
                        {
                            title: "Celtic", subprompts:
                                [
                                    { title: "Bardic", prompt: "Convert this to Bardic verse.", selection: true },
                                    { title: "Filí", prompt: "Convert this to Filí-an verse.", selection: true }
                                ]
                        },
                    ],
                    ai_request: aiRequest,
                    setup: (editor) => {
                        editor.ui.registry.addButton("giphy", {
                            text: "Giphy",
                            icon: "image",
                            tooltip: "Insert GIF",
                            onAction: () => {
                                setGiphyModalOpen(true);
                            }
                        });
                    },
                    images_reuse_filename: true,
                    images_file_types: "jpeg,jpg,png,gif,svg",
                    images_upload_handler: imageUploadHandler,
                    file_picker_types: "file image media",
                }
                }
                onEditorChange={onChangeBody}
                {...rest}
            />
            <GiphySearch
                open={giphyModalOpen}
                onSelect={handleGiphySelect}
                onClose={() => setGiphyModalOpen(false)}
            />

            <OpenAiKeyPopup
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
                open={openAIApiKeyModalOpen}
                onClose={()=> setOpenAIApiKeyModalOpen(false)}
            />
        </StyledEditor>
    );
};

export default TEditor;