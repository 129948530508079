import React from "react";
import {Helmet} from "react-helmet";
import useBulkEmailUnsubscribe from "./useBulkEmailUnsubscribe";

const ThankYou = () => {
    const classes = useBulkEmailUnsubscribe();

    return (
        <div className={classes.page}>
            <Helmet>
                <title>Unsubscribe</title>
            </Helmet>
            <div className={classes.container}>
                <div className={classes.innerContainer}>
                    <img src="https://d24gujj67p1uc1.cloudfront.net/assets/bulk-email/images/releases/1.0.1/message-00910.svg" alt="Message icon"/>

                    <div className={classes.bottom}>
                        <h2 className={classes.title}>Thank your for staying with us ❤️</h2>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThankYou;