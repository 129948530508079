import React from "react";
import {
  Backdrop,
  Button,
  Fade,

  Modal,

  makeStyles,
  styled,

} from "@material-ui/core";
import { Error } from "@material-ui/icons";

const modalStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 525,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "8px",
    boxShadow: theme.shadows[5],
    "&:focus-visible": {
      outline: "none",
    },
  },
 
 
}));

const Wrapper = styled("div")(({ theme }) => ({
    maxWidth: 625,
    minHeight: 200,
  
    "& .modalHeader": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 20px",
      borderBottom: "1px solid #d3d3d3",
  
      "& .titleWrapper": {
        display: "flex",
        alignItems: "center",
        gap: 8,
      },
  
      "& .titleHeader": {
        fontSize: "20px",
      },
    },
  
    "& .modalBody": {
      padding: "16px 24px 0",
      textAlign: "center",
  
      "& .bodyContent": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
      },
    },
  
    "& .actionButton": {
      backgroundColor: "#3E50F7",
      minWidth: 130,
      "&:hover, &:focus": {
        backgroundColor: "#3E50F7",
      },
    },
  
    "& .modalFooter": {
      padding: 16,
      display: "flex",
      justifyContent: "flex-end",
    },
    "& .message":{
      color: "red",
    }
  }));


const NumberPurchaseErrorModal = ({ open, onClose, message }) => {
  const classes = modalStyles();
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>

        <Wrapper className="contentWrapper">

      <div className="modalBody">
        <div className="bodyContent">
          <div>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'12px'}}>
            <div style={{ height: 32, color: 'red',marginTop:'16px' }}>
                <Error />
            </div>
            <h5 style={{ fontSize: 20, fontWeight: 600 }}>
              Unable to Buy Phone Number
            </h5>
            </div>
            <p className="message">
              {message}
            </p>
          </div>
        </div>
      </div>

      <div className="modalFooter">
        <Button
          variant="contained"
          color="primary"
          className={"actionButton"}
          disableElevation
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    </Wrapper>
          
        </div>
      </Fade>
    </Modal>
  );
};

export default NumberPurchaseErrorModal;
