import { all, call, put, takeEvery } from "redux-saga/effects";

import {
    getUserActivities,
    getUserInbox,
    getUserNotifications,
} from "../components/layouts/floatingActions/helpers/Api";
import { FETCH_SIDEBAR_NOTIFICATION, FETCH_USER_ACTIVITY, FETCH_USER_INBOX } from "../reducers/floatingSidebarTypes";
import { fetchSidebarNotificationFail, fetchSidebarNotificationSuccess, fetchUserActivityFail, fetchUserActivitySuccess, fetchUserInboxFail, fetchUserInboxSuccess } from "../actions/floatingSidebarAction";

function* floatingSidebarWatcher() {
  yield takeEvery(FETCH_USER_ACTIVITY, fetchSidebarActivity);
  yield takeEvery(FETCH_USER_INBOX, fetchSidebarInbox);
  yield takeEvery(FETCH_SIDEBAR_NOTIFICATION, fetchSidebarNotifications);
}

function* fetchSidebarActivity(action) {
  try {
    const apiCall = getUserActivities;
    const response = yield call(apiCall, action.payload);

    if (response?.success) {
      yield put(fetchUserActivitySuccess(response.data));
    } else {
      yield put(
        fetchUserActivityFail(response?.message || "Something went wrong")
      );
    }
  } catch (error) {
    yield put(
      fetchUserActivityFail(error.message || "An unexpected error occurred")
    );
  }
}

function* fetchSidebarInbox(action) {
  try {
    const apiCall = getUserInbox;
    const response = yield call(apiCall, action.payload);

    if (response?.success) {
      yield put(fetchUserInboxSuccess(response.data));
    } else {
      yield put(
        fetchUserInboxFail(response?.message || "Something went wrong")
      );
    }
  } catch (error) {
    yield put(
      fetchUserInboxFail(error.message || "An unexpected error occurred")
    );
  }
}

function* fetchSidebarNotifications(action) {
  try {
    const apiCall = getUserNotifications;
    const response = yield call(apiCall, action.payload);

    if (response?.success) {
      yield put(fetchSidebarNotificationSuccess(response.data));
    } else {
      yield put(
        fetchSidebarNotificationFail(
          response?.message || "Something went wrong"
        )
      );
    }
  } catch (error) {
    // Handle API or execution errors
    yield put(
      fetchSidebarNotificationFail(
        error.message || "An unexpected error occurred"
      )
    );
  }
}

export default function* floatingSidebarMiddleware() {
  yield all([floatingSidebarWatcher()]);
}
