import { useState } from "react";
import './style.css';

const PreviewImageFile = ({link, alt="Inbox mms"}) => {
    const [isShowImage, setIsShowImage] = useState(false);
    const handleImgLoad = (event) => {
        if(event !== undefined && event != null){
            if(event.type !== undefined && event.type === "load"){
                setIsShowImage(true)
            }
            else{
                setIsShowImage(false)
            }
        }
        else{
            setIsShowImage(false)
        }
    }
    const handleImgError = (event) => {
        if(event !== undefined && event != null){
            if(event.type !== undefined && event.type === "error"){
                setIsShowImage(false)
            }
        }
    }
    return (
        <>
            <img 
                alt={alt}
                src={link}
                onLoad={handleImgLoad}
                onError={handleImgError}
                id="PreviewImageFile_id"
                className={isShowImage ? "image_preview_component_show": "image_preview_component_hide"}
            />
            {!isShowImage && 
            <div className="image_preview_component_hide_hints">Invalid file ! Can not open file. <a href={link} target="_blank" rel="noreferrer" >Link</a></div>
            }
        </>
    );
}
export default PreviewImageFile;