import {useContext, useState, useEffect} from 'react'
import ApiList from "../../ApiList"
import CustomAccordion, { CustomAccordionBody, CustomAccordionHeader } from "../../components/Accordion/CustomAccordion"
import { icons } from "../../icons"
import { ContactDetailContext } from "../../reducer/GlobalReduers"
import ListIcon from '@material-ui/icons/List';
import EachField from './EachField'
import DynamicSkeleton from '../../../common/DynamicSkeleton'


const CustomField = (props) => {
    const {detailStates} = useContext(ContactDetailContext)
    const [expand, setExpand] = useState(false)
    const [customFields, setCustomFields] = useState([])
    const [conactCustomFields, setConactCustomFields] = useState([])
    const [fetchingData, setFetchingData] = useState(false)

    useEffect(() => {
        if(expand && detailStates.contactId != null) {
            setFetchingData(true)
            fetchCustomField()
        }
    }, [expand, detailStates.contactId])

    const fetchCustomField = () => {
        // ApiList.getUserCustomField().then(res => {
        //     let response = res.data
        //     setCustomFields(response.data)
        //     setFetchingData(false)
        // })
        window.globalCustomFieldWithPersonalized({
            get_custom_data: true,
            get_personalized_data: false,
            field_name: ['*'],
            for_drop_down: false,
            drop_down_field: {
            value_field: 'id',
            title_field: 'title'
            }
            }).then(res => {
            let customFieldDatas = res.data.custom_field;
            setCustomFields(customFieldDatas);
            setFetchingData(false)
            })
        setConactCustomFields(detailStates.contactDetails.all_contact_custom_fields)
    }

    const updateFieldData = () => {
        ApiList.getContactDetailsById({id : detailStates.contactId}).then(res => {
            let response = res.data
            setConactCustomFields(response.data.people.all_contact_custom_fields)
        })
    }

    const renderFields = () => {
        let fields = []
        customFields.map((item, index) => {
            fields.push(
                <EachField 
                    item = {item}
                    index={index}
                    key={index}
                    conactCustomFields={conactCustomFields}
                    updateFieldData={updateFieldData}
                />
            )
        })
        return fields;
    }

    return(
        <div className="g-more-info-left g-more-info-column">
            <CustomAccordion expand={expand} className="g-contact-details-collapsible">
                <CustomAccordionHeader onClick={() => setExpand(!expand)} className={`g-collapsible-header ${expand ? 'expand' : ''}`}>
                    <span class="g-header-title"><ListIcon /> Custom Fields </span>
                    <span className="g-custom-caret">{icons.caretIcon}</span>
                </CustomAccordionHeader>
                <CustomAccordionBody className="g-collapsible-body" expand={expand}>
                    {fetchingData ? 
                     <DynamicSkeleton/>
                    :
                    <div className="g-contact-custom-fields awesome__scroll_bar">
                        {customFields.length === 0 ?
                            <span className="g-empty-custom-field">No custom field added!</span>
                        :
                            renderFields()
                        }
                    </div>
                    }
                </CustomAccordionBody>
            </CustomAccordion>
        </div> 
    )
}
export default CustomField;