import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ContactInfo from "./contactInfo/ContactInfo";
import ContactBody from "./contacts/contactBody/ContactBody";
import MoreInfo from "../MoreInfo";
import { RightSideStyle } from "./RightSideStyle";
import Utils from "../Helpers/Utils";
import Policy from "../moreInfo/policy/Policy";
import { getSourceListDropdown } from "../../../api/contactApi";
import { AGENCY_BUSINESS_TYPE } from "../../../helpers/CoreConstant";

const RightSide = (props) => {
  const { contactDetails } = props || {};
  const { customScrollBar } = RightSideStyle();
  const [sourceList, setSourceList] = useState([]);

  useEffect(() => {
      sourceListDropdown();
  },[]);
  const sourceListDropdown = () => {
    getSourceListDropdown().then((res)=>{
        setSourceList(res.data)
    }).catch((err) => {
        console.error('get source list ', err)
    });
}
  return (
    <Box>
      <ContactBody
        closeModal={props.closeModal}
        contactDetails={contactDetails}
      />
      <Box sx={{ marginTop: "12px",height: "calc(100vh - 276px)", overflowY: "auto",paddingRight:'5px' }}   className={customScrollBar}>
      {Utils.getAccountData("agencyBusinessType") &&
        Utils.getAccountData("agencyBusinessType") === AGENCY_BUSINESS_TYPE.INSURANCE && (
          <Policy  sourceList={
            sourceList !== undefined && sourceList != null ? sourceList : null
          }/>
        )}
        <ContactInfo />
        <MoreInfo 
        contactDetails={contactDetails}
        sourceList={sourceList}
         />
      </Box>
    </Box>
  );
};

export default RightSide;
