const inboxUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/inbox`;
 const tenantBackendV1 = `${process.env.REACT_APP_PYPEPRO_TENANT_BACKEND_SERVER}/api/v1`;
const dealUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/deal`
const Api = {
    conversations: `${inboxUrl}/contact-conversation-v2`,
    markAsImportant: `${inboxUrl}/mark-as-important`,
    deleteContact: `${inboxUrl}/contact/delete`,
    archiveContact: `${inboxUrl}/contact/archive`,
    markAsUnread: `${inboxUrl}/mark-as-unread`,
    emails: `${inboxUrl}/user-email-list`,
    virtualNumbers: `${inboxUrl}/virtual-number-list`,
    getContactPolicyCategoryApi:`${tenantBackendV1}/contact/get-policy-category`,
    addContactPolicyApi:`${dealUrl}/user-policies`,
    updateContactPolicyApi:`${tenantBackendV1}/contact/contact-policy`,
    createUserPolicyApi:`${tenantBackendV1}/contact/contact-policy`,
    deleteContactPolicyApi:`${tenantBackendV1}/contact/contact-policy`,
    updateContactPolicyStatusApi:`${tenantBackendV1}/contact/contact-policy/update-status`,
    getContactPolicyApi:`${tenantBackendV1}/contact/contact-policy`,
    getPolicyApi: `${tenantBackendV1}/contact/get-policy-data`,
}

export default Api;