import HttpRequest from '../../../api/HttpRequest';
import { sendRequest, sendRequestBackEnd } from '../../../api/rootApi';
import Api from "./Api";

export const fetchConversation = async (payload) => {
    return sendRequest("post", Api.conversations, payload);
}
export const formBuilderAWSPreSignedUrl = async (payload) =>{
    return sendRequest("post", Api.formBuilderAWSPreSignedUrl, payload);
}
export const sendMessage = async (payload) => {
    return sendRequest("post", Api.sendMessage, payload);
}
export const markAsImportant = async (payload) => {
    return sendRequest("post", Api.markAsImportant, payload);
}
export const deleteContact = async (payload) => {
    return sendRequest("post", Api.deleteContact, payload);
}
export const archiveContact = async (payload) => {
    return sendRequest("post", Api.archiveContact, payload);
}
export const markAsUnread = async (payload) => {
    return sendRequest("post", Api.markAsUnread, payload);
}
export const fetchVirtualNumber = async (payload) => {
    return sendRequest("post", Api.virtualNumbers, payload);
}

export const addContactPolicy = async (payload) => {
    return HttpRequest.post( Api.addContactPolicyApi, payload);
}

export const getContactPolicyCategory = async (payload) => {
    return HttpRequest.get(Api.getContactPolicyCategoryApi, payload);
}
export const createUserPolicy = async (payload) => {
    return HttpRequest.post( Api.createUserPolicyApi, payload);
}

export const updateContactPolicy = async (payload) => {
    return HttpRequest.put( Api.updateContactPolicyApi, payload);
}
export const deleteContactPolicy = async (payload) => {
    return HttpRequest.delete( Api.deleteContactPolicyApi, payload);
}
export const updateContactPolicyStatus = async (payload) => {
    return HttpRequest.put(Api.updateContactPolicyStatusApi, payload);
}

export const getContactPolicies = (payload) => {
    return HttpRequest.get(Api.getContactPolicyApi, payload);
}

export const getPolicyCompanyAgentData = (payload) => {
    return HttpRequest.get(Api.getPolicyApi, payload);
}
