import {useContext, useState} from 'react';
import SendEmailHelper from '../assets/Helper';
import {
    storeBccMailInfo,
    storeCcMailInfo,
    updateCcAndBccFlag,
    updateToEmail,
    updateToEmailSingle
} from '../reducers/Action';
import { EmailSendContext } from "../reducers/GlobalReduers"
import CustomSelect from "../components/CustomSelect";
import {Chip, MenuItem} from "@material-ui/core";
import BasicInput from "../../../custom/BasicInput"
import GlobalContactRelatedContactSelectorInsurance
    from "../../../GlobalContactRelatedContactSelectorInsurance/GlobalContactRelatedContactSelectorInsurance";

const ToEmail = (props) => {
    const {states, dispatch} = useContext(EmailSendContext)
    const [removeRequest, setRemoveRequest] = useState(null);

    const hanldeToEmail = (value) => {
        dispatch(updateToEmail(value))

        // single to email select on primary email
        dispatch(updateToEmailSingle(value));

        if (Array.isArray(states.ccMails)){
            if (states.ccMails.includes(value.email)){
                dispatch(storeCcMailInfo({
                    ccMails: [...states.ccMails.filter((email)=>email !== value.email), states.selectedToEmail.email]
                }));
            }else {
                dispatch(storeCcMailInfo({
                    ccMails: [...states.ccMails.filter((email)=>email !== value.email)]
                }));
            }
        }
    }

    // for cc mail option
    const addCcMail = (e) => {
        var code = (e.keyCode ? e.keyCode : e.which);
        if(code == 13) {
            if(e.target.value == ''){
                dispatch(storeCcMailInfo({
                    isCcValid: false, 
                    showCcValidMessage: 'Enter a mail address to add CC'
                }))
            }
            else if(!SendEmailHelper.validateEmail(e.target.value)){
                dispatch(storeCcMailInfo({
                    isCcValid: false, 
                    showCcValidMessage: 'Enter a valid mail address to add CC'
                }))
            }
            else if(states.ccMails.includes(e.target.value)){
                dispatch(storeCcMailInfo({
                    isCcValid: false, 
                    showCcValidMessage: 'This mail already added in CC'
                }))
            }
            else{
                let newData = [...states.ccMails];
                newData.push(e.target.value)
                dispatch(storeCcMailInfo({
                    ccMails: newData, 
                    isCcValid: true,
                    textToAddCc: ''
                }))
            }
        }
    }
    const addCcMailChange = (event) => {
        dispatch(storeCcMailInfo({
            textToAddCc: event.target.value,  
            isCcValid: true, 
            showCcValidMessage: ''
        }))
    }
    const removeFromCc = (item, index) => {
        setRemoveRequest(item);
        const oldData = [...states.ccMails];
        oldData.splice(index, 1)
        dispatch(storeCcMailInfo({
            ccMails: oldData
        }))
    }
    const handleCcOption = (status) => {
        dispatch(updateCcAndBccFlag({activeCc: status}))
        setRemoveRequest(-1);
        if(!status){
            dispatch(storeCcMailInfo({
                ccMails: [...states.defaultCc],
                textToAddCc: '',  
                isCcValid: true, 
                showCcValidMessage: ''
            }))
        }
    }
    // cc end
    // bcc start
    const handleBccOption = (status) => {
        dispatch(updateCcAndBccFlag({activeBcc: status}))
        if(!status){
            dispatch(storeCcMailInfo({
                bccMails: []
            }))
        }
    }
    const addBccMail = (e) => {
        var code = (e.keyCode ? e.keyCode : e.which);
        if(code == 13) {
            if(e.target.value == ''){
                dispatch(storeBccMailInfo({
                    isBccValid: false, 
                    showBccValidMessage: 'Enter a mail address to add BCC'
                }))
            }
            else if(!SendEmailHelper.validateEmail(e.target.value)){
                dispatch(storeBccMailInfo({
                    isBccValid: false, 
                    showBccValidMessage: 'Enter a valid mail address to add BCC'
                }))
            }
            else if(states.bccMails.includes(e.target.value)){
                dispatch(storeBccMailInfo({
                    isBccValid: false, 
                    showBccValidMessage: 'This mail already added in BCC'
                }))
            }
            else{
                let newData = [...states.bccMails];
                newData.push(e.target.value)
                dispatch(storeBccMailInfo({
                    bccMails: newData, 
                    isBccValid: true,
                    textToAddBcc: '',
                    showBccValidMessage: ''
                }))
            }
        }
    }
    const addBccMailChange = (event) => {
        dispatch(storeBccMailInfo({
            textToAddBcc: event.target.value,  
            isBccValid: true, 
            showBccValidMessage: ''
        }))
    }
    const removeFromBcc = (item, index) => {
        const oldData = [...states.bccMails];
        oldData.splice(index, 1)
        dispatch(storeBccMailInfo({
            bccMails: oldData
        }))
    }

    // bcc end
    return(
        <>
            <div className="g-to-email-content email_send_row to__email__v2">
                <h3 className="g-to-email-text">To</h3>
                {
                    states.emailId == null &&
                    <CustomSelect
                        formControlClass="g-to-email-select"
                        value={states.selectedToEmail}
                        handleChange={(event) => hanldeToEmail(event.target.value)}
                        placeholder={(
                            <MenuItem className="dropdownhelper-menuitem-class" value="" disabled> Select a mail
                                address </MenuItem>)}
                        item={states.allToEmails}
                        titel_field={'label'}
                        menuItemClass="dropdownhelper-menuitem-class"
                        renderValue={(selected) => {
                            if (selected == '') {
                                return <em>Select a mail address</em>;
                            }
                            return selected.label
                        }}

                    />
                }

                {
                    states.emailId != null &&
                    <input className="to__email__v2__to__input" type="text" value={states.emailId} readOnly></input>
                }

                <span className={`g__email_modal_add_cc_button${states.activeCc ? ' active-cc-bcc' : ''}`}
                      onClick={() => handleCcOption(true)}>Add CC</span>
                <span className={`g__email_modal_add_bcc_button${states.activeBcc ? ' active-cc-bcc' : ''}`}
                      onClick={() => handleBccOption(true)}>Add BCC</span>
                {
                    !states.relationSuperContactId &&
                    <GlobalContactRelatedContactSelectorInsurance
                        data={{
                            contactId: states.contactId,
                            type: "email",
                            callback: (data, remove) => {
                                let newData = Array.from(new Set([...states.ccMails, ...data.emails]));

                                if (remove.emails[0]) {
                                    newData = newData.filter(item => !remove.emails.includes(item));
                                }

                                if (newData[0]) {
                                    dispatch(updateCcAndBccFlag({activeCc: true}));
                                } else {
                                    dispatch(updateCcAndBccFlag({activeCc: false}));
                                }

                                dispatch(storeCcMailInfo({
                                    ccMails: newData,
                                    isCcValid: true,
                                    textToAddCc: ''
                                }));
                            },
                            removeRequest: removeRequest,
                            removeRequestCallback: () => {
                                setRemoveRequest(null);
                            }
                        }}
                    />
                }
            </div>


            {states.activeCc &&
                <div className="g__email_send_cc_body">
                    <div className="g__email_send_cc_container">
                    <h3 className="g__email_send_cc_title">Cc</h3>
                <div className="g__email_send_cc_content">
                    <div className="g__email_send_cc_mails_content">
                        <div style={{ paddingBottom: "10px", paddingLeft: "1px" }}>
                            <BasicInput
                                onKeyPress={addCcMail}
                                value={states.textToAddCc}
                                onChange={addCcMailChange}
                                placeholder="Add cc mail address"
                                fullWidth
                            />
                        </div>
                        <div className="g__email_send_cc_mails_content__inner">
                            {states.ccMails.map((item, index) => {
                                return (
                                    <Chip className="g__email_send_cc_bcc_chip" key={item + "-" + index} label={item} onDelete={()=>{ removeFromCc(item, index) }}/>
                                )
                            })}
                        </div>
                    </div>
                    <span className="g__email_send_cc_clear_all" onClick={() => handleCcOption(false)}>Clear All</span>
                </div>
                    </div>
                    {!states.isCcValid &&
                        <span className="g__email_send_cc_invalid">{states.showCcValidMessage}</span>
                    }
                </div>
            }

            {states.activeBcc &&
                <div className="g__email_send_cc_body">
                    <div className="g__email_send_cc_container">
                        <h3 className="g__email_send_cc_title" style={{ marginRight: "12px" }}>Bcc</h3>
                        <div className="g__email_send_cc_content">
                            <div className="g__email_send_cc_mails_content">
                                <div style={{ paddingBottom: "10px", paddingLeft: "1px" }}>
                                    <BasicInput
                                        onKeyPress={addBccMail}
                                        value={states.textToAddBcc}
                                        onChange={addBccMailChange}
                                        placeholder="Add bcc mail address"
                                        fullWidth
                                    />
                                </div>
                                <div className="g__email_send_cc_mails_content__inner">
                                    {states.bccMails.map((item, index) => {
                                        return (
                                            <Chip className="g__email_send_cc_bcc_chip" key={item + "-" + index} label={item} onDelete={()=>{ removeFromBcc(item, index) }}/>
                                        )
                                    })}
                                </div>
                            </div>
                            <span className="g__email_send_cc_clear_all"
                                  onClick={() => handleBccOption(false)}>Clear All</span>
                        </div>
                    </div>
                    {!states.isBccValid &&
                        <span className="g__email_send_cc_invalid">{states.showBccValidMessage}</span>
                    }
                </div>
            }
        </>
    )
}
export default ToEmail;