
import React, { useEffect, useRef, useState } from 'react';

const AppAuthentication = () => {
  const iframeRef = useRef();
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const updateIframeHeight = () => {
    if (iframeRef.current) {
      iframeRef.current.style.height = `${window.innerHeight}px`;
    }
  };

  useEffect(() => {
    // Set initial window height
    setWindowHeight(window.innerHeight);

    // Update the height when the window is resized
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      updateIframeHeight();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <iframe
        ref={iframeRef}
        height={windowHeight}
        width="100%"
        src={process.env.REACT_APP_CDN_LINK + 'asset/documment/releases/1.0.2/Authorization-UserManual.pdf'}
        title="App Authentication Documentation"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </>
  );
};

export default AppAuthentication;
