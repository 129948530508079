import React, { useEffect, useState } from 'react'
import { MenuItem, Select, FormControl } from '@material-ui/core';  

const CustomSelect = (props) => {
    const [value, setValue] = useState(props.value)

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    const renderTitle = (text, item) => {
        let title = text
        if(typeof props.modifyTitle != 'undefined'){
            title = props.modifyTitle(text, item)
        }
        return title
    }

    let extra_props = {}
    if(typeof props.renderValue != 'undefined'){
        extra_props['renderValue'] = props.renderValue
    }

    return(
        <FormControl className="alt customFormControl">
            <Select
                disabled={props.disabled || false}
                id={`${props.id || ('simple-select-'+Math.floor(Math.random() * 999999999))}`}
                value={ value } 
                onChange={ props.handleChange }
                displayEmpty
                className={`trigger__select ${typeof props.customClass != 'undefined' ? props.customClass : ''}`}
                inputProps={ { 'aria-label': 'Without label' } }
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null
                }}
                {...extra_props}
            >
                {typeof props.placeholder != 'undefined' && props.placeholder }
                {props.item.map((item, index) => {
                    let key = ('custom-selector-'+ index), value = item
                    if(typeof props.value_field != 'undefined'){
                        if(props.value_field === '__FULL_ITEM__'){
                            value = item
                        }
                        else{
                            value = item[props.value_field]
                            key = 'custom-selector-'+index+'-'+item[props.value_field]
                        }
                    }
                    return(
                        <MenuItem className={typeof props.menuItemClass != 'undefined' ? props.menuItemClass  : ''} value={value} key={key}>{typeof item.icon != 'undefined' && item.icon}{renderTitle(item[props.titel_field], item)}</MenuItem> 
                    )
                })} 
            </Select>
        </FormControl>
    )
}
export default CustomSelect;