import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import BasicInput from "../custom/BasicInput";
import { ArrowForwardIos, KeyboardBackspace } from "@material-ui/icons";
import {
  getSignUpPageData,
  updateLoading,
  updateValidationCheck,
} from "../../actions/signupAction";
import toastr from "toastr";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { checkRegistrationValidate } from "../../api/signupApi";
import BasicSelect from "../custom/BasicSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: (props) => props.agencyThemeColor || "#FFFFFF",
    color: (props) => props.agencyTextColor || "var(--dark_blue)",
  },
  mainContainer: {
    width:'100%',  
    height: 'calc(100% - 75px)'
  },
  loginLinkContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "16px 20px 0px 20px",
    justifyContent: "space-between",
    [theme.breakpoints.down("420")]: {
      padding:'0px'
    },
  },
  linkText: {
    fontSize:'15px !important'
  },
  bodyContainer: {
    width:'100%', 
    height:'100%', 
    display:'flex',
    flexDirection:'column', 
    justifyContent:'center', 
    alignItems:'center',
    '& .MuiSelect-select:focus': {
      backgroundColor: '#fff !important'
    }
  },
  imageContainer: {
    width: "100%",
    maxHeight: "150px",
    display: "flex",
    justifyContent: "center",
    marginTop: "12px",
  },
  image: {
    width: "150px",
    height: "100%",
    objectFit: "contain",
  },
  formContainer: {
    maxWidth: "500px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  formLabel: {
    fontSize: "15px !important",
    fontWeight:600,
    marginBottom:'4px'

  },
  link: {
    textDecoration: "underline",
    color: (props) => props.agencyTextColor || "var(--dark_blue)",
    fontWeight:600,
     fontSize:'14px !important'
  },
  checkbox: {
    color: (props) => props.agencyTextColor || "var(--dark_blue)",
    "&.Mui-checked": {
      color: (props) => props.agencyTextColor || "var(--dark_blue)",
    },
  },
  button: {
    border: "unset",
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "24px",
    textAlign: "center",
    letterSpacing: ".01em",
    color: "#fff",
    width: "100%",
    minHeight:'38px',
    cursor: "pointer",
    padding: "6px 0",
    textTransform: "capitalize",
    backgroundColor: (props) => props.accentColor || "var(--light_blue)",
    borderRadius: "5px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover ,&:focus": {
      backgroundColor: (props) => props.accentColor || "var(--light_blue)",
    },
  },
  textWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "40px",
  },
  discription: {
    height: "unset !important",
    fontSize:'11px !important',
    textAlign: "center",
    [theme.breakpoints.down("768")]: {
      maxWidth: "100%",
    },
  },
  refreshButton: {
    width:'100%',
    minHeight:'32px',
    color: "var(--light_blue)",
    textTransform: "capitalize",
    borderColor: "var(--light_blue)",
    "&:focus,&:hover": {
      background: "transparent",
    },
    "& .MuiButton-label": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  errorText: {
    fontSize: "12px",
    color: "var(--red)",
    marginLeft: "12px",
  },
  welComeText: {
    height: "unset !important",
    fontWeight: 600,
    fontSize: "32px",
    textAlign:'center',
    [theme.breakpoints.down("520")]: {
      fontSize: "26px",
    },
    [theme.breakpoints.down("450")]: {
      fontSize: "21px",
    },
    [theme.breakpoints.down("400")]: {
      fontSize: "18px",
    },

  },
  welComeTextCompanyTab: {
    height: "unset !important",
    fontWeight: 600,
    fontSize: "24px",
    textAlign:'center',
    marginBottom:'32px',
    [theme.breakpoints.down("450")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.down("400")]: {
      fontSize: "18px",
    },
  }
}));
const tab = {
  personalInfoTab: 0,
  companyNameTab: 1,
  hearAboutTab: 2,
};
const SignUpFormInsurance = (props) => {
  const {
    signupType,
    pageLogo,
    accentColor,
    agencyThemeColor,
    agencyTextColor,
    agencyName,
    privacyPolicy,
    termsAndConditions,
  } = props;
  const classes = useStyles({ agencyThemeColor, agencyTextColor, accentColor });
  const insuranceCompanyCategory = 1;
  const [aboutUsItems, setAboutUsItems] = useState(props.aboutUsItems);
  const [companyName, setCompanyName] = useState("");
  const [companyCategory, setCompanyCategory] = useState(insuranceCompanyCategory);
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [aboutUsItem, setAboutUsItem] = useState("");
  const recaptchaRef = useRef(null);
  const [isPolicyAndConditionChecked, setIsPolicyAndConditionChecked] =
    useState(true);
  const [currentTab, setCurrentTab] = useState(0);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setAboutUsItems(props.aboutUsItems);
  }, [props.aboutUsItems]);


  const validateFields = () => {
    const newErrors = {};

    if (!fullName.trim()) {
      newErrors.fullName = "Full Name is required.";
    }

    if (!email.trim()) {
      newErrors.email = "Email Address is required.";
    } else if (!validateEmail(email)) {
      newErrors.email = "Invalid Email Address.";
    }

    if (!phoneNumber.trim()) {
      newErrors.phoneNumber = "Phone Number is required.";
    }

    if (!isPolicyAndConditionChecked) {
      newErrors.isPolicyAndConditionChecked =
        "You must agree to the Terms and Privacy Policy.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const checkLetterExistInText = (text) => {
    var regExp = /[a-zA-Z]/g;
    if (!regExp.test(text)) {
      setPhoneNumber(text);
      if (errors.phoneNumber) {
        setErrors((prevState) => ({ ...prevState, phoneNumber: "" }));
      }
    }
  };

  const validateEmail = (email) => {
    //eslint-disable-next-line
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
    if (email.match(regexEmail)) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = () => {
    if (currentTab === tab.personalInfoTab) {
      if (!validateFields()) {
        return;
      }
      checkValidationApi();
    } else if (currentTab === tab.companyNameTab) {
      if (!companyName) {
        setErrors({ companyName: "Company Name is required" });
        return;
      }

      setErrors({});
      setCurrentTab(currentTab + 1);

    }
    else if (currentTab === tab.hearAboutTab) {
      if (!aboutUsItem) {
        setErrors({ aboutUsItem: "Please Select a Options" });
        return;
      }
      setErrors({});
      submitForm();
    } else {
      console.error("Invalid tab state");
    }
  };

  const checkValidationApi = async () => {
    const formData = new FormData();
    formData.append("company_name", "companyName");
    formData.append("name", fullName);
    formData.append("phone", phoneNumber);
    formData.append("email", email);
    formData.append("hear_about_us_item_id", "aboutUsItem");
    formData.append("company_category_id", "companyCategory");
    formData.append("agency_id", props.agencyId);

    try {
      const response = await checkRegistrationValidate(formData);

      if (response.data.status === "success") {
        setCurrentTab(currentTab + 1);
      } else {
        const errors = Object.entries(response.data.html).map(
          ([key, value]) => `${key}: ${value}`
        );

        toastr.error(errors);
        return true;
      }
    } catch (error) {

      console.error("Error during form submission:", error);
    } finally {
      updateLoading(false);
    }
  };

  const submitForm = async () => {
    const formData = new FormData();
    formData.append("company_name", companyName);
    formData.append("name", fullName);
    formData.append("phone", phoneNumber);
    formData.append("email", email);
    formData.append("hear_about_us_item_id", aboutUsItem);
    formData.append("company_category_id", companyCategory);
    formData.append("agency_id", props.agencyId);

    try {
      updateLoading(true);

      const response = await checkRegistrationValidate(formData);

      if (response.data.status === "success") {
        props.updateValidationCheck({
          validationCheck: true,
          companyName: companyName,
          fullName: fullName,
          email: email,
          phoneNumber: phoneNumber,
          aboutUsItem: aboutUsItem,
          companyCategory: companyCategory,
          signupType: signupType,
        });
      } else {
        const errors = Object.entries(response.data.html).map(
          ([key, value]) => `${key}: ${value}`
        );
        toastr.error(errors);
      }
    } catch (error) {
      console.error("Error during form submission:", error);
    } finally {
      updateLoading(false);
    }
  };


  return (
    <div className={classes.root}>
      <div className={classes.mainContainer}>
        <div className={classes.loginLinkContainer}>
        <div>
          { (currentTab !== tab.personalInfoTab) && ( 
            <Button
            onClick={() => setCurrentTab(currentTab - 1)}
            variant="outlined"
            className={classes.refreshButton}
            startIcon={<KeyboardBackspace />}
          >
            Back
          </Button>
          )}
          </div>
          <div className={classes.linkText}>
            Have an account? <a style={{fontWeight:600}} href="/login">Sign in</a>
          </div>
        </div>
        <div className={classes.bodyContainer}>
        <div className={classes.imageContainer}>
          <img
            className={classes.image}
            src={pageLogo}
            alt="Logo"
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width:'100%'
          }}
        >
          <div className={classes.formContainer}>
            {currentTab === tab.personalInfoTab && (
              <Grid
                container
                spacing={2}
                style={{ paddingInline: 5, marginTop: "16px", width: "100%" }}
              >
                 <Grid item xs={12} style={{display:'flex',flexDirection:'column', alignItems:'center', justifyContent:'center' }}>
               <Typography
                  className={classes.welComeText}
                >
                  {`Close more deals with ${agencyName}.`}
                </Typography> 
               { (props.agencyId === 236 && signupType ==='trial') && ( 
                <Typography className={classes.welComeText}
                >
                  Start your free 14-day trial!
                </Typography>
              )}
               </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" className={classes.formLabel}>
                    Full Name <span>*</span>
                  </Typography>
                  <BasicInput
                    name="fullName"
                    value={fullName}
                    onChange={(e) => {
                      setFullName(e.target.value);
                      if (errors.fullName) {
                        setErrors((prevState) => ({
                          ...prevState,
                          fullName: "",
                        }));
                      }
                    }}
                    fullWidth
                    placeholder="Enter Full Name"
                  />
                  {errors.fullName && (
                    <div className={classes.errorText}>{errors.fullName}</div>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" className={classes.formLabel}>
                    Email Address <span>*</span>
                  </Typography>
                  <BasicInput
                    name="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      if (errors.email) {
                        setErrors((prevState) => ({ ...prevState, email: "" }));
                      }
                    }}
                    fullWidth
                    placeholder="Enter Email Address"
                  />
                  {errors.email && (
                    <div className={classes.errorText}>{errors.email}</div>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" className={classes.formLabel}>
                    Phone Number <span>*</span>
                    <span style={{fontSize:'13px'}}>(Number must be able to receive a text)</span>
                  </Typography>
                  <BasicInput
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={(e) => checkLetterExistInText(e.target.value)}
                    fullWidth
                    placeholder="Enter Phone Number"
                  />
                  {errors.phoneNumber && (
                    <div className={classes.errorText}>
                      {errors.phoneNumber}
                    </div>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size={"small"}
                        className={classes.checkbox}
                        checked={isPolicyAndConditionChecked}
                        onChange={() => {
                          setIsPolicyAndConditionChecked(
                            (prevChecked) => !prevChecked
                          );
                          if (errors.isPolicyAndConditionChecked) {
                            setErrors((prevState) => ({
                              ...prevState,
                              isPolicyAndConditionChecked: "",
                            }));
                          }
                        }}
                      />
                    }
                    label={
                      <Typography
                        style={{
                          fontSize: "14px",
                          color: agencyTextColor || "var(--dark_blue)",
                        }}
                      >
                        I agree to the{" "}
                        {termsAndConditions ? (
                          <a className={classes.link} href={termsAndConditions}>
                            Terms And Conditions
                          </a>
                        ) : (
                          <Link
                            className={classes.link}
                            to="/terms-and-conditions"
                          >
                            Terms & Conditions
                          </Link>
                        )}
                        {" and "}
                        {privacyPolicy ? (
                          <a className={classes.link} href={privacyPolicy}>
                            Privacy Policy
                          </a>
                        ) : (
                          <Link className={classes.link} to="/privacy-policy">
                            Privacy Policy
                          </Link>
                        )}
                      </Typography>
                    }
                  />
                  {errors.isPolicyAndConditionChecked && (
                    <div className={classes.errorText}>
                      {errors.isPolicyAndConditionChecked}
                    </div>
                  )}
                </Grid>
              </Grid>
            )}

            {currentTab === tab.companyNameTab && (
              <Grid
                container
                spacing={2}
                style={{ paddingInline: 5, marginTop: "40px", width: "100%" }}
              >
                <Grid item xs={12}>
                  <div className={classes.welComeTextCompanyTab}
                  >
                    Nice to meet you, <span style={{fontWeight:600}}>{fullName}</span>
                  </div>
                  <div className={classes.formLabel}>
                    What is your company's name? <span>*</span>
                  </div>
                  <BasicInput
                  style={{marginBottom:'16px'}}
                    name="companyName"
                    value={companyName}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                      if (errors.companyName) {
                        setErrors((prevState) => ({
                          ...prevState,
                          companyName: "",
                        }));
                      }
                    }}
                    fullWidth
                    placeholder="Enter Company Name"
                  />
                  {errors.companyName && (
                    <div className={classes.errorText}>
                      {errors.companyName}
                    </div>
                  )}
                </Grid>
              </Grid>
            )}

            {currentTab === tab.hearAboutTab && (
              <Grid
                container
                spacing={2}
                style={{ paddingInline: 5, marginTop: "40px",marginBottom:'16px' }}
              >
                <Grid item xs={12}>
                  <div className={classes.formLabel}>
                    How Did You Hear About Us? <span>*</span>
                  </div>
                  <BasicSelect
                    fullWidth
                    name="aboutUs"
                    defaultText="Select a option"
                    options={aboutUsItems}
                    mapping={{ label: "title", value: "id" }}
                    value={aboutUsItem}
                    onChange={(e) => {
                      setAboutUsItem(e.target.value);
                      if (errors.aboutUsItem) {
                        setErrors({ ...errors, aboutUsItem: "" });
                      }
                    }}
                  />
                  {errors.aboutUsItem && (
                    <div className={classes.errorText}>
                      {errors.aboutUsItem}
                    </div>
                  )}
                </Grid>
              </Grid>
            )}

            <Grid
              container
              spacing={2}
              style={{ paddingInline: 5, marginTop: "16px" }}
            >
              <Grid item xs={12}>
                <Button
                  className={classes.button}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  {currentTab === tab.hearAboutTab ? (
                    "Submit"
                  ) : (
                    <>
                      Next
                      <span style={{ marginLeft: "6px" }}>
                        <ArrowForwardIos
                          style={{ paddingTop: "5px" }}
                          fontSize="small"
                        />
                      </span>
                    </>
                  )}
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.textWrapper}>
          <Typography className={classes.discription}>
          {`By submitting this form, you are giving your consent to receive
  communications from ${agencyName} via email, text `} <br />
  {`message, automatic telephone dialing systems and artificial/pre-recorded messages. Message and data 
  `} <br />
  {`rates may apply. Message frequency varies. Reply HELP for help and STOP to unsubscribe.`}
          </Typography>
        </div>
        </div>
      </div>
      {props.googleCaptchaSiteKey && (
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={props.googleCaptchaSiteKey.value}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    agencyId: state.signupReducer.agencyId,
    aboutUsItems: state.signupReducer.aboutUsItems,
    companyCategories: state.signupReducer.companyCategories,
    packages: state.signupReducer.packages,
    googleCaptchaSiteKey: state.signupReducer.googleCaptchaSiteKey,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSignUpPageData: (params) => dispatch(getSignUpPageData(params)),
    updateValidationCheck: (params) => dispatch(updateValidationCheck(params)),
    updateLoading: (params) => dispatch(updateLoading(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpFormInsurance);
