import { Box } from "@material-ui/core";
import React, {useEffect, useState} from "react";
import ContactTitle from "./ContactTitle";
import { LeadStatus } from "../LeadStatus/LeadStatus";
import Conversation from "./Conversation";
import ContactActions from "./ContactActions";
import { connect } from "react-redux";

// Destructure all necessary props here, including those from Redux
const ContactBody = ({ closeModal, handleOpen, contactDetails, contact }) => {

    const [lastCommunicationMessage, setLastCommunicationMessage] = useState('No Conversation yet!');

    useEffect(() => {
        getLastCommunicationInfo();
    }, [contact]);

    const getLastCommunicationInfo = () => {
        setLastCommunicationMessage(contact?.contact_activity?.local_last_communication?.for_human
            ? contact.contact_activity.local_last_communication.for_human : "No conversation yet!")
    }

    return (
        <Box
            sx={{
                paddingX: 1,
                paddingY: 4,
                border: "1px solid",
                borderRadius: "8px",
                backgroundColor: "#181F48",
                color: "#FFFFFF",
                position: 'relative',
                "&:hover .contactTitleAction": { opacity: 1 }
            }}
        >
            <ContactTitle handleOpen={handleOpen} contactDetails={contactDetails} />
            <LeadStatus contactDetails={contactDetails}/>
            <Conversation lastCommunicationMessage={lastCommunicationMessage}/>
            {/* <ContactActions closeModal={closeModal}/> */}
        </Box>
    );
};

const mapStateToProps = state => ({
    contact: state.addContactReducer.contact,
});

const mapDispatchToProps = dispatch => ({
    // Actions if you have any can be mapped here
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactBody);
