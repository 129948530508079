import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme)=>({
    root: {
        position: 'relative',
        '&.Mui-disabled': {
            color: '#fff',
        },
        '& .MuiCircularProgress-root': {
            position: 'absolute',
            top: '30%',
            left: '10px',
            color: 'white'
        }
    }
}));

const LoaderButton = ({ loading, children, ...rest }) => {
    const classes = useStyles();

    return (
        <Button
            {...rest}
            disabled={loading}
            classes={classes}
        >
            {loading && <CircularProgress size={12}/>}
            {loading ? 'Loading...' : children}
        </Button>
    );
};

export default LoaderButton;
