import moment from "moment-timezone";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { appendSiteNotification, closeSiteNotification, fetchSiteNotification } from "../../actions/notificationAction";
import { updateSiteNotificationApi } from "../../api/notificationApi";
import { SITE_NOTIFICATION } from "../../constants/CoreConstants";
import Utils from "../../helpers/Utils";
import OnScrollFetchBox from "../common/OnScrollFetchBox";
import { CampaignTypeNotification } from "./CampaignTypeNotification";
import OutcomeStatus from "./OutcomeStatus";
import "./Notifications.css"
import {RenderSkeleton} from "./RenderSkeleton";

const SiteNotifications = props => {

    const dispatch = useDispatch();
    const { siteNotifications, siteNotificationsLoaded } = useSelector(state => state.notificationReducer);

    useEffect(() => {
        if(!siteNotificationsLoaded) {
            dispatch(fetchSiteNotification())
        }
    },[]);
    
    const handleFetchSiteNotification = () => {
        dispatch(appendSiteNotification())
    }

    const closeNotification = (id,index) => {
        dispatch(closeSiteNotification({
            index : index,
            notification_id : id
        }))
    }

    const getHtmlText = (message) => {
        
        //eslint-disable-next-line
        let regix = /\@.*?\)/g;
        let matchString = message.match(regix);
        if (matchString !== null) {
            matchString.forEach((str) => {
                //eslint-disable-next-line
                let eachStr = str.match(/@\[(.*)\]\(.*\)/);
                if(eachStr != null)
                    message = message.replace(str, `<b>@${eachStr[1]}</b>`);
            });
        }
        return message.replace(/\n/g, "<br />");
    }

    const handleEachItemOnClick = (index, item) => {
        props.closePopup()
        siteNotifications[index].seen = 1
        updateNotificationStatus(item, 1)
    }

    const updateNotificationStatus = (item, status = 1) => {
        updateSiteNotificationApi({
            id: item.id,
            status: status
        }).then(res => {
            let response = res.data;
            if(response.status === "success"){
                window.updateNotificationCount();
            }
        })
    }

   

    const renderSiteNotifications = () => {
        if(!siteNotifications) {
            return (
                <div className="notification-skeleton-parent">
                   <RenderSkeleton/>
                </div>
            )
        }else{
            if(siteNotifications.length===0){
                return (
                    <div className="notification-skeleton-parent">
                         <div className="no-notification">No notifications</div>
                    </div>
                   
                );
            }else{
                return siteNotifications.map((notification,index) => {

                let usageDate = '';
                if(notification.created_at !== undefined){
                    let userTimeZone = Utils.getAccountData('userTimezoneRaw');
                    try{
                        usageDate = moment.utc(notification.created_at).tz(userTimeZone).format('lll');

                    }catch(e){
                        console.log(e);
                    }
                }


                if(notification.contact_id === null) {

                    if(notification.type !== SITE_NOTIFICATION.CAMPAIGN_PAUSE) {
                        return null;
                    }

                    return <CampaignTypeNotification
                                key={index}
                                notification={notification}
                                usageDate={usageDate}
                                onClose={() => closeNotification(notification.id,index)}
                                onDataClick={() => handleEachItemOnClick(index, notification)}/>
                }

                const fullName = notification.contact ? notification.contact.first_name + ' ' + notification.contact.last_name : '';
                const number = notification.contact ? notification.contact.number : '';
                const email = notification.contact ? notification.contact.email : '';
                let message = notification.message.replace(/<head>[\s\S]*?<\/head>/, '').replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;|&lt;|&gt;|&amp;|&quot;|&apos;|&cent;|&pound;|&yen;|&euro;|&copy;|&reg;|\\n/g, '').trim();
                message = message.length > 200 ? message.slice(0, 200)+'...' : message;

                return <div key={index} className={`people-list-items d-flex align-items-center ${notification.seen === 0 ? 'unseen' : ''}`}>
                    <Link
                        to={{
                            pathname: `/contacts/${notification.contact.id}`,
                            state: {
                                for: "show-mention-user",
                                notificationType: notification?.type
                            }
                        }}
                        // to={"/contacts/"+notification.contact.id}
                        onClick={e => handleEachItemOnClick(index, notification)} className="d-flex align-items-center"><span
                        className="avatar d-flex align-items-center justify-content-center">{Utils.getInitial(fullName)}</span>
                        <div className="notification-name">
                            <p className="name"> {Utils.getContactDisplayName(fullName,number,email,true)}
                                <span className="notification-time ml-5">{usageDate}</span>
                            </p>
                            <p className="title" dangerouslySetInnerHTML={{__html : getHtmlText(message)}}/>
                            <OutcomeStatus notification={notification}/>
                            
                        </div>
                        <div className="notification-time" onClick={e => {e.preventDefault();e.stopPropagation(); closeNotification(notification.id,index)}}>
                            <svg width="21" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="m14.383 16.637 2.01-2.012L6.135 4.361l-2.01 2.011 10.258 10.265z" fill="#FF264A"/>
                                <path d="M6.135 16.64 16.393 6.374l-2.01-2.012L4.125 14.627l2.01 2.012z" fill="#FF264A"/>
                            </svg>
                        </div>
                    </Link>
                </div>
                })
            }    
            
        }
       
    }
    
    return ( 
        <OnScrollFetchBox 
            className="people-list-content awesome__scroll_bar" scrollCallBack={handleFetchSiteNotification}>
            {renderSiteNotifications()}
        </OnScrollFetchBox>
        
    );
}
 
export default SiteNotifications;