import React, {Component} from "react";
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import Utils from "../Helpers/Utils";

let recordAudio;
let videoStream;
var totalSeconds = 0;
let url = window.location.protocol + '//' + window.location.hostname;

function pad(val) {
    var valString = val + "";
    if (valString.length < 2) {
        return "0" + valString;
    } else {
        return valString;
    }
}

const icons = {
    stop :  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.7547 7.99414H8.25913C8.12756 7.99414 8.01953 8.09732 8.01953 8.23097V13.7861C8.01953 13.917 8.12687 14.0243 8.25913 14.0243H13.7547C13.8863 14.0243 13.9929 13.9177 13.9929 13.7861V8.23097C13.9922 8.09801 13.8863 7.99414 13.7547 7.99414Z" fill="#FF264A"/>
                <path d="M11.0061 0.592285C5.2606 0.592285 0.601562 5.24993 0.601562 10.9969C0.601562 16.7438 5.2606 21.4014 11.0061 21.4014C16.7524 21.4014 21.4093 16.7438 21.4093 10.9969C21.4093 5.24993 16.7524 0.592285 11.0061 0.592285ZM11.0061 19.6633C6.21969 19.6633 2.33901 15.7826 2.33901 10.9969C2.33901 6.20902 6.21969 2.32904 11.0061 2.32904C15.7933 2.32904 19.6719 6.20902 19.6719 10.9969C19.6719 15.7826 15.7933 19.6633 11.0061 19.6633Z" fill="#FF264A"/>
            </svg>,
    audio:  <svg width="15" height="23" viewBox="0 0 15 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.58602 0.799316C5.49026 0.799316 3.78516 2.50442 3.78516 4.60018V12.0703C3.78516 14.1658 5.49026 15.8709 7.58602 15.8709C9.68179 15.8709 11.3869 14.1776 11.3869 12.0964V4.60018C11.3869 2.50442 9.68179 0.799316 7.58602 0.799316ZM9.80295 12.0961C9.80295 13.304 8.80837 14.2869 7.58602 14.2869C6.36368 14.2869 5.36885 13.2926 5.36885 12.07V4.60018C5.36885 3.37758 6.36342 2.38301 7.58602 2.38301C8.80863 2.38301 9.8032 3.37758 9.80295 4.60018V12.0961Z" fill="#3C7EF3"/>
                <path d="M8.37666 18.1416H6.79297V21.7049H8.37666V18.1416Z" fill="#3C7EF3"/>
                <path d="M9.66931 20.9116H5.49888C5.06151 20.9116 4.70703 21.2661 4.70703 21.7035C4.70703 22.1408 5.06151 22.4953 5.49888 22.4953H9.66931C10.1067 22.4953 10.4612 22.1408 10.4612 21.7035C10.4612 21.2661 10.1067 20.9116 9.66931 20.9116Z" fill="#3C7EF3"/>
                <path d="M13.576 10.751C13.1386 10.751 12.7842 11.1055 12.7842 11.5428V12.1763C12.7842 15.0433 10.4514 17.3761 7.58431 17.3761C4.71704 17.3761 2.38447 15.0434 2.38447 12.1763V11.5428C2.38447 11.1055 2.02999 10.751 1.59263 10.751C1.15526 10.751 0.800781 11.1055 0.800781 11.5428V12.1763C0.800781 15.9167 3.84387 18.9598 7.58431 18.9598C11.3248 18.9598 14.3678 15.9167 14.3678 12.1763V11.5428C14.3678 11.1055 14.0134 10.751 13.576 10.751Z" fill="#3C7EF3"/>
            </svg>,
    upload: <svg width="27" height="22" viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.77493 7.41419C5.98568 2.59093 10.8771 -0.337565 15.7004 0.873186C19.4697 1.81939 22.2066 5.0771 22.4886 8.95303C25.1649 9.39439 26.9767 11.9217 26.5354 14.5981C26.143 16.9774 24.0814 18.7198 21.67 18.7103H17.5772V17.0731H21.67C23.4783 17.0731 24.9442 15.6072 24.9442 13.7989C24.9442 11.9906 23.4783 10.5247 21.67 10.5247C21.2179 10.5247 20.8514 10.1582 20.8514 9.7061C20.8555 5.6374 17.5605 2.33575 13.4918 2.33171C9.96971 2.32818 6.93782 4.81813 6.25653 8.27363C6.18925 8.61865 5.90925 8.88222 5.56075 8.92847C3.32291 9.24715 1.76719 11.3196 2.08581 13.5574C2.37195 15.5668 4.08765 17.063 6.11737 17.0731H9.39161V18.7103H6.11737C2.95282 18.7007 0.395231 16.1275 0.40485 12.963C0.41283 10.3288 2.21591 8.03931 4.77493 7.41419Z" fill="#3C7EF3"/>
                <path d="M14.0656 10.762L17.3398 14.0362L16.1857 15.1904L14.303 13.3159V21.1659H12.6659V13.3159L10.7914 15.1904L9.6372 14.0362L12.9114 10.762C13.2307 10.4446 13.7463 10.4446 14.0656 10.762Z" fill="#3C7EF3"/>
            </svg>
}

class AudioRecord extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRecordPlayer: false,
            showRecordedPlayer: false,
            showSaveButton: false,
            showStopButton: false,
            showStartButton: true,
            showRefreshButton: false,
            startCount: false,
            videoUrl: '',
            minutes: '00',
            seconds: '00',
            showRecordingaudio:false,
            uploadfile:false,
            startRecording: false
        }
    }
    componentDidMount(){
        if(videoStream){
            videoStream.getTracks().forEach(track => track.stop());
        }
    }
    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if(totalSeconds>=180){
    //         this.stopRecord();
    //     }
    // }

    calculateTime = () => {
        this.myIntervat = setInterval(() => {
            ++totalSeconds;
            this.setState(prevState => ({
                    // startRecording: true,
                    // startCount: false,
                    seconds: pad(totalSeconds % 60),
                    minutes: pad(parseInt(totalSeconds / 60))
                })
            );
            if (totalSeconds > 180) {
                this.stopRecord(true);
            }
        }, 1000);
    }
    requestUserMedia = () => {
        let self = this;
        videoStream=null;
        navigator.mediaDevices.getUserMedia({audio: true})
            .then(function (mediaStream) {

                self.setState({
                    showStartButton: false,
                    showStopButton: true,
                    showRecordedPlayer: false,
                    showSaveButton: false,
                    showRefreshButton: false,
                    startCount: true,
                    minutes: '00',
                    seconds: '00',
                    videoUrl: '',
                    audioFile: [],
                    showRecordingaudio:true,
                    uploadfile:false,
                    startRecording: true
                }, () => {
                    let audio = document.getElementById('record-audio');
                    audio.srcObject=mediaStream;
                    videoStream = mediaStream;
                    audio.onloadedmetadata = function(e) {
                        audio.play();
                    };
                    // self.startRecord()
                });
                self.startRecord()
            })
            .catch(function (err) {
                self.setState({loader: 0, welcomeStatus: 2});
                return 0;
            });
    }
    showAudio=()=>{
        var sound = document.createElement('audio');
        sound.controls = 'controls';
        sound.src      = videoStream;
    }
    startRecord = () => {
        if(this.props.callback != undefined){
            this.props.callback()
        }
        totalSeconds = 0;
        this.calculateTime();
        recordAudio = RecordRTC(videoStream, {type: 'audio/webm', mimeType: 'audio/webm', recorderType: StereoAudioRecorder, audioBitsPerSecond: 128000});
        recordAudio.startRecording();

    }
    openMic = (e) => {
        e.preventDefault();
        let hasGetUserMedia = '';
        try {
            hasGetUserMedia = !!(navigator.mediaDevices || navigator.mediaDevices.getUserMedia({audio: true}) || navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia || navigator.msGetUserMedia);
        } catch (e) {
            this.setState({welcomeStatus: 2});
            return 0;
        }


        if (!hasGetUserMedia) {
            this.setState({welcomeStatus: 2});
            return 0;
        }

        this.setState({loader: 1}, () => {
            this.requestUserMedia();
        });
    }
    stopRecord = (stop = false) => {
        if(!stop && !this.state.startRecording){
            return;
        }
        // e.preventDefault();
        if (totalSeconds < 5) {
            Utils.showNotification("Audio duration must be between 5 seconds to 3 minutes", "error");
            return false;
        }
        clearInterval(this.myIntervat);
        recordAudio.stopRecording(() => {
            let urlCreator = window.URL || window.webkitURL;
            let videoUrl = urlCreator.createObjectURL(recordAudio.blob);
            videoStream.getTracks().forEach(track => track.stop());
            // this.convertAudio(videoUrl);

            let mimeType = 'audio/webm';
            let blob = recordAudio instanceof Blob ? recordAudio : recordAudio.getBlob();
            blob = new File([blob], 'RecordRTC-audio.webm', {
                type: mimeType
            });
            this.setState({
                videoUrl: videoUrl,
                showRecordedPlayer: true,
                showRefreshButton: true,
                showStopButton: false,
                showStartButton: true,
                showRecordingaudio:false,
                uploadfile:false,
                startRecording: false,
                startCount: false
            });
            this.props.voiceRecordCallback(blob);
            totalSeconds = 0;
            return false;
        });
    }
    saverecord = () => {
        // this.props.callBack();
    }
    reFreshData=()=>{
        this.setState({
            showStartButton: true,
            showStopButton: false,
            showRecordedPlayer: false,
            showSaveButton: false,
            showRefreshButton: false,
            startCount: true,
            minutes: '00',
            seconds: '00',
            videoUrl: '',
            showRecordingaudio:false,
            uploadfile:false,
        });
    }

    voiceUploadCallback=(e)=>{
        e.preventDefault();
        let file = document.querySelector('#voice-file-for-backend').files[0];
        if(file.size > 5242880){
            alert("File is too big!");
            return false;
        };
        this.props.voiceRecordCallback(file);
        if(this.props.callback != undefined){
            this.props.callback()
        }
        this.setState({uploadfile:true,showRecordedPlayer:false},()=>{});


        if (e.target.value) {
            let reader = new FileReader();
            reader.addEventListener("load", function () {
                let preview = document.getElementById("voice_file_preview-for-backend");
                preview.src = reader.result;
                let previewVoiceForBackend = document.getElementById('preview-voice-for-backend');

                if (previewVoiceForBackend) {
                    document.getElementById('preview-voice-for-backend').classList.remove("d-none");
                    document.getElementById('preview-voice-for-backend').classList.add("d-flex");
                }
            }, false);
            if (file) {
                reader.readAsDataURL(file);
            }
        }
    }
    uploadFrompc=()=>{
        if (document.getElementById("voice-file-for-backend"))
        document.getElementById("voice-file-for-backend").click();
    }

    render() {
        return (
            <React.Fragment>
                <div className="audio-voice">
                    <div className="mb-0 row">
                        <div className="col s12">
                            <div className="card">
                                <p className="title">Record/Upload a new audio file</p>
                                <div className="audio-wrapper">
                                    <div className="audio-icons">
                                        <div className="icon-record">
                                            <a className={`cursor-pointer ${this.state.startRecording ? 'opacity-5' : ''}`} disabled={this.state.showStartButton?false:true} onClick={this.state.showStartButton?this.openMic:null}>
                                                {icons.audio}
                                            </a>
                                            <span>Record</span>
                                        </div>
                                        <div className="icon-record">
                                            <a className={`cursor-pointer ${this.state.startRecording ? '' : 'opacity-5'}`} disabled={this.state.showStopButton?false:true} onClick={this.state.showStopButton?this.stopRecord:null}>
                                                {icons.stop}
                                            </a>
                                            <span>Stop</span>
                                        </div>
                                        <div className="icon-record">
                                            <a className={`cursor-pointer ${this.state.startRecording ? 'opacity-5' : ''}`} onClick={this.state.startRecording ? null : this.uploadFrompc}>
                                                {icons.upload}
                                            </a>
                                            <span>Upload</span>
                                        </div>
                                    </div>
                                    <div className="audio-setup">
                                        {this.state.startRecording &&
                                            <div className="inbox-record-timer">{`${this.state.minutes} : ${this.state.seconds}`}</div>
                                        }
                                        <div className="upload-record">
                                            <input onChange={this.voiceUploadCallback} type="file" id='voice-file-for-backend' accept="audio/mp3, video/webm" className={'mb-2'} style={{'display':'none'}}/>
                                        </div>
                                        <audio className="d-none" controls='controls' id='record-audio' muted />
                                        {this.props.showAudio != null &&
                                        <>
                                        {/* {
                                            this.state.showRecordingaudio && (
                                                <audio controls='controls' id='record-audio' muted />
                                            )
                                        } */}
                                        {/* <audio className="d-none" controls='controls' id='record-audio' muted /> */}
                                        {
                                            this.state.showRecordedPlayer && (
                                                <span className='2'>
                                                    <audio controls="controls" src={this.state.videoUrl} />
                                                </span>
                                            )
                                        }
                                        {
                                            this.state.uploadfile && (
                                                <span className='3'>
                                                    <audio controls id={'voice_file_preview-for-backend'} src=""/>
                                                </span>
                                            )
                                        }
                                        </>
                                        }
                                    </div>
                                </div>
                                <p className="alert-title"><i
                                    className="material-icons ">info_outline</i>Audio duration must be between 5 seconds to 3 minutes</p>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default AudioRecord;
