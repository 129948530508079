import React from 'react';
import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

const SkeletonContainer = styled.div`
  padding: 16px;
`;

const SkeletonElement = styled.div`
  background: #f0f0f0;
  background-image: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200px 100%;
  animation: ${shimmer} 2.5s infinite linear;
  border-radius: 4px;
  margin-bottom: 12px;
`;

const SkeletonUserItem = styled(SkeletonElement)`
  height: ${({ height }) => height || '50px'};
  width: ${({ width }) => width || '100%'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '15px'};
`;

const DynamicSkeleton = ({ count = 10, width = '100%', height = '50px', marginBottom = '15px', ...otherProps }) => {
  return (
    <SkeletonContainer {...otherProps}>
      {[...Array(count)].map((_, index) => (
        <SkeletonUserItem 
          key={index} 
          width={width} 
          height={height} 
          marginBottom={marginBottom}
        />
      ))}
    </SkeletonContainer>
  );
};

export default DynamicSkeleton;