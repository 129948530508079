import React from "react";
import ConversationBox from "./ConversationBox";
import { addSelectedContact } from "../LeftSide/inboxAction";
import { connect } from "react-redux";
import BottomBox from "./BottomBox";
import { RightSideStyle } from "../RightSide/RightSideStyle";

const LeftSide = (props) => {
  const { customScrollBar } = RightSideStyle();

  return (
    <div>
      <div
        className={customScrollBar}
        style={{
          height: props.bottomBoxVisible
            ? "calc(100vh - 480px)"
            : "calc(100vh - 140px)",
          overflowY: "auto",
        }}
      >
        <ConversationBox />
      </div>
      <div style={{ position: "relative" }}>
        <BottomBox handleClose={props.handleClose} />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    selectedContact: state.inboxReducer?.selectedContact,
    contactIdFromDashboard: state.inboxReducer?.contactIdFromDashboard,
    bottomBoxVisible: state.inboxReducer?.bottomBoxVisible,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSelectedContact: (params) => dispatch(addSelectedContact(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftSide);
