import React from "react";

const ConversationCloseIcon = () => {
  return (
    <svg
      className="interface-icon o__standard"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 16 16"
    >
      <path
        d="M15 8.482v4.679a.87.87 0 0 1-.875.839H1.875A.867.867 0 0 1 1 13.179V9.045c0-.048.024-.149.071-.304l2.554-6.205A.887.887 0 0 1 4.429 2h7.142a.84.84 0 0 1 .804.554l2.554 6.098c.016.05.03.183.04.347.022.376.031-.579.031-.517zm-1.84.517c.284-.002.493-.204.394-.49-.052-.15-.755-1.898-2.108-5.241A.474.474 0 0 0 11 3.002L5 3c-.289 0-.409.118-.498.268-.09.15-1.925 4.935-2.038 5.241-.113.307.123.49.34.49h1.625c.894-.005.988.41.97 1.01v.135c0 .455.141.836.587.872l3.968-.009c.425-.028.546-.507.546-1.007.028-.608.308-1.002 1.071-1h1.59zM5.5 5h5a.5.5 0 1 1 0 1h-5a.5.5 0 0 1 0-1zm0 2h5a.5.5 0 1 1 0 1h-5a.5.5 0 0 1 0-1z"
        fillRule="evenodd"
      ></path>
    </svg>
  );
};

export default ConversationCloseIcon;
