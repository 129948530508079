import {
    Backdrop, CircularProgress,
    FormControl,
    FormHelperText,
    InputBase,
    makeStyles,
    MenuItem,
    Select,
    withStyles
} from "@material-ui/core";
import React from "react";
import A2pLearnMore from "../a2pLearnMore";
import CampaignUseCaseDescriptionLearnMore from "../a2pLearnMore/CampaignUseCaseDescriptionLearnMore";
import CampaignUseCaseLearnMore from "../a2pLearnMore/CampaignUseCaseLearnMore";
import Styles from "./Styles.module.css";

const CustomInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
            color: "black !important",
            fontSize: "20px!important"
        },
    },
    input: {
        fontSize: "14px !important",
        height: "auto !important",
        minHeight: "200px !important",
        color: "#707070 !important",
        borderRadius: "4px !important",
        position: "relative",
        border: "1px solid #ced4da!important",
        padding: "10px 12px !important",
        marginBottom: "0!important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            color: "#707070 !important",
            boxShadow: "none!important",
            borderColor: "black !important",
        },
    },
}))(InputBase);

const BootstrapInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        fontSize: "14px !important",
        borderRadius: "4px !important",
        position: "relative",
        color: "#707070 !important",
        border: "1px solid #ced4da !important",
        padding: "10px 26px 10px 12px !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 4
        },
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

const CampaignDetails = ({ inputScrollToViewRef, formData, callback, errorCallback, a2pDlcError, campaignUseCase, loading, a2pDlcFeedBacks }) => {
    const classes = useStyles();

    const handleFormDataChange = (name, value) => {
        if (name === "useCase"){
            if (value.trim() === ""){
                errorCallback("useCase","Campaign Use Case is required!");
            }else{
                errorCallback("useCase","");
            }
        }else if (name === "useCaseDescription"){
            if (value.trim() === ""){
                errorCallback("useCaseDescription","Campaign Use Case Description is required!");
            }else if(value.length < 40 || value.length > 4096){
                errorCallback("useCaseDescription","Campaign Use Case Description can not be less than 40 characters and more than 4096 characters!");
            }else {
                errorCallback("useCaseDescription","");
            }
        }
        callback(name, value);
    };

    const renderCampaignUseCaseListOptions = () => {
        const item = [];
        for(let i=0;i<campaignUseCase.length;i++){
            item.push(<MenuItem className={`dropdownhelper-menuitem-class ${Styles.campaignUseCaseLi}`} value={campaignUseCase[i].code}>
                <div className={Styles.campaignUseCaseName}>{campaignUseCase[i].name}</div>
            </MenuItem>);
        }
        return item;
    };

    const renderErrorMessage = (fieldName) => {
        return <FormHelperText error>{a2pDlcError[fieldName]}</FormHelperText>;
    };

    return (
        <div className={Styles.contentWrapper}>
            <div className={Styles.contentHeader}>
                <h1 className={Styles.contentTitle}>Read before answering questions:</h1>
                <p className={Styles.contentDescription}>Before the phone companies are willing to approve your messaging campaigns they need to review some examples of the types of messages you intend to send. Complete the info below.</p>
            </div>
            <div className={Styles.contentBody}>
                <div className={Styles.contentBodyLeft}>
                    <FormControl ref={el => inputScrollToViewRef.current[0] = el} className={Styles.formControl}>
                        <span className={`${Styles.label} ${Styles.dFlex} ${Styles.justifySpaceBetween}`}>
                            <span>
                                Campaign Use Case
                                <span className={Styles.required}> *</span>
                            </span>
                            <span onClick={()=>{
                                A2pLearnMore({
                                    title: "Campaign Use Case",
                                    innerBody: <CampaignUseCaseLearnMore/>
                                });
                            }} className={Styles.learnMoreLink}>Learn More</span>
                          </span>

                        <Select
                            name="useCase"
                            value={formData.useCase}
                            displayEmpty
                            onChange={(e)=>{ handleFormDataChange("useCase", e.target.value) }}
                            input={<BootstrapInput/>}
                            inputProps={{ "aria-label": "Without label" }}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                        >
                            <MenuItem className="dropdownhelper-menuitem-class" value="" disabled>Select a campaign use case</MenuItem>
                            {renderCampaignUseCaseListOptions()}
                        </Select>
                        {renderErrorMessage("useCase")}
                    </FormControl>
                    <FormControl ref={el => inputScrollToViewRef.current[1] = el} className={Styles.formControl}>
                      <span className={`${Styles.label} ${Styles.dFlex} ${Styles.justifySpaceBetween}`}>
                        <span>
                            Campaign Use Case Description
                            <span className={Styles.required}> *</span>
                        </span>
                          <span onClick={(event)=> {
                              A2pLearnMore({
                                  title: "Campaign Use Case Description",
                                  innerBody: <CampaignUseCaseDescriptionLearnMore/>
                              });
                          }} className={Styles.smallButton}>Suggestions</span>
                      </span>
                        {
                            a2pDlcFeedBacks && a2pDlcFeedBacks.useCaseDescription &&
                            <span className={Styles.feedBacks}>{a2pDlcFeedBacks.useCaseDescription}</span>
                        }
                        <CustomInput
                            placeholder={"Example:\n" +
                                "We reach out to clients and prospects who have opted in to receive information about the products and services that we offer.\n" +
                                "\n" +
                                "Example:\n" +
                                "This campaign sends a one time passcode to end users when they try to log into our company's website.\n" +
                                "\n" +
                                "Example:\n" +
                                "We use this campaign to confirm appointments and send appointment reminders."}
                            name="useCaseDescription"
                            value={formData.useCaseDescription}
                            onChange={(e)=>{handleFormDataChange("useCaseDescription", e.target.value)}}
                            multiline
                        />
                        {renderErrorMessage("useCaseDescription")}
                        <span className={Styles.multilineInputLimit}>Min length: 40 characters. Max length: 4096 characters.</span>
                    </FormControl>
                </div>
            </div>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

export default CampaignDetails;