import React, { useEffect } from "react";
import { Drawer, IconButton, styled, Tooltip } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { useDispatch, useSelector } from "react-redux";
import Utils from "../../../../helpers/Utils";

import { Link } from "react-router-dom";

import { CustomScrollbar } from "../common/CustomScrollbar";
import RightListSkeleton from "../common/RightListSkeleton";
import SubTitle from "../common/SubTitle";
import Title from "../common/Title";
import NotificationContentTypeIcon from "./NotificationContentTypeIcon";
import { fetchSidebarNotification, resetSidebarPage, setSidebarPage } from "../../../../actions/floatingSidebarAction";

export const DrawerWrapper = styled(Drawer)(({ theme }) => ({
  "& .wrapper": {
    width: 500,
  },

  "& .headerWrapper": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "18px 22px",
    borderBottom: "1px solid #E9EAEC",

    "& .headerTitle": {
      margin: 0,
      fontWeight: 600,
      fontSize: "20px",
    },
  },

  "& .bodyWrapper": {
    padding: "12px 16px",
  },

  "& .list": {
    display: "flex",
    flexDirection: "column",
    gap: 12,
  },

  "& .listItem": {
    padding: "13px 15px",
    borderRadius: 12,
    border: "1px solid #E9EAEC",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px !important",

    "&.unseenItem": {
      backgroundColor: "rgba(60,126,243,.1)",

      "& .message": {
        fontWeight: 700,
      },
    },

    "& .titleWrapper": {
      display: "flex",
      justifyContent: "space-between",

      "& .title": {
        fontSize: "16px",
        cursor: "pointer",
        lineHeight: "21px",
        fontWeight: 400,

        maxWidth: 265,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",

        "&:hover": {
          color: "#346FEF",
        },
      },
    },

    "& .message": {
      color: "#141928cc",
      // whiteSpace: "break-spaces",

      width: 290,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",

      "&:hover": {
        color: "#346FEF",
      },
    },
  },
}));

const NotificationDrawer = ({ open, onClose }) => {
  const dispatch = useDispatch();


  const { notificationList, isLoading, page, hasMore } = useSelector((state) => state.floatingSidebarReducer);

  useEffect(() => {
    dispatch(fetchSidebarNotification({ page }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      scrollTop + clientHeight >= scrollHeight - 10 &&
      hasMore &&
      !isLoading
    ) {
      dispatch(setSidebarPage(page + 1));
    }
  };

  const renderNotifications = () => {
    if (isLoading && notificationList.length === 0) {
      return <RightListSkeleton itemCount={7} />;
    } else if (notificationList.length === 0) {
      return <p className="d-flex justify-content-center">NO NOTIFICATIONS</p>;
    } else {
      return notificationList.map((notification, index) => {
        const fullName = notification.contact_id
          ? notification.first_name + " " + notification.last_name
          : "";

        const message = notification?.message?.replace(/<[^>]+>/g, "");
        const timeAgo = window.globalTimezoneConversionToDifferentTimezone(
          notification.created_at,
          "UTC",
          "",
          "from_now"
        );
        return (
          <Link
            key={index}
            to={"/contacts/" + notification.contact_id}
            onClick={(e) => {
              // e.preventDefault();
              // history.push("/contacts/" + notification.contact_id);
              onClose();
            }}
            rel="noreferrer"
          >
            <div
              className={`listItem ${
                notification.seen !== 1 ? "unseenItem" : ""
              }`}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 12,
                }}
              >
                <div>
                  <NotificationContentTypeIcon
                    contentType={notification.type}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <div className="titleWrapper">
                    <Tooltip
                      title={Utils.getContactDisplayName(
                        fullName,
                        notification.contact_number,
                        notification.contact_email,
                        true
                      )}
                      placement="top-start"
                    >
                      <Title
                        className="title"
                        text={
                          Utils.getContactDisplayName(
                            fullName,
                            notification.contact_number,
                            notification.contact_email
                          ) || "N/A"
                        }
                      />
                    </Tooltip>

                    <SubTitle text={timeAgo || ""} className="subTitle" />
                  </div>

                  <SubTitle text={message} className="message" />
                </div>
              </div>
            </div>
          </Link>
        );
      });
    }
  };

  const closeDrawer = () => {
    onClose();
    dispatch(resetSidebarPage());
  };

  return (
    <DrawerWrapper anchor={"right"} open={open} onClose={closeDrawer}>
      <div className="wrapper">
        <div className="headerWrapper">
          <h6 className="headerTitle">Notifications</h6>

          <IconButton
            className="closeButton"
            size="small"
            onClick={closeDrawer}
          >
            <HighlightOffIcon style={{ color: "#ff0055" }} />
          </IconButton>
        </div>

        <div className="bodyWrapper">
          <CustomScrollbar
            style={{ maxHeight: "calc(100vh - 125px)", overflow: "auto" }}
            onScroll={handleScroll}
          >
            <div className="list">{renderNotifications()}</div>
            {isLoading && <RightListSkeleton itemCount={3} />}
          </CustomScrollbar>
        </div>
      </div>
    </DrawerWrapper>
  );
};

export default NotificationDrawer;
