import { sendRequest } from "../../../api/rootApi";
import { getCookie } from "../../../helpers/Cookie";
import Utils from "../../../helpers/Utils";
import CustomSelectBox from "../../../components/common/CustomSelectBox";
import Loading from "../../../components/common/Loading";
import ListSkeleton from "../../../components/common/Skeletons/ListSkeleton";
import GlobalModal from "../../GlobalModal/Modal/GlobalModal";
import QuickReplyCard from "../../QuickReplyCard";
import GlobalCustomFieldGrouped from "../../GlobalCustomFieldGrouped/Index";

const LinkFile = {
    'getCookie' : getCookie,
    'sendRequest' : sendRequest,
    'CustomSelectBox' : CustomSelectBox,
    'Utils' : Utils,
    'Loading': Loading,
    'skeleton': ListSkeleton,
    'globalModal': GlobalModal,
    'QuickReplyCard': QuickReplyCard,
    'GlobalCustomFieldGrouped': GlobalCustomFieldGrouped,
}
export default LinkFile;