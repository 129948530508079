import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Backdrop, Box, Fade, Modal, Typography } from "@material-ui/core";
import { storeMultipleReducers } from "../../actions/globalDialerAction";
import { checkUserA2pPopupRequiredApi, getCustomFieldAndPersonalizedFieldApi } from "../../api/globalApi";
import { fetchAccountData, fetchDirectAccountData } from '../../api/loginApi';
import { checkCookie, setCookie } from "../../helpers/Cookie";
import Utils, { setLayoutStyles } from "../../helpers/Utils";
import A2p10DlcRegistration from "../a2p10DlcRegistrationV2";
import A2p10DlcOnBoard from "../a2pDlcOnboardV2";
import AddContactModalV2 from "../addContact/addContactModalV2/AddContactModalV2";
import A2pCampaignUseCaseRegistration from "../campaignUseCaseRegistrationV2";
import EnhancedData from "../enhancedData/enhanceData";
import DownloadPopup from "../enhancedData/enhanceData/DownloadPopup";
import ImportEnhancePopUp from "../enhancedData/enhanceData/ImportEnhancePopUp";
import GlobalAddContactHelperModal from "../GlobalAddContectHelperModal/GlobalAddContactHelperModal";
import GlobalAddDealModalV3 from "../GlobalAddDealModalV3/CombinedDealAddModal";
import GlobalAddDealModalV3ForPolicy from "../GlobalAddDealModalV3ForPolicy/CombinedDealAddModalForPolicy";
import PowerDialerModal from "../GlobalCreatePowerDialerListModal/PowerDialerModal";
import GlobalDealStatus from "../GlobalDealStatus";
import GlobalInsuranceDealStatus from "../GlobalInsuranceDealStatus";
import GlobalInsuranceDealStatusLost from "../GlobalInsuranceDealStatusLost/GlobalInsuranceDealStatusLost";
import ActivityModal from "../GlobalModal/ActivityModal";
import NewCampaignPopup from "../GlobalModal/NewCampaignPopup";
import PhoneSettingsModal from "../phoneSettingsModal";
import SmsRestriction from "../smsRestriction";
import A2pSoleRegistration from "../soleCampaignUseCaseRegistrationV2";
import EmailSendModule from "./EmailSendModule";
import GlobalContactDetailsForDealModule from "./GlobalContactDetailsForDealModule";
import GlobalContactDetailsModule from "./GlobalContactDetailsModule";
import socket from "./GlobalDialerSocket";
import HeaderInsurance from "./insurance/HeaderInsurance";
import SidebarInsurance from "./insurance/SidebarInsurance";
import SendModule from "./SendModule";
import SmsSendModule from "./SmsSendModule";
import './UserLayout.css';
import VoiceSendModule from "./VoiceSendModule";
import FloatingActions from "./floatingActions/FloatingActions";
import { AGENCY_BUSINESS_TYPE } from "../../helpers/CoreConstant";

const UserLayout = (props) => {
    const history = useHistory()
    const [avatar, setAvatar] = useState(null);
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [agencyLogo, setAgencyLogo] = useState('');
    const [minimizedLogo, setMinimizedLogo] = useState('');
    const [favIcon, setFavIcon] = useState('');
    const [menuCollapsed, setMenuCollapsed] = useState(false);
    const [blockUiStatus, setBlockUiStatus] = useState(false);
    const [sendModuleInfo, setSendModuleInfo] = useState(null)
    const [pwdModuleInfo, setPwdModuleInfo] = useState(null)
    const [showPowerDialerModal, setShowPowerDialerModal] = useState(false)
    const [a2pCampaignUseCaseRegistration, setA2pCampaignUseCaseRegistration] = useState(false)
    const [a2pCampaignUseCaseRegistrationData, setA2pCampaignUseCaseRegistrationData] = useState(null)
    const [a2p10DlcRegistration, setA2p10DlcRegistration] = useState(false)
    const [a2p10DlcRegistrationData, setA2p10DlcRegistrationData] = useState(null)
    const [a2p10DlcOnBoard, setA2p10DlcOnBoard] = useState(false)
    const [a2p10DlcOnBoardData, setA2p10DlcOnBoardData] = useState(null)
    const [a2pSoleRegistration, setA2pSoleRegistration] = useState(false)
    const [a2pSoleRegistrationData, setA2pSoleRegistrationData] = useState(null)
    const [smsRestriction, setSmsRestriction] = useState(false)
    const [smsRestrictionData, setSmsRestrictionData] = useState(null)
    const [enhancedData, setEnhanced] = useState(false)
    const [enhancedDataData, setEnhancedData] = useState(null)
    const [downloadLoadPopUp, setDownloadLoadPopUp] = useState(false)
    const [downloadLoadPopUpData, setDownloadLoadPopUpData] = useState(null)
    const [importEnhancePopUp, setImportEnhancePopUp] = useState(false)
    const [importEnhancePopUpData, setImportEnhancePopUpData] = useState(null)
    const [addDealGlobal, setAddDealGlobal] = useState(false)
    const [addDealGlobalData, setAddDealGlobalData] = useState(null)
    const [dealStatusGlobal, setDealStatusGlobal] = useState(false)
    const [dealStatusGlobalData, setDealStatusGlobalData] = useState(null)
    const [addContactHelperGlobal, setAddContactHelperGlobal] = useState(false)
    const [addContactHelperGlobalData, setAddContactHelperGlobalData] = useState(null)

    const [dealInsuranceStatusGlobal, setDealInsuranceStatusGlobal] = useState(false)
    const [dealInsuranceStatusGlobalData, setDealInsuranceStatusGlobalData] = useState(null)

    const [dealInsuranceStatusLostGlobal, setDealInsuranceStatusLostGlobal] = useState(false)
    const [dealInsuranceStatusLostGlobalData, setDealInsuranceStatusLostGlobalData] = useState(null)

    const [addContactModal, setAddContactModal] = useState(false)
    const [addContactModalData, setAddContactModalData] = useState(null);
    const [openAddNewCampaignModal, setOpenAddNewCampaignModal] = React.useState(false);

    const [activityModalInfos, setActivityModalInfos] = useState({
        open:false,
        subUserList: null,
        callbackFunction: null,
        people: null,
        editData: null,
        showAll: null,
        slotInfo: [],
        activityFrom: ""
    })

    const [buyVirtualNumberModal, setBuyVirtualNumberModal] = useState(false);
    const [buyVirtualNumberModalData, setBuyVirtualNumberModalData] = useState(null);

    useEffect(() => {

        props.storeMultipleReducers({ globalDialerSocket: socket });
        if (checkCookie(process.env.REACT_APP_ACCESS_TOKEN)) {
            fetchDirectAccountData().then(response => {
                if (response.data.status) {
                    setCookie(process.env.REACT_APP_ACCOUNT_DATA, JSON.stringify(response.data.data), process.env.REACT_APP_ACCESS_TOKEN_VALIDITY);
                    if (!response.data.data.AuthUserAgencyAccountStatus) {
                        setBlockUiStatus(true);
                        // history.push('/');
                    } else {
                        setBlockUiStatus(false);
                    }
                } else {
                    Utils.showNotification('Opps! something went wrong. Please reload the page', 'error');
                }
            }).catch((err) => console.log(err))
        }
    }, [])

    //collapse and show sidebar
    useEffect(() => {
        window.setCollapsedMenu = (collapsed) => {
            if (collapsed) {
                document.querySelector("body").classList.add("active__minimal_sidebar");
            } else {
                document.querySelector("body").classList.remove("active__minimal_sidebar");
            }
            setMenuCollapsed(collapsed);
        };
    }, [])

    window.isMenubarCollapsed = () => {
        return menuCollapsed;
    }

    useEffect(() => {

        let accountData = Utils.getAccountData('userId');

        if (!accountData) {
            fetchAccountData().then(response => {
                if (response.data.status) {
                    setCookie(process.env.REACT_APP_ACCOUNT_DATA, JSON.stringify(response.data.data), process.env.REACT_APP_ACCESS_TOKEN_VALIDITY);
                    setStateData();
                } else {
                    Utils.showNotification('Opps! something went wrong. Please reload the page', 'error');
                }
            });
        } else {
            setStateData();
        }
        accountData = Utils.getAccountData('userId');
        const channel = new BroadcastChannel('pypepro-channel');

        // Listen for incoming messages from other tabs
        channel.addEventListener('message', (event) => {
            //   setMessage(event.data);
            if (event.data && event.data.action && event.data.action === 'reload-page-' + accountData
                && event.data.link) {

                window.location.href = event.data.link

            } else if (event.data && event.data.action && event.data.action && event.data.link === "RELOAD") {
                window.location.reload();
            }
        });

        // Clean up the channel when component unmounts
        return () => {
            channel.close();
        };
    }, []);

    window.globalFetchAccountData = () => {
        fetchAccountData().then(response => {
            if (response.data.status) {
                setCookie(process.env.REACT_APP_ACCOUNT_DATA, JSON.stringify(response.data.data), process.env.REACT_APP_ACCESS_TOKEN_VALIDITY);
                setStateData();
            } else {
                Utils.showNotification('Opps! something went wrong. Please reload the page', 'error');
            }
        });
    }

    window.globalCustomFieldWithPersonalized = (request_data) => {
        let default_data = {
            get_custom_data: false,
            get_personalized_data: false,
            field_name: ['*']
        }
        let data = { ...default_data, ...request_data }

        return getCustomFieldAndPersonalizedFieldApi({
            ...data
        })
    }

    const setStateData = () => {
        setAvatar(Utils.getAccountData('profileImage'));
        setUserEmail(Utils.getAccountData('email'));
        setUserName(Utils.getAccountData('fullName'));
        setAgencyLogo(Utils.getAccountData('agencyLogo'));
        setFavIcon(Utils.getAccountData('favIcon'));
        setMinimizedLogo(Utils.getAccountData('minimizedLogo'));
        setLayoutStyles();
    }

    window.openVideoEmailSend = info => {

        if (info.open) {
            info.videoOpen = true
            setSendModuleInfo({ ...info, 'videoOpen': true });

        } else {
            setSendModuleInfo(null);
        }
    }

    window.handleGlobalSmsSendModal = info => {

        if (info.open) {
            setSendModuleInfo({ ...info, 'smsOpen': true });

        } else {
            setSendModuleInfo(null);
        }
    }

    window.handleGlobalEmailSendModal = info => {
        if (info.open) {
            setSendModuleInfo({ ...info, 'emailOpen': true });

        } else {
            setSendModuleInfo(null);
        }
    }

    window.handleGlobalVoiceSendModal = info => {
        if (info.open) {
            setSendModuleInfo({ ...info, 'voiceOpen': true });
        } else {
            setSendModuleInfo(null);
        }
    }

    window.sendCommunication = (info, type) => {

        if (info.open) {

            switch (type) {
                case 'voice':
                    setSendModuleInfo({ ...info, 'voiceOpen': true });
                    break;
                case 'sms':
                    setSendModuleInfo({ ...info, 'smsOpen': true });
                    break;
                case 'email':
                    setSendModuleInfo({ ...info, 'emailOpen': true });
                    break;

                case 'videoEmail':
                    setSendModuleInfo({ ...info, 'videoOpen': true });
                    break;
                default:
                    setSendModuleInfo(null);
            }
        } else {
            setSendModuleInfo(null);
        }
    }

    window.sendGlobalDirectMailV2 = (info) => {
        // card_type = postcard, letter, notecard/greeting card, gift
        // for = direct-mail-send, campaign-setting
        let prams_id = info.contactId;
        if (info.for === 'campaign-setting') {
            prams_id = info.campaignSetting.campaignId
        }
        history.push({
            // pathname: ('/directmail-send/'+info.contactId),
            pathname: ('/directmail-send/' + prams_id),
            state: {
                from: info.from,
                contactId: (info.contactId !== undefined ? info.contactId : null),
                card_type: info.card_type,
                for: info.for,
                campaign_setting: (info.campaignSetting !== undefined ? info.campaignSetting : null)
            }
        });
    }

    window.globalPowerDialerModal = (isOpen = false, infos) => {
        if (isOpen) {
            setPwdModuleInfo(infos);
            setShowPowerDialerModal(true)
        } else {
            setShowPowerDialerModal(false)
            setPwdModuleInfo(null);
        }
    }

    window.globalA2pCampaignUseCaseRegistration = (isOpen = false, data = null, from = null) => {
        if (!data) {
            data = {};
        }
        if (isOpen) {
            if (from) {

                data.from = from;
            }
            setA2pCampaignUseCaseRegistrationData(data);
            setA2pCampaignUseCaseRegistration(true);

        } else {
            setA2pCampaignUseCaseRegistration(false);
            setA2pCampaignUseCaseRegistrationData(null);
        }
    }

    window.globalA2p10DlcRegistration = (isOpen = false, data = null, from = null) => {
        if (isOpen) {
            setA2p10DlcRegistration(true);
            if (from) {
                if (!data) {
                    data = {};
                }
                data.from = from;
            }
            setA2p10DlcRegistrationData(data);
        } else {
            setA2p10DlcRegistration(false);
            setA2p10DlcRegistrationData(null);
        }
    }

    window.globalA2p10DlcOnBoard = (isOpen = false, data = null, from = null) => {
        if (isOpen) {
            setA2p10DlcOnBoard(true);
            if (from) {
                if (!data) {
                    data = {};
                }
                data.from = from;
            }
            setA2p10DlcOnBoardData(data);
        } else {
            setA2p10DlcOnBoard(false);
            setA2p10DlcOnBoardData(null);
        }
    }

    window.globalA2p10DlcManual = (isOpen = false, activeStep = "WELCOME_DLC", data = null, from = null) => {
        if (isOpen) {
            setA2p10DlcOnBoard(true);
            try {
                setA2p10DlcOnBoardData({ isDataLoading: true });
                checkUserA2pPopupRequiredApi()
                    .then((res) => {
                        res = res.data;
                        if (res.success) {
                            let info = res.data;
                            info.activeStep = activeStep;
                            info.isDataLoading = false;
                            info.from = from;
                            if (data) {
                                info = { ...info, ...data };
                            }
                            setA2p10DlcOnBoardData(info);
                        } else {
                            setA2p10DlcOnBoardData({ isDataLoading: false });
                        }

                    })
                    .catch((err) => {
                        setA2p10DlcOnBoardData({ isDataLoading: false });
                        console.log(err);
                    });
            } catch (err) {
                console.log(err);
            }
        } else {
            setA2p10DlcOnBoard(false);
            setA2p10DlcOnBoardData(null);
        }
    }

    window.globalA2pSoleRegistration = (isOpen = false, data = null, from = null) => {
        if (isOpen) {
            setA2pSoleRegistration(true);
            if (from) {
                if (!data) {
                    data = {};
                }
                data.from = from;
            }
            setA2pSoleRegistrationData(data);
        } else {
            setA2pSoleRegistration(false);
            setA2pSoleRegistrationData(null);
        }
    }

    window.globalSmsRestriction = (isOpen = false, data = null) => {
        if (isOpen) {
            setSmsRestriction(true);
            setSmsRestrictionData(data);
        } else {
            setSmsRestriction(false);
            setSmsRestrictionData(null);
        }
    }

    window.globalEnhancedData = (isOpen = false, data = null) => {
        if (isOpen) {
            setEnhanced(true);
            setEnhancedData(data);
        } else {
            setEnhanced(false);
            setEnhancedData(null);
        }
    }

    window.globalDownloadPopupData = (isOpen = false, data = null) => {
        if (isOpen) {
            setDownloadLoadPopUp(true);
            setDownloadLoadPopUpData(data);
        } else {
            setDownloadLoadPopUp(false);
            setDownloadLoadPopUpData(null);
        }
    }

    window.globalImportEnhancePopupData = (isOpen = false, data = null) => {
        if (isOpen) {
            setImportEnhancePopUp(true);
            setImportEnhancePopUpData(data);
        } else {
            setImportEnhancePopUp(false);
            setImportEnhancePopUpData(null);
        }
    }

    window.globalAddDeal = (isOpen = false, data = null) => {

        if (isOpen) {
            setAddDealGlobal(true);
            setAddDealGlobalData(data);
        } else {
            setAddDealGlobal(false);
            setAddDealGlobalData(null);
        }
    }

    window.globalDealStatus = (isOpen = false, data = null) => {
        if (isOpen) {
            setDealStatusGlobal(true);
            setDealStatusGlobalData(data);
        } else {
            setDealStatusGlobal(false);
            setDealStatusGlobalData(null);
        }
    }

    window.globalInsuranceDealStatus = (isOpen = false, data = null) => {
        if (isOpen) {
            setDealInsuranceStatusGlobal(true);
            setDealInsuranceStatusGlobalData(data);
        } else {
            setDealInsuranceStatusGlobal(false);
            setDealInsuranceStatusGlobalData(null);
        }
    }

    window.globalInsuranceDealStatusLost = (isOpen = false, data = null) => {
        if (isOpen) {
            setDealInsuranceStatusLostGlobal(true);
            setDealInsuranceStatusLostGlobalData(data);
        } else {
            setDealInsuranceStatusLostGlobal(false);
            setDealInsuranceStatusLostGlobalData(null);
        }
    }

    window.globalAddContactHelper = (isOpen = false, data = null) => {
        if (isOpen) {
            setAddContactHelperGlobal(true);
            setAddContactHelperGlobalData(data);
        } else {
            setAddContactHelperGlobal(false);
            setAddContactHelperGlobalData(null);
        }
    }

    window.showAddContactModal = (from = "", sourceList = null) => {
        let data = {};

        if (sourceList){
            data.sourceList = sourceList;
        }

        setAddContactModalData(data);
        setAddContactModal(true);
    };

    window.openGlobalActivityModal= async(info)=>{
        let tempActivityModalInfos = {...activityModalInfos}
        if(info.subUserList !== undefined && info.subUserList != null){
            tempActivityModalInfos.subUserList =info.subUserList
        }
        if(info.callBack !== undefined && info.callBack != null){

            tempActivityModalInfos.callbackFunction =info.callBack
        }
        if(info.contactDetails !== undefined && info.contactDetails != null){
            tempActivityModalInfos.people =info.contactDetails
        }
        if(info.slotInfo !== undefined && info.slotInfo != null){
            tempActivityModalInfos.slotInfo = info.slotInfo
        }
        if(info.editData !== undefined && info.editData != null){
            tempActivityModalInfos.editData = info.editData

        }
        if(info.taskType !== undefined && info.taskType != null && info.taskType === 'Appointment'){
            tempActivityModalInfos.showAll =false
        }else{
            tempActivityModalInfos.showAll =true;
        }
        if(info.from !== undefined && info.from != null){
            tempActivityModalInfos.activityFrom = info.from
        }else{
            tempActivityModalInfos.activityFrom = '';
        }
        tempActivityModalInfos.open =true

        setActivityModalInfos(tempActivityModalInfos);
    }

    window.globalAddCampaignModal = (isOpen = false) => {
        setOpenAddNewCampaignModal(isOpen);
    };

    window.buyVirtualNumberModal = (isOpen = false, data = null) => {
        setBuyVirtualNumberModal(isOpen);
        setBuyVirtualNumberModalData(data);
    };

    const closeModal = (sendType) => {
        setSendModuleInfo(null);
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        backgroundColor: '#fff',
        border: 'none',
        boxShadow: 24,
        padding: 20,
    };

    return (
        <>
            {/* {loading &&
            <div style={{'marginTop': '50vh'}}>
                <Loading />
            </div>
        } */}

            {/* {!loading && */}
            <>

                {blockUiStatus && <div style={{ position: 'absolute', left: 0, right: 0, height: "100%", width: "100%", zIndex: 999, backgroundColor: "rgba(255,255,255,0.5)" }}></div>}
                {blockUiStatus &&
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={true}
                        onClose={() => { }}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                    >
                        <Fade in={true}>
                            <Box style={style}>
                                <Typography id="transition-modal-title color-red" variant="h5" component="h2" style={{ color: "red" }}>
                                    Notice
                                </Typography>
                                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                    Please contact system administrator.
                                </Typography>
                            </Box>
                        </Fade>
                    </Modal>
                }
                <Helmet>
                    <link rel="icon" href={favIcon} />
                    <title>{`${Utils.getAccountData('AuthUserAgencyName') ? Utils.getAccountData('AuthUserAgencyName') : ''}`}</title>
                    <meta name="description" content="All-In-One Sales & Marketing Platform" />
                    <meta name="keywords" content={`CRM, Automation, Sales, Marketing, ${Utils.getAccountData('AuthUserAgencyName')}`} />
                    <meta name="author" content={Utils.getAccountData('AuthUserAgencyName')} />
                </Helmet>
                {/*<Header menuCollapsed={menuCollapsed} avatar={avatar} userName={userName} userEmail={userEmail} agencyLogo={agencyLogo} minimizedLogo={minimizedLogo} />*/}
                {
                    props.fullScreen !== true &&
                    <HeaderInsurance
                        menuCollapsed={menuCollapsed}
                        avatar={avatar}
                        userName={userName}
                        userEmail={userEmail}
                        agencyLogo={agencyLogo}
                        minimizedLogo={minimizedLogo}
                    />
                }
                <div className="main__content_wrapper d-flex awesome__scroll_bar main__content_wrapper_parent_module" id="main__content_wrapper_parent_module" style={props.fullScreen === true ? { height: '100vh' } : {}}>
                    {/*<Sidebar menuCollapsed={menuCollapsed} getUserSettingData={props.getUserSettingData} />*/}
                    {
                        props.fullScreen !== true &&
                        <SidebarInsurance menuCollapsed={menuCollapsed} getUserSettingData={props.getUserSettingData} />
                    }
                    <main className="main__content" id="main__content" style={props.fullScreen === true ? { margin: 0 } : {}}>
                        {props.children}

                        {(Utils.getAccountData("agencyBusinessType") === AGENCY_BUSINESS_TYPE.INSURANCE) && <FloatingActions />}
                    </main>
                </div>
                {sendModuleInfo != null && sendModuleInfo.videoOpen &&
                    <SendModule info={sendModuleInfo} closeModal={closeModal} />
                }
                {sendModuleInfo != null && sendModuleInfo.smsOpen &&
                    <SmsSendModule info={sendModuleInfo} closeModal={closeModal} />
                }
                {sendModuleInfo != null && sendModuleInfo.emailOpen &&
                    <EmailSendModule info={sendModuleInfo} closeModal={closeModal} />
                }
                {sendModuleInfo != null && sendModuleInfo.voiceOpen &&
                    <VoiceSendModule info={sendModuleInfo} closeModal={closeModal} />
                }

                {
                    showPowerDialerModal &&
                    <PowerDialerModal
                        isOpen={showPowerDialerModal}
                        data={pwdModuleInfo}
                        onClose={() => {
                            setShowPowerDialerModal(false)
                            setPwdModuleInfo(null)
                        }}
                    />
                }

                {
                    (a2pCampaignUseCaseRegistration && a2pCampaignUseCaseRegistrationData) &&
                    <A2pCampaignUseCaseRegistration
                        data={a2pCampaignUseCaseRegistrationData}
                        isOpen={a2pCampaignUseCaseRegistration}
                        onClose={() => {
                            setA2pCampaignUseCaseRegistration(false);
                        }}
                    />
                }
                {
                    a2p10DlcRegistration &&
                    <A2p10DlcRegistration
                        isOpen={a2p10DlcRegistration}
                        data={a2p10DlcRegistrationData}
                        onClose={() => {
                            setA2p10DlcRegistration(false);
                        }}
                    />
                }

                {
                    a2p10DlcOnBoard &&
                    <A2p10DlcOnBoard
                        isOpen={a2p10DlcOnBoard}
                        data={a2p10DlcOnBoardData}
                        onClose={() => {
                            setA2p10DlcOnBoard(false);
                        }}
                    />
                }

                {
                    a2pSoleRegistration &&
                    <A2pSoleRegistration
                        data={a2pSoleRegistrationData}
                        isOpen={a2pSoleRegistration}
                        onClose={() => {
                            setA2pSoleRegistration(false);
                        }}
                    />
                }

                {
                    smsRestriction &&
                    <SmsRestriction
                        data={smsRestrictionData}
                        isOpen={smsRestriction}
                        onClose={() => {
                            setSmsRestriction(false);
                        }}
                    />
                }

                {
                    enhancedData &&
                    <EnhancedData
                        data={enhancedDataData}
                        isOpen={enhancedData}
                        onClose={() => {
                            setEnhanced(false);
                            setEnhancedData(null);
                        }}
                    />
                }

                {
                    downloadLoadPopUp &&
                    <DownloadPopup
                        data={downloadLoadPopUpData}
                        isOpen={downloadLoadPopUp}
                        onClose={() => {
                            setDownloadLoadPopUp(false);
                            setDownloadLoadPopUpData(null);
                        }}
                    />
                }

                {
                    importEnhancePopUp &&
                    <ImportEnhancePopUp
                        data={importEnhancePopUpData}
                        isOpen={importEnhancePopUp}
                        onClose={() => {
                            setImportEnhancePopUp(false);
                            setImportEnhancePopUpData(null);
                        }}
                    />
                }

                <GlobalContactDetailsModule />
                <GlobalContactDetailsForDealModule />
                {
                    (Utils.getAccountData("agencyBusinessType") === undefined || Utils.getAccountData("agencyBusinessType") !== "INSURANCE") ?
                        <GlobalAddDealModalV3
                            open={addDealGlobal}
                            onClose={() => {
                                setAddDealGlobal(false);
                                setAddDealGlobalData(null);
                            }}
                            data={addDealGlobalData}
                        /> :
                        <GlobalAddDealModalV3ForPolicy
                            open={addDealGlobal}
                            onClose={() => {
                                setAddDealGlobal(false);
                                setAddDealGlobalData(null);
                            }}
                            data={addDealGlobalData}
                        />
                }




                <GlobalDealStatus
                    open={dealStatusGlobal}
                    onClose={() => {
                        setDealStatusGlobal(false);
                        setDealStatusGlobalData(null);
                    }}
                    data={dealStatusGlobalData}
                />

                <GlobalInsuranceDealStatus
                    open={dealInsuranceStatusGlobal}
                    onClose={() => {
                        setDealInsuranceStatusGlobal(false);
                        setDealInsuranceStatusGlobalData(null);
                    }}
                    data={dealInsuranceStatusGlobalData}
                />

                <GlobalInsuranceDealStatusLost
                    open={dealInsuranceStatusLostGlobal}
                    onClose={() => {
                        setDealInsuranceStatusLostGlobal(false);
                        setDealInsuranceStatusLostGlobalData(null);
                    }}
                    data={dealInsuranceStatusLostGlobalData}
                />

                <GlobalAddContactHelperModal
                    open={addContactHelperGlobal}
                    onClose={() => {
                        setAddContactHelperGlobal(false);
                        setAddContactHelperGlobalData(null);
                    }}
                    data={addContactHelperGlobalData}
                />

                {
                    addContactModal && (
                    <AddContactModalV2
                        open={addContactModal}
                        data={addContactModalData}
                        onClose={() => {
                            setAddContactModal(false);
                            setAddContactModalData(null);
                        }}
                    />
                   )
                }

                {
                    activityModalInfos.open &&
                    <ActivityModal
                        openActivityModal={activityModalInfos.open}
                        closeActivityModel={()=>{
                            setActivityModalInfos({ open:false, subUserList: null, callbackFunction: null,
                                people: null, editData: null, showAll: null, slotInfo: [], activityFrom: "" });
                        }}
                        showAll={activityModalInfos.showAll}
                        slotInfo={activityModalInfos.slotInfo}
                        subUserList={activityModalInfos.subUserList}
                        callBack={() => {
                            if(activityModalInfos.callbackFunction != null){
                                activityModalInfos.callbackFunction();
                            }
                        }}
                        people={activityModalInfos.people}
                        editData={activityModalInfos.editData}
                        activityFrom={activityModalInfos.activityFrom}
                    />
                }

                {
                    openAddNewCampaignModal &&
                    <NewCampaignPopup
                        handleAddNewCampaignModal={(status)=>{ setOpenAddNewCampaignModal(status) }}
                        open={openAddNewCampaignModal}
                        close={() => { setOpenAddNewCampaignModal(false) }}
                    />
                }

                {
                    buyVirtualNumberModal &&
                    <PhoneSettingsModal
                        open={buyVirtualNumberModal}
                        data={buyVirtualNumberModalData}
                        onClose={()=>{ setBuyVirtualNumberModal(false) }}
                    />
                }
            </>
            {/* } */}
        </>
    );
};

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
        storeMultipleReducers: (params) => dispatch(storeMultipleReducers(params)),

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(UserLayout));