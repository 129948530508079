import React, { useEffect, useState } from "react";
import { GiphyFetch } from "@giphy/js-fetch-api";
import { Grid } from "@giphy/react-components";
import {IconButton, InputAdornment, Modal} from "@material-ui/core";
import {styled} from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import BasicInput from "../../custom/BasicInput";

const StyledModal = styled(Modal)(({ theme })=>({
    "& .giphy-wrapper": {
        position: "absolute",
        width: "640px",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        top: "50%",
        left: "50%",
        transform: `translate(-50%, -50%)`,
        borderRadius: "10px"
    },
    "& .giphy-inner": {
        padding: "18px"
    },
    "& .giphy-grid-scroll": {
        height: "60vh",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "10px",
        },
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
            backgroundColor: "#2c3e50",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "var(--purple)",
            borderRadius: "10px",
        },
    },
    "& .giphy-heading": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "8px",
        paddingBottom: "16px"
    }
}));

const GiphySearch = ({ onSelect, open, onClose }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [loadingDataCount, setLoadingDataCount] = useState(1);
    const LIMIT = 20;

    useEffect(() => {
        setLoadingDataCount(loadingDataCount+1);
    }, [searchTerm]);

    const handleSelect = (gifUrl) => {
        onSelect(gifUrl);
        onClose();
    };

    const giphyFetch = new GiphyFetch(process.env.REACT_APP_GIPHY_API_KEY ?? "");

    const fetchGifs = (offset)=> (searchTerm === "" )
        ? giphyFetch.trending({ limit: LIMIT, offset})
        : giphyFetch.search(searchTerm, { limit: LIMIT, offset: offset});

    const onGifClick = (gif, e) => {
        e.preventDefault();
        if(gif.images) {
            handleSelect(gif.images.original.url)
        }
    }

    return (
        <StyledModal
            open={open}
            onClose={()=>{}}
        >
            <div className="giphy-wrapper">
                <div className="giphy-inner">
                    <div className="giphy-heading">
                        <BasicInput
                            fullWidth
                            id="input-with-icon-textfield"
                            placeholder={"Search GIFs"}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon sx={{height: "16px", width: "16px"}}/>
                                    </InputAdornment>
                                ),
                            }}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <IconButton
                            variant={"outlined"}
                            size={"small"}
                            color={"inherit"}
                            onClick={onClose}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <div className="giphy-grid-scroll">
                        <Grid
                            width={590}
                            columns={3}
                            fetchGifs={fetchGifs}
                            key={loadingDataCount}
                            onGifClick={onGifClick}
                        />
                    </div>

                    <div style={{textAlign: "right"}}>
                        <img style={{marginBottom: "-16px"}}
                             src="https://s3.us-east-1.amazonaws.com/pypepro/user/1/6VjSeGclapKlu5siMSU9DdhLA3SIQ3eeu9PLTNBK.png"
                             alt="Powered by GIPHY"/>
                    </div>
                </div>
            </div>
        </StyledModal>
    );
};

export default GiphySearch;