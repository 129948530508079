import React from "react";

const FavouriteIcon2 = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0192 5.15443L8.9252 4.81953L7.0068 0L5.08839 4.81953L0 5.15443L3.90275 8.46827L2.62208 13.4952L7.0068 10.7236L11.3915 13.4952L10.1109 8.46827L14.0192 5.15443Z"
        fill="#fbc916"
      />
    </svg>
  );
};

export default FavouriteIcon2;
