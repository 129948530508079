import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
const useStyles = makeStyles({
  titleText: {
    color: "#181f48",
    fontWeight: "600 !important",
    marginBottom: "0px !important",
  },
});

const GlobalAddContactModalSkeleton = () => {
  const { titleText } = useStyles();

  return (
        
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
        </Grid>

        <Grid item xs={12} md={12}>
          <Skeleton variant="text" width="20%" className={titleText} />
          <Skeleton variant="rectangular" width="100%" height={40} />
        </Grid>

        <Grid item xs={12} md={12}>
          <Skeleton variant="text" width="20%" className={titleText} />
          <Skeleton variant="rectangular" width="100%" height={40} />
        </Grid>

        <Grid item xs={12} md={12}>
          <Skeleton variant="text" width="20%" className={titleText} />
          <Skeleton variant="rectangular" width="100%" height={40} />
        </Grid>

        <Grid container spacing={2} style={{ marginTop: "4px", padding: "0px 8px" }}>
          <Grid item xs={6}>
            <Skeleton variant="text" width="40%" className={titleText} />
            <Skeleton variant="rectangular" width="100%" height={40} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="text" width="40%" className={titleText} />
            <Skeleton variant="rectangular" width="100%" height={40} />
          </Grid>
        </Grid>
        
        <Grid container spacing={2} style={{ marginTop: "4px", padding: "0px 8px" }}>
          <Grid item xs={6}>
            <Skeleton variant="text" width="40%" className={titleText} />
            <Skeleton variant="rectangular" width="100%" height={40} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="text" width="40%" className={titleText} />
            <Skeleton variant="rectangular" width="100%" height={40} />
          </Grid>
        </Grid>
        
        <Grid item xs={12} md={12}>
          <Skeleton variant="text" width="20%" className={titleText} />
          <Skeleton variant="rectangular" width="100%" height={40} />
        </Grid>
        <Grid container spacing={2} style={{ marginTop: "4px", padding: "0px 8px" }}>
          <Grid item xs={6}>
            <Skeleton variant="text" width="40%" className={titleText} />
            <Skeleton variant="rectangular" width="100%" height={40} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="text" width="40%" className={titleText} />
            <Skeleton variant="rectangular" width="100%" height={40} />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: "4px", padding: "0px 8px" }}>
          <Grid item xs={6}>
            <Skeleton variant="text" width="40%" className={titleText} />
            <Skeleton variant="rectangular" width="100%" height={40} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="text" width="40%" className={titleText} />
            <Skeleton variant="rectangular" width="100%" height={40} />
          </Grid>
        </Grid>

      </Grid>
  );
};

export default GlobalAddContactModalSkeleton;
