import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Styles from "./Style.module.css";
import React from "react";
import Icons from "./Icons";

const App = (props) => {

  return (
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className={Styles.mainWrp}>
              <div className={Styles.bodyContainer}>
                  <div className={Styles.bodyTextContainer}>
                      <div className={Styles.imageContainer}>
                          {Icons.doNotCall}
                      </div>
                      <div className={Styles.reasonTitle}>
                          Do-Not-Call
                      </div>
                      <div className={Styles.messageTitleCenter}>
                          Do-Not-Call is currently enabled for this phone number.
                          <br/>
                          <br/>
                          Select an option below.
                      </div>
                      <div className={Styles.actionContainer}>
                          <span onClick={()=> {
                              props.takeRisk();
                              onClose();
                          }} className={`${Styles.riskActionButton} mr-2`}>
                              Ok, I understand
                          </span>
                          <span onClick={()=> {
                              onClose();
                          }} className={`${Styles.actionButton}`}>
                               {props.cancelButtonText}
                          </span>
                      </div>
                  </div>
              </div>
          </div>
        );
      },
      closeOnClickOutside: props.closeOnClickOutside,
      overlayClassName: Styles.overlay
    })
  )
}
export default App;