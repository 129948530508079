import { makeStyles } from "@material-ui/core";
import React from "react";
const purpleColor = '#3E50F7';

const useStyles = makeStyles({
    centerAlign: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
        backgroundColor:'#FFF',
    },
    congratulationsPage: {
        textAlign: "center",
        maxWidth: "500px !important",
        width: "100%",
    },
    siteLogo: {
        marginBottom: "32px",
    },
    logoImage: {
        width: "160px", 
        height: "51px",
        objectFit:'cover'
    },

    
    congratulationImage: {
        width: "350px",
        height: "300px",
        objectFit:'cover',
        marginBottom: "20px",
    },
    title: {
        fontSize: "24px",
        margin: "10px 0",
        fontWeight:600,
        color:purpleColor
    },
    description: {
        fontSize: "16px",
        margin: "10px 0",
    },


});

const SignUpSuccess = ({logo, agencyName}) => {
    const classes = useStyles();
    return (
        <div className={classes.centerAlign}>
            <div className={classes.congratulationsPage}>
                <div className={classes.siteLogo}>
                    <img src={logo} alt="Logo" className={classes.logoImage} />
                </div>
                <div className={classes.congratulation}>
                    <img
                        src={`${process.env.REACT_APP_CDN_LINK}asset/images/registration/releases/1.0.0/congratulations.png`}
                        alt="Congratulation"
                        className={classes.congratulationImage}
                    />
                    <h1 className={classes.title}>{`Welcome to ${agencyName}!`}</h1>
                    <p className={classes.description}>Your account has been created.</p>
                    <div className={classes.description}>
                    We’ve sent your login credentials via email and text. <br/>
                    See you on the inside.

                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUpSuccess;