import React, { useEffect } from "react";
import * as ReactDOM from "react-dom";
import { CdnFiles } from "./CdnFiles";
import Error from "./components/common/Error";
import NewLoader from "./components/common/NewLoader";
import { ModuleSkeleton } from "./ModuleSkeleton";
import './skeletonUI/SkeletonUI.css'

function MicroFrontend({ name, host, history }) {

    useEffect(() => {
        const scriptId = `micro-frontend-script-${name}`;
        const styleId = `micro-frontend-style-${name}`;

        const renderMicroFrontend = () => {

            if (window[`render${name}`]) {
                try {
                    window[`render${name}`](`${name}-container`, history);
                }
                catch (e) {
                    console.log('render module problem')
                }
            }
        };


        if (document.getElementById(scriptId) && name !== 'DashboardModule' && history.location.pathname === '/get-started') {

            renderMicroFrontend();
            return;
        }

        fetch(`${host}/asset-manifest.json`)
            .then((res) => res.json())
            .then((manifest) => {

                // console.log(manifest)
                // Load CSS
                if (process.env.REACT_APP_PRODUCTION_MODE === 'true') {
                    const link = document.createElement("link");
                    link.id = styleId;
                    link.rel = 'stylesheet';
                    link.type = "text/css";
                    link.media = "screen,projection";
                    link.href = `${host}${manifest.files["main.css"]}`;
                    document.head.appendChild(link);
                }
                CdnFiles(name)
                // Load Script
                const script = document.createElement("script");
                script.id = scriptId;
                script.crossOrigin = "";
                script.src = `${host}${manifest.files["main.js"]}`;
                script.onload = () => {
                    renderMicroFrontend();
                };
                document.head.appendChild(script);
            }).catch(error => {

                console.log(error)
                if (document.getElementById(`${name}-container`)) {
                    ReactDOM.render(<Error />, document.getElementById(`${name}-container`));

                }
            });

        return () => {
            try {
                if (window[`unmount${name}`]) {
                    window[`unmount${name}`] && window[`unmount${name}`](`${name}-container`);
                }

            } catch (e) {
                // console.log('unmount problem : '+e)
            }
        };
    });

    return <main id={`${name}-container`} className={`${name}-container-parent-class`} style={{ position: 'relative' }}>
        <div className="parent-loader-div">
            <ModuleSkeleton moduleName={name} />
        </div>
    </main>;
}

MicroFrontend.defaultProps = {
    document,
    window,
};

export default MicroFrontend;
