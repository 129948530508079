import { Popover } from "@material-ui/core";
import React from "react";
import { InsurancePolicyEditPopOver, InsurancePolicyEditPopOverFlexBox, InsurancePopOverItemText } from "./PolicyStyle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AirplanemodeInactiveIcon from "@material-ui/icons/AirplanemodeInactive";
import NewConfirmAlert from "./custom/NewConfirmAlert"
import AirplanemodeActiveIcon from "@material-ui/icons/AirplanemodeActive";
import { deleteContactPolicy, updateContactPolicyStatus } from "../../LeftSide/inboxApi";

const EditPolicyPopOver = ({
  id,
  open,
  setPolicyId,
  anchorEl,
  handleClose,
  fetchContactPolicyData,
  item,
  setOpenPolicyModal,
}) => {
  const handleEdit = () => {
    setOpenPolicyModal(true);
    setPolicyId(item.id);
    handleClose();
  };

  const updateStatus = async (status) => {
    try {
      const response = await updateContactPolicyStatus({ contact_policy_id: item.id, status: status });
      if (response && response?.success) {
        window.showNotification("SUCCESS", response.message);
        fetchContactPolicyData();
        handleClose();
      }
    } catch (error) {
      console.error(error);
    } 
  };
  const deletePolicy = async () => {
    NewConfirmAlert({
      title: "Confirm to delete",
      description: "Are you sure to delete?",
      cancelText: "Cancel",
      submitText: "Yes, Delete!",
      onSubmit: async () => {
        try {
          const response = await deleteContactPolicy({ contact_policy_id: item.id });
          if (response && response?.success) {
            window.showNotification("SUCCESS", response.message);
            fetchContactPolicyData();
            setPolicyId('')
            handleClose();
          }
        } catch (error) {
          console.error(error);
        } finally {
        }
      },
    });
  };
  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <InsurancePolicyEditPopOver>
          <InsurancePolicyEditPopOverFlexBox onClick={handleEdit}>
            <EditIcon />
            <InsurancePopOverItemText>Edit policy info</InsurancePopOverItemText>
          </InsurancePolicyEditPopOverFlexBox>
          {item.status === 'ACTIVE'? (
            <InsurancePolicyEditPopOverFlexBox onClick={()=>updateStatus('INACTIVE')}>
              <AirplanemodeInactiveIcon />
              <InsurancePopOverItemText>Make policy inactive</InsurancePopOverItemText>
            </InsurancePolicyEditPopOverFlexBox>
          ) : (
            <InsurancePolicyEditPopOverFlexBox onClick={()=>updateStatus('ACTIVE')}>
              <AirplanemodeActiveIcon />
              <InsurancePopOverItemText>Make policy active</InsurancePopOverItemText>
            </InsurancePolicyEditPopOverFlexBox>
          )}
          <InsurancePolicyEditPopOverFlexBox onClick={deletePolicy}>
            <DeleteIcon color='error' />
            <InsurancePopOverItemText>Delete policy</InsurancePopOverItemText>
          </InsurancePolicyEditPopOverFlexBox>
        </InsurancePolicyEditPopOver>
      </Popover>
    </div>
  );
};

export default EditPolicyPopOver;
