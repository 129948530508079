import React, {Fragment, useEffect} from "react";
import {
    Button,
    makeStyles,
    Typography
} from "@material-ui/core";
import Styles from "./Styles.module.css";
import {a2pOnBoard} from "./index";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            "&.MuiButton-root": {
                backgroundColor: "#9670ff",
                padding: "5px 32px",
                borderRadius: "4px",
                fontWeight: 700,
            },
            "&.MuiButton-text": {
                color: "#9670ff"
            },
            "&.MuiButton-contained": {
                color: "white",
                padding: "7px 32px",
            },
            "&.MuiButton-outlined": {
                backgroundColor: "#ffffff",
                color: "#9670ff",
                borderColor: "#9670ff",
                border: "2px solid !important",
            }
        }
    };
});

const EinConfirm = ({ setIsBack, data, onClose, setActiveStep }) => {
    const classes = useStyles();
    useEffect(() => {
        setIsBack(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return(
        <Fragment>
            <div className={Styles.headerWrapper}>
                <Typography className={`${Styles.headerTitle} ${Styles.headerTitleCenter}`} variant="h5">A2P/10DLC Registration</Typography>
                <Button size="small" onClick={() => {
                    setActiveStep(a2pOnBoard.ASK_REGION)
                }} className={`${classes.root}`} variant="outlined">
                    BACK
                </Button>
            </div>

            <div className={Styles.modalBody}>
                <div className={Styles.askConsent}>
                    Does the business you're registering have a tax ID (EIN#)?
                </div>
                <div>
                    <Button  size="large" onClick={() => {
                        setActiveStep(a2pOnBoard.ASK_VOLUME)
                    }} className={`${classes.root} ${Styles.overwriteButton} ${Styles.mr1} ${Styles.width140px}`} variant="contained">
                        YES
                    </Button>
                    <Button onClick={()=>{
                        if (data?.count > 1){
                            setActiveStep(a2pOnBoard.RESTRICT_SOLE)
                        }else {
                            onClose();
                            window.globalA2pSoleRegistration(true);
                        }
                    }} size="large" className={`${classes.root} ${Styles.redButtonFill} ${Styles.width140px}`} variant="contained">
                        NO
                    </Button>
                </div>
            </div>
            <div className={Styles.modalFooter}>
                <div className={`${Styles.welcomeMessage} ${Styles.headerTitleCenter}`}>
                    <p>
                        * You can find your EIN number on your IRS forms or previous tax return documents (W2, W9, or CP575).
                    </p>
                </div>
            </div>
        </Fragment>
    );
};

export default EinConfirm;