import React, {useEffect} from "react";
import {useState} from "react";
import VideoEmailModal from "../SendComponents/Video/VideoEmailModal";
import {connect} from "react-redux";
import { changeLikeStatus } from "../SendComponents/Video/redux/videoEmailAction";

const VideoEmailInitialState = {
    isOpenVideoEmailSendModal: false,
    contactId: null,
    contactDetails: {},
    from: '',
    fetchContactDetails: false,
    showAllTimeSettings: false,
    campaignId: null,
    currentCampaignSetting: undefined,
    handleRemoveCurrentCurrentCampaignSetting: undefined,
    currentQuickReply: undefined,
    responseCallback: undefined,
    open:false,
    relationSuperContactId: null,
};

const SendModule = (props) => {
    
    //Video Email States
    const [videoEmailState, setVideoEmailState] = useState(VideoEmailInitialState);
    // const [isOpenVideoEmailSendModal, setIsOpenVideoEmailSendModal] = useState(false);

    useEffect (()=>{

        let info = props.info;
        setVideoEmailState({
            ...videoEmailState,
            contactId: typeof info.contactId === 'undefined' ? videoEmailState.contactId : info.contactId,
            relationSuperContactId: info.relationSuperContactId,
            contactDetails: typeof info.contactDetails === 'undefined' ? videoEmailState.contactDetails : info.contactDetails,
            from: info.from,
            showAllTimeSettings: typeof info.showAllTimeSettings === 'undefined' ? videoEmailState.showAllTimeSettings : info.showAllTimeSettings,
            campaignId: typeof info.campaignId === 'undefined' ? videoEmailState.campaignId : info.campaignId,
            currentCampaignSetting: typeof info.currentClip === 'undefined' ? videoEmailState.currentClip : info.currentClip,
            handleRemoveCurrentCurrentCampaignSetting: typeof info.handleRemoveCurrentClip === 'undefined' ? videoEmailState.handleRemoveCurrentClip : info.handleRemoveCurrentClip,
            currentQuickReply: typeof info.currentQuickReply === 'undefined' ? videoEmailState.currentQuickReply : info.currentQuickReply,
            responseCallback: info.responseCallback,
            userInfo: typeof info.userInfo === 'undefined' ? null : info.userInfo,
            open: true
        });
        // setIsOpenVideoEmailSendModal(true)
    },[]) 
    //  = info => {
    //     if (info.open) {
    //         setVideoEmailState({
    //             ...videoEmailState,
    //             contactId: typeof info.contactId === 'undefined' ? videoEmailState.contactId : info.contactId,
    //             contactDetails: typeof info.contactDetails === 'undefined' ? videoEmailState.contactDetails : info.contactDetails,
    //             from: info.from,
    //             showAllTimeSettings: typeof info.showAllTimeSettings === 'undefined' ? videoEmailState.showAllTimeSettings : info.showAllTimeSettings,
    //             campaignId: typeof info.campaignId === 'undefined' ? videoEmailState.campaignId : info.campaignId,
    //             currentCampaignSetting: typeof info.currentClip === 'undefined' ? videoEmailState.currentClip : info.currentClip,
    //             handleRemoveCurrentCurrentCampaignSetting: typeof info.handleRemoveCurrentClip === 'undefined' ? videoEmailState.handleRemoveCurrentClip : info.handleRemoveCurrentClip,
    //             currentQuickReply: typeof info.currentQuickReply === 'undefined' ? videoEmailState.currentQuickReply : info.currentQuickReply,
    //             responseCallback: info.responseCallback
    //         });
    //         setIsOpenVideoEmailSendModal(true);
    //     } else {
    //         setVideoEmailState(VideoEmailInitialState);
    //         setIsOpenVideoEmailSendModal(false);
    //     }
    // }

    const closeVideoEmailSendModal = () => {
        props.changeLikeStatus(false);

        props.closeModal('videoEmail')
        // setVideoEmailState(VideoEmailInitialState);
        // setIsOpenVideoEmailSendModal(false);
    }


  
 

   

    return (
        <React.Fragment>
            {videoEmailState.open &&
                <VideoEmailModal
                    id={videoEmailState.contactId}
                    relationSuperContactId={videoEmailState.relationSuperContactId}
                    from={videoEmailState.from}
                    fetchContactDetails={videoEmailState.fetchContactDetails}
                    responseCallback={videoEmailState.responseCallback ?? null}
                    openVideoEmailModal={videoEmailState.open}
                    closeVideoEmailSendModal={closeVideoEmailSendModal}
                    showAllTimeSettings={videoEmailState.showAllTimeSettings}
                    selectedContact={videoEmailState.contactDetails}
                    campaignId={videoEmailState.campaignId}
                    currentCampaignSetting={videoEmailState.currentCampaignSetting}
                    handleRemoveCurrentCurrentCampaignSetting={videoEmailState.handleRemoveCurrentCurrentCampaignSetting}
                    currentQuickReply={videoEmailState.currentQuickReply}
                    userInfo={videoEmailState.userInfo}
                />
            }EmailS
        </React.Fragment>
    );
}
const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changeLikeStatus: (params) => dispatch(changeLikeStatus(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SendModule);
