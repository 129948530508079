import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { unsubscribePublicContact } from "../../api/globalApi";
import ContactEmailUnsubscribeStyle from "./ContactEmailUnsubscribeStyle";

const Unsubscribe = () => {
    const classes = ContactEmailUnsubscribeStyle();
    const { contactId } = useParams();
    const [submitting, setSubmitting] = useState(false);
    const [unsubscribing, setUnbscribing] = useState(false);

    const ContactCampaignEmailUnsubscribe = async () => {
        try {
            setUnbscribing(true);
            setSubmitting(true);
            const resp = await unsubscribePublicContact({
                contact_id: contactId,
            });

            if (resp?.data && resp.data.success){
                window.open(`/campaign/${contactId}/unsubscribe?unsubscribed=true`, "_self");
            }else {
                if (window.showNotification){
                    window.showNotification("error", "Failed to unsubscribe");
                }
            }
            console.log(resp);
        }catch (err){
            console.log(err);
            if (window.showNotification){
                window.showNotification("error", "Failed to unsubscribe");
            }
        }finally {
            setSubmitting(false);
            setUnbscribing(false);
        }
    }

    return (
        <div className={classes.page}>
            <Helmet>
                <title>Unsubscribe</title>
            </Helmet>
            <div className={classes.container}>
                <div className={classes.innerContainer}>
                    <img src="https://d24gujj67p1uc1.cloudfront.net/assets/bulk-email/images/releases/1.0.1/message-00909.svg" alt="Message icon"/>

                    <div className={classes.bottom}>
                        <h2 className={classes.title}>Do you want to unsubscribe?</h2>
                        <p className={classes.subtitle}>If you unsubscribe, you will stop receiving emails from us.</p>
                        <div className={classes.buttons}>
                            <button disabled={submitting} id="unsubscribe" onClick={ContactCampaignEmailUnsubscribe}>{unsubscribing ? "Unsubscribing" : "Unsubscribe"}</button>
                            <button id="cancel" onClick={()=>{ window.open(`/campaign/${contactId}/unsubscribe?thank_you=true`, "_self"); }}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Unsubscribe;