import {Helmet} from "react-helmet";

const ZendireactGreetingProcessPreview = ({
    image_url,
    selectedTemplate,
    typeYourMessageSteps,
    handleDataChange
}) => {
    const renderFontClass = () => {
        return "";
        return typeYourMessageSteps.handWritingStyle.class       
    }
    const renderPageView = () => {
        if(typeYourMessageSteps.showingPage === 'first_page'){
            return (
                <img 
                    alt={selectedTemplate.title}
                    src={selectedTemplate.image_path}
                    className={'dmpc_tm_zd_gg_pc_fp_image'}
                />
            );
        }
        else if(typeYourMessageSteps.showingPage === 'second_page'){
            return (
                <div className="dmpc_tm_zd_gg_pc_sp_container">
                    <span className={renderFontClass()}>{typeYourMessageSteps.message}</span>
                </div>
            );
        }
        else if(typeYourMessageSteps.showingPage === 'third_page'){
            return (
                <div className="dmpc_tm_zd_gg_pc_sp_container">
                    <span className={renderFontClass()}>{typeYourMessageSteps.thirdPageText}</span>
                </div>
            );
        }
    }
    const handleStepChange = (value) => {
        let step = 'second_page' //first_page, second_page, third_page
        if(value === 'back'){
            if(typeYourMessageSteps.showingPage === 'first_page'){
                step = 'third_page'
            }
            else if(typeYourMessageSteps.showingPage === 'second_page'){
                step = 'first_page'
            }
            else if(typeYourMessageSteps.showingPage === 'third_page'){
                step = 'second_page'
            }
        }
        else{
            if(typeYourMessageSteps.showingPage === 'first_page'){
                step = 'second_page'
            }
            else if(typeYourMessageSteps.showingPage === 'second_page'){
                step = 'third_page'
            }
            else if(typeYourMessageSteps.showingPage === 'third_page'){
                step = 'first_page'
            }
        }
        handleDataChange({showingPage: step})
    }
    return(
        <div className="dmpc_tm_zd_gg_process_c">
            {/* <Helmet>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
                <link 
                    href="https://fonts.googleapis.com/css2?family=Allura&family=Handlee&family=Shrikhand&family=Fasthand&family=Freehand&family=Bilbo+Swash+Caps&family=Gochi+Hand&family=Playfair+Display&family=Edu+TAS+Beginner&display=swap" 
                    rel="stylesheet" 
                />
            </Helmet> */}
            <div className="dmpc_tm_zd_gg_page__change__stepper">
                <span onClick={() => handleStepChange('back')}>{'<'}</span>
                <span>{typeYourMessageSteps.showingPage.toUpperCase().replace(/_/g, ' ')}</span>
                <span onClick={() => handleStepChange('forward')}>{'>'}</span>
            </div>
            <div className={`dmpc_tm_zd_gg_process_container ${selectedTemplate.orientation === 2 ? 'zd_gg_portrait' : 'zd_gg_landscape'}`}>
                {renderPageView()}
            </div>
        </div>
    );
}
export default ZendireactGreetingProcessPreview;