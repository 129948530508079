import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {registrationWithPayment, updateErrorMessage, updateSubmitting} from '../../actions/signupAction'
import {connect} from "react-redux";
import {getCookieOfThirdParty} from "../../helpers/Cookie";
import { CircularProgress } from "@material-ui/core";

const App = (props) => {
    const stripe = useStripe();
    const elements = useElements();

    const [submitting, setSubmitting] = useState(false)

    useEffect(() => {
        setSubmitting(props.submitting)
    }, [props.submitting])

    const handleSubmit = async (event) => {

        
        // Block native form submission.
        event.preventDefault();
        if(submitting){
            return false;
        }
        setSubmitting(true)
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            setSubmitting(false)

            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        // Use your card Element with other Stripe.js APIs
        // const {error, paymentMethod} = await stripe.createPaymentMethod({
        //     type: 'card',
        //     card: cardElement,
        // });

        const {error, token} = await stripe.createToken(cardElement);

        if (error) {
            setSubmitting(false);
            props.updateErrorMessage(error.message);
            window.showNotification("error", error.message);
        } else {

            const firstPromoterTID = getCookieOfThirdParty('_fprom_tid');
            const firstPromoterTrack = getCookieOfThirdParty('_fprom_track');
            const firstPromoterCode = getCookieOfThirdParty('_fprom_code');

            let formData = new FormData();
            formData.append('agency_id', props.agencyId);
            formData.append('package_id', props.selectedPackage.id);
            formData.append('stripeToken', token.id);
            formData.append('name', props.fullName);
            formData.append('full_name', props.fullName);
            formData.append('email', props.email);
            formData.append('company_name', props.companyName);
            formData.append('company_category_id', props.companyCategory);
            formData.append('hear_about_us_item_id', props.aboutUsItem);
            formData.append('role_id', 1);
            formData.append('phone', props.phoneNumber);
            formData.append('page', props.signupType);
            formData.append('_fprom_tid', firstPromoterTID);
            formData.append('_fprom_track', firstPromoterTrack);
            formData.append('_fprom_code', firstPromoterCode);
            formData.append('subscription_type', props.selectedPackage.plan_type);
            formData.append('pricing_id', props.selectedPackage.selectedPricing.id);

            try{
                if(props.agencyId == 82 && window?.tolt_referral){
                    formData.append('tolt_referral', window.tolt_referral);
                }
            }
            catch(error){}

            props.registrationWithPayment(formData, () => {
                try{
                    if (window.tolt_referral) {
                        window.tolt.signup(props.email)
                    }
                }
                catch(error){
                    console.log(error)
                }
            });
        }

    };

    return (
        <form id="payment-form" className="col s12" onSubmit={handleSubmit}>
            <div className="row">
                <div className="input-field payment_input col s12">
                    <label>Contact Information</label>
                </div>

                <div className="input-fields s12">


                    <div className="mt-2">
                        <div className="input-field payment_input col s6">
                            <input placeholder="First Name" id="first_name" type="text" className="validate" />
                            {/*<label htmlFor="city">First Name</label>*/}
                        </div>
                        <div className="input-field payment_input col s6">
                            <input placeholder="Last Name" id="last_name" type="text" className="validate" />
                            {/*<label htmlFor="state">Last Name</label>*/}
                        </div>
                        <div className="input-field payment_input col s4">
                            <input placeholder="City" id="city" type="text" className="validate" />
                            {/*<label htmlFor="city">Give Your City</label>*/}
                        </div>
                        <div className="input-field payment_input col s4">
                            <input placeholder="State" id="state" type="text" className="validate" />
                            {/*<label htmlFor="state">Give Your State</label>*/}
                        </div>
                        <div className="input-field payment_input col s4">
                            <input placeholder="Zip" id="zip_code" type="text" className="validate" />
                            {/*<label htmlFor="zip_code">Give Your Zip Code</label>*/}
                        </div>
                    </div>

                </div>

            </div>
            <div className="row">

                <div className="input-field payment_input col s12 mb-5">
                    <label>Payment Information</label>
                </div>

                <div id="card_no_input__v2__v3" className="input-field card_no_input s12">

                    <CardElement
                        id="card-element"
                        hidePostalCode={true}
                        options={{
                            style: {
                                base: {
                                    fontSize: '16px',
                                    color: '#133159',
                                    '::placeholder': {
                                        color: '#aab7c4',
                                    },
                                },
                                invalid: {
                                    color: '#9e2146',
                                },
                            },
                            hidePostalCode: true
                        }}
                        onChange={(event)=>{
                            props.updateErrorMessage("");
                        }}
                    />

                    {
                        props.errorMessage &&
                        <div className="d-flex justify-content-start">
                            <p className="error-message-signup">{props.errorMessage}</p>
                        </div>
                    }

                    <div className="row">
                        <div className="payment_btn">
                            <button type="submit" style={!stripe || submitting ? {paddingRight:36,position:'relative'} : {}} className="btn accent--bg--color border-radius-10" disabled={!stripe || submitting}>{submitting ? <>Submitting <CircularProgress size={21} style={{right:10,top:7}}/></> : 'Create My Account'}</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

const mapStateToProps = state => {
    return {
        user: state.signupReducer.user,
        agencyId: state.signupReducer.agencyId,
        selectedPackage: state.signupReducer.selectedPackage,
        companyName: state.signupReducer.companyName,
        fullName: state.signupReducer.fullName,
        email: state.signupReducer.email,
        phoneNumber: state.signupReducer.phoneNumber,
        aboutUsItem: state.signupReducer.aboutUsItem,
        companyCategory: state.signupReducer.companyCategory,
        signupType: state.signupReducer.signupType,
        submitting: state.signupReducer.submitting,
        errorMessage: state.signupReducer.errorMessage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        registrationWithPayment: (params, callback) => dispatch(registrationWithPayment(params, callback)),
        updateSubmitting: (params) => dispatch(updateSubmitting(params)),
        updateErrorMessage: (params) => dispatch(updateErrorMessage(params)),
    }
}

const PaymentForm = connect(mapStateToProps, mapDispatchToProps)(App);

export default PaymentForm;