import React, { useState } from "react";
import { ListItem, ListItemIcon, ListItemText, Popover, styled } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: "none",
    }
}));

const SidebarPopoverMenuInsuranceWrapper = styled("div")(({ theme }) => ({
    "& .sidebarPopup": {
        height: "unset",
        backgroundColor: "#fff",
        width: "270px",
        display: "flex",
        flexDirection: "column",
        willChange: "transform",
        zIndex: 160,
        pointerEvents: "auto",
        transition: ".5s ease-in-out",
        top: "110px",
        boxShadow: "0 5px 5px 4px hsla(0, 0%, 81.2%, .5), 0 7px 0 0 var(--accent-color), 0 -5px 5px 2px hsla(0, 0%, 81.2%, .5)",
        borderRadius: "10px",
        overflow: "visible",
        left: "11px",

        "& .sidebarContent": {
            height: "100%",
            width: "100%"
        },

        "& .sidebarMenuListUl": {
            listStyle: "none",
            margin: 0,
            padding: 0,
            display: "flex",
            flexDirection: "column",
            height: "100",
            overflowY: "auto",
            maxHeight: "calc(100vh - 200px)",
            paddingBottom: "4px",

            "& .sidebarMenuListLi": {
                padding: "15px 20px 5px",
                margin: "0",
                cursor: "pointer",
                userSelect: "none",

                "& h4": {
                    color: "var(--dark_blue)",
                    fontSize: "14px",
                    lineHeight: "24px",
                    padding: "0",
                    fontWeight: "400",
                    height: "unset",
                    margin: "0 !important",

                    "& span": {
                        fontSize: "12px",
                        lineHeight: "22px",
                        color: "#546376",
                        marginTop: "5px",
                        display: "block"
                    }
                },
                "&:hover": {
                    background: "rgba(60, 126, 243, .1)"
                }
            },

            "&::-webkit-scrollbar": {
                width: "6px",
                height: "6px",
                scrollbarWidth: "thin"
            },
            "&::-webkit-scrollbar-track": {
                borderRadius: "4px",
                background: "#f4f5f7",
                boxShadow: "inset 1px 0px 0px rgba(148, 157, 178, 0.12)",
            },
            "&::-webkit-scrollbar-thumb": {
                borderRadius: "10px",
                boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
                backgroundColor: "#949db2",
                boxSizing: "border-box",
            },
        }
    }
}));

const SidebarPopoverMenuInsurance = ({ menuCollapsed, gotoUrl, selectedSidebarItem, item: { module, icon: Icon, label, sub_menus } }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <ListItem
            button
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            selected={selectedSidebarItem === module}
            className="sidebarItem"
        >
            <ListItemIcon>
                <Icon color={"var(--sidebar-icon-color)"} />
            </ListItemIcon>
            <ListItemText primary={label} />

            <Popover
                className={classes.popover + ` slide__nav_wrapper`}
                classes={{ paper: classes.paper }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "left",
                }}
                style={{ left: 20 }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <SidebarPopoverMenuInsuranceWrapper>
                    <div className="sidebarPopup">
                        <div className="sidebarContent">
                            <ul className="sidebarMenuListUl">
                                {
                                    sub_menus.map((item, i) => (
                                        item.visible ?
                                            <li key={i} className="sidebarMenuListLi" onClick={() => {
                                                if (item.url && !item.onclick) {
                                                    gotoUrl(item.url, module, item.state, item.search, item.target);
                                                } else if (!item.url && item.onclick) {
                                                    item.onclick();
                                                }
                                                handlePopoverClose();
                                            }}>
                                                <h4>
                                                    {item.label}
                                                    <span>{item.description}</span>
                                                </h4>
                                            </li> :
                                            null
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </SidebarPopoverMenuInsuranceWrapper>
            </Popover>
        </ListItem>
    );
};

export default SidebarPopoverMenuInsurance;