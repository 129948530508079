import axios from "axios";
import { getCookie } from "../helpers/Cookie";

export const sendRequest = (method, url, payload = [], config = []) => {
  let data = [];
  try {
    const apiKey = getCookie(process.env.REACT_APP_ACCESS_TOKEN);

    payload.append('api-key', apiKey)
    data = payload;
  } catch (error) {
    data = { ...payload, ...{ 'api-key': getCookie(process.env.REACT_APP_ACCESS_TOKEN) } };
  }
  return axios.request({
    method: method,
    url: url,
    data: data,
    params: (method === 'get' || method === 'GET') ? data : null
  });
}

export const sendRequestWithHighTimeout = (method, url, payload = [], config = []) => {
  let data = [];
  try {
    const apiKey = getCookie(process.env.REACT_APP_ACCESS_TOKEN);

    payload.append('api-key', apiKey)
    data = payload;
  } catch (error) {
    data = { ...payload, ...{ 'api-key': getCookie(process.env.REACT_APP_ACCESS_TOKEN) } };
  }
  return axios.request({
    method: method,
    url: url,
    data: data,
    params: (method === 'get' || method === 'GET') ? data : null,
    timeout: 120000
  });
}

export const sendRequestBackEnd = (method, url, postData = null, queryparams = null) => {

  if (queryparams != null) {
    url = url + queryparams
  }
  const apiKey = getCookie(process.env.REACT_APP_ACCESS_TOKEN);

  if (method === 'GET' || method === 'get') {


    return fetch(url, {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + apiKey,
        // 'Access-Control-Allow-Headers': '*',

        // Authorization: 'Bearer DGM2RNXDL8KS85JD066E' //stage api

      },
      // referrer: 'origin-when-cross-origin',
      referrerPolicy: 'origin',
      // mode: 'cors',
    })
      .then((response) => response.json())
      .then((responseData) => {
        return responseData
      })
      .catch(error => {
        return false;
      })
  }
  return fetch(url, {
    method: method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + apiKey,
      // 'Access-Control-Allow-Headers': '*',

      // Authorization: 'Bearer DGM2RNXDL8KS85JD066E' //stage api

    },
    // referrer: 'origin-when-cross-origin',
    referrerPolicy: 'origin',
    // mode: 'cors',
    body: JSON.stringify(postData)
  })
    .then((response) => response.json())
    .then((responseData) => {
      return responseData
    })
    .catch(error => {
      return false;
    })

}

export const image_upload_handler = (blobInfo, success, failure, progress) => {
  const emailUploadFileApi = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/calendar/upload-email-file`;
  var xhr, formData;

  const apiKey = getCookie(process.env.REACT_APP_ACCESS_TOKEN);

  xhr = new XMLHttpRequest();
  xhr.withCredentials = false;
  xhr.open('POST', emailUploadFileApi);

  xhr.upload.onprogress = function (e) {
    progress(e.loaded / e.total * 100);
  };

  xhr.onload = function () {
    var json;

    if (xhr.status === 403) {
      failure('HTTP Error: ' + xhr.status, { remove: true });
      return;
    }

    if (xhr.status < 200 || xhr.status >= 300) {
      failure('HTTP Error: ' + xhr.status);
      return;
    }

    json = JSON.parse(xhr.responseText);

    if (!json || typeof json.location != 'string') {
      failure('Invalid JSON: ' + xhr.responseText);
      return;
    }

    success(json.location);
  };

  xhr.onerror = function () {
    failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
  };
  formData = new FormData();
  formData.append('file', blobInfo.blob(), blobInfo.filename());
  formData.append('api-key', apiKey)
  xhr.send(formData);
}

// export const sendRequest = (method, url, data = [], config = []) => {
//     switch (method) {
//         case 'get':
//             return axios.get(url, {data})
//                 .then(res => res.data)
//                 .catch(err => {
//                     console.log(err);
//                 });
//
//         case 'post':
//             return axios.post(url, data, config)
//                 .then((response) => response.data)
//                 .catch(err => {
//                     console.log(err);
//                 });
//         default:
//             return {
//                 status: false,
//                 message: "Unfamiliar request type"
//             }
//     }
// }