import { combineReducers } from 'redux'
import loginReducer from "./loginReducer";
import signupReducer from "./signupReducer";
import globalDialerReducer from "./globalDialerReducer";
import notificationReducer from "./notificationReducer";
import addContactReducer from './addContactReducer';
import contactPopUpReducer from './contactPopUpReducer';
import tagReducer from './tagReducer';
import rightsideReducer from './rightsideReducer';
import contactDetailReducer from '../components/contactDetails/redux/contactDetailReducer';
import videoEmailReducer from '../components/SendComponents/Video/redux/videoEmailReducer';
import cardBroadcastReducer from '../components/DirectCardSend/redux/cardBroadcastReducer';
import enhancedDataReducer from "../components/enhancedData/redux/enhancedDataReducer";
import inboxReducer from "../components/GlobalContactDetailsForDeal/LeftSide/inboxReducer";
import floatingSidebarReducer from './floatingSidebarReducer';



export default combineReducers({
    loginReducer,
    signupReducer,
    globalDialerReducer,
    notificationReducer,
    addContactReducer,
    contactPopUpReducer,
    contactDetailReducer,
    videoEmailReducer,
    cardBroadcastReducer,
    enhancedDataReducer,
    tagReducer,
    rightsideReducer,
    inboxReducer,
    floatingSidebarReducer
})