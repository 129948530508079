import React from 'react';
import NotificationsIcon from "@material-ui/icons/Notifications";
import { ChatIcon , EmailIcon  , CallIcon , SmsIcon,ContactIcon,FormIcon,PauseIcon} from './InboxDrawerIcon';

const NotificationContentTypeIcon = ({ contentType }) => {
    const CONTENT_TYPE_SMS = 0;
    const CONTENT_TYPE_EMAIL = 1;
    const CONTENT_TYPE_CALL = 2;
    const CONTENT_TYPE_SHARE_CONTACT = 3;
    const CONTENT_TYPE_FORM_SUBMISSION = 4;
    const CONTENT_TYPE_CAMPAIGN_PAUSE_ALERT = 5;
    const CONTENT_TYPE_CHAT = 15;

  const getIcon = () => {
    switch (contentType) {
      case CONTENT_TYPE_SMS:
        return <ChatIcon />;
      case CONTENT_TYPE_EMAIL:
        return <EmailIcon />;
      case CONTENT_TYPE_CALL:
        return <CallIcon />;
      case CONTENT_TYPE_SHARE_CONTACT:
        return <ContactIcon />;
      case CONTENT_TYPE_CAMPAIGN_PAUSE_ALERT:
        return <PauseIcon />;
      case CONTENT_TYPE_CHAT:
        return < SmsIcon/>;
      case CONTENT_TYPE_FORM_SUBMISSION:
        return <FormIcon />;
      default:
        return <NotificationsIcon />;
    }
  };

  return (
    <div
      style={{
        padding: "10px",
        backgroundColor: "#f0f0f5", // Light gray color
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "40px",
        height: "40px",
      }}
    >
      {getIcon()}
    </div>
  );
};

export default NotificationContentTypeIcon;