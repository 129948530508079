import If, { Else } from "if-else-react/src";
import React, { useRef, useState } from 'react';
import { connect } from "react-redux";
import { attemptOtpChecking, loginSuccess } from "../../actions/loginAction";
import { resendOtpFromLoginScreen } from "../../api/loginApi";
import { setCookie, setCookieLogin } from "../../helpers/Cookie";
import './OtpForm.css';
import './login.css';

const OtpFormForLogin = props => {
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [agencyTextColor, setAgencyTextColor] = useState(props.agencyTextColor);
    const [showOtpErrorMessage, setShowOtpErrorMessage] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [otp, setOtp] = useState(['', '', '', '', '']);
    const inputRefs = useRef([]);

    const handleChange = (event, index) => {
        setShowErrorMessage(false);
        setShowOtpErrorMessage(false);
        const newOtp = [...otp];
        newOtp[index] = event.target.value;
        setOtp(newOtp);
    };

    const focusNext = (event, index) => {
        if ((event.key === 'Delete' || event.key === 'Backspace')) {
            event.preventDefault();
        }else{
            if (index === 4) return;
            inputRefs.current[index + 1].focus();
        }
    };

    const verifyOtp = (e) => {
        e.preventDefault();

        let userOtp = otp.join("");
        if(userOtp.length !== 5){
            setShowErrorMessage(true);
            return;
        }
        setSubmitting(true);
        let formData = new FormData();
        formData.append('userOtp', userOtp);
        formData.append('email', props.email);
        formData.append('password', props.password);
        formData.append('agencyId',props.agencyId);
        props.attemptOtpChecking(formData, loginResponse);
    }

    const loginResponse = response => {
        if (response.status === 'success') {
            setCookie(process.env.REACT_APP_ACCESS_TOKEN, response.api_key, process.env.REACT_APP_ACCESS_TOKEN_VALIDITY);
            setCookieLogin(process.env.REACT_APP_ACCOUNT_DATA, JSON.stringify(response.accountData), process.env.REACT_APP_ACCESS_TOKEN_VALIDITY);
            props.loginSuccess(response.api_key);
        }else{
            setSubmitting(false);
            setShowOtpErrorMessage(true);
        }
    }

    const resendOtp = () => {
        let formData = new FormData();

        formData.append('email', props.email);
        formData.append('password', props.password);
        formData.append('agencyId',props.agencyId);

        resendOtpFromLoginScreen(formData)
            .then(response => {
                if (response.data.status === 'success') {
                    window.showNotification("SUCCESS", response.data.message);
                }else {
                    window.showNotification("ERROR", response.data.message)
                }
            }).finally(() => {

        });
    }

    return (
        <React.Fragment>
            <div className="topbar">
                <div className="row">
                    <div className=" top__bar__content__v2 ">
                        <div className="col s12 m12 l12 lognin_link">
                            <div className="text text_v2">
                                <p>
                                    Already Have an Account? <a href="/login">Log In</a>
                                </p>
                            </div>
                        </div>
                        <div className="col s12 m12 l12 ">
                            <div className="main_logo otp_screen_logo_container">
                                    <img className="login-page-logo" src={props.pageLogo} alt="logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="custom__container">
                <div className="row">
                    <div className="otp_form_wrapper" >
                        <form id="" action="" className="from_content otp_form_login_page">
                            <h5 className="form_content_title" style={{color:agencyTextColor}}>Please enter OTP</h5>
                            <p style={{color:agencyTextColor}}>We have sent you one time password to your mobile</p>
                            {/*<span className="form_content_time">1:52</span>*/}

                            <div className="verification_code">
                                {otp.map((field, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        value={field}
                                        onChange={(e) => handleChange(e, index)}
                                        onKeyUp={(e) => focusNext(e,index)}
                                        ref={(el) => (inputRefs.current[index] = el)}
                                        maxLength={1}
                                    />
                                ))}
                            </div>

                            <If condition={showErrorMessage}>
                                <p className="form_content_otp_error">
                                    OTP length must be 5 digits long
                                </p>
                            </If>

                            <If condition={showOtpErrorMessage}>
                                <p className="form_content_otp_error">
                                    OTP is not correct, please try again
                                </p>
                            </If>

                            <p style={{color:agencyTextColor}} className="form_content_resend">
                                Didn't receive the OTP?
                                <a href="#" className="resend" onClick={()=>resendOtp()}> RESEND</a>
                            </p>

                            <If condition={!submitting}>
                                <button className="btn_verify" onClick={(e)=>verifyOtp(e)}>Verify</button>
                                <Else />
                                <button className="btn_verify" disabled={'disabled'}>Verifying . . . </button>
                            </If>

                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};


const mapStateToProps = state => {
    return {
        user: state.loginReducer.user,
        apiKey: state.loginReducer.apiKey,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        attemptOtpChecking: (params, callback) => dispatch(attemptOtpChecking(params, callback)),
        loginSuccess: (params) => dispatch(loginSuccess(params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OtpFormForLogin);