// import { TAB_ALL } from "../components/inbox/LeftSide";
import * as ACTION from "./constants/inboxActionTypes";

const initialState = {
    user : null,
    conversations: [],
    threads: [],
    selectedContact: null,
    selectedContactIndex: null,
    virtualNumbers : [],
    customFieldData : [],
    messageTemplates: null,
    videoLoader: true,
    videoFolders: null,
    videos: null,
    s3ServerVideoUrl: '',
    selectedVideo: null,
    folderId: null,
    bottomBoxVisible: false,
    rightSidebarVisible: false,
    tabOptionInBottomBox: 'inbox-email-active',
    // tabOptionLeftSide: TAB_ALL,
    updateUnreadContactId: 0,
    contactIdFromDashboard: null,
    lastConnectedVirtualNumberId: null,

    /* for inbox middle part */
    isShowSendOption: false,
    activeSendOption: '',
    carrierLookupPrices: null,
    contactAdditionalContacts: [],
    contactPolicies:[],
    contactPolicyCompanyAgentData:{
        policyTypes: [],
        insuranceCompanies: [],
        agents: []
    }
};

const inboxReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.STORE_VIRTUAL_NUMBERS:
            return {...state, virtualNumbers: action.payload}

        case ACTION.STORE_CONVERSATION:
            return {...state, conversations: [...action.payload, ...state.conversations]}

        case ACTION.STORE_CUSTOM_FIELD:
            return {...state, customFieldData: action.payload}

        case ACTION.ADD_SELECTED_CONTACT:
            if(action.payload == null){
                return {...state, selectedContact: null, selectedContactIndex: null}
            }
            if(typeof action.payload === 'object'){
                return {...state, selectedContact: action.payload.contact, selectedContactIndex: action.payload.index}
            }
            return {...state, selectedContact: action.payload}

        case ACTION.SET_USER:
            return {...state, user: action.payload}

        case ACTION.UPDATE_SELECTED_CONTACT:
            if(action.payload.is_read != undefined){
                return {...state, selectedContact: {...state.selectedContact, is_read: action.payload.is_read}}
            }   
            else{
                return {...state, selectedContact: {...state.selectedContact, is_favourite: action.payload.is_favourite}}
            }
        /* Message Template Start */
        case ACTION.STORE_MESSAGE_TEMPLATE:
            return {...state, messageTemplates: action.payload}
        /* Message Template End */

        case ACTION.UPDATE_BOTTOM_BOX:
            return {...state, bottomBoxVisible: action.payload}
        /* Video Email End */
        
        case ACTION.TOGGLE_RIGHT_SIDEBAR:
            return {...state, rightSidebarVisible: !state.rightSidebarVisible}
            
        case ACTION.CHANGED_TAB_IN_BOTTOM_BOX:
            return {...state, tabOptionInBottomBox: action.payload}

        case ACTION.SELECTED_TAB_LEFT_SIDE:
            return {...state, tabOptionLeftSide: action.payload}

        case ACTION.UPDATE_INBOX_UNREAD_CONTACT:
            return {...state, updateUnreadContactId: action.payload}

        case ACTION.SET_CONTACT_ID_FROM_DASHBOARD:
            return {...state, contactIdFromDashboard: action.payload}

        case ACTION.HANDLE_SEND_VIEW_FLAG:
            return {...state, isShowSendOption: action.payload}

        case ACTION.UPDATE_ACTIVE_SEND_OPTION:
            return {...state, activeSendOption: action.payload}

        case ACTION.UPDATE_LAST_CONTACTED_NUMBER:
            return {...state, lastConnectedVirtualNumberId: action.payload}

        case ACTION.UPDATE_MULTIPLE_DATA:
            return {...state, ...action.payload}
        default:
            return state;
    }
}

export default inboxReducer;
