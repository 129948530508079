import React from 'react'
import Header from './TypeYourMessagePages/Header';
import Customize from './TypeYourMessagePages/Customize';
import useDelayCallback from '../../../../hooks/useDelayCallback';
import { useIsMounted } from '../../../../hooks/useIsMounted';
import RightSidePreview from './TypeYourMessagePages/RightSidePreview';
import { generateThanksIoPdfFileApi, getThanksIoImagePreviewApi } from '../../api/ApiAction';

let MESSAGE_TYPE_POSTCARD = 21;
let MESSAGE_TYPE_THANKSIO_GREETING_CARD = 22;
let MESSAGE_TYPE_GIFT = 23;
let MESSAGE_TYPE_LETTER = 24;
let MESSAGE_TYPE_THANKSIO_GIFT = 25;
let MESSAGE_TYPE_GREETING_CARD = 22;

const TypeYourMessage = ({
    image_url,
    handleStepChange,
    handleDataChange,
    typeYourMessageSteps,
    selectSizeStep,
    selectedTemplate,
    type,
    generatePdf=false
}) => {
    const isMounted = useIsMounted();

    // useDelayCallback(() => {
    //     if(isMounted){
    //         if(type !== "zendirect_gift"){
    //             handleGeneratePreviewImage()
    //         }
    //         else{
    //             if(typeYourMessageSteps.isProcessingPdf){
    //                 return
    //             }
    //             handleDataChange({pdfUrl: "", isShowingPdf: false})
    //         }
    //     }
    // },[typeYourMessageSteps.message, typeYourMessageSteps.thirdPageText, typeYourMessageSteps.handWritingStyle], 3000)

    const handleGeneratePreviewImage = () => {
        if(typeYourMessageSteps.message.trim() === ""){
            handleDataChange({previewImage: null})
            return;
        }
        if(typeYourMessageSteps.processingPreviewImage){
            return
        }
        handleDataChange({processingPreviewImage: true, pdfUrl: "", isShowingPdf: false})
        let form_data = {
            file_type: (type === 'postcard' ? selectSizeStep.selected_size : null),
            handwriting_style_id: parseInt(typeYourMessageSteps.handWritingStyle),
            message: typeYourMessageSteps.message,
            message_type: (
                type === 'postcard' ? MESSAGE_TYPE_POSTCARD :
                    (type === 'letter' ? MESSAGE_TYPE_LETTER : 
                        (type === 'notecard' ? MESSAGE_TYPE_THANKSIO_GREETING_CARD : MESSAGE_TYPE_GREETING_CARD)
                    )
            )
        }
        if(type === 'letter'){
            form_data['bg_image'] = selectedTemplate.image_path
        }
        getThanksIoImagePreviewApi(form_data)
        .then(res => {
            let response = res.data;
            if(response.status === "success"){
                handleDataChange({previewImage: response.image_preview, processingPreviewImage: false, pdfUrl: "", isShowingPdf: false})
            }
            else{
                window.showNotification('Error', response.message)
                handleDataChange({previewImage: null, processingPreviewImage: false, pdfUrl: "", isShowingPdf: false})
            }
        }).catch(error => {
            console.log("error")
            handleDataChange({processingPreviewImage: false})
        })
    }

    const handleGeneratePdf = () => {
        if(typeYourMessageSteps.message.trim() === ""){
            return;
        }
        if(typeYourMessageSteps.isProcessingPdf){
            return
        }
        handleDataChange({isProcessingPdf: true})
        let form_data = {
            file_type: (type === 'postcard' ? selectSizeStep.selected_size : null),
            handwriting_style_id: typeYourMessageSteps.handWritingStyle.value,
            message: typeYourMessageSteps.message,
            message_type: (
                type === 'postcard' ? MESSAGE_TYPE_POSTCARD :
                    (type === 'letter' ? MESSAGE_TYPE_LETTER : MESSAGE_TYPE_GREETING_CARD)
            ),
            template_id: selectedTemplate.id,
            image_file_preview_url: image_url
        }
        if(type === 'letter'){
            form_data['template_image_url'] = selectedTemplate.image_path
        }
        if(type === 'zendirect_gift'){
            form_data['template_orientation'] = selectedTemplate.orientation;
            form_data['template_image_url'] = selectedTemplate.image_path;
            form_data['second_page_message'] = typeYourMessageSteps.message;
            form_data['third_page_message'] = typeYourMessageSteps.thirdPageText;
            form_data['handwriting_style'] = typeYourMessageSteps.handWritingStyle.value;
        }
        generateThanksIoPdfFileApi(form_data).then(res => {
            let response = res.data;
            if(response.status === "success"){
                handleDataChange({pdfUrl: response.pdf_preview, emptyPdfFile: response.empty_pdf, isProcessingPdf: false})
            }
            else{
                handleDataChange({isProcessingPdf: false})
            }
        }).catch(error => {
            console.log("error")
            handleDataChange({isProcessingPdf: false})
        })
    }

    const handleForward = () => {
        if(!generatePdf){
            if(typeYourMessageSteps.message === "" || typeYourMessageSteps.processingPreviewImage || typeYourMessageSteps.previewImage == null){
                return;
            }
            handleStepChange(true)
            return;
        }
        if(typeYourMessageSteps.pdfUrl === ''){
            handleGeneratePdf()
        }
        else{
            handleStepChange(true)
        }
    }

    const handleGeneratePreviewImageByButton = () => {
        if(type !== "zendirect_gift"){
            handleGeneratePreviewImage()
        }
        else{
            if(typeYourMessageSteps.isProcessingPdf){
                return
            }
            handleDataChange({pdfUrl: "", isShowingPdf: false})
        }
    }

    return(
        <div className='dmpc_3rd_middle'>
            <Header type={type}/>
            
            <div className='dmpc_3rd_templete_view'>
                {/* need to work here later */}
                <Customize 
                    handleDataChange={handleDataChange}
                    typeYourMessageSteps={typeYourMessageSteps}
                    type={type}
                    handleGeneratePreviewImageByButton={handleGeneratePreviewImageByButton}
                    generatePdf={generatePdf}
                />
                <RightSidePreview 
                    typeYourMessageSteps={typeYourMessageSteps} 
                    handleStepChange={handleStepChange} 
                    handleDataChange={handleDataChange} 
                    image_url={typeYourMessageSteps.previewImage}
                    handleForward={handleForward}
                    type={type}
                    selectedTemplate={selectedTemplate}
                    generatePdf={generatePdf}
                />
            </div>
        </div>
    )
}
export default TypeYourMessage;