import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    modalWrapper: {
        display: 'grid',
        placeContent: 'center',
    },
    modalInner: {
        '&:focus-visible': {
            border: 'none !important',
            boxShadow: 'none !important',
            outline: 'none !important',
        },
    },
    headerWrapper: {
        display: 'flex',
        alignItems: 'center',
        padding: 0,
    },
    headerInner: {
        padding: '15px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #cf9494',
    },
    headerTitle: {
        fontSize: '20px',
        margin: 0,
        padding: 0,
        color: '#e51717',
    },
    modalBody: {
        marginTop: '20px',
    },
    bodyContainer: {
        padding: '0 14px 24px',
        overflowY: 'auto',
        width: '700px',
        '&::-webkit-scrollbar': {
            width: '6px',
            height: '6px',
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: '4px',
            background: '#f4f5f7',
            boxShadow: 'inset 1px 0px 0px rgba(148, 157, 178, 0.12)',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
            backgroundColor: '#949db2',
        },
    },
    closeCircleIcon: {
        '& svg': {
            backgroundColor: '#ff264aff',
            width: '25px',
            height: '25px',
            borderRadius: '50px',
            cursor: 'pointer',
        },
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '25px',
    },
    bodyTextContainer: {
        textAlign: 'justify',
        padding: '20px',
        color: '#e51717',
    },
    messageTitle: {
        display: 'block',
        fontSize: '13px',
        textAlign: 'center',
        color: '#475656',
        padding: '0px 30px'
    },
    textStrong: {
        fontWeight: 'bold',
    },
    actionContainer: {
        marginTop: '50px',
        display: 'flex',
        justifyContent: 'center',
    },
    actionButton: {
        backgroundColor: '#547cf8',
        padding: '10px',
        color: '#fff',
        borderRadius: '13px',
        fontSize: '14px',
        fontWeight: 600,
        '&:hover': {
            opacity: 0.9,
        },
        cursor: 'pointer'
    },
    riskActionButton: {
        backgroundColor: '#e11f1f',
        padding: '15px 30px',
        color: '#fff',
        borderRadius: '4px',
        fontSize: '14px',
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.9,
        },
    },
    reasonTitle: {
        padding: '10px',
        textAlign: 'center',
        fontWeight: '600',
        fontSize: '22px',
        color: "#000"
    },
    zoomInZoomOut: {
        animation: `$zoomInZoomOut 2s ease-out infinite`,
    },
    '@keyframes zoomInZoomOut': {
        '0%': {
            transform: 'scale(1, 1)',
        },
        '50%': {
            transform: 'scale(1.1, 1.1)',
        },
        '100%': {
            transform: 'scale(1, 1)',
        },
    },
}));

export default useStyles;