import ActionType from "./ActionType";

export const storeProps = (payload) => ({
    type: ActionType.STORE_PROPS,
    payload: payload
});

export const handleLoading = (payload) => ({
    type: ActionType.HANDLE_LOADING,
    payload: payload
});

export const storePersonalized = (payload) => ({
    type: ActionType.STORE_PERSONALIZED,
    payload: payload
});

export const storeContactLastVn = (payload) => ({
    type: ActionType.UPDATE_LAST_CONNECTED_VN,
    payload: payload
});

export const storeVnList = (payload) => ({
    type: ActionType.UPDATE_VN_LIST,
    payload: payload
});

export const updateSelectedVn = (payload) => ({
    type: ActionType.UPDATE_SELECTED_VN,
    payload: payload
});

export const updateMessage = (payload) => ({
    type: ActionType.UPDATE_MESSAGE,
    payload: payload
});

export const updateSaveAsTemplateFlag = (payload) => ({
    type: ActionType.UPDATE_SAVE_AS_TEMPLATE_FLAG,
    payload: payload
});

export const updateShowQuickReply = (payload) => ({
    type: ActionType.UPDATE_IS_SHOW_TEMPLATE,
    payload: payload
});

export const updateQuickReplyTitle = (payload) => ({
    type: ActionType.UPDATE_TITLE,
    payload: payload
});

export const updateMmsFile = (payload) => ({
    type: ActionType.UPDATE_MMS_FILE,
    payload: payload
});

export const updateSendingFlag = (payload) => ({
    type: ActionType.HANDLE_SENDING,
    payload: payload
});
export const updateUnsubTextFlag = (payload) => ({
    type: ActionType.UPDATE_UNSUB_TEXT,
    payload: payload
});