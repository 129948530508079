export const setCookie = (cname, cvalue, exTime) => {
    var d = new Date();
    d.setTime(d.getTime() + timeToMiliSecond(exTime));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

const timeToMiliSecond = time => {
    const number = time.substring(0, time.indexOf(' '));
    switch (time.substr(time.indexOf(' ')+1)) {
        case 'day':
            return number * 24 * 60 * 60 * 1000;
        case 'hour':
            // console.log('here');
            return number * 60 * 60 * 1000;
        case 'minute':
            return number * 60 * 1000;
        case 'second':
            return number * 1000;
        default:
            return number * 60 * 1000;
    }
}

export const getCookie = cname => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}


export const checkCookie = (cname) => {
    const cookieInfo = getCookie(cname);
    return !!cookieInfo;
}

export const getCookieLogin = cname => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            let str = c.substring(name.length, c.length).replace(/\s/g, ''); 
            return decodeURIComponent(window.atob(str))
        }
    }
    return "";
}