import React, { useState , useEffect } from "react";
import {connect} from 'react-redux'
import CustomAccordion, {
  CustomAccordionBody,
  CustomAccordionHeader,
} from "../../components/Accordion/CustomAccordion";
import {
  Box,
  Button,
  Popover,
  makeStyles,
} from "@material-ui/core";
import { changeContactSource } from "../../../../api/contactApi";
import {DropdownHelper} from "./DropdownHelper";
import {contactDetails} from "../../../addContact/CoreConstants";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const LeadSource = (props) => {
  const [expand, setExpand] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedLeadSource, setSelectedLeadSource] = useState(null);
  const [sourceList, setSourceList]= useState([])
  const [sourceName, setSourceName] = useState("")

  const [sourceId, setSourceId]= useState('')

  useEffect(()=>{
    setSourceList(props.sourceList);
  },[props?.sourceList])

  useEffect(()=>{
    setSourceId(props.contactSource)
  },[props?.contactSource])

  const updateSourceOfContact=(id)=>{
    setSourceId(id);
    changeContactSource({
      contact_id : props.contactDetails.id,
      source_id : id
    })
        .then(res => {
          if(res.status === 200){
            window.showNotification("SUCCESS","Source Updated");
          }else{
            window.showNotification("ERROR",'Something was wrong, please try to reload the page.');
          }

        })
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLeadSource = (data) => {
    setSelectedLeadSource(data);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const classes = useStyles();
  return (
    <div className="" style={{marginTop:'10px'}}>
      <CustomAccordion
        expand={expand}
        className="g-contact-details-collapsible"
      >
        <CustomAccordionHeader
          onClick={() => setExpand(!expand)}
          className={`g-collapsible-header ${expand ? "expand" : ""}`}
        >
          <span className="g-header-title">
            <span className="material-icons" style={{ marginTop: "-2px" }}>
              <svg
                width="25"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.4529 10.5947L15.1719 16.8042C15.0114 17.113 14.5564 17.4395 14.205 17.4395L1.04762 17.4419C0.769658 17.4419 0.503156 17.3318 0.306748 17.1352C0.11034 16.9386 0 16.6724 0 16.3943L0.00212976 6.17746C0.00212976 5.59967 0.47052 5.13084 1.04837 5.13028L2.01037 5.12937V6.02186H1.36677C1.2408 6.02186 1.11982 6.07185 1.03068 6.16099C0.941636 6.25012 0.891555 6.37102 0.891555 6.49708L0.892495 16.0746C0.892495 16.3373 1.10538 16.5498 1.36781 16.5498H2.02694L5.13638 10.2596C5.31206 9.90827 5.64402 9.62429 5.9949 9.62429H13.61L13.6126 7.09291C14.1099 7.13902 14.5023 7.55147 14.5023 8.06048V9.62432H17.9949C18.4074 9.62855 18.7547 10.0364 18.4529 10.5947ZM2.61704 13.3834C2.58863 10.2524 2.61704 0.859076 2.61704 0.859076C2.61704 0.385142 3.00246 0 3.47592 0H10.3542C10.4644 0 10.5695 0.0461031 10.644 0.127191L12.8677 2.54325C12.9343 2.616 12.9717 2.71125 12.9717 2.80994V9.06889H12.2503V3.35039C12.2503 3.25145 12.1699 3.17109 12.0709 3.17109H10.3689C10.1697 3.17109 10.0077 3.00938 10.0077 2.81056V0.900826C10.0077 0.801855 9.92734 0.721519 9.82828 0.721519H3.47636C3.40047 0.721519 3.33871 0.78325 3.33871 0.859076V12.5959L2.78667 13.7128C2.7867 13.7126 2.61989 13.7004 2.61704 13.3834ZM10.7299 2.44994H11.8014L10.7299 1.28522V2.44994ZM11.1206 3.90585H4.4729C4.22798 3.90585 4.02916 4.10486 4.02916 4.34978C4.02916 4.59452 4.22817 4.79362 4.4729 4.79362H11.1208C11.3657 4.79362 11.5652 4.59436 11.5652 4.34978C11.5648 4.10505 11.3654 3.90585 11.1206 3.90585ZM11.5648 6.93478C11.5648 6.69004 11.3656 6.49123 11.1206 6.49123H4.4729C4.22798 6.49123 4.02916 6.69042 4.02916 6.93478C4.02916 7.17951 4.22817 7.37833 4.4729 7.37833H11.1208C11.3654 7.37836 11.5648 7.17951 11.5648 6.93478ZM4.02888 9.53728C4.02888 9.78199 4.22798 9.98084 4.47271 9.98084H4.6321C4.91267 9.19887 5.60816 9.09373 5.60816 9.09373H4.47271C4.22836 9.09373 4.02888 9.29255 4.02888 9.53728Z"
                  fill="#181f48"
                ></path>
              </svg>
            </span>{" "}
            Lead Source{" "}
          </span>

          <span className="g-custom-caret">
          {sourceList != null &&
            <DropdownHelper
                datalist={sourceList}
                noneFieldRequired={true}
                noneFieldTitle="Update Source"
                className=""
                mapping={{ title: "source_title" }}
                menuItemClassName="new__dropdown_effect"
                updateSelectedData={updateSourceOfContact}
                selectedValue={sourceId}
                updateDatatable={()=>console.log('update')}
                dropdownHeader={'ownerChange'}
                parentDivClassName="lead-source-rightside"
            />
            }
          </span>
        </CustomAccordionHeader>
        <CustomAccordionBody className="g-collapsible-body" >
          {/* <Box sx={{ width: "300px", padding: "16px" }}>
            {sourceList != null &&
            <DropdownHelper
                datalist={sourceList}
                noneFieldRequired={true}
                noneFieldTitle="Update Source"
                className=""
                mapping={{ title: "source_title" }}
                menuItemClassName="new__dropdown_effect"
                updateSelectedData={updateSourceOfContact}
                selectedValue={sourceId}
                updateDatatable={()=>console.log('update')}
                dropdownHeader={'ownerChange'}
                parentDivClassName="lead-source-rightside"
            />
            }
          </Box> */}
        </CustomAccordionBody>
      </CustomAccordion>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    contact: state.rightsideReducer.contact
  };
};

const mapDispatchToProps = dispatch => {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadSource);
