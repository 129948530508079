import {
    AppointmentsIcon,
    CashAmountIcon,
    DashboardIcon, DataInsidesIcon, EnhanceData, FindLeadsIcon, FunnelAndForms, GetStarted, InsuranceIcon,
    MessageIcon, PowerDialer, PromotionsIcon, SettingsIcon, TeamMemberIcon
} from "../../GlobalAddDealModalV3/components/Icons";
import Utils from "../../../helpers/Utils";
import { AGENCY_BUSINESS_TYPE } from "../../../helpers/CoreConstant";
import { FREE_MAP_ACCESS_AGENCIES } from "../../../constants/CoreConstants";

export const INSURANCE_SIDEBAR = () => {
    const isTemplateUser = Utils.getAccountData("isTemplateUser");
    const userId = Utils.getAccountData("userId");
    const agencyId = Utils.getAccountData("agencyId");
    const agencyBusinessType = Utils.getAccountData("agencyBusinessType");
    const dnc = Utils.getAccountData("dnc");
    const carrierLookup = Utils.getAccountData("carrierLookup");
    const skiptrace = Utils.getAccountData("skiptrace");
    const userOnboard = agencyBusinessType === AGENCY_BUSINESS_TYPE.INSURANCE ? Utils.getAccountData("userOnboard") : false;

    return [
        { module: "dashboard", icon: DashboardIcon, label: "Dashboard", url: "/", visible: !userOnboard },
        { module: "inbox", icon: MessageIcon, label: "Inbox", url: "/inbox", visible: !isTemplateUser && !userOnboard, showUnreadCount: true },
        { module: "deal", icon: CashAmountIcon, label: "Deals", url: "/deal-stages", visible: !userOnboard },
        {
            module: "calendar", icon: AppointmentsIcon, label: "Calendar", visible: !userOnboard, sub_menus:
                [
                    { module: "calendar", label: "Calendar", description: "View and manage your calendar", url: "/activity-calendar", visible: !isTemplateUser },
                    { module: "appointment-scheduler", label: "Appointment Scheduler", description: "Share your availability online so clients can self-book", url: "/calendar-widgets", visible: true }
                ]
        },
        { module: "contact", icon: TeamMemberIcon, label: "Contacts", url: "/contacts", visible: !isTemplateUser && !userOnboard },
        {
            module: "marketing", icon: PromotionsIcon, label: "Marketing", visible: !userOnboard, sub_menus:
                [
                    { module: "campaigns", label: "Campaigns", description: "Create automated drip marketing campaigns", url: "/campaign", visible: true },
                    { module: "campaigns-templates", label: "Campaign Templates", description: "Marketing campaign templates", url: "/campaign/template", visible: true },
                    { module: "quick-replies", label: "Quick Replies", description: "Create templates for commonly sent messages", url: "/quick-replies", visible: true },
                    { module: "bulk-emails", label: "Bulk Email", description: "Reach a large audience with bulk email", url: "/bulk-email", visible: true, target: "_blank" },
                    { module: "trigger", label: "Triggers", description: "Create if/then rules to trigger automation", url: "/triggers", visible: true },
                ]
        },
        {
            module: "phone", icon: PowerDialer, label: "Phone", visible: !userOnboard, sub_menus:
                [
                    { module: "new-call", label: "New Call", description: "Make a new phone call", visible: true, onclick: () => { window.initiateGlobalDialer(); } },
                    { module: "power-dialer", label: "Power Dialer", description: "Increase your call connections using the power dialer", visible: true, url: "/power-dialer", target: "_blank" },
                    { module: "power-dialer-call-logs", label: "Power Dialer Call Logs", description: "Review your power dialer session call logs", visible: true, url: "/power-dialer/call-logs" },
                    { module: "call-logs", label: "Call Logs", description: "Review your phone call logs", visible: true, url: "/user/telephony-service/call-logs" },
                    { module: "phone-settings", label: "Phone Settings", description: "View/Edit your phone settings", visible: true, url: "/user/telephony-service/phone-settings" },
                ]
        },
        {
            module: "funnel-forms", icon: FunnelAndForms, label: "Funnels & Forms", visible: !userOnboard, sub_menus:
                [
                    { module: "websites", label: "Websites & Funnels", description: "Create and customize awesome websites and grow your business", url: "/wbuilder/sites", visible: agencyId !== 62 },
                    { module: "form-builder", label: "Form Builder", description: "Create and customize custom form", url: "/user/form-builder", visible: userId < 8790 },
                    { module: "multi-page-form-builder", label: "Multi-page Form Builder", description: "Create and customize multi-page form", url: "/user/new/form-builder", visible: userId < 8790 },
                    { module: "smart-form", label: "Smart Forms", description: "Create and customize multi-page form", url: "/fbuilder/forms", visible: true },
                ]
        },
        {
            module: "map_finder", icon: FindLeadsIcon, label: "Find Leads", visible: (FREE_MAP_ACCESS_AGENCIES.includes(agencyId) && !userOnboard ) ? true : false, sub_menus:
                [
                    { module: "find-new-leads", label: "Find New leads", description: "This feature makes it easy to pinpoint new leads in your local market", url: "/map-finder", visible: true },
                    { module: "my-lists", label: "My Lists", description: "Your saved lists are stored here", url: "/user/map-orders", visible: true },
                    { module: "gro-farming-settings", label: "Geo-Farming Settings", description: "You can update GeoFarming settings here", url: "/map/integration", visible: (!FREE_MAP_ACCESS_AGENCIES.includes(agencyId)) }
                ]
        },
        { module: "clean-data", icon: EnhanceData, label: "Clean Data", visible: (carrierLookup || dnc || skiptrace) && !userOnboard, url: "/contacts/clean-list" },
        { module: "invoices", icon: InsuranceIcon, label: "Invoices", visible: Utils.getAccountData("agencyBusinessType") !== AGENCY_BUSINESS_TYPE.INSURANCE && !userOnboard, url: "/ibuilder/invoices" },
        {
            module: "report", icon: DataInsidesIcon, label: "Reports", visible: !isTemplateUser && !userOnboard, sub_menus:
                [
                    { module: "sales-reports", label: "Sales Reports", description: "Run detailed sales reports to track your business performance", visible: true, url: agencyBusinessType === AGENCY_BUSINESS_TYPE.INSURANCE ? "/user/sales-reports" : "/user/products", state: { from: "settingPage", tab: 7 } },
                    { module: "business-report", label: "Business Reports", description: "Your business analytics dashboard", url: "/business-report", visible: true },
                    { module: "goal-report", label: "Goal Report", description: "Create company and individual goals", url: "/goal/progress", visible: agencyBusinessType !== AGENCY_BUSINESS_TYPE.INSURANCE },
                    { module: "direct-mail-orders", label: "Direct Mail Orders", description: "Your direct mail history", url: "/user-order/report", visible: true },
                    { module: "drip-batch-report", label: "Drip Batch Report", description: "Assign contacts to campaign in drip mode", url: "/batches", visible: true },
                    { module: "smart-webhook-report", label: "Smart Webhook Report", description: "Your smart webhook report", url: "/user/smart-webhook-report", visible: true },
                    { module: "bill-report", label: "Bill Report", description: "Carrier bill list", url: "/bills", visible: false },
                    { module: "dnc", label: "Do-Not-Call (DNC)", description: "Review your internal DNC list", url: "/user/dnc-numbers", visible: true },
                ]
        },
        { module: "setting", icon: SettingsIcon, label: "Settings", url: "/user/settings", visible: !userOnboard },
        { module: "get-started", icon: GetStarted, label: "Getting Started", url: "/onboarding", visible: userOnboard },
    ]
};

//INSURANCE DASHBOARD TOP-BAR COLOR
export const HEADER_COLORS = {
    ADD_ICON: "#3e50f7",
    NOTIFICATION_ICON: "#ffc905",
    CALL_ICON: "#3e50f7",
    DOLLER_ICON: "#58cc8a"
};