import React from "react";
import {styled} from "@material-ui/core/styles";

const PublicLayoutWrapper = styled("div")({
    height: "100vh",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
        width: "10px",
    },
    "&::-webkit-scrollbar-track": {
        borderRadius: "10px",
        backgroundColor: "#2c3e50",
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "var(--purple)",
        borderRadius: "10px",
    },
});

export const PublicLayout = ({ children }) => {
  return (
      <PublicLayoutWrapper className="public_page_container">
        {children}
      </PublicLayoutWrapper>
  );
}