import React, { useEffect, useState } from "react";
import Utils from "../addContact/Utils";
import TrophyALert from "./NewAlert/TrophyAlert";
import ConfirmDealModal from "./ConfirmDealModal";
import { changeDealStatus } from "../addContact/contactApi";

const DEAL_STATUS = {
  WON: 2,
  LOST: 3,
};
const GlobalDealStatus = ({ open, onClose, data = null }) => {
  const [confirmDealModal, setConfirmDealModal] = useState(false);

  useEffect(() => {
    if (data?.contact_stages_id) {
      if(data?.status === DEAL_STATUS.WON){
        TrophyALert({
          title: "Well Done " + Utils.getAccountData("fullName") + "!",
          setConfirmDealModal: setConfirmDealModal,
          closeOnClickOutside: false,
        });
        data?.callback({ stageId: data?.contact_stages_id });
      }else if(data?.status === DEAL_STATUS.LOST){
        handleUpdateDealStatus().then();
      }
    }
  }, [data]);

  const handleUpdateDealStatus = async () => {
    try{
      changeDealStatus({
        stage_id: data?.contact_stages_id,
        status: data?.status,
      }).then((response) => {
        if (response.data.status === "success") {
          if(data?.status === DEAL_STATUS.WON){

          }else if(data?.status === DEAL_STATUS.LOST){
            data?.callback({ stageId: data?.contact_stages_id });
            window.showNotification("SUCCESS", "Deal mark as lost successfully !");
          }
        } else {
          window.showNotification("ERROR", "Deal status not changed");
        }
      });
    }catch (err){

    }
  };

  return (
    <>
      {confirmDealModal && (
        <ConfirmDealModal
          data={data}
          contact_stages_id={data?.contact_stages_id}
          open={confirmDealModal}
          handleUpdateDealStatus={handleUpdateDealStatus}
          onClose={() => {
            setConfirmDealModal(false);
            onClose();
          }}
        />
      )}
    </>
  );
};

export default GlobalDealStatus;
