import { styled } from "@material-ui/core";
import React from "react";

const TitleWrapper = styled("h6")(({ theme }) => ({
  // fontFamily: "'Inter', sans-serif",
  fontSize: 24,
  fontWeight: 600,
  lineHeight: "32px",
  color: "#14151A",
  margin: 0,
}));

const Title = ({ text, className = "", ...props }) => {
  return <TitleWrapper className={className} {...props}>{text}</TitleWrapper>;
};

export default Title;
