import { Button } from "@material-ui/core";
import { Fragment, useEffect, useRef, useState } from "react";
import SimpleReactValidator from 'simple-react-validator';
import { getCanSpamAddress, saveCanSpamAddress } from "../../../api/onboardApi";
import Utils from "../../../helpers/Utils";
import Loading from "../../common/Loading";

const CanSpamAddress = (props) => {
    const [componentLoading, setComponentLoading] = useState(true);
    const [loading, setLoading] = useState(false);

    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [userId, setUserId] = useState(null);

    //validator
    const [, forceUpdate] = useState() //this is a dummy state, when form submitted, change the state so that message is rendered
    const simpleValidator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate: forceUpdate },
        className: 'small text-danger mdi mdi-alert pt-1 pl-1'
    }));

    useEffect(() => {
        loadData();
        setUserId(Utils.getAccountData('userId'));
    }, [])

    const loadData = () => {
        getCanSpamAddress({})
            .then(response => {
                if (response.data.data.canSpam) {
                    setName(response.data.data.canSpam.name);
                    setAddress(response.data.data.canSpam.address);
                    setCity(response.data.data.canSpam.city);
                    setState(response.data.data.canSpam.state);
                    setZip(response.data.data.canSpam.zip);
                }
            }).finally(() => {
                setComponentLoading(false);
            });
    }

    const onSubmitHandle = (e) => {
        e.preventDefault();

        if (simpleValidator.current.allValid()) {
            setLoading(true);

            saveCanSpamAddress({
                full_name: name,
                address: address,
                city: city,
                state: state,
                zip: zip,
                user_id: userId
            }).then((response) => {
                if (response.data.status === 'success') {
                    props.callback();
                } else if (response.data.status === 'validation-error') {
                    for (var key of Object.keys(response.data.message)) {
                        let errors = response.data.message[key];
                        errors.forEach(error => {
                            Utils.showNotification(error, 'error');
                        });
                    }
                } else {
                    Utils.showNotification(response.data.message, 'error');
                }
            }).finally(() => {
                setLoading(false);
            })
        } else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
        }
    }

    return (
        <Fragment>
            {
                (componentLoading || !userId) ? (
                    <div style={{ paddingTop: 50 }}>
                        <Loading />
                    </div>
                ) : (
                    <Fragment>
                        <div id="cut_time" class="col s12">
                            <p className="onboard__step__canspam__form__sub">This address will be appended to the end of your emails in compliance with the CAN-SPAM Act.</p>
                            <p>The CAN-SPAM Act is a U.S. law that regulates commercial email. This law requires that email messages contain a valid physical postal address or P.O. box address. You can modify the physical postal address later, but it must be a valid one where you can receive postal mail.</p>
                            <p class="msg onboard__step__canspam__form__msg">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 0C4.486 0 0 4.48606 0 10.0001C0 15.5141 4.486 20 10 20C15.514 20 20 15.5141 20 10.0001C20 4.48606 15.514 0 10 0ZM10 18.1818C5.48848 18.1818 1.81818 14.5115 1.81818 10.0001C1.81818 5.48861 5.48848 1.81818 10 1.81818C14.5115 1.81818 18.1818 5.48861 18.1818 10.0001C18.1818 14.5115 14.5115 18.1818 10 18.1818Z" fill="#133159" fillOpacity="0.65" />
                                    <path d="M10.0009 4.24219C9.3327 4.24219 8.78906 4.78619 8.78906 5.45485C8.78906 6.12292 9.3327 6.66643 10.0009 6.66643C10.6692 6.66643 11.2128 6.12292 11.2128 5.45485C11.2128 4.78619 10.6692 4.24219 10.0009 4.24219Z" fill="#133159" fillOpacity="0.65" />
                                    <path d="M9.99893 8.48438C9.49687 8.48438 9.08984 8.89141 9.08984 9.39347V14.848C9.08984 15.3501 9.49687 15.7571 9.99893 15.7571C10.501 15.7571 10.908 15.3501 10.908 14.848V9.39347C10.908 8.89141 10.501 8.48438 9.99893 8.48438Z" fill="#133159" fillOpacity="0.65" />
                                </svg>
                                <a href="https://www.ftc.gov/tips-advice/business-center/guidance/can-spam-act-compliance-guide-business" target="_blank" rel="noreferrer">Read more about the CAN-SPAM Act on the FTC website.</a>
                            </p>
                            <form action="#" method="POST" onSubmit={onSubmitHandle} className="onboarding__number__section__input on_ns_input_wr">
                                <div class="row mt-4 margin_top_zero_alt">
                                    <div class="col xl3 l4 m4 spamp_form_top_pl_padding">
                                        <p class="pl-4 spamp_form_pl_padding">Name <span class="required__field onboard__step__required__field">*</span></p>
                                    </div>
                                    <div class="col xl8 l8 m8 pl-0">
                                        <input class="personal_info_text_field " type="text" value={name} onChange={e => setName(e.target.value)} />
                                        {simpleValidator.current.message('name', name, 'required')}
                                    </div>
                                </div>
                                <div class="row mt-4 margin_top_zero_alt">
                                    <div class="col xl3 l4 m4 spamp_form_top_pl_padding">
                                        <p class="pl-4 spamp_form_pl_padding">Address <span class="required__field onboard__step__required__field">*</span></p>
                                    </div>
                                    <div class="col xl8 l8 m8 pl-0">
                                        <textarea rows="5" className="personal_info_text_field" value={address} onChange={e => setAddress(e.target.value)} style={{ height: '100px' }}></textarea>
                                        {simpleValidator.current.message('address', address, 'required')}
                                    </div>
                                </div>
                                <div class="row mt-4 margin_top_zero_alt">
                                    <div class="col xl3 l4 m4 spamp_form_top_pl_padding">
                                        <p class="pl-4 spamp_form_pl_padding">City <span class="required__field onboard__step__required__field" >*</span></p>
                                    </div>
                                    <div class="col xl8 l8 m8 pl-0">
                                        <input class="personal_info_text_field" type="text" value={city} onChange={e => setCity(e.target.value)} />
                                        {simpleValidator.current.message('city', city, 'required')}
                                    </div>
                                </div>
                                <div class="row mt-4 margin_top_zero_alt">
                                    <div class="col xl3 l4 m4 spamp_form_top_pl_padding">
                                        <p class="pl-4 spamp_form_pl_padding">State <span class="required__field onboard__step__required__field" >*</span></p>
                                    </div>
                                    <div class="col xl8 l8 m8 pl-0">
                                        <input class="personal_info_text_field" type="text" value={state} onChange={e => setState(e.target.value)} />
                                        {simpleValidator.current.message('state', state, 'required')}
                                    </div>
                                </div>
                                <div class="row mt-4 margin_top_zero_alt">
                                    <div class="col xl3 l4 m4 spamp_form_top_pl_padding">
                                        <p class="pl-4 spamp_form_pl_padding">Zip code <span class="required__field onboard__step__required__field">*</span></p>
                                    </div>
                                    <div class="col xl8 l8 m8 pl-0">
                                        <input class="personal_info_text_field" type="text" value={zip} onChange={e => setZip(e.target.value)} />
                                        {simpleValidator.current.message('zip', zip, 'required')}
                                    </div>
                                </div>
                                <hr />
                                <Button className="can_spam_form_send_btn_wr" variant="contained" color="primary" disabled={loading} type="submit">
                                    {!loading ? 'Save Can Spam Address' : 'Saving...'}
                                </Button>
                            </form>
                        </div>
                    </Fragment>
                )
            }
        </Fragment>
    )
}

export default CanSpamAddress;