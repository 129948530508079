import { sendRequest } from './rootApi';
import Api from "../constants/Api";
import HttpRequest from "./HttpRequest";

export const fetchOnboardData = async (params) => {
    return sendRequest("post", Api.fetchOnboardData, params);
}

export const onboardGoToStep = async (params) => {
    return sendRequest("post", Api.onboardGoToStep, params);
}

export const getVirtualSearchNumberList = async (payload) => {
    return sendRequest("post", Api.virtualSearchNumberList, payload);
}

export const buyVirtualNumber = async (payload) => {
    return sendRequest("post", Api.buyVirtualNumber, payload);
}

export const getCanSpamAddress = async (payload) => {
    return sendRequest("post", Api.getCanSpamAddress, payload);
}

export const saveCanSpamAddress = async (payload) => {
    return sendRequest("post", Api.saveCanSpamAddress, payload);
}

export const updateTimezone = async (payload) => {
    return sendRequest("post", Api.updateTimezone, payload);
}

export const saveEmailProvider = async (payload) => {
    return sendRequest("post", Api.saveEmailProvider, payload);
}

export const onboardGuideCompleted = async (payload) => {
    return sendRequest("post", Api.onboardGuideCompleted, payload);
}

export const connectNylas = async (payload) => {
    return HttpRequest.post(Api.connectNylas, payload);
};

export const markStepComplete = async (body) => {
    return HttpRequest.put(Api.markStepComplete, body);
}