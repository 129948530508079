import React, { useState, useEffect, useContext} from 'react'
import ApiList from './ApiList'
import BasicInfo from './BasicInfo'
import LinkFile from './LinkFile'
import MoreInfo from './MoreInfo'
import Notes from './notes/Notes'
import { handleFrom, handleLoading, handleTab, storeContactId, storeContactInfo, storeDealFunction, storeTotalCampaign } from './reducer/Action'
import { ContactDetailContext } from './reducer/GlobalReduers'
import TopBar from './TopBar'
import DetailsSkeleton from './DetailsSkeleton'

const Details = (props) => {
    const {detailStates, detailsDispatch} = useContext(ContactDetailContext)
    const [title, setTitle] = useState('Contact Details')
    const [error, setError] = useState('')

    useEffect(() => {
        if(props.from !== undefined){
            detailsDispatch(handleFrom(props.from))
            detailsDispatch(storeContactId(props.id))
            if(props.from === 'deal-details' && props.dealCallback !== undefined){
                detailsDispatch(storeDealFunction(props.dealCallback))
            }
        }
        ApiList.getContactDetailsById({id : props.id}).then(res => {
            if(res.data.status === 'error'){
                setError(res.data.message)
                detailsDispatch(handleLoading(false))
            }
            else{
                if(res.data.data.people === null){
                    setError("Something went wrong!!!")
                }
                else{
                    setError("")
                    detailsDispatch(storeContactInfo(res.data.data.people))
                    detailsDispatch(storeTotalCampaign(res.data.data.totalCampaign))
                }
                detailsDispatch(handleLoading(false))
            }
        }).catch(e => {
            setError("Something went wrong!!!")
            detailsDispatch(handleLoading(false))
        })

        return () => {
            detailsDispatch(storeContactId(null))
            detailsDispatch(handleLoading(true))
            detailsDispatch(storeContactInfo(null))
            detailsDispatch(storeTotalCampaign(0))
            detailsDispatch(handleFrom(''))
            detailsDispatch(storeDealFunction(null))
            detailsDispatch(handleTab('info'))
        }
    }, [props.from])

    const renderTitle = () => {
        if(props.title !== undefined){
            return props.title();
        }
        return title;
    }

    const refresh = () => {
        ApiList.getContactDetailsById({id : props.id}).then(res => {
            detailsDispatch(storeContactInfo(res.data.data.people))
            detailsDispatch(storeTotalCampaign(res.data.data.totalCampaign))
        })
    }

    /* will remove if not used */
    const handleTitle = (contactData) => {
        let title = 'Contact Details'
        if(contactData !== undefined && contactData !== null){
            if((contactData.first_name !== null && contactData.first_name !== "") && (contactData.last_name !== null && contactData.last_name !== "")){
                title = contactData.first_name + ' ' + contactData.last_name
            }
            else if(contactData.first_name !== null && contactData.first_name !== "") {
                title = contactData.first_name
            }
            else if(contactData.last_name !== null && contactData.last_name !== ""){
                title = contactData.last_name
            }
            else if(contactData.email !== null && contactData.email !== ""){
                title = contactData.email
            }
            else if(contactData.number !== null && contactData.number !== ""){
                title = contactData.number
            }
        }
        setTitle(title)
    }

    return(
        <LinkFile.modal
            open={props.open}
            title={renderTitle()}
            onClose={props.onClose}
            hideFooter={true}
            className="global-750-modal"
            extra_props_active={true}
        >
            <div className="gloabl-contact-details">

                {detailStates.isLoading &&
                <DetailsSkeleton/>
                }

                {(!detailStates.isLoading && error !== '') &&
                    <div className="empty-content">{error}</div>
                }

                {(!detailStates.isLoading && error === '') &&
                <>
                    {/* top bar with tab and extra options */}
                    <TopBar
                        deal={props.dealInfo !== undefined ? props.dealInfo : null}
                        onClose={props.onClose}
                        loggedOnUser={props.loggedOnUser}
                    />

                    {/* basic info */}
                    {detailStates.tab === 'info' &&
                        <div className="g-contact-info-tab">
                            <BasicInfo refresh={refresh} />
                            <MoreInfo onClose={props.onClose}/>
                        </div>
                    } 
                    
                    {/* notes info */}
                    {detailStates.tab === 'note' &&
                        <Notes />
                    }       
                </>
                }
            </div>
        </LinkFile.modal>
    )
}
export default Details;
/* 
    first check LinkFile 
    props:
        open={props.open}
        onClose={props.onClose}
        title={props.title}
        dealInfo={props.dealInfo || null}
        id={props.id}
        callbackOnOpen={props.callbackOnOpen}
        from={props.from}
        dealCallback={props.dealCallback}
*/