import React, { useEffect, useRef, useState } from "react";
import { CallIcon } from "../../GlobalAddDealModalV3/components/Icons";
import { IconButton, styled } from "@material-ui/core";
import Utils from "../../addContact/Utils";
import GlobalDialer from "../../user/GlobalDialer/GlobalDialer";
import GlobalModal from "../../GlobalModal/Modal/GlobalModal";
import ContactDetailCallOutcome from "../../contactDetails";
import { storeMultipleReducers } from "../../../actions/globalDialerAction";
import { connect } from "react-redux";
import { HEADER_COLORS } from "./constants";

const HeaderGlobalDialerInsuranceWrapper = styled("div")(({ theme }) => ({
    backgroundColor: "var(--white)",
    borderRadius: "10px",
    boxShadow: "0 0 17px 1px #bbb",
    right: "-135px",
    overflow: "visible",
    position: "absolute",
    top: "60px",
    width: "320px",
    zIndex: "999",

    "&:before": {
        background: "url(/static/media/arrow.20587642.svg) no-repeat 50% / 34px 24px",
        backgroundSize: "cover",
        content: "''",
        display: "block",
        height: "23px",
        position: "absolute",
        right: "44%",
        top: "-12px",
        width: "34px",
        zIndex: "-1"
    },

    "& .dial-input-wrapper svg": {
        background: "#d3d3d3",
        borderRadius: "50%",
        height: "18px",
        width: "18px",
        position: "absolute",
        top: "25px",
        right: "16px"
    },

    "& .dial-search-wrapper svg": {
        position: "absolute",
        left: "20px",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: "3"
    },

    "& .list-all-contact h5": {
        fontSize: "14px",
        lineHeight: "24px",
        letterSpacing: ".01em",
        columns: "var(--dark-blue)"
    },

    "& .contact-info ul": {
        margin: "0"
    }
}));

const HeaderGlobalDialerInsurance = (props) => {
    const [updateNumber, setUpdateNumber] = useState({ update: false, dialerContactNumber: null });
    const [openSearchMenu, setOpenSearchMenu] = useState(false);
    const [dialOccurrence, setDialOccurrence] = useState(0);
    const [openContactDetailModal, setOpenContactDetailModal] = useState(false);
    const [connectedCallerInfo, setConnectedCallerInfo] = useState(null);
    const [callTime, setCallTime] = useState(0);
    const buttonRef = useRef(null);
    const popoverRef = useRef(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        let interval = null;

        if (props.isCallInProgress === true &&
            (props.incomingCallConnection === null || (props.incomingCallConnection !== null && props.callStatus === 'Received'))
        ) {
            // Start the interval when the call is in progress
            interval = setInterval(() => {
                setCallTime((prevTime) => prevTime + 1);
            }, 1000);
        } else {
            // Clear the interval when the call is not in progress
            clearInterval(interval);
            setCallTime(0); // Reset call time if needed
        }

        // Cleanup interval on component unmount or dependency change
        return () => clearInterval(interval);
    }, [props.isCallInProgress, props.callStatus]);

    useEffect(() => {
        if (!openSearchMenu) {
            setUpdateNumber((prev) => { return { update: !(prev.update), dialerContactNumber: null } });
        }
    }, [openSearchMenu])

    const handleClickOutside = (event) => {
        if (buttonRef.current && !buttonRef.current.contains(event.target)
            && popoverRef.current && !popoverRef.current.contains(event.target)) {
            setOpenSearchMenu(false);
        }
    };



    window.initiateGlobalDialer = (contact = null, contactListInfo = null) => {
        if (contact != null) {
            if (contact.dialToNumber) {
                props.storeMultipleReducers({ stage: "dial", dialNumber: contact.number })
                setDialOccurrence(occur => occur + 1);
                localStorage.setItem("isDialFromMap", true);
            }
            else {
                if (contactListInfo) {
                    props.storeMultipleReducers({
                        listCurrentIndex: contactListInfo.currentIndex ?? 0, stage: "contact",
                        recentContacts: (contactListInfo.currentIndex) ? contactListInfo.contactList ?? [contact] : [contact]
                    })
                }
                setUpdateNumber({ stage: "contact", update: !updateNumber.update, dialerContactNumber: contact.number, contactInfo: contact, contactListInfo: contactListInfo });
            }
        } else {
            setUpdateNumber({ update: false, dialerContactNumber: null, contactInfo: null, contactListInfo: contactListInfo })
        }
        setTimeout(() => {
            setOpenSearchMenu(true);
        }, 1000);
    }

    const closeDialog = () => {
        localStorage.setItem("isDialFromMap", "false");
        setOpenSearchMenu(false);
        setDialOccurrence(0);
    };

    const handleOpenContactDetailModal = () => {
        setOpenSearchMenu(false);
        setTimeout(() => {
            setOpenContactDetailModal(true);
        }, 500);
    };

    const onCloseCallOutcomeModal = () => {
        setOpenContactDetailModal(false);
        if (props.goToNextInList) {
            let currentIndex = props.listCurrentIndex + 1;
            if (props.recentContacts[currentIndex]) {
                do {
                    if (props.recentContacts[currentIndex].number && props.recentContacts[currentIndex].number !== "") {
                        let contact = props.recentContacts[currentIndex];
                        props.storeMultipleReducers({ goToNextInList: false, listCurrentIndex: currentIndex });
                        setUpdateNumber({
                            update: !updateNumber.update, dialerContactNumber: contact.number, contactInfo: contact,
                            contactListInfo: {
                                from: "contactListPage", contactList: props.recentContacts,
                                totalContacts: props.recentContacts.length, currentIndex: currentIndex
                            }
                        });

                        currentIndex = -1
                    } else {
                        currentIndex++;
                    }
                } while (props.recentContacts[currentIndex] && currentIndex !== -1)
            }
        }
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return [
            minutes.toString().padStart(2, '0'),
            secs.toString().padStart(2, '0'),
        ].join(':');
    };


    const hangUpCall = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (connectedCallerInfo !== null) {
            connectedCallerInfo.endCall();
        }
    }

    return (
        <div style={{ position: "relative" }}>
            {props.isCallInProgress &&
                (props.incomingCallConnection === null || (props.incomingCallConnection !== null && props.callStatus === 'Received')) ?
                <IconButton className="actionButtonTimer" buttonRef={buttonRef}
                    onClick={() => { setOpenSearchMenu((prevState) => (!prevState)); }}>
                    <div className="callEndSection">
                        <span style={{ fontSize: '15px' }}>{formatTime(callTime)}</span>
                        <div className="svgContainer d-flex justify-content-center align-items-center" onClick={hangUpCall}>
                            <svg width="24" height="16" viewBox="0 0 48 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.59628 18.5844C11.1269 17.0538 12.8691 15.7903 14.7639 14.8368C15.6998 14.377 16.3393 13.3007 16.3278 12.4374L17.0168 7.50435C22.0169 5.98456 26.0324 5.98391 30.9843 7.50299L31.6625 12.3003C31.6618 13.3698 32.2505 14.3423 33.2129 14.8448C35.1172 15.8038 36.8594 17.0658 38.3907 18.5971C39.4968 19.7033 41.2967 19.7033 42.4028 18.5972L46.9939 14.0061C48.1001 12.8999 48.1001 11.1001 46.9939 9.99394C34.3149 -2.68512 13.685 -2.68505 1.0059 9.99401C-0.10022 11.1001 -0.100223 12.9 1.0059 14.0061L5.58423 18.5845C6.69035 19.6904 8.49022 19.6904 9.59628 18.5844Z"
                                    fill="white" />
                            </svg>
                        </div>
                    </div>
                </IconButton>
                :
                props.isCallInProgress && props.incomingCallConnection !== null && props.callStatus !== 'Received' ?
                    <IconButton className="actionButton ">
                        <CallIcon className="shaking-call-button" color={HEADER_COLORS.CALL_ICON} /></IconButton>

                    :
                    <IconButton buttonRef={buttonRef} className="actionButton" onClick={() => { setOpenSearchMenu((prevState) => (!prevState)); }}>
                        <CallIcon color={HEADER_COLORS.CALL_ICON} />{/*Call icon color Blue*/}
                    </IconButton>
            }

            <HeaderGlobalDialerInsuranceWrapper ref={popoverRef} style={{ visibility: openSearchMenu ? "visible" : "hidden" }}>
                {Utils.getAccountData("userId") !== 446 ?
                    <GlobalDialer
                        updateNumber={updateNumber}
                        openDialog={() => { setOpenSearchMenu(true); }}
                        closeDialog={() => closeDialog()}
                        setConnectedCalledInfo={(info) => setConnectedCallerInfo(info)}
                        OpenContactDetailModal={handleOpenContactDetailModal}
                        dialOccurrence={dialOccurrence}
                    /> :
                    <GlobalDialer
                        updateNumber={updateNumber}
                        openDialog={() => { setOpenSearchMenu(true); }}
                        closeDialog={() => closeDialog()}
                        setConnectedCalledInfo={(info) => setConnectedCallerInfo(info)}
                        OpenContactDetailModal={handleOpenContactDetailModal}
                        dialOccurrence={dialOccurrence}
                    />
                }
            </HeaderGlobalDialerInsuranceWrapper>

            {
                openContactDetailModal &&
                <GlobalModal
                    open={openContactDetailModal}
                    title="Contact Details"
                    onClose={() => onCloseCallOutcomeModal()}
                    buttonText={"Add Deal"}
                    hideFooter={true}
                    extra_props_active={true}
                >
                    <ContactDetailCallOutcome closeModal={() => { setOpenContactDetailModal(false) }} />
                </GlobalModal>
            }
        </div>
    );
};

const mapStateToProps = state => {
    return {
        listCurrentIndex: state.globalDialerReducer.listCurrentIndex,
        goToNextInList: state.globalDialerReducer.goToNextInList,
        recentContacts: state.globalDialerReducer.recentContacts,
        isCallInProgress: state.globalDialerReducer.isCallInProgress,
        callTime: state.globalDialerReducer.callTime,
        incomingCallConnection: state.globalDialerReducer.incomingCallConnection,
        callStatus: state.globalDialerReducer.callStatus,

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        storeMultipleReducers: (params) => dispatch(storeMultipleReducers(params)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(HeaderGlobalDialerInsurance);