import React, {useEffect, useRef, useState} from "react";
import {NotificationIcon} from "../../GlobalAddDealModalV3/components/Icons";
import {IconButton, styled, Badge} from "@material-ui/core";
import Notifications from "../../notifications/Notifications";
import {useSelector} from "react-redux";
import { HEADER_COLORS } from "./constants";

const NotificationPopoverMenuInsuranceWrapper = styled("div")(({ theme }) => ({
    backgroundColor: "var(--white)",
    borderRadius: "10px",
    boxShadow: "0 0 17px 1px #bbb",
    right: "-194px",
    overflow: "visible",
    position: "absolute",
    top: "60px",
    width: "450px",
    zIndex: "999",

    "&:before": {
        background: "url(/static/media/arrow.20587642.svg) no-repeat 50% / 34px 24px",
        backgroundSize: "cover",
        content: "''",
        display: "block",
        height: "23px",
        position: "absolute",
        right: "44%",
        top: "-12px",
        width: "34px",
        zIndex: "-1"
    },

    "& .MuiBox-root": {
        padding: "0"
    },

    "& .MuiPaper-root.MuiAppBar-root": {
        backgroundColor: "var(--white)",
        height: "50px",
        padding: "0 20px",
        boxShadow: "none",
        color: "rgba(0, 0, 0, 0.87)",

        "& .MuiTabs-indicator": {
            background: "var(--light_blue)",
            height: "4px"
        },

        "& .MuiTab-root": {
            color: "var(--dark_blue) !important",
            fontSize: "14px !important",
            lineHeight: "22px",
            textTransform: "capitalize",
            minWidth: "50%",
            borderBottom: "4px solid #e5e8ef",

            "&.Mui-selected": {
                color: "var(--light_blue) !important",
            },

            "&:focus": {
                backgroundColor: "transparent"
            }
        },
    },
}));

const HeaderNotificationPopover = () => {
    const notificationCount = useSelector(state => state.notificationReducer.notificationCount);
    const [openSearchMenu, setOpenSearchMenu] = useState(false);
    const buttonRef = useRef(null);
    const popoverRef = useRef(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (buttonRef.current && !buttonRef.current.contains(event.target)
            && popoverRef.current && !popoverRef.current.contains(event.target)) {
            setOpenSearchMenu(false);
        }
    };

    return (
        <div style={{ position: "relative" }}>
            <Badge badgeContent={notificationCount > 10 ? "10+" : notificationCount} color="error">
                <IconButton buttonRef={buttonRef} className="actionButton" onClick={()=>{ setOpenSearchMenu((prevState)=>(!prevState)); }}>
                    <NotificationIcon color={HEADER_COLORS.NOTIFICATION_ICON}/>{/*Notification icon color Yellow*/}
                </IconButton>
            </Badge>

            <NotificationPopoverMenuInsuranceWrapper ref={popoverRef} style={{ visibility: openSearchMenu ? "visible" : "hidden" }}>
                <Notifications onClose={() => {setOpenSearchMenu(false);}} />
            </NotificationPopoverMenuInsuranceWrapper>
        </div>
    );
};

export default HeaderNotificationPopover;