import React, { useState, useEffect } from 'react';
import BasicInput from '../../custom/BasicInput'
import BasicSelect from "../../custom/BasicSelect";
import SendEmailHelper from "../Email/assets/Helper";
import { makeStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import GlobalContactRelatedContactSelectorInsurance from "../../GlobalContactRelatedContactSelectorInsurance/GlobalContactRelatedContactSelectorInsurance";
import { getProfileDataApi } from "../../contactDetails/redux/contactDetailApi";



const useStyles = makeStyles((theme, props) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  BasicInputStyle: {
    width: '500px !important'
  }, BasicSelectStyle: {
    width: '1010px !important',
  },
  toBasicSelect: {
    width: '100% !important',
  },
  BasicInputStyleSub: {
    width: '842px !important',
  },
  deleteChip: {
    height: '22px',
    backgroundColor: '#3c7ef3 !important',
    color: '#fff !important',
    '& .MuiChip-deleteIcon': {
      WebkitTapHighlightColor: 'transparent',
      color: 'hsla(0, 0%, 100%, 0.7)',
      fontSize: '16px',
      cursor: 'pointer',
      margin: '0 4px 0 -4px',
    },
  },
  toContainer: {
    display: 'flex',
    alignItems: "center",
    height: '50px',
    width: '100%'
  },
  label: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#777',
    cursor: 'pointer',
    lineHeight: 'unset',
    maxWidth: '120px',
    width: '100%',
  },
  selectContainer: {
    display: 'flex',
    alignItems: "center",
    width: '100%'
  },
  ccButton: {
    border: '1px solid',
    borderRadius: '6px',
    cursor: 'pointer',
    padding: '5px 15px',
    height: '38px',
    alignItems: 'center',
    display: 'flex',
    backgroundColor: '#fff',
    marginLeft: '10px',
    minWidth: '90px'
  },
  ccContainer: {
    padding: '10px',
    minHeight: '60px',
    border: '1px solid #ddd',
    borderRadius: '6px',
    margin: '10px 0px 10px 120px'
  },
  ccStyle: {
    display: 'flex',
    position: 'relative',
    paddingRight: '107px'
  },
  ccLabel: {
    width: 'auto',
    marginRight: '20px',
    cursor: 'default',
    fontSize: '14px',
    fontWeight: '500',
    color: '#777',
    lineHeight: 'unset',
    maxWidth: '120px',
  },
  ccInputContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  chipContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    flexWrap: 'wrap'
  },
  clearButton: {
    height: '36.5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
    color: '#fff',
    background: 'var(--red)',
    marginLeft: '10px',
    position: 'absolute',
    right: '-3px',
    top: '0',
    width: '100px'
  },
  errorMsg: {
    color: "var(--red)",
    margin: '14px 0 0',
    display: 'inline-block'
  }
}));

const ToMail = (props) => {
  const [defaultCc, setDefaultCc] = useState('');
  const [ccMailError, setCcMailError] = useState('');
  const [removeRequest, setRemoveRequest] = useState(null);
  const [displayCC, setDisplayCC] = useState(false);
  const classes = useStyles({ defaultCc });

  useEffect(() => {
    getProfileData();
  }, [])

  const addCcMail = (e) => {
    var code = (e.keyCode ? e.keyCode : e.which);
    if (ccMailError) {
      setCcMailError('');
    }
    if (code == 13) {
      if (e.target.value == '') {
        setCcMailError('Enter a mail address to add CC');

      }
      else if (!SendEmailHelper.validateEmail(e.target.value)) {
        setCcMailError('Enter a valid mail address to add CC');
      }
      else if (props.cc.includes(e.target.value)) {
        setCcMailError('This mail already added in CC');
      }
      else {
        let newData = [...props.cc];
        newData.push(e.target.value)
        props.setCC(newData)
        e.target.value = '';
      }
    }
  }
  const removeFromCc = (item, index) => {
    setRemoveRequest(item);
    const oldData = [...props.cc];
    oldData.splice(index, 1);
    props.setCC(oldData)
  };
  const handleRemoveall = () => {
    setRemoveRequest(-1);
    props.setCC([]);
    setDisplayCC(false);
    setCcMailError('');
  };

  const getProfileData = async () => {
    try {
      const resp = await getProfileDataApi();

      if (resp.data && resp.data.profile) {
        setDefaultCc(resp.data.profile.email);
      } else {
        console.log('Nothing: Profile data not found');
      }
    } catch (error) {
      console.error('Error fetching profile data:', error);
    }
  };

  const handleAddCC = () => {
    if (!displayCC) {
      setDisplayCC(true);
      props.setCC([defaultCc]);
    }
  }

  return (
    <div style={{ margin: "0px 10px" }}>
      <div className={classes.toContainer}>
        <div className={classes.label}>
          <h5 className="video__email__left__label">To:</h5>
        </div>
        <div className={classes.selectContainer}>
          <div className={classes.toBasicSelect}>
            <BasicSelect
              name="fromEmail"
              options={props.toEmails}
              value={props.selectedToMail}
              onChange={(e) => props.setSelectedToMail(e.target.value)}
              mapping={{
                label: "label",
                value: "value",
              }}
              defaultText="select a mail"
            />
          </div>

          <span className={classes.ccButton}
            onClick={handleAddCC}
            style={{ borderColor: displayCC ? '#3c7ef3' : '#ddd' }}>Add CC</span>

          <div style={{ width: '196px' }}>
            {!props.relationSuperContactId &&
              <GlobalContactRelatedContactSelectorInsurance
                data={{
                  contactId: props.id,
                  type: "email",
                  callback: (data, remove) => {
                    let newData = Array.from(new Set([...props.cc, ...data.emails]));

                    if (remove.emails[0]) {
                      newData = newData.filter(item => !remove.emails.includes(item));
                    }
                    if (!displayCC) {
                      setDisplayCC(true);
                      props.setCC([defaultCc, ...props.cc, ...newData]);
                    } else {
                      props.setCC(newData);
                    }

                  },
                  removeRequest: removeRequest,
                  removeRequestCallback: () => {
                    setRemoveRequest(null);
                  }
                }}
              />}
          </div>




        </div>
      </div>
      {
        displayCC && (
          <div className={classes.ccContainer}>
            <div className={classes.ccStyle}>
              <h3 className={classes.ccLabel}>Cc</h3>
              <div className={classes.ccInputContainer}>
                <div style={{ width: '100%' }}>
                  <div style={{ paddingBottom: "10px", paddingLeft: "1px" }}>
                    <BasicInput
                      onKeyPress={addCcMail}
                      onChange={addCcMail}
                      placeholder="Add cc mail address"
                      fullWidth
                    />
                  </div>
                  <div className={classes.chipContainer}>
                    {props.cc.map((item, index) => {
                      return (
                        <Chip className={classes.deleteChip} key={item + "-" + index} label={item}
                          onDelete={() => { removeFromCc(item, index) }}
                        />
                      )
                    })}
                  </div>
                </div>
                <span className={classes.clearButton} onClick={handleRemoveall}>Clear All</span>
              </div>
            </div>

            {ccMailError && <span className={classes.errorMsg}>{ccMailError}</span>}

          </div>
        )

      }

    </div>
  );
};
export default ToMail;