import { useContext, useEffect, useState } from 'react'
import ApiList from '../ApiList'
import { ContactDetailContext } from "../reducer/GlobalReduers"
import { AddNote } from './AddNote'
import { NoteItem } from './NoteItem'
import DynamicSkeleton from '../../common/DynamicSkeleton'


const Notes = (props) => {
    // const {detailStates} = useContext(ContactDetailContext)
    const [noteList, setNoteList] = useState([])
  
    const [updateData, setUpdateData] = useState(null)
    const [editNote, setEditNote] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
        if(props.contact_id !== null){
            fetchNotes()
        }
    }, [])
  
    const fetchNotes = () => {
        setIsLoading(true)
        ApiList.getNotes({
            contact_id: props.contact_id,
            content_type: 16,
            skip: 0
        }).then(res => {
            let response = res.data;
            setNoteList(response)
            setIsLoading(false)
        })
    }
  
    const updateNote = (item) => {
        setUpdateData(item)
        setEditNote(true)
    }

    const renderNotes = () => {
        let showNote = [];
        noteList.map((item, index) => {
            showNote.push(
              <NoteItem data={item} key={item.id} updateNote={updateNote}/>
            )
        })
        return showNote;
    }
  
    return(
        <div className="g-contact-note-tab">
            {/* add note */}
            <AddNote 
                editNote={editNote}
                updateData={updateData}
                handleEditFlag={(status) => {
                    setEditNote(status)
                    if(!status){
                        setUpdateData(null)
                    }
                }}
                refresh={fetchNotes}
            />

            <div className="g-contact-list-content awesome__scroll_bar">
                {isLoading ? (
                    <DynamicSkeleton count={5} height='100px'/>
                    
                ) : noteList.length === 0 ? (
                    <div className="g-empty-note-content">No note added for this contact</div>
                ) : (
                    renderNotes()
                )}
            </div>
        </div>
    )
}
export default Notes;
  
 