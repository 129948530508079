const ProgressBar = ({completed}) => {
    const containerStyles = {
        height: 20,
        backgroundColor: "#FFFFFF",
        borderRadius: 50,
        width: "90%"
    }

    const fillerStyles = {
        height: '100%',
        width: `0%`,
        backgroundColor: 'var(--accent-color)',
        borderRadius: 'inherit',
        textAlign: 'right',
        transition: 'all 0.3s linear'
    }

    const labelStyles = {
        padding: 5,
        color: 'white',
        fontWeight: 'bold'
    }

    const uploadingLabel = {
        fontWeight: 'bold',
    }

    const loaderWrapper = {
        height: "280px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    }

    return (
        <div style={loaderWrapper}>
            {completed < 100 ?
                <p style={uploadingLabel}>Uploading ...</p>
                :
                <p style={uploadingLabel}>Upload Successful</p>
            }
            <div style={containerStyles}>
                <div style={fillerStyles} id='progress-bar'>
                    <span style={labelStyles} id='progress-bar-number'>0%</span>
                </div>
            </div>
        </div>
    );
}

export default ProgressBar;