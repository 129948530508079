import {
    Modal,
    Paper,
    Typography
} from "@material-ui/core";
import { connect } from "react-redux";
import Styles from "./EnhanceData.module.css";
import icon from "./icon";
import StripedProgress from "./StripedProgress";
import ResultStyles from "./ValidationResultModal.module.css";

const DownloadPopup = ({ isOpen, onClose }) => {

    return (
      <>
        <Modal
          open={isOpen}
          onClose={() => {}}
          aria-labelledby="enhance-modal-title"
          aria-describedby="enhance-modal-description"
          className={`${Styles.modalWrapper} global-xs-modal`}
        >
            <Paper className={Styles.modalInner}>
            <div style={{ padding: "10px", width: "100%" }}>
                <div id="downloadPopupProgress">
                    <h5 id="downloadPopupSuccessHeader" className={`${ResultStyles.successTitle} ${ResultStyles.displayHidden}`} >Processing: 100% complete</h5>
                    <div id="downloadPopupHeader">
                        <StripedProgress
                            width={100}
                            text={
                                "Processing... this may take a few minutes depending on the number of records"
                            }
                        />
                    </div>

                    <div className={ResultStyles.borderedDiv}>
                        <span className={ResultStyles.topText}>RESULTS</span>
                        <div className={ResultStyles.content}>
                            <div>
                                <div className={`${ResultStyles.svgIcon}`}>{icon.google}</div>
                                <div id="helpTextPopupModal" style={{textAlign:"center"}}>
                                    <p className={ResultStyles.unsetMargin}>
                                        <b>
                                            You can wait here or close the screen.
                                        </b>
                                    </p>
                                    <p className={ResultStyles.unsetMargin}>
                                        <b>
                                            We'll notify you when your export is ready!
                                        </b>
                                    </p>

                                </div>
                                <input hidden id="download_file" type="text"/>
                                <div id="successTextPopupModal" className={`${ResultStyles.displayHidden}`}>
                                    <Typography variant="p">
                                        File Name : <span id="downloadFileName"></span>
                                    </Typography>
                                </div>
                                <div  style={{textAlign:"center"}} id="downloadPopupModal" className={`${ResultStyles.displayHidden}`}>
                                    <button className={`${ResultStyles.downloadButton}`}>
                                        Download
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={ResultStyles.closeButtonDiv}>
                    <button id="closeDownloadPopUp" className={ResultStyles.closeButton}  onClick={onClose}>
                        Close
                    </button>
                </div>
            </div>
            </Paper>
        </Modal>
      </>
    )
}

export default connect()(DownloadPopup);