import React, { useEffect, useState } from "react";
import './signup.css';
import { connect } from "react-redux";
import { getSignUpPageData } from "../../actions/signupAction";
import Loading from "../common/Loading";
import SignUpForm from "./SignUpForm";
import SelectPackage from "./SelectPackage";
import If, {Else} from "if-else-react";
import SignUpSuccess from "./SignUpSuccess";
import {Helmet} from "react-helmet";
import Utils from "../../helpers/Utils";
import {CODE_SNIPPET} from "../../constants/CoreConstants";
import OtpForm from "./OtpForm";
import SignUpFormInsurance from "./SignUpFormInsurance";

const SignUp = props => {
    const [loading, setLoading] = useState(props.loading);
    const [validationCheck, setValidationCheck] = useState(props.validationCheck);
    const [isSignUpSuccess, setIsSignUpSuccess] = useState(props.isSignUpSuccess);
    const [pageInfo, setPageInfo] = useState({
        logo: '',
        favIcon: '',
        agencyName: '',
        privacyPolicy: '',
        agencyThemeColor: '',
        agencyTextColor: '',
        termsAndConditions: '',
        agencyId: null
    });
    const insuranceCompanyAgencyIds = [236];

    useEffect(() => {
        document.body.style.backgroundColor = "white";
        props.getSignUpPageData({'type': props.match.params.type}, responseSignUpPageData);
    }, []);

    useEffect(() => {
        setValidationCheck(props.validationCheck);
    }, [props.validationCheck]);

    useEffect(() => {
        console.log()
    }, [props.otpNeededForUser]);

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);

    useEffect(() => {
        setIsSignUpSuccess(props.isSignUpSuccess);
    }, [props.isSignUpSuccess]);

    const responseSignUpPageData = response => {
        setPageInfo({
            ...pageInfo,
            logo: response.data.agencyLogo,
            favIcon: response.data.favIcon,
            agencyName: response.data.agencyName,
            agencyId: response.data.agencyId,
            agencyAccentColor: response.data.agencyAccentColor,
            agencyThemeColor: response.data.agencyThemeColor,
            agencyTextColor: response.data.agencyTextColor,
            privacyPolicy: response.data.privacyPolicy,
            termsAndConditions: response.data.termsAndConditions,
        });
        Utils.loadCodeSnippets(CODE_SNIPPET.REGISTRATION, {agencyId: response.data.agencyId});
    }

    return (
        <div className="signup__wrapper signup__wrapper__wr_v2 height-100" style={{ background: pageInfo.agencyThemeColor }}>
            <Helmet>
                <link rel="icon" href={pageInfo.favIcon} />
                <title>{`Signup | ${pageInfo.agencyName}`}</title>
                <meta name="description" content="All-In-One Sales & Marketing Platform" />
                <meta name="keywords" content={`CRM, Automation, Sales, Marketing, ${pageInfo.agencyName}`} />
                <meta name="author" content={pageInfo.agencyName} />
                {pageInfo?.agencyId == 82 &&
                <script async src="https://cdn.tolt.io/tolt.js" data-tolt="b48a2a3f-cc72-41d0-af5e-2211ae0108dc"></script>
                }
            </Helmet>
            {loading &&
                <div className='pre-loader'>
                    <Loading />
                </div>
            }
            {!loading &&
                <If condition={!isSignUpSuccess}>
                    <React.Fragment>
                    {(!validationCheck && (insuranceCompanyAgencyIds.includes(pageInfo?.agencyId))) ? (
                        <SignUpFormInsurance
                            signupType={props.match.params.type}
                            pageLogo={pageInfo.logo}
                            accentColor={pageInfo.agencyAccentColor}
                            agencyThemeColor={pageInfo.agencyThemeColor}
                            agencyTextColor={pageInfo.agencyTextColor}
                            agencyName={pageInfo.agencyName}
                            privacyPolicy={pageInfo.privacyPolicy}
                            termsAndConditions={pageInfo.termsAndConditions}
                        />
                    ) : !validationCheck ? (
                        <SignUpForm
                            signupType={props.match.params.type}
                            pageLogo={pageInfo.logo}
                            accentColor={pageInfo.agencyAccentColor}
                            agencyThemeColor={pageInfo.agencyThemeColor}
                            agencyTextColor={pageInfo.agencyTextColor}
                            agencyName={pageInfo.agencyName}
                            privacyPolicy={pageInfo.privacyPolicy}
                            termsAndConditions={pageInfo.termsAndConditions}
                        />
                    ) : null}
                        {validationCheck &&
                            <SelectPackage
                                agencyThemeColor={pageInfo.agencyThemeColor}
                                agencyTextColor={pageInfo.agencyTextColor}
                                privacyPolicy={pageInfo.privacyPolicy}
                                termsAndConditions={pageInfo.termsAndConditions}
                                agencyId={pageInfo?.agencyId}
                                type={props.match.params.type}
                            />
                        }
                    </React.Fragment>
                <Else />
                    <If condition={props.otpNeededForUser !== ''}>
                        <OtpForm
                            signupType={props.match.params.type}
                            pageLogo={pageInfo.logo}
                            accentColor={pageInfo.agencyAccentColor}
                            agencyName={pageInfo.agencyName}
                            privacyPolicy={pageInfo.privacyPolicy}
                            termsAndConditions={pageInfo.termsAndConditions}
                            otpNeededForUser={props.otpNeededForUser}
                        />
                        <Else />
                        <SignUpSuccess logo={pageInfo.logo}  agencyName={pageInfo.agencyName}/>
                    </If>
                </If>

            }
        </div>
    );
};


const mapStateToProps = state => {
    return {
        validationCheck: state.signupReducer.validationCheck,
        loading: state.signupReducer.loading,
        agencyId: state.signupReducer.agencyId,
        isSignUpSuccess: state.signupReducer.isSignUpSuccess,
        userEmail: state.signupReducer.email,
        otpNeededForUser: state.signupReducer.otpNeededForUser,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getSignUpPageData: (params, callback) => dispatch(getSignUpPageData(params, callback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);