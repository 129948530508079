import { styled } from "@material-ui/core";

export const CustomScrollbar = styled('div')({
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: '#888 transparent', // Change color as needed
    '&::-webkit-scrollbar': {
        width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888', // Change color as needed
        borderRadius: '6px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#555', // Change color as needed
    },
});