import React, {useState} from "react";
import { TableCell, TableRow, styled } from "@material-ui/core";
import LoaderButton from "../common/LoaderButton";
import {STEP_TABS} from "./constants";
import { buyVirtualNumber } from "../../api/tenantApi";
import NumberPurchaseErrorModal from "./NumberPurchaseErrorModal";
import {useDispatch} from "react-redux";
import {getVirtualNumbers} from "../../actions/globalDialerAction";

const TableRowWrapper = styled(TableRow)(({ theme }) => ({
  color: theme.palette.text.primary,

  "& .titleWrap": {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },

  "& .folderWrap": {
    display: "flex",
    flexWrap: "wrap",
    gap: 4,
    marginTop: 2,

    "& .folderItem": {
      display: "flex",
      alignItems: "center",
      gap: 8,
      // border: "1px solid #D0D5DD",
      borderRadius: "4px",
      padding: "2px 4px",
      marginRight: 10,

      "& .folderName": {
        fontSize: "12px !important",
        color: "#475467",
        lineHeight: 1,
      },
    },
  },

  "& .iconFavorite": {
    fontSize: 16,
    color: "gray",
    cursor: "pointer",
    "&.active": {
      color: "#f79009",
    },
  },

  "& .MuiTableCell-root": {
    fontSize: "15px",
    fontWeight: 500,
  },

  "& .MuiIconButton-root": {
    "&:focus": {
      background: "transparent",
    },
  },

  "& .actionButton": {
    backgroundColor: "#3E50F7",
    minWidth: 130,
    "&:hover, &:focus": {
      backgroundColor: "#3E50F7",
    },
  },
}));

const EachTableItem = ({ item, setStep, data }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [purchaseErrorModalOpen, setPurchaseErrorModalOpen] = useState(false);
  const[errorMessage,setErrorMessage] = useState('');

  const handleBuyVirtualNumber = async () => {
    
    setIsLoading(true);
    try{
      const response = await buyVirtualNumber({phoneNumber:item.phoneNumber});
      if (response.success){
        dispatch(getVirtualNumbers());
        if (data && data.callback){
            data.callback(response.data);
        }
        setStep(STEP_TABS.NUMBER_ADDED);
      }
      else{
        setErrorMessage(response.message)
        setPurchaseErrorModalOpen(true);
      }
     
    }catch (err){
      console.log(err);
      
    }finally {
      setIsLoading(false);
    }
  };

  return (
    <TableRowWrapper>
      <TableCell component="th" scope="row">
        <div className="titleWrap">{item.phoneNumber}</div>
      </TableCell>
      <TableCell align="left">{item.friendlyName}</TableCell>
      <TableCell align="center">{item.isoCountry}</TableCell>
      <TableCell align="center">{item.postalCode}</TableCell>
      <TableCell align="center">
        <LoaderButton
          variant="contained"
          color="primary"
          className={"actionButton"}
          disableElevation
          loading={isLoading}
          onClick={handleBuyVirtualNumber}
          disabled={isLoading}
        >
          Add Number
        </LoaderButton>
        {
          purchaseErrorModalOpen && (
            <NumberPurchaseErrorModal
              open={purchaseErrorModalOpen}
              onClose={() => setPurchaseErrorModalOpen(false)}
              message={errorMessage}
            />
          )
        }
      </TableCell>
    </TableRowWrapper>
  );
};

export default EachTableItem;
