import { styled } from "@material-ui/core";
import React from "react";

const SubTitleWrapper = styled("p")(({ theme }) => ({
  // fontFamily: "'Inter', sans-serif",
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "24px",
  color: "#7E869A",
  margin: 0,
   whiteSpace: "pre"
}));

const SubTitle = ({ text = "SubTitle", className = "", ...props }) => {
  return <SubTitleWrapper className={className} {...props}>{text}</SubTitleWrapper>;
};

export default SubTitle;
