import React, {useState, useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import NoteAddOutlined from '@material-ui/icons/NoteAddOutlined.js';

// import './modal.css'
import { Slide } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function GlobalModal (props)  {
    const classes = useStyles();

    const [show, setShow] = useState(props.open)

    useEffect(() => {
        setShow(props.open);
    },[props.open])

    const closeModal=()=>{

        if(props.customCloseModal){
            props.onClose(true, true)
        }else {
            setShow(false);
            setTimeout(()=>{
                props.onClose(false)
            },500)
        }

    }

    let extra_props = {}
    if(props.extra_props_active !== undefined){
        extra_props['disableEnforceFocus'] = true;
        extra_props['disableAutoFocus'] = true;
    }


    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={show}
                onClose={props.setOpenSMSModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 1000,
                }}
                onBackdropClick ={closeModal}
                {...extra_props}
            >
                <Slide in={show} direction="left">
                    <div className={`globalModalWrapper cardBordcastTab ${props.className !== undefined ? props.className : ''} ${props.hideFooter !== true ? '': 'global-modal-has-no-footer' }`}>
                        <div id="globalModal">
                            <div className="globalModalTitle d-flex justify-content-between align-items-center">
                                <div className="sub_title">
                                    <h2>{props.title}</h2>
                                </div>
                                <div onClick={closeModal} className="share_campaign_cross_button d-flex justify-content-center align-items-center modal-close">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M15.1252 17.0812L17.1357 15.0693L6.878 4.80485L4.86747 6.8167L15.1252 17.0812Z"
                                            fill="white"/>
                                        <path
                                            d="M6.878 17.0838L17.1357 6.81934L15.1252 4.80749L4.86747 15.072L6.878 17.0838Z"
                                            fill="white"/>
                                    </svg>
                                </div>
                            </div>

                            {/* Modal Body */}

                            <div className="modalBody">
                              {props.children}
                            </div>

                            {/*Send Massage and close button*/}
                            {
                                props.hideFooter !== true &&
                                <div className="massage_send_close_button d-flex justify-content-center align-items-center ml-5">

                                    {props.previousButton === true &&
                                        <a href="#!" onClick={(e) =>{ e.preventDefault();props.previousButtonOnSubmit(e)}} className="send_massage accent--bg--text--color">
                                        <span>
                                        {props.previousButtonIcon}
                                        </span> <p className="global-modal-buttontext">{props.previousButtonText}</p>
                                    </a>
                                    }
                                    <a href="#!" onClick={(e) =>{ e.preventDefault();props.onSubmit(e)}} disabled={props.submitButtonDisable} className="send_massage accent--bg--text--color">
                                        <span>
                                                {props.buttonIcon}<NoteAddOutlined style={{ fontSize: '30px', marginTop: '1px' }} />

                                        </span> <p className="global-modal-buttontext">{props.buttonText}</p>
                                    </a>
                                    <a className="close_btn" href="#!" onClick={e =>{ e.preventDefault(); closeModal()} }>
                                        <span>
                                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="13" cy="13" r="13" fill="white"/>
                                        <path d="M16.5355 16.5353C16.2097 16.8612 15.918 17.0963 15.5921 16.7704L13 14.1783L10.4079 16.7704C10.0826 17.0957 9.79032 16.8612 9.46447 16.5353C9.13861 16.2095 8.90408 15.9172 9.22935 15.5919L11.8215 12.9998L9.22935 10.4076C8.90408 10.0824 9.13861 9.7901 9.46447 9.46424C9.79032 9.13839 10.0826 8.90386 10.4079 9.22913L13 11.8213L15.5921 9.22913C15.918 8.90327 16.2097 9.13839 16.5355 9.46424C16.8614 9.7901 17.0965 10.0818 16.7706 10.4076L14.1785 12.9998L16.7706 15.5919C17.0965 15.9178 16.8614 16.2095 16.5355 16.5353Z" fill="#FF264A"/>
                                        </svg>
                                        </span> <p className="global-modal-buttontext"> Close</p>
                                    </a>
                                </div>
                            }
                        </div>
                    </div>
                </Slide>
            </Modal>
        </>
    );
}