import { AppBar, Box, Tab, Tabs, Typography } from "@material-ui/core";
import { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import LeadNotifications from "./LeadNotifications";
import SiteNotifications from "./SiteNotifications";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { restoreGeneralNotificaiton, restoreLeadNotificaiton, setAllLeadNotificationMarkAsSeen, setAllSiteNotificationMarkAsSeen } from "../../actions/notificationAction";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from '@material-ui/icons/Delete';
import { markAllNotificationsAsClosedApi } from "../../api/notificationApi";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    clearAllNotification: {
        borderRadius: '8px',
        padding:'4px',
        transition: 'background-color 0.3s ease',
        backgroundColor: '#f0f0f0',
        '&:hover': {
            backgroundColor: '#ff0000',
            cursor: 'pointer', 
            '& .MuiSvgIcon-root': {
                fill: 'White',
            },
            '& span': {
                color: 'white', 
            },
        },
    },
}));

const TabPanel = props => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const Notifications = (props) => {

    const dispatch = useDispatch();
    const [tabValue, setTabValue] = useState(0);
    const { siteNotifications, leadNotifications } = useSelector(state => state.notificationReducer);
    const classes = useStyles();



    const markAllAsSeen = () => {
        props.onClose();
        confirmAlert({
            title: 'Are you sure?',
            message: 'Do you want to mark all notifications as seen?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        if(tabValue == 0) {
                            dispatch(setAllSiteNotificationMarkAsSeen());
                        } else {
                            dispatch(setAllLeadNotificationMarkAsSeen());
                        }
                        window.showNotification('SUCCESS', 'Successfully set all mark as done');
                    }
                },
                {
                    label: 'No',
                    onClick: () => {return}
                }
            ]
        });
    }
    const clearAllNotification = () => {
        let bodyParams = {
            notification_type: tabValue === 0 ? 'site' : 'lead'
        }
        markAllNotificationsAsClosedApi(bodyParams).then(res => {
            let response = res.data;
            if(response.status === 'success'){
                if(tabValue === 0){
                    dispatch(restoreGeneralNotificaiton());
                }else{
                    dispatch(restoreLeadNotificaiton());
                }
                window.updateNotificationCount();
            }
            else{
                window.showNotification('ERROR', response.html);
            }
        })
    }
    const confirmToClearAll = () => {
        confirmAlert({
            title: 'Are you sure?',
            message: `Do you want to clear all ${tabValue === 0 ? 'general' : 'lead'} notifications? The request can not be undone.`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        clearAllNotification()
                    }
                },
                {
                    label: 'No',
                    onClick: () => { return }
                }
            ]
        });
    } 
    const renderClearButton = () => {
        if((tabValue === 0 && siteNotifications && siteNotifications.length > 0) || (tabValue === 1 && leadNotifications && leadNotifications.length > 0)){
            return (
                 <div
                    className={classes.clearAllNotification}
                    onClick={confirmToClearAll}
                >
                    <DeleteIcon fontSize="small" style={{marginBottom:"-4px"}} />
                    <span style={{ padding: "2px"}}>Clear All</span>
                </div>
            )
        }
        return null;
    }

    return ( 
        <>
                                                    
            <div className="notification-title d-flex align-items-center header___notifications__content">
                <h5>Latest Notifications</h5>
                <div className="latest__notifications__header">
                    {renderClearButton()}
                    <div onClick={() => props.onClose()}
                        className=" cross-icon d-flex justify-content-center align-items-center">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15.1262 17.1368L17.1367 15.125L6.87897 4.86052L4.86845 6.87237L15.1262 17.1368Z"
                                fill="white"/>
                            <path
                                d="M6.87898 17.1395L17.1367 6.875L15.1262 4.86315L4.86845 15.1276L6.87898 17.1395Z"
                                fill="white"/>
                        </svg>
                    </div>
                </div>
            </div>

            <div>
                <AppBar position="static" color="default">
                    <Tabs value={tabValue}
                            onChange={(event, newValue) => {setTabValue(newValue);}}
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="simple tabs example"
                            className="topbar_notification_tab_indicator">
                        <Tab label="General Notification"/>
                        <Tab label="Lead Notification"/>
                    </Tabs>
                </AppBar>
                <div className="mark-see-all-button d-flex justify-content-center mt-3">
                    <a className="all-read btn" onClick={() => markAllAsSeen()}>Mark all as Read</a>
                    <Link to={`/user/all-${tabValue == 0 ? 'site':'lead'}-notification`} className="see-all btn" onClick={() => props.onClose()}  >See all</Link>
                </div>
                <TabPanel className="notification-tab-panel site-notification-scroll" value={tabValue} index={0}>
                    <SiteNotifications closePopup={() => props.onClose()}/>
                </TabPanel>
                <TabPanel className="notification-tab-panel site-notification-scroll" value={tabValue} index={1}>
                    <LeadNotifications closePopup={() => props.onClose()}/>
                </TabPanel>
            </div>                        
        </>
     );
}
 
export default Notifications;