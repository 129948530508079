import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { contactUnsubscribedApi } from '../../../api/contactApi';

const useStyles = makeStyles((theme) => ({
  unsubscribedDetails: {
    backgroundColor: 'var(--dark_blue)',
    borderRadius: '10px',
    padding: '7px 12px 10px 12px!important',
    marginBottom: '20px',
  },
  unsubTitle: {
    fontWeight: 600,
    fontSize: '15px',
    color: '#facc01',
  },
  unsubHints: {
    whiteSpace: 'normal',
    fontSize: '14px',
    color: '#fff',
    wordBreak: 'break-word',
  },
  unsubHintsBold: {
    fontWeight: 500,
    color: 'var(--dark_blue)',
  },
}));

const Unsubscribed = (contact_id) => {
   
  const classes = useStyles();
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);


  useEffect(() => {
    contactUnsubscribedApi({ contact_id:contact_id })
      .then((res) => {
        let response = res.data;
        if (response.status) {
          setIsUnsubscribed(true);
        } else {
          setIsUnsubscribed(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (!isUnsubscribed) {
    return null;
  }

  return (
    <div className={classes.unsubscribedDetails}>
      {isUnsubscribed && (
        <>
          <div className={classes.unsubTitle}>UNSUBSCRIBE NOTICE:</div>
          <div className={classes.unsubHints}>
            <span>This contact has opted out of receiving future messages.</span>
          </div>
        </>
       )} 
    </div>
  );
};

export default Unsubscribed;