import React, {Fragment, useEffect, useState} from "react";
import {
    Button,
    makeStyles,
    Typography
} from "@material-ui/core";
import Styles from "./Styles.module.css";
import {a2pOnBoard} from "./index";
import itemImage from "./download.jpeg";
import {volumePolicyType} from "./AskVolume";
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            "&.MuiButton-root": {
                backgroundColor: "#9670ff",
                padding: "5px 32px",
                borderRadius: "4px",
                fontWeight: 700,
            },
            "&.MuiButton-text": {
                color: "#9670ff"
            },
            "&.MuiButton-contained": {
                color: "white",
                padding: "7px 32px",
            },
            "&.MuiButton-outlined": {
                backgroundColor: "#ffffff",
                color: "#9670ff",
                borderColor: "#9670ff",
                border: "2px solid !important",
            },
            "&.Mui-disabled": {
                backgroundColor: "rgba(0, 0, 0, 0.12) !important"
            }
        }
    };
});

const addZeroes = (num) => {
    num = num.toString();
    let value = Number(num);
    const res = num.split(".");
    if(res.length === 1 || res[1].length < 3) {
        value = value.toFixed(2);
    }
    return value;
};

const addFix = (num) => {
    num = num.toString();
    let value = Number(num);
    value = value.toFixed(2);
    return value;
};

const RegistrationPricing = ({ data, onClose, setActiveStep, selectedVolume }) => {
    const { currentCredit } = useSelector(state => state.globalDialerReducer);
    const [totalCharge, setTotalCharge] = useState(0);
    const classes = useStyles();

    useEffect(()=>{
        let tempTotal = 0;
        if (selectedVolume === volumePolicyType.policyTypeLowVolume){
            if (data?.low_vol_reg_price){
                tempTotal += data?.low_vol_reg_price;
            }
        }else if(selectedVolume === volumePolicyType.policyTypeHighVolume){
            if (data?.standard_reg_price){
                tempTotal += data?.standard_reg_price;
            }
        }

        setTotalCharge(tempTotal);
    },[volumePolicyType,data]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleClose = () => {
        onClose();
    };

    const registrationMappingList = {
        [volumePolicyType.policyTypeLowVolume]: [
            {
                label: "Low Volume Brand Registration",
                pricing: addZeroes(data?.low_vol_reg_price ?? 0),
                items: [
                    "EIN required for registration",
                    "More than 1 phone number allowed",
                ]
            },
            {
                label: "Low Volume Campaign Registration",
                pricing: addZeroes(data?.campaign_reg_price ?? 0),
                items: [
                    "Increased delivery rate",
                    "Send up to 6,000 segments per day"
                ]
            }
        ],
        [volumePolicyType.policyTypeHighVolume]: [
            {
                label: "Standard Brand Registration",
                pricing: addZeroes(data?.standard_reg_price ?? 0),
                items: [
                    "EIN required for registration",
                    "More than 1 phone number allowed",
                ]
            },
            {
                label: "Standard Campaign Registration",
                pricing: addZeroes(data?.campaign_reg_price ?? 0),
                items: [
                    "Increased delivery rate",
                    "Send up to 6,000 segments per day"
                ]
            }
        ]
    };

    const renderPricingItems = () => {
        let item = [];

        for (let i=0;i<registrationMappingList[selectedVolume]?.length;i++){
            let lItem = registrationMappingList[selectedVolume][i];

            item.push(
                <div className={Styles.priceItem}>
                    <h2 className={Styles.prodTitle}>{lItem.label}</h2>
                    <h1 className={Styles.prodPrice}>${lItem.pricing} - One Time</h1>
                    <ul>
                        {
                            lItem?.items?.map((llItem)=>(<li className={Styles.prodDesc}><img src={itemImage} alt="icon"/>{llItem}</li>))
                        }
                    </ul>
                </div>
            );
        }

        return item;
    }

    return(
        <Fragment>
            <div className={Styles.headerWrapper}>
                <Typography className={`${Styles.headerTitle} ${Styles.headerTitleCenter}`} variant="h5">A2P/10DLC Registration</Typography>
                <Button size="small" onClick={() => {
                    setActiveStep(a2pOnBoard.ASK_VOLUME)
                }} className={`${classes.root}`} variant="outlined">
                    BACK
                </Button>
            </div>

            <div className={`${Styles.modalBody} ${Styles.modalBodyStart}`}>
                {
                    currentCredit < totalCharge &&
                    <div className={Styles.balanceSum}>
                        <p className={Styles.balanceOne}>
                            You need a minimum balance of ${addZeroes(totalCharge)} to complete the registration process. Your current balance is ${addFix(currentCredit)}.
                        </p>
                        <p className={Styles.balanceOne}>
                            To proceed, please <span onClick={()=>{ window.open("/billing", "_self") }} className={Styles.redirectLink}>Recharge Now</span>
                        </p>
                    </div>
                }
                <div className={Styles.modalBodyHeader}>
                    <div className={Styles.modalBodyTitleWrapper}>
                        <h1 className={Styles.modalBodyTitle}>Brand and Campaign Details</h1>
                        <p className={Styles.priceSubtitle}>The fees associated with registration are displayed below.</p>
                    </div>
                </div>
                <div className={Styles.contentBody}>
                    {renderPricingItems()}
                </div>
                <p className={Styles.bottomMessage}>* These fees are paid directly to the phone companies and exist regardless of the texting platform you use</p>
                <p className={Styles.bottomMessage}>* The phone companies also charge campaign fees up to $12/mo in accordance with TCR rules</p>
            </div>
            <div className={Styles.modalFooter}>
                <Button size="large" onClick={handleClose} className={`${classes.root} ${Styles.mr1} ${Styles.redButton}`} variant="outlined">
                    Cancel
                </Button>
                <Button size="large" onClick={()=>{
                    if (currentCredit < totalCharge){
                        window.showNotification("Error", "Current balance is less than total charge");
                        return;
                    }else {
                        if (data && data.from){
                            window.globalA2p10DlcRegistration(true,{ policyType: selectedVolume }, data.from);
                        }else {
                            window.globalA2p10DlcRegistration(true,{ policyType: selectedVolume });
                        }
                        onClose();
                    }
                }} className={`${classes.root} ${Styles.overwriteButton}`} variant="contained" disabled={(currentCredit < totalCharge)}>
                   Next
                </Button>
            </div>
        </Fragment>
    );
};

export default RegistrationPricing;