import React from 'react';
import { Container, Box, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(5),
    textAlign: 'center',
  },
  card: {
    padding: theme.spacing(2),
    textAlign: 'center',
    height:'300px',
    marginTop:'20px'
  },
  cardImage: {
    width: '100%',
    height: '100px',
    backgroundColor: theme.palette.grey[200],
    marginBottom: theme.spacing(2),
  },
}));

function PostcardDesignSkeleton() {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      {/* Title Skeleton */}
      <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
        <Skeleton variant="text" width="50%" height={80} />
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
        <Skeleton variant="text" width="30%" height={30} />
      </Box>

      {/* Postcard Cards Skeleton */}
      <Grid container spacing={4} justify="center">
        {[...Array(2)].map((_, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Paper className={classes.card}>
              <Skeleton variant="rect" className={classes.cardImage} />
              <Skeleton variant="text" height={30} />
              <Skeleton variant="text" height={20} />
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Button Skeleton */}
      <Skeleton variant="rect" width={100} height={40} style={{ margin: '30px auto' }} />
    </Container>
  );
}

export default PostcardDesignSkeleton;
