import React, { useEffect, useState, useContext } from "react";
import{
  CustomAccordionBody,
  CustomAccordionHeader,
} from "../../components/Accordion/CustomAccordion";
import { icons } from "../../icons";
import EditIcon from '@material-ui/icons/Edit';
import { Grid } from "@material-ui/core";
import ApiList from "../../ApiList";
import AddIcon from '@material-ui/icons/Add';
import { connect } from "react-redux";
import Utils from "../../../../helpers/Utils";
import DealConfirmAlert from "./custom-alert/new-alert/DealConfirmAlert";
import {
  changeDealStatus,
  fetchContactStages,
} from "../../../../api/contactApi";
import RightSideModalEdit from "./RightSideModalEdit";
import DealDrawer from "./DealDrawer";

const Deal = (props) => {
  const [expand, setExpand] = useState(false);
  const [contactStages, setContactStages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [state, setState] = React.useState(false);
  const [selectedPipeline, setSelectedPipeline] = useState("1");
  const [selectedStorage, setSelectedStorage] = useState("1");
  const [editDeal, setEditDeal] = useState(0);
  const [activeTab, setActiveTab] = useState("open");
  const [showEditModal, setShowEditModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (props.contact?.id) {
      setLoading(true);
      ApiList.getContactDeals({
        id: props.contact.id,
      }).then((res) => {
        let response = res.data;
        setContactStages(response.deal);
        setLoading(false);
      });
    }
  }, [props.contact]);

  const toggleDrawer = () => {
    setState(!state);
  };
  const pipelines = [
    { id: 1, value: "1", label: "default pipeline" },
    { id: 2, value: "2", label: "maga pipeline" },
    { id: 3, value: "3", label: "apple pipeline" },
  ];

  useEffect(() => {
    refreshData("firstTime");
  }, [props.contact]); // eslint-disable-line react-hooks/exhaustive-deps

  const refreshData = (condition = "") => {
    fetchContactStages({ id:props.contactDetails?.id })
      .then((res) => {
        if (res.data.status === "success") {
          setContactStages(res.data.data);
        } else {
          setContactStages([]);
        }
      })
      .catch((e) => {
        setContactStages([]);
      });
  };

  const dealMarkAsWinLose = (contactStageId, status) => {
    DealConfirmAlert({
      onSubmit: () => {
        changeDealStatus({
          stage_id: contactStageId,
          status: status,
        }).then((response) => {
          refreshData();
        });
      },
      title: `Please confirm your selection below:`,
      description: "",
      cancelText: "Cancel",
      symbol: status === 2 ? 1 : 0,
      submitText: status === 2 ? "Mark Deal As Won!" : "Mark Deal As Lost!",
    });
  };

  const populateDeals = () => {
    let filteredList = contactStages.filter((item, index) => {
      if (activeTab === "open" && item.status === 1) {
        return true;
      } else if (activeTab === "won" && item.status === 2) {
        return true;
      } else if (activeTab === "lost" && item.status === 3) {
        return true;
      } else {
        return null;
      }
    });
    if (filteredList.length > 0) {
    
      return filteredList.map((eachStage, index) => {
        return (
          <div
          style={{marginBottom:'16px'}}
            className="deal_each_list_inbox_extra deal-each-list__inbox "
            key={`eachStage-${index}`}
          >
            <h6 className="text-gray cursor-pointer truncate i-deal-title-alt m-0">
              {" "}
              <b>Pipeline:</b>{" "}
              {eachStage.stage &&
              eachStage.stage.pipeline &&
              eachStage.stage.pipeline.title
                ? eachStage.stage.pipeline.title
                : "N/A"}
            </h6>
            <h6 className="text-gray cursor-pointer truncate i-deal-title-alt m-0">
              {" "}
              <b>Stage:</b>{" "}
              {eachStage.stage && eachStage.stage.stage
                ? eachStage.stage.stage
                : "N/A"}
            </h6>
            {/*<div className="d-flex justify-content-between align-items-center">*/}
            <div className="">
              <div
                className="i-deal-list-info__each"
                style={{ display: "flex" }}
              >
                <span className=" deal_each_list_inbox_extra_new text-gray cursor-pointer truncate i-deal-title-alt m-0">
                  {eachStage.title ? (
                    <span>
                      <b>Deal: </b>
                      {eachStage.title}
                    </span>
                  ) : (
                    "no title"
                  )}
                </span>
                <span className="i-deal-value__alt">
                  ({Utils.calculateCash(eachStage.deal_value)})
                </span>
              </div>

              <div
                className="deal-won-lost__inbox"
                style={{ display: "flex", gap: "10px",marginTop:'8px' }}
              >
                {eachStage.status === 1 && (
                  <span
                    style={{
                      border:'1px solid gray',
                      borderRadius:'20px',
                      display: "flex",
                      padding:'0px 6px',
                      alignItems: "center",
                      gap: "4px",
                    }}
                    className="deal_each_list_inbox_extra_edit  small-round-icon cursor-pointer " 
                    onClick={() => {
                      openRightSideEditModal(eachStage);
                    }}
                  >
                    {<EditIcon style={{fontSize:'16px'}}/>}
                    <span>Edit</span>
                  </span>
                )}
                {eachStage.status !== 2 && (
                  <div
                    onClick={() => {
                      dealMarkAsWinLose(eachStage.id, 2);
                    }}
                    className="ml-1 px-2 d-flex justify-content-center align-items-center cursor-pointer each-deal-won-action-button"
                    style={{
                      border:'1px solid #6fcf97',
                      borderRadius:'20px',
                      display: "flex",
                      padding:'0px 6px',
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <svg
                      className="deal_each_list_inbox_extra_svg"
                      width="14"
                      height="13"
                      viewBox="0 0 14 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M.953 12.864h1.36a.953.953 0 0 0 .954-.951V5.66a.953.953 0 0 0-.953-.951H.953A.953.953 0 0 0 0 5.66v6.252c0 .524.427.951.953.951zM10.28 13a2.173 2.173 0 0 0 2.145-1.805l.61-3.533a2.174 2.174 0 0 0-2.146-2.544H8.32s.409-.816.409-2.175c0-1.63-1.225-2.174-1.77-2.174-.544 0-.816.272-.816 1.63 0 1.292-1.253 2.332-2.059 2.867V12c.872.403 2.617 1 5.325 1h.872z"
                        fill="#6FCF97"
                      />
                    </svg>
                    <span className="deal_each_list_inbox_extra_won">
                      {"WON"}
                    </span>
                  </div>
                )}
                {eachStage.status !== 3 && (
                  <div
                  style={{
                    border:'1px solid #EB5757',
                    borderRadius:'20px',
                    display: "flex",
                    padding:'0px 6px',
                    alignItems: "center",
                    gap: "4px",
                  }}
                    className="px-2 d-flex justify-content-center align-items-center cursor-pointer each-deal-lost-action-button"
                    onClick={() => {
                      dealMarkAsWinLose(eachStage.id, 3);
                    }}
                  >
                    <svg
                      className="deal_each_list_inbox_extra_svg"
                      viewBox="0 0 14 13"
                      width="14"
                      height="13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.114.136h-1.361a.953.953 0 0 0-.953.951V7.34c0 .524.427.951.953.951h1.36a.953.953 0 0 0 .954-.951V1.087a.953.953 0 0 0-.953-.951zM2.787 0A2.173 2.173 0 0 0 .642 1.805l-.61 3.533a2.174 2.174 0 0 0 2.145 2.544h2.57s-.408.816-.408 2.175c0 1.63 1.225 2.174 1.77 2.174.543 0 .816-.272.816-1.63 0-1.292 1.252-2.332 2.058-2.867V1C8.112.597 6.367 0 3.658 0h-.871z"
                        fill="#EB5757"
                      />
                    </svg>
                    <span className="deal_each_list_inbox_extra_won">
                      {"LOST"}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="no-deal-div">
          <span>{`No ${activeTab} deal`}</span>
        </div>
      );
    }
  };

  const openRightSideEditModal = (deal) => {
    if (deal.status === 1) {
      let isTrue = false;
      if (deal.contact_id === Utils.getAccountData("userId")) {
        isTrue = true;
      } else if (Utils.getAccountData("userIsOwner")) {
        isTrue = true;
      } else if (Utils.getAccountData("userIsAdmin")) {
        isTrue = true;
      } else {
        if (props.collaborators.length > 0) {
          isTrue = props.collaborators.some(
            (item) => item.contact_id === Utils.getAccountData("userId")
          );
        }
      }

      if (isTrue) {
        setEditDeal(deal);
        setShowEditModal(true);
      } else {
        window.showNotification(
          "ERROR",
          "You can not add or update stage to other user lead"
        );
      }
    }
  };

  const openRightSideModal = () => {
    /* collaborator, team owner and account owner can */
    let isTrue = false;
    if (props.people.user_id === Utils.getAccountData("userId")) {
      isTrue = true;
    } else if (Utils.getAccountData("userIsOwner")) {
      isTrue = true;
    } else if (Utils.getAccountData("userIsAdmin")) {
      isTrue = true;
    } else {
      if (props.collaborators.length > 0) {
        isTrue = props.collaborators.some(
          (item) => item.user_id === Utils.getAccountData("userId")
        );
      }
    }

    if (isTrue) {
      setShowModal(true);
    } else {
      window.showNotification(
        "ERROR",
        "You can not add or update stage to other user lead"
      );
    }
  };

  return (
    <div className="" style={{ marginTop: "10px" }}>
      <CustomAccordionHeader
        onClick={() => setExpand(!expand)}
        className={`${expand ? "g-collapsible-header-for-deal" : "g-collapsible-header"}  ${expand ? "expand" : ""}`}
      >
        <span class={`${expand ? "g-header-title-for-deal" : "g-header-title"}`} >
          
            <svg
              width="23"
              height="16"
              fill={expand?"#fff":"#181f48"}
             

              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.829 11.196a.62.62 0 0 0 .057-.876l-.035-.039a.618.618 0 0 0-.426-.208h-.041a.618.618 0 0 0-.41.154l-.833.73-.759.666a.621.621 0 0 0-.056.875l.035.04c.227.257.62.282.877.056l.584-.51 1.007-.888z"
                
              />
              <path
                d="M5.83 12.892c-.018 0-.035 0-.052-.002a.765.765 0 0 1-.529-.26l-.035-.04a.77.77 0 0 1 .07-1.084l1.592-1.396a.774.774 0 0 1 1.087.07l.05.056a.769.769 0 0 1-.085 1.068l-1.592 1.397a.763.763 0 0 1-.507.19zm1.554-2.674a.473.473 0 0 0-.311.118L5.48 11.733a.473.473 0 0 0-.043.666l.035.04a.474.474 0 0 0 .667.043l1.592-1.397a.472.472 0 0 0 .043-.666l-.035-.04a.468.468 0 0 0-.324-.159l-.032-.002zm2.676 2.528a.62.62 0 0 0-.41.153l-.821.72a.62.62 0 1 0 .82.934l.822-.72a.62.62 0 0 0-.41-1.087z"
                
              />
              <path
                d="M9.24 14.858a.77.77 0 0 1-.509-1.347l.822-.72a.795.795 0 0 1 1.088.07.77.77 0 0 1-.071 1.086l-.822.72a.768.768 0 0 1-.508.191zm.821-1.96a.472.472 0 0 0-.313.115l-.821.72a.472.472 0 1 0 .624.71l.822-.719a.472.472 0 0 0-.312-.827zM2.733 1.363l-1.67 7.276 1.335.75 3.456-5.613-3.121-2.413z"
                
              />
              <path
                d="M2.448 9.587.89 8.714l1.747-7.612 3.41 2.637-3.6 5.848zM1.23 8.563l1.114.624L5.655 3.81 2.824 1.622 1.23 8.563zm4.666 1.786a.62.62 0 0 0 .057-.877l-.01-.012a.617.617 0 0 0-.427-.21h-.042a.618.618 0 0 0-.41.155l-.997.872a.62.62 0 0 0-.057.876.626.626 0 0 0 .888.07l.076-.067.76-.665.162-.142z"
                
              />
              <path
                d="M4.494 11.53c-.019 0-.038 0-.058-.003a.791.791 0 0 1-.537-.27.769.769 0 0 1 .07-1.086l.997-.874a.772.772 0 0 1 1.087.07.773.773 0 0 1-.06 1.1l-.996.872a.758.758 0 0 1-.503.19zm.98-2.127a.472.472 0 0 0-.312.12l-.997.872a.472.472 0 0 0-.043.667c.085.099.206.16.336.17a.463.463 0 0 0 .342-.114l.996-.873a.472.472 0 0 0 .044-.667.48.48 0 0 0-.336-.172l-.03-.003zM9.37 12.09a.616.616 0 0 0-.153-.45l-.01-.01a.617.617 0 0 0-.425-.208h-.041a.618.618 0 0 0-.41.154l-.097.086-1.008.883-.268.234a.63.63 0 0 0 .378 1.096.616.616 0 0 0 .451-.153l1.373-1.203a.619.619 0 0 0 .21-.429z"
                
              />
              <path
                d="M7.378 14.02c-.017 0-.034 0-.05-.002a.777.777 0 0 1-.468-1.357l1.371-1.204a.778.778 0 0 1 .559-.19.764.764 0 0 1 .528.26.772.772 0 0 1-.06 1.096l-1.373 1.203a.766.766 0 0 1-.507.193zm.952-2.253-1.274 1.117a.473.473 0 0 0-.045.666c.084.1.204.161.334.17a.469.469 0 0 0 .344-.117L9.062 12.4a.472.472 0 0 0 .043-.666.476.476 0 0 0-.334-.17H8.74a.467.467 0 0 0-.312.119l-.098.084z"
                
              />
              <path
                d="m17.591 10.104-4.842-3.94c-1.854.984-3.167.478-3.795.078a.706.706 0 0 1 .06-1.219l1.613-.855a2.443 2.443 0 0 0-.119-.016c-1.456.23-2.927.357-4.401.379L3.285 9.113l.63.478.739-.648c.513-.45 1.294-.4 1.745.113l.011.013c.148.17.246.376.285.597a1.23 1.23 0 0 1 1.613.21l.035.04c.217.246.326.568.304.894v.002c.057-.006.114-.008.17-.006.328.02.634.171.85.418l-.223.213.232-.203c.217.246.327.568.305.895l-.002.012a1.23 1.23 0 0 1 1.314 1.307 1.222 1.222 0 0 1-.205.595l1.327.764c.29.165.66.067.831-.22a.601.601 0 0 0-.18-.805l-2.113-1.461c-.002 0-.003-.003-.004-.005l-.142-.098a.307.307 0 0 1 .35-.505l2.98 2.06a.606.606 0 0 0 .842-.152.606.606 0 0 0-.148-.837l-2.965-2.1c-.002 0-.003-.003-.005-.004a.307.307 0 1 1 .357-.501l3.325 2.354a.643.643 0 0 0 .767-1.034l-3.577-2.573a.307.307 0 1 1 .357-.5l.245.174h.003l3.315 2.385.017.015.054.039.03.023.06.041a.642.642 0 0 0 .873-.12.63.63 0 0 0-.096-.879z"
                
              />
              <path
                d="M12.722 15.038a.75.75 0 0 1-.374-.1l-1.465-.844.09-.13a1.081 1.081 0 0 0-.836-1.678.73.73 0 0 0-.11.003l-.2.013.011-.17c.019-.24-.043-.48-.175-.68l-.112.098-.201-.219.113-.108a1.081 1.081 0 0 0-.647-.267.99.99 0 0 0-.13.004l-.16.012-.02-.176v-.006a1.084 1.084 0 0 0-.268-.775l-.035-.04a1.079 1.079 0 0 0-1.418-.183l-.19.127-.04-.225a1.092 1.092 0 0 0-1.796-.637l-.83.728-.835-.634 2.934-4.766.08-.002a31.237 31.237 0 0 0 4.378-.376l.02-.004.022.002c.04.003.083.01.127.016l.447.067-2.013 1.067a.558.558 0 0 0-.048.963c.59.376 1.85.867 3.644-.083l.088-.047L17.69 9.99a.776.776 0 0 1 .118 1.087.792.792 0 0 1-1.078.148l-.167-.125-3.554-2.555a.158.158 0 0 0-.221.036v.001a.158.158 0 0 0 .036.221l3.578 2.576a.791.791 0 0 1-.94 1.276L12.136 10.3a.16.16 0 0 0-.184.259l2.97 2.105c.336.24.418.703.184 1.042a.755.755 0 0 1-1.05.191l-2.979-2.061a.159.159 0 1 0-.18.26l2.26 1.565a.752.752 0 0 1-.23 1.348.783.783 0 0 1-.206.028zM11.3 13.991l1.196.69a.46.46 0 0 0 .492-.773l-1.688-1.17a1.373 1.373 0 0 1 0 1.253zm-.31-2.479c.092 0 .182.028.258.08l2.98 2.062a.457.457 0 0 0 .635-.116.457.457 0 0 0-.112-.631l-2.986-2.117a.455.455 0 0 1 .545-.731l3.325 2.355a.496.496 0 0 0 .59-.796l-3.573-2.57a.455.455 0 1 1 .53-.742l.249.176 3.386 2.439c.015.01.03.023.043.036l.043.027a.493.493 0 0 0 .671-.09.482.482 0 0 0-.07-.673l-4.767-3.878c-1.88.96-3.22.431-3.857.025a.854.854 0 0 1 .071-1.476l.912-.484c-.928.126-2.194.22-3.665.268l-2.708 4.4.426.323.648-.568a1.387 1.387 0 0 1 1.956.127c.124.14.22.305.279.482a1.378 1.378 0 0 1 1.63.337l.035.04c.206.233.327.529.342.839h.03c.32.02.624.153.857.373l.004-.004.081.092.009.01.093.106-.004.005c.16.218.251.48.262.75h.019c.132.008.262.036.386.081a.455.455 0 0 1 .447-.558v.001zm9.228-10.259-3.12 2.413 2.886 4.688a.27.27 0 0 1 .053.035.3.3 0 0 1 .085.188l.432.702 1.334-.75-1.67-7.276z"
                
              />
              <path
                d="m20.503 9.478-.525-.853-.003-.035a.154.154 0 0 0-.042-.097l-.057-.034-.017-.03-2.957-4.8L20.314.994l1.747 7.611-1.558.874zm-.238-.952.341.553 1.114-.624-1.593-6.943-2.831 2.19 2.797 4.542a.272.272 0 0 1 .05.039.443.443 0 0 1 .123.243zm-3.967-4.025a2.746 2.746 0 0 1-1.902.07l-1.638-.532a.894.894 0 0 0-.682.06l-.555.294-.018.01-2.2 1.166a.093.093 0 0 0-.053.081.084.084 0 0 0 .04.078c.647.413 1.755.713 3.342-.196a.309.309 0 0 1 .348.028l5.001 4.07c.07.058.133.123.188.194l1.236-1.236-2.635-4.28-.472.193z"
                
              />
              <path
                d="m18.155 10.039-.104-.131a1.024 1.024 0 0 0-.163-.17L12.885 5.67a.16.16 0 0 0-.178-.014c-1.652.947-2.813.627-3.497.192a.232.232 0 0 1-.108-.21.24.24 0 0 1 .132-.206l2.772-1.47a1.05 1.05 0 0 1 .798-.07l1.638.53a2.6 2.6 0 0 0 1.798-.064l.59-.242 2.763 4.487-1.438 1.437zm-5.371-4.703c.105 0 .207.036.289.102l5.001 4.07c.035.028.069.059.1.091l1.042-1.04-2.508-4.073-.354.145a2.895 2.895 0 0 1-2.005.072l-1.636-.53a.745.745 0 0 0-.568.05L9.457 5.65c.623.362 1.647.582 3.101-.252a.456.456 0 0 1 .226-.06z"
                
              />
            </svg>
          
          Deals{" "}
          <span className="g-header-count" style={{color:'black'}}>
            {contactStages != null ? contactStages.length : 0}
          </span>{" "}
        </span>
        <div className="g-header-action-content">
          <span className="g-add-task-button" onClick={toggleDrawer} style={{background:'white',height:'26px',width:'26px',display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'50%'}}>
            <AddIcon
              style={{
                fontSize:'14px',
                color: "var(--light_blue)",
              }}
            />
          </span>
          <span className={`${expand?"g-custom-caret-for-deal":"g-custom-caret"}`}>{icons.caretIcon}</span>
        </div>
      </CustomAccordionHeader>
      <CustomAccordionBody className="g-collapsible-body" expand={expand}>
        <React.Fragment>
          {contactStages !== undefined &&
            contactStages != null &&
            contactStages.length > 0 && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className="accordion__deal__expend d-flex justify-content-between mt-2">
                    <a
                      href="#!"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("open");
                      }}
                      class={`deals_btn_collapsible py-1 px-4  `}
                      style={{
                        backgroundColor:
                          `${activeTab}` === "open"
                            ? "var(--light_blue)"
                            : "var(--gray_dark)",
                        color: `${activeTab}` === "open" ? "white" : "black",
                        borderRadius: "6px",
                      }}
                    >
                      Open
                    </a>
                    <a
                      href="#!"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("won");
                      }}
                      class={`deals_btn_collapsible py-1 px-4 `}
                      style={{
                        backgroundColor:
                          `${activeTab}` === "won"
                            ? "var(--light_blue)"
                            : "var(--gray_dark)",
                        color: `${activeTab}` === "won" ? "white" : "black",
                        borderRadius: "6px",
                      }}
                    >
                      Won
                    </a>
                    <a
                      href="#!"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("lost");
                      }}
                      class={`deals_btn_collapsible py-1 px-4 `}
                      style={{
                        backgroundColor:
                          `${activeTab}` === "lost"
                            ? "var(--light_blue)"
                            : "var(--gray_dark)",
                        color: `${activeTab}` === "lost" ? "white" : "black",
                        borderRadius: "6px",
                      }}
                    >
                      Lost
                    </a>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  {contactStages != null && populateDeals()}
                </Grid>
              </Grid>
            )}
          {(contactStages === undefined ||
            contactStages == null ||
            contactStages.length === 0) && (
            <div className="task-list-wrapper">
              <div className="m-widget2 custom-list-container">
                No Deal Available
              </div>
            </div>
          )}
        </React.Fragment>
      </CustomAccordionBody>

      <DealDrawer
        state={state}
        setState={setState}
        selectedPipeline={selectedPipeline}
        pipelines={pipelines}
        setSelectedPipeline={setSelectedPipeline}
        selectedStorage={selectedStorage}
        setSelectedStorage={setSelectedStorage}
      />
      {showEditModal && (
        <RightSideModalEdit
          isOpen={showEditModal}
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
          url={"/user/update-contact-deal"}
          // toggle={toggleRightSideModalEdit}
          contact_id={props.contactId}
          // onModalFormSubmit={onModalFormSubmit}
          edit_deal={editDeal}
        />
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    contact: state.addContactReducer.contact,
  };
};

export default connect(mapStateToProps)(Deal);
