const checkEmptyJson = data => {
    if(JSON.stringify(data) === '{}'){
       return false
    }
   return true
}

const validateEmail = (email) => {
   //eslint-disable-next-line
   var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   return re.test(email);
}

const validateMobileNumber = (mobileNumber) => {
   if(mobileNumber !== '' && mobileNumber.length < 10){
       return false;
   }
   else if (mobileNumber !== '' && mobileNumber.length > 11){
       return false
   }else if(mobileNumber.length === 11 && mobileNumber.substring(0,1) !== 1){
       return false;
   }
   return true    
}

const checkLetterExistInText = (text) => {
   var regExp = /[a-zA-Z]/g;
   let result = false
   if(regExp.test(text)){
       result = true
   }
   return result
}

const formatPhoneNumber = (phoneNumberString) => {
   var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
   var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
   if (match) {
       var intlCode = (match[1] ? '+1 ' : '')
       return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
   }
   return phoneNumberString
}

const isEmpty = (text) => {
   if(text !== undefined && text!= null && text !== '' && text.trim() !== ''){
       return false
   }else{
       return true
   }
}

const isValidText = (text) => {
   if(text !== undefined && text!= null){
       return true
   }
   return false
}

const getAvaterText = (firstName, lastName) => {
   var FN = SendEmailHelper.isEmpty(firstName);
   var LN = SendEmailHelper.isEmpty(lastName);
   if (FN && LN) {
     return false;
   }
   else if (!FN && !LN) {
     return firstName.substring(0, 1).toUpperCase() + "" + lastName.substring(0, 1).toUpperCase()
   }
   else if (FN && !LN) {
     return lastName.substring(0, 2).toUpperCase()
   } else if (!FN && LN) {
     return firstName.substring(0, 2).toUpperCase()
   } else {
     return false;
   }
}

const getCookie = cname => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return atob(c.substring(name.length, c.length));
        }
    }
    return "";
}

const getCookieLogin = cname => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            let str = c.substring(name.length, c.length).replace(/\s/g, ''); 
            return decodeURIComponent(window.atob(str))
        }
    }
    return "";
}

const timeIntervalOptionsView = (interval) => {
  let option = [];
  for (let ap = 1; ap <= 2; ap++) {
    for (let i = 0; i < 12; i++) {
      for (let j = 0; j < 60 / interval; j++) {
        let hv = i;
        let h = i;
        if (i === 0) h = 12;
        if (ap !== 1) hv = hv + 12;
        option.push(
          <option
            value={("0" + hv).slice(-2) + ":" + ("0" + interval * j).slice(-2)}
            data-content="<i className='fa fa-address-book-o' aria-hidden='true'></i>Option1"
          >
            {("0" + h).slice(-2) +
              ":" +
              ("0" + interval * j).slice(-2) +
              " " +
              (ap === 1 ? "AM" : "PM")}
          </option>
        );
      }
    }
  }
  return option;
};

const timeIntervalOptions = (interval) => {
  let option = [];
  for (let ap = 1; ap <= 2; ap++) {
    for (let i = 0; i < 12; i++) {
      for (let j = 0; j < 60 / interval; j++) {
        let hv = i;
        let h = i;
        if (i === 0) h = 12;
        if (ap !== 1) hv = hv + 12;
        option.push({
          label: ("0" + hv).slice(-2) + ":" + ("0" + interval * j).slice(-2),
          value:
            ("0" + h).slice(-2) +
            ":" +
            ("0" + interval * j).slice(-2) +
            " " +
            (ap === 1 ? "AM" : "PM"),
          id: ap + "-" + i + "-" + j,
        });
      }
    }
  }
  return option;
};

const calculateCash = (amount, fixedCount = 2) => {
    var flag = ''
    // 1000 = 1K
    // 1000000 = 1M = 1K * 1000
    // 1M * 1000 = 1B
    // 1B * 1000 = 1T
    if (amount >= 1000000000000000000000000) {
      amount = amount / 1000000000000000000000000
      flag = 'Y'
    }
    if (amount >= 1000000000000000000000) {
      amount = amount / 1000000000000000000000
      flag = 'Z'
    }
    if (amount >= 1000000000000000000) {
      amount = amount / 1000000000000000000
      flag = 'E'
    }
    if (amount >= 1000000000000000) {
      amount = amount / 1000000000000000
      flag = 'P'
    }
    if (amount >= 1000000000000) {
      amount = amount / 1000000000000
      flag = 'T'
    }
    else if (amount >= 1000000000) {
      amount = amount / 1000000000
      flag = 'B'
    }
    else if (amount >= 1000000) {
      amount = amount / 1000000
      flag = 'M'
    }
    else if (amount >= 1000) {
      amount = amount / 1000
      flag = 'K'
    }
    // return '$' + (Math.round(totalDeal * 100) / 100) + flag
    if(amount != null){
        return '$' + amount.toFixed(fixedCount) + flag
    }
    return '$' + amount + flag
}


/**
 * Function name: getAccountData
 * This function will provide
 * AuthUserAgencyName
 * packageType
 * packageType
 * starterPack
 * packageType
 * starterProPack
 * packageType
 * standardPack
 * packageType
 * starterPack
 * starterProPack
 * standardPack
 * baseUrl
 * userIsAgent
 * userIsOwner
 * userIsAdmin
 * button_text_transform_class
 * userTimezone
 * userTimezoneRaw
 * userId
 * agencyId
 * canvaApiKey
 */
 export const getAccountData = (needle) => {
    let accountData = null;
    let check_new = getCookie("encode__process__new")
    if(check_new !== undefined){
        accountData = getCookieLogin(process.env.REACT_APP_ACCOUNT_DATA);
    }else{
        accountData = getCookie(process.env.REACT_APP_ACCOUNT_DATA);
    }
    if (accountData) {
        accountData = JSON.parse(accountData);
        return accountData[needle];
    }
    return false;
}

const SendEmailHelper = {
   checkEmptyJson,
   validateEmail,
   validateMobileNumber,
   checkLetterExistInText,
   formatPhoneNumber,
   isEmpty,
   isValidText,
   getAvaterText,
   getCookie,
   timeIntervalOptions,
   timeIntervalOptionsView,
   getAccountData,
   calculateCash,
   getCookieLogin
}
export default SendEmailHelper;