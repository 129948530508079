import { Box, Button, Paper, Typography, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { RightSideStyle } from "../RightSideStyle";
import CustomTitle from "../common/CustomTitle";
import Styles from "../common/customTitle.module.css";
import EditAddressModal from "./EditAddressModal";
import { icons } from "../../icons.js"
import ListIcon from '@material-ui/icons/List';
import CustomAccordion, { CustomAccordionBody, CustomAccordionHeader } from "../../components/Accordion/CustomAccordion";
import {updateMultipleData} from "../../../../actions/contactAction";
import {getState} from "../../../../helpers/Utils"
import {
  CheckCircle as CheckCircleIcon
} from "@material-ui/icons";


const useStyles = makeStyles({
  hoverDiv: {
    "&:hover": {
      "& $icon": {
        opacity: 1,
        transition: "width .35s",
        width: "42px",
      },
    },
  },
  icon: {
    opacity: 0,
    width: 0,
  },
});

const Address = (props) => {


  const { contact } = props;

  const [open, setOpen] = useState(false);
  const [openSkipTrace, setOpenSkipTrace] = useState(false);

  const { flexCenterBetween, textDarkBlue, warningButtonRounded, heighFit } = RightSideStyle();

  const hoverIcon = useStyles();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const lastSkipTraceDate = (fDate) => {
    try{
      if (window.globalTimezoneConversionToDifferentTimezone && fDate){
        fDate = window.globalTimezoneConversionToDifferentTimezone(fDate,'UTC','','MM-DD-YY');

        return fDate;
      }

      return "Traced";
    }catch (err){
      return "Traced";
    }
  }

  const addressContent = [
    contact?.city,
    getState(contact?.state) || contact?.state,
    contact?.zip,
  ]
      .filter(Boolean)
      .join(", ");

  return (
   
     
       
     <Box className={hoverIcon.hoverDiv} sx={{mt:2}}>
      <Box sx={{ gap: 10 }} mb={2}>
        <CustomTitle
          handleOpen={handleOpen}
          title={"ADDRESS"}
          iconHide={hoverIcon.icon}
        />
      </Box>

      <Box sx={{ display: "flex" }}>
        {
          contact?.address && <Paper
          variant="outlined"
          elevation={0}
          style={{ padding: "15px 6px 15px 8px", width: "100%" }}
        >
          <Box
            className={flexCenterBetween}
            sx={{ alignItems: "normal !important", display: "flex" }}
          >
            <Box sx={{ height: "auto !important" }}>
              {contact?.address && (
                  <Typography variant='body1' className={`${textDarkBlue} ${heighFit}`}>
                    {contact?.address}
                  </Typography>
              )}

              <Typography variant='body1' className={`${textDarkBlue} ${heighFit}`}>
                {addressContent}
              </Typography>
            </Box>

            <Box sx={{ height: "auto !important" }}>
              {props.contact?.contact_additional_informations &&
              props.contact.contact_additional_informations?.skip_trace_status === "TRACED" ? (
                  <Button
                      className={`${Styles.buttonStyle} ${Styles.tracedButton}`}
                      variant='contained'
                      color='primary'
                      size='small'
                      disableElevation
                      onClick={() => {}}
                      startIcon={<CheckCircleIcon />}
                  >
                    {lastSkipTraceDate(props.contact?.contact_additional_informations.skip_trace_date)}
                  </Button>
              ) : (
                  <Button
                      className={`${Styles.buttonStyle} skipTraceButton`}
                      variant='contained'
                      color='primary'
                      size='small'
                      disableElevation
                      onClick={() => {
                        setOpenSkipTrace(true);
                      }}
                  >
                    SkipTrace
                  </Button>
              )}
            </Box>
          </Box>
        </Paper>
        }
      </Box>
      {open && <EditAddressModal open={open} onClose={handleClose} />}
    </Box>
         
  
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.addContactReducer.contact,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateMultipleData: (params) => dispatch(updateMultipleData(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Address);
