import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const DetailsSkeleton = () => {
  return (
    <Box padding="16px" bgcolor="#f4f6fc" borderRadius="8px">
      <Grid container spacing={2}>
        <Grid item xs={12} container justify="space-between" alignItems="center">
          <Grid item style={{display:'flex'}}>
            <Skeleton variant="circle" width={40} height={40} />
            <Skeleton variant="circle" width={40} height={40} style={{ marginLeft: 4 }} />
            <Skeleton variant="circle" width={40} height={40} style={{ marginLeft: 4 }} />
            <Skeleton variant="circle" width={40} height={40} style={{ marginLeft: 4 }} />
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Skeleton variant="rect" height={400} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rect" height={400} />
        </Grid>

        
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={6}>
            <Skeleton variant="rect" height={50} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="rect" height={50} />
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={6}>
            <Skeleton variant="rect" height={50} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="rect" height={50} />
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={6}>
            <Skeleton variant="rect" height={50} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="rect" height={50} />
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={6}>
            <Skeleton variant="rect" height={50} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="rect" height={50} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DetailsSkeleton;
