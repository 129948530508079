import {useContext} from 'react';
import CustomSelect from '../components/CustomSelect';
import { updateFromEmail } from '../reducers/Action';
import { EmailSendContext } from "../reducers/GlobalReduers"
import { MenuItem } from "@material-ui/core";

const FromEmail = (props) => {
    const {states, dispatch} = useContext(EmailSendContext)

    const hanldeFromEmail = (value) => {
        dispatch(updateFromEmail(value))
    }

    return(
        <div className="g-from-email-content email_send_row">
            <h3 className="g-from-email-text">From : </h3>
            <CustomSelect
                formControlClass="g-from-email-single-select"
                value={states.selectedFromEmail}
                handleChange={(event) => hanldeFromEmail(event.target.value)}
                placeholder={(<MenuItem className="dropdownhelper-menuitem-class" value="" disabled> Select a from email </MenuItem>)}
                item={states.allFromEmails}
                titel_field={'label'}
                disabled={states.nylasActive || states.threadData?.from}
                menuItemClass="dropdownhelper-menuitem-class"
                renderValue={(selected) => {
                    if (selected === '') {
                        return <em>Select a from email</em>;
                    }
                    return selected.label
                }}
            />
        </div>
    )
}
export default FromEmail;