// Global Floating sidebar
export const FETCH_USER_ACTIVITY = 'FETCH_USER_ACTIVITY'
export const FETCH_USER_ACTIVITY_SUCCESS = 'FETCH_USER_ACTIVITY_SUCCESS'
export const FETCH_USER_ACTIVITY_FAIL = 'FETCH_USER_ACTIVITY_FAIL'

export const SET_SIDEBAR_SCHEDULE_TYPE = 'SET_SIDEBAR_SCHEDULE_TYPE'
export const SET_SIDEBAR_PAGE = 'SET_SIDEBAR_PAGE'
export const RESET_SIDEBAR_PAGE = 'RESET_SIDEBAR_PAGE'

export const FETCH_USER_INBOX = 'FETCH_USER_INBOX'
export const FETCH_USER_INBOX_SUCCESS = 'FETCH_USER_INBOX_SUCCESS'
export const FETCH_USER_INBOX_FAIL = 'FETCH_USER_INBOX_FAIL'

export const FETCH_SIDEBAR_NOTIFICATION = 'FETCH_SIDEBAR_NOTIFICATION'
export const FETCH_SIDEBAR_NOTIFICATION_SUCCESS = 'FETCH_SIDEBAR_NOTIFICATION_SUCCESS'
export const FETCH_SIDEBAR_NOTIFICATION_FAIL = 'FETCH_SIDEBAR_NOTIFICATION_FAIL'