import axios from "axios";

export const getCookie = (cName) => {
    const name = cName + "=";
    const cookie = document.cookie.split(";");
    for(let i = 0; i < cookie.length; i++) {
        let c = cookie[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return atob(c.substring(name.length, c.length));
        }
    }
    return "";
}

const responseBody = (response) => {
    return response.data;
};

const errorResponseBody = (error) => {
    if (error.response) {
        return error.response;
    } else if (error.request) {
        console.log(error.request);
    } else {
        console.log("Error: axios ", error.message);
    }
};

const HttpRequest = {
    get: (url = "", params = {}) =>
        axios
            .get(url, {
                params: { ...params, "api-key": getCookie(process.env.REACT_APP_ACCESS_TOKEN) },
                headers: {
                    Authorization: `Bearer ${getCookie(process.env.REACT_APP_ACCESS_TOKEN)}`,
                },
            })
            .then(responseBody)
            .catch(errorResponseBody),

    post: (url = "", body = {}) =>
        axios
            .post(url, { ...body, "api-key": getCookie(process.env.REACT_APP_ACCESS_TOKEN) }, {
                headers: {
                    Authorization: `Bearer ${getCookie(process.env.REACT_APP_ACCESS_TOKEN)}`,
                },
            })
            .then(responseBody)
            .catch(errorResponseBody),

    put: (url = "", body = {}) =>
        axios
            .put(url, { ...body, "api-key": getCookie(process.env.REACT_APP_ACCESS_TOKEN) }, {
                headers: {
                    Authorization: `Bearer ${getCookie(process.env.REACT_APP_ACCESS_TOKEN)}`,
                },
            })
            .then(responseBody)
            .catch(errorResponseBody),

    delete: (url = "", params = {}) =>
        axios
            .delete(url, {
                params: { ...params, "api-key": getCookie(process.env.REACT_APP_ACCESS_TOKEN) },
                headers: {
                    Authorization: `Bearer ${getCookie(process.env.REACT_APP_ACCESS_TOKEN)}`,
                },
            })
            .then(responseBody)
            .catch(errorResponseBody),
};

export default HttpRequest;