import React from "react";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

const SidebarItemInsurance = ({ menuCollapsed, gotoUrl, selectedSidebarItem, inboxCount, item: { module, icon: Icon, label, url, visible, sub_menus, target = undefined, state = null, search = null, showUnreadCount = false } }) => {

    const showUnread = () => {

        if (showUnreadCount && inboxCount > 0) {
            return (
                <>
                    {label}
                    <div className="sidebar-count-chip">
                        {inboxCount > 10 ? "10+" : inboxCount}
                    </div>
                </>
            )
        }
        return label;
    }
    return (
        <BootstrapTooltip
            title={menuCollapsed ? label : ""}
            arrow
            placement="right"
        >
            <ListItem
                button
                selected={selectedSidebarItem === module}
                onClick={() => gotoUrl(url, module, state, search, target)}
                className="sidebarItem"
            >
                <ListItemIcon>
                    <Icon color={"var(--sidebar-icon-color)"} />
                </ListItemIcon>
                <ListItemText primary={showUnread()} />
            </ListItem>
        </BootstrapTooltip>
    );
};

export default SidebarItemInsurance;