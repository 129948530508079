import HttpRequest from "./HttpRequest";
import Api from "../constants/Api";
import {sendRequest} from "./rootApi";

export const getUserPolicies = (payload) => {
   return HttpRequest.get(Api.userPolicies, payload);
};

export const getUserContactStageProducts = (payload) => {
  return HttpRequest.get(`${Api.userContactStageProducts}/${payload}`);
};

export const updateSuccessDealPolicy = (payload) => {
  return HttpRequest.put(Api.updateSuccessDealPolicy, payload);
};

export const stagesByPipeline = (payload) => {
  return sendRequest("POST", Api.stagesByPipeline, payload);
};

export const getActiveProductList = (payload) => {
  return HttpRequest.get(Api.activeProductList, payload);
}