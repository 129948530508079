import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ThankYou from "./ThankYou";
import Unsubscribe from "./Unsubscribe";
import YouLost from "./YouLost";
import Loading from "./Loading";
import Unsubscribed from "./Unsubscribed";

const ContactEmailUnsubscribe = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const showThankYou = params.get("thank_you");
  const unsubscribed = params.get("unsubscribed");
  const [tab, setTab] = useState(-1);

  useEffect(() => {
    if (showThankYou) {
      setTab(1);
    } else if (unsubscribed) {
      setTab(2);
    } else {
      setTab(0);
    }
  }, [search]);

  if (tab === -1) {
    return <Loading />;
  } else if (tab === 0) {
    return <Unsubscribe />;
  } else if (tab === 1) {
    return <ThankYou />;
  } else if (tab === 2) {
    return <Unsubscribed />;
  } else {
    return <YouLost />;
  }
};

export default ContactEmailUnsubscribe;
