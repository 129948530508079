import React, {useState} from "react";
import { Box, Typography } from "@material-ui/core";

import { RightSideStyle } from "../../RightSideStyle";

const Conversation = (props) => {
  const {  alignJustifyCenter, textFontSize } = RightSideStyle();

  

  return (
    <Box className={alignJustifyCenter} sx={{ color: "white" }}>
    
        <Typography variant='body2' className={textFontSize}>
            Last contact: {props?.lastCommunicationMessage}
        </Typography>
     
    </Box>
  );
};



export default Conversation;
