import React, {  useState, useEffect } from "react";
import { Box, Button, FormControl, InputBase, MenuItem, Select, Tooltip, Typography } from "@material-ui/core";
import BootstrapTooltip from "../../../../globals/BootstrapTooltip";
import Styles from "./leadstatus.module.css";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {getSubUserList, setSubUserListFromAction} from "../../../../../actions/contactAction";
import {
  changeLeadOwner,
  getSubUserListForContactlist,
  receiveBulkContactPermissionRequest
} from "../../../../../api/contactApi";
import Utils from "../../../../../helpers/Utils";
import NewConfirmAlert from "../../../alert/NewConfirmAlert";
import {leadPermission} from "../../../../../constants/CoreConstants";


const BasicInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "&.MuiInputBase-multiline": {
      padding: "0 !important",
    },
  },
  input: {
    position: "relative",
    fontSize: "14px !important",
    margin: "0 !important",
    height: "2rem !important",
    padding: "0px 24px 4px 10px !important",
    borderRadius: "4px !important",
    background: "white !important",
    border: "1px solid #ced4da !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&.MuiSelect-select:focus": {
      background: "white !important",
    },
    "&.MuiSelect-selectMenu .subtitle": {
      display: "none",
    },
    "&.MuiSelect-selectMenu .selectedTitle": {
      overflow: "hidden",
      marginTop:'9px',
      textOverflow: "ellipsis",
      "@media(minWidth: 1300px)": {
        width: "100%",
      },
    },

    "&:focus": {
      borderRadius: 4,
      background: "white !important",
      boxShadow: "unset !important",
    },
  },
}))(InputBase);

const LeadOwner = (props) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subUserList, setSubUserList] = useState(null);
  const [ownerInfo, setOwnerInfo] = useState({});
  const [name, setName] = useState(ownerInfo);

  useEffect(() => {
    loadTeamUserList();
  }, []);

  useEffect(()=>{

    if (subUserList && props.contactDetails !== undefined && props.contactDetails !== null) {
      let newOwner = subUserList.find((user) => user.id === props.contactDetails.user_id);
      if (newOwner) {
        updateOwnerInfos(subUserList, props.contactDetails.user_id, true);
      }
    }
  },[subUserList, props.contactDetails])

  const loadTeamUserList = () => {
    if (props.subUserList != null) {
      updateOwnerInfos(props.subUserList, props.contact.user_id, true);
    } else {
      getSubUserListForContactlist()
          .then((response) => {
            props.setSubUserList(response.data.data);
            setSubUserList(response.data.data);
          })
          .catch((error) => {
            console.log("something is wrong" + error);
          });
    }
  };

  const updateOwnerInfos = (tempSubUserList, ownerId, firstLoad = false) => {
    if (ownerId === Utils.getAccountData("userId") || Utils.getAccountData("userIsOwner")) {
      setOwnerInfo({ id: ownerId });
      setShow(true);
    } else {
      if (tempSubUserList != null) {
        for (let j = 0; j < tempSubUserList.length; j++) {
          if (tempSubUserList[j].id === ownerId) {
            setOwnerInfo({ id: ownerId, full_name: tempSubUserList[j].full_name });
            break;
          }
        }
      }
      setShow(true);
    }
    if (firstLoad) {
      setSubUserList(tempSubUserList);
    }
  };

  const handleSubmit = (user_id) => {
    setName(user_id);
    if (user_id != "_claimLead_") {
      if (props.contactDetails.user_id === Utils.getAccountData("userId") || Utils.getAccountData("userIsOwner")) {
        setLoading(true);
        changeLeadOwner({
          contact_id: props.contact.id,
          user_id: user_id,
        })
            .then((res) => {
              if (res.status === 200) {
                try {
                  window.showNotification("SUCCESS", "Lead Owner Successfully Changed");
                  updateOwnerInfos(subUserList, user_id);
                } catch (e) {
                  console.log(e);
                }
              } else {
                try {
                  window.showNotification("ERROR", "Something was wrong, Please reload the page and try again");
                } catch (e) {
                  console.log(e);
                }
              }
            })
            .finally(() => {
              setLoading(false);
            });
      } else {
        window.showNotification("ERROR", "You cannot change lead owner");
      }
    } else {
      let message = "Your are going to send a request to lead/team owner to claim lead";
      if (Utils.getAccountData("userIsOwner")) {
        message = "As a team owner, you can claim lead ownership.";
      }

      NewConfirmAlert({
        onSubmit: () => {
          receiveBulkContactPermissionRequest({
            requestType: leadPermission.REQUEST_TYPE_CHANGE_OWNER,
            contactIds: [props.contactDetails.id],
            requestedForUserIds: [Utils.getAccountData("userId")],
            filter: ["no-filter"],
            from: "claim-lead",
          })
              .then((res) => {
                if (res.success) {
                  window.showNotification("SUCCESS", res.message);
                } else {
                  window.showNotification("ERROR", res.message);
                }
              })
              .catch((err) => {
                window.showNotification("ERROR", "Something went wrong. Please try again later!");
              });
        },
        title: "Are your sure?",
        description: message,
        cancelText: "No",
        submitText: "Yes",
        width: "480px",
      });
    }
  };

  return (
    <Box className={Styles.leadOwnerBoxWidth}>
          <FormControl fullWidth sx={{ marginLeft: 2 }}>
            <Select
              key={ownerInfo?.id}
              value={ownerInfo?.id}
              displayEmpty
              onChange={(event) => handleSubmit(event.target.value)}
              input={<BasicInput className='basicinputbackground' />}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem className='dropdownhelper-menuitem-class' value='default' disabled>
                <Typography variant='body1' color='textSecondary' style={{ lineHeight: 1 }}>
                  Lead Owner
                </Typography>
              </MenuItem>
              {subUserList?.map((subUser) => (
                  <MenuItem key={subUser.id} className='dropdownhelper-menuitem-class' value={subUser.id}>
                    <BootstrapTooltip title={subUser.full_name}>
                      <Typography
                          className={`${Styles.leadOwnerTextWidth} selectedTitle`}
                          variant='body1'
                          color='textSecondary'
                          style={{ lineHeight: 1}}
                      >
                        {" "}
                        {subUser.full_name}
                      </Typography>
                    </BootstrapTooltip>
                  </MenuItem>
              ))}
            </Select>
          </FormControl>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.addContactReducer.userInfo,
    subUserList: state.addContactReducer.subUserList,
    contact: state.addContactReducer.contact,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    setSubUserList: (params) => dispatch(setSubUserListFromAction(params)),
    getAllSubUsers: (params, callback) => dispatch(getSubUserList(params, callback)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LeadOwner);
