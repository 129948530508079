import React from 'react';
import { Skeleton } from '@material-ui/lab';
const DailyGoalSkeleton = () => {
    
    return (
    
        <div style={{width:'100%',backgroundColor:'white',padding:'12px'}}>
            <div style={{display:'flex',justifyContent:'space-between', width:'100%'}}>
                <div style={{width:'100%',display:'flex',flexDirection:'column',gap:'12px'}}>
                    <Skeleton variant="rect" width={'60%'} height={20} />
                    <Skeleton variant="rect" width={'80%'} height={20} />
                </div>
                <div style={{width:'100%',display:'flex',gap:'12px',justifyContent:'end'}}>
                    <Skeleton variant="rect"  width={80} height={20} />
                    <Skeleton variant="rect"  width={80} height={20} />
                </div>
            </div>
            <div style={{ display: "flex",justifyContent:'space-between',marginTop:'12px' }}>
                <div style={{width:'50%',display:'flex', flexDirection:'column',gap:'20px'}}>
                    <Skeleton variant="rect" width={'100%'} height={110} style={{borderRadius:'6px'}}/>
                    <Skeleton variant="rect" width={'100%'} height={110} style={{borderRadius:'6px'}}/>
                </div>
                <div style={{width:'50%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <Skeleton variant="circle" width={'245px'} height={'245px'} />
                </div>
            </div>
            <div style={{ display: "flex",justifyContent:'space-between',marginTop:'20px',gap:'20px' }}>
                <div style={{width:'50%',display:'flex', flexDirection:'column'}}>
                    <Skeleton variant="rect" width={'100%'} height={110} style={{borderRadius:'6px'}}/>
                   
                </div>
                <div style={{width:'50%',display:'flex', flexDirection:'column'}}>
                <Skeleton variant="rect" width={'100%'} height={110} style={{borderRadius:'6px'}}/>
                </div>
            </div>
        </div>
    
    );
};

export default DailyGoalSkeleton;
