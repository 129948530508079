import { PHONE_STATE } from "../../constants/CoreConstants";
import { getCookie, getCookieLogin } from "./Cookie";
import { US_STATE_LIST } from "./CoreConstants";

/**
 * Handle error exception
 * 
 * @param Error error 
 * @param Function/null callback 
 */
const handleException = (error, callback = null) => {
    console.error(error);

    // to do
}

/**
 * Get contact's display name
 * 
 * @param string name 
 * @param string number 
 * @param string email 
 * @returns string
 */
const getContactDisplayName = (name, number, email) => {
    if (name === '' || name === ' ' || name === null || name === 'null null') {
        if (number === '' || number === ' ') {
            if (email === '' || email === ' ') {
                return '[No Name]';
            } else {
                return email.length > 15 ? email.substr(0, 13)+' ...' : email;
            }
        } else {
            return number;
        }
    }

    return name.length > 15 ? name.substr(0, 13)+' ...' : name;
}

/**
 * Display notification
 * 
 * @param string message 
 * @param string type 
 * @param string/null title 
 * @param boolean sticky 
 */
const showNotification = (message = 'Something went wrong', type = 'error') => {
    // alert(message)
};

const countSms = (message) => {
    const charset7bit = ['£', '@', 'Â£', '$', 'Â¥', 'Ã¨', 'Ã©', 'Ã¹', 'Ã¬', 'Ã²', 'Ã‡', "\n", 'Ã˜', 'Ã¸', "\r", 'Ã…', 'Ã¥', 'Î”', '_', 'Î¦', 'Î“', 'Î›', 'Î©', 'Î ', 'Î¨', 'Î£', 'Î˜', 'Îž', 'Ã†', 'Ã¦', 'ÃŸ', 'Ã‰', ' ', '!', '"', '#', 'Â¤', '%', '&', "'", '(', ')', '*', '+', ',', '-', '.', '/', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':', ';', '<', '=', '>', '?', 'Â¡', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'Ã„', 'Ã–', 'Ã‘', 'Ãœ', 'Â§', 'Â¿', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'Ã¤', 'Ã¶', 'Ã±', 'Ã¼', 'Ã '];
    const charset7bitext = ["\f", '^', '{', '}', '\\', '[', '~', ']', '|', 'â‚¬'];
    const chars_arr = message.split("");
    let coding = '7bit';
    let parts = 1;
    let part = 1;
    let chars_used = 0;
    let chars_sms = 160;
    const charsLength = chars_arr.length;

    for (let i = 0; i < charsLength; i++) {
        if (charset7bit.includes(chars_arr[i])) {
            chars_used += 1;
        } else if (charset7bitext.includes(chars_arr[i])) {
            chars_used += 2;
        } else {
            coding = '16bit';
            chars_used = charsLength;
            break;
        }
    }

    if (coding === '7bit') {
        if (chars_used > 160) {
            parts = Math.ceil(chars_used / 153);
            let part_chars_used = 7;
            chars_sms = 153;
            for (let i = 0; i < charsLength; i++) {
                if (part_chars_used + 1 > 160) {
                    part += 1;
                    part_chars_used = 7;
                }
                if (charset7bitext.includes(chars_arr[i]) && part_chars_used + 2 > 160) {
                    part += 1;
                    part_chars_used = 7;
                }
                if (charset7bitext.includes(chars_arr[i])) {
                    part_chars_used += 2;
                } else {
                    part_chars_used += 1;
                }
            }
        } else {
            chars_sms = 160;
        }
    } else {
        if (chars_used > 70) {
            parts = Math.ceil(chars_used / 67);
            let part_chars_used = 3;
            chars_sms = 67;
            for (let i = 0; i < charsLength; i++) {
                if (part_chars_used + 1 > 70) {
                    part += 1;
                    part_chars_used = 3;
                }
                part_chars_used += 1;
            }
        } else {
            chars_sms = 70;
        }
    }

    return {
        sms: parts,
        length: chars_used,
        chars_per_sms: chars_sms,
    };
}

/**
 * Get initial of name
 * 
 * @param string name 
 * @returns string
 */
const getInitial = (name) => {
    try {
        if (typeof name != 'undefined') {
            let fullNameInitial = 'X'
            if(name === 'null null' || name === '' || name === ' '){
                fullNameInitial = 'X';
            }else if(name !== 'undefined' || name !== null){
                fullNameInitial = name.split(" ").map((n,i,a)=> i === 0 || i+1 === a.length ? n[0] : null).join("");
            }
            return fullNameInitial;
        } else {
            return 'X';
        }
    } catch (error) {
        return 'X';
    }
}

const abbreviateNumber = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
}

const getContactTitle = (contact) => {
    let contactName = contact['first_name'] + ' ' + contact['last_name']
    let contactTitle = (contactName.length > 1 ) ? contactName : contact['email'];

    return contactTitle.length > 1 ? contactTitle : contact['number'];
}

const generateTimezoneList = ($tzone = null) => {
    let timezone =  [
        {'America/New_York': 'Eastern Time'},
        {'America/Chicago': 'Central Time'},
        {'America/Denver': 'Mountain Time'},
        {'America/Phoenix': 'Arizona Time'},
        {'America/Los_Angeles': 'Pacific Time'},
        {'America/Anchorage': 'Alaska Time'},
        {'Pacific/Honolulu': 'Hawaii Time'},
    ];

    if ($tzone) {
        
    }


    return timezone;
}

/**
 * Utility helper
 */
 const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '')
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return phoneNumberString
}
const validateEmail = (email) => {
   //eslint-disable-next-line 
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

const validateMobileNumber = (mobileNumber) => {

    if(mobileNumber !== '' && mobileNumber.length < 10){
        return false;
    }
    else if (mobileNumber !== '' && mobileNumber.length > 11){
        return false
    }else if(mobileNumber.length === 11 && mobileNumber.substring(0,1) != 1){
        return false;
    }
    return true    
}

const checkLetterExistInText = (text) => {
    var regExp = /[^0-9]+/g;
    let result = false
    if(regExp.test(text)){
        
        result = true
    }
    return result
}

/**
 * Function name: getAccountData
 * This function will provide
 * AuthUserAgencyName
 * packageType
 * packageType
 * starterPack
 * packageType
 * starterProPack
 * packageType
 * standardPack
 * packageType
 * starterPack
 * starterProPack
 * standardPack
 * baseUrl
 * userIsAgent
 * userIsOwner
 * userIsAdmin
 * button_text_transform_class
 * userTimezone
 * userTimezoneRaw
 * userId
 * agencyId
 * canvaApiKey
*/
export const getAccountData = (needle) => {
    let accountData = null;
    let check_new = getCookie("encode__process__new")
    if(check_new !== undefined){
        accountData = getCookieLogin(process.env.REACT_APP_ACCOUNT_DATA);
    }else{
        accountData = getCookie(process.env.REACT_APP_ACCOUNT_DATA);
    }
    if (accountData) {
        accountData = JSON.parse(accountData);
        return accountData[needle];
    }
    return false;
}
export const getState = (value) => US_STATE_LIST.find((state) => state.value === value)?.label;
export const setNumberType = (value) => {
    try{
      let list = [PHONE_STATE.mobile, PHONE_STATE.landline, PHONE_STATE.voip, PHONE_STATE.unknown];
  
      if (value && typeof value === "string"){
        value = value.toLowerCase();
      }
  
      if (value && list.includes(value)){
        return value
      }
  
      return PHONE_STATE.unknown;
    }catch (er){
      return PHONE_STATE.unknown;
    }
  }

const Utils = {
    handleException,
    getContactDisplayName,
    getInitial,
    showNotification,
    abbreviateNumber,
    getContactTitle,
    generateTimezoneList,
    countSms,
    formatPhoneNumber,
    validateEmail,
    validateMobileNumber,
    checkLetterExistInText,
    getAccountData,
    getState,
    setNumberType
}



export default Utils;