import React, {useEffect, useState} from "react";
import GlobalSmsSendModule from "../SendComponents/Sms";

const SmsSendInitialState = {
    /* props data */
    // open: false,
    contactId: null,
    id: null,
    contactDetails: null,
    contactInfo: null,
    from: '',
    open:false,
    // onClose: null,
    relationSuperContactId: null,
    sendSuccessCallBack: () => {}
};


const SmsSendModule = (props) => {
    const [smsSendState, setSmsSendState] = useState(SmsSendInitialState);

    useEffect(()=>{

        let info = props.info;
            setSmsSendState({
                ...smsSendState,
                // open: info.open,
                contactId: info.id,
                id: info.id,
                contactDetails: info.contactInfo,
                contactInfo: info.contactInfo,
                relationSuperContactId: info.relationSuperContactId,
                from: info.from,
                // onClose: info.onClose,
                sendSuccessCallBack: typeof info.sendSuccessCallBack === 'undefined' ? SmsSendInitialState.sendSuccessCallBack : info.sendSuccessCallBack,
                open: true
            });
    },[])

    const closeSmsSendModal = () => {
        props.closeModal()
    }

    return (
        <React.Fragment>
            {smsSendState.open &&
                <GlobalSmsSendModule
                    open={smsSendState.open}
                    onClose={closeSmsSendModal}
                    {...smsSendState}
                />
            }
        </React.Fragment>
    );
}
export default SmsSendModule;