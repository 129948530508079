import React, { useEffect, useState, useContext} from "react";
import ApiList from "../../ApiList";
import LinkFile from "../../LinkFile";
import { ContactDetailContext } from "../../reducer/GlobalReduers";
import EditIcon from '@material-ui/icons/Edit';
import BasicInput from "../../../custom/BasicInput";

const UpdateContactInfo = (props) => {
    const {detailStates} = useContext(ContactDetailContext)
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [country, setCountry] = useState('')
    const [company, setCompany] = useState('')
    const [zipCode, setZipCode] = useState(0)
    const [submitUpdating, setSubmitUpdating] = useState(false)

    useEffect(() => {
      if(props.open){
        setAddress(props.data.address)
        setCompany(props.data.company_name)
        setCity(props.data.city)
        setState(props.data.state)
        setZipCode(props.data.zip)
        setCountry(props.data.country)
      }
      return () => resetForm()
    }, [props.open])

    const resetForm = () => {
      setAddress('')
      setCompany('')
      setCity('')
      setState('')
      setZipCode('')
      setCountry('')
      setSubmitUpdating(false)
    }

    const closeModal = () => {
      resetForm()
      props.onClose()
    }

    const handleSubmit = () => {
      if(submitUpdating) return;
      setSubmitUpdating(true)
      let formData = {
        'contact_id': props.data.id,
        'field' : 'address',
        address: address,
        company: company,
        city: city,
        country: country,
        state: state,
        zip: zipCode
      }
      ApiList.updateDealInfo(formData).then(res => {
        let response = res.data;
        if(response.status == 'success'){
          if(detailStates.from === 'deal-details'){
            if(detailStates.dealCallback !== undefined && detailStates.dealCallback !== null){
                detailStates.dealCallback(true)
            }
          }
          window.showNotification("SUCCESS",response.html)
          closeModal()
        }
        else{
          window.showNotification("ERROR", response.html);
        }
        setSubmitUpdating(false)
      })
    }
  
    return <LinkFile.modal              
              open={props.open} 
              className={props.customClass ?? ""}
              title={props.title} 
              onClose={closeModal}
              buttonText={props.title}
              buttonIcon={<EditIcon fontSize={'small'}/>}
              handleSubmit={handleSubmit}
              showLoader={submitUpdating}
            >
             <div style={{marginBottom:'12px'}}>
             <h3 className="aditional__info__modal_title"> Company</h3>
              <BasicInput
              fullWidth 
              value={company} 
              onChange={(event) => setCompany(event.target.value)}
              placeholder="Enter company"
              />
             </div>
              <div style={{marginBottom:'12px'}}>
              <h3 className="aditional__info__modal_title"> Address</h3>
              <BasicInput
              fullWidth
              value={address} 
              onChange={(event) => setAddress(event.target.value)} 
              placeholder="Enter address"
              />
              </div>
              <div style={{marginBottom:'12px'}}>
              <h3 className="aditional__info__modal_title">City</h3>
              <BasicInput
              fullWidth
              value={city} 
              onChange={(event) => setCity(event.target.value)} 
              placeholder="Enter city"
              />
              </div>
              <div style={{marginBottom:'12px'}}>
              <h3 className="aditional__info__modal_title">State</h3>
              <BasicInput
              fullWidth
              value={state} 
              onChange={(event) => setState(event.target.value)} 
              placeholder="Enter state" 
              />
              </div>
              <div style={{marginBottom:'12px'}}>
              <h3 className="aditional__info__modal_title">Country</h3>
              <BasicInput
              fullWidth
              value={country} 
              onChange={(event) =>setCountry(event.target.value)} 
              placeholder="Enter country"
              />
             
              </div>
              <div style={{marginBottom:'12px'}}>
              <h3 className="aditional__info__modal_title">Zip code</h3>
              <BasicInput
              type="number"
              fullWidth
              min="0" 
              value={zipCode} 
              onChange={(event) => setZipCode(event.target.value)} 
              placeholder="Enter zip code"
              />
              </div>
          </LinkFile.modal>;
}
export default UpdateContactInfo;