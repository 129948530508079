import * as ACTION from "../reducers/floatingSidebarTypes";

export const fetchUserActivity = (payload)=>({
    type: ACTION.FETCH_USER_ACTIVITY,
    payload: payload
});

export const fetchUserActivitySuccess = (payload)=>({
    type: ACTION.FETCH_USER_ACTIVITY_SUCCESS,
    payload: payload
});

export const fetchUserActivityFail = (payload)=>({
    type: ACTION.FETCH_USER_ACTIVITY_FAIL,
    payload: payload
});

export const setSidebarScheduleType = (payload)=>({
    type: ACTION.SET_SIDEBAR_SCHEDULE_TYPE,
    payload: payload
});
export const setSidebarPage = (payload)=>({
    type: ACTION.SET_SIDEBAR_PAGE,
    payload: payload
});
export const resetSidebarPage = (payload)=>({
    type: ACTION.RESET_SIDEBAR_PAGE,
    payload: payload
});

export const fetchUserInbox = (payload)=>({
    type: ACTION.FETCH_USER_INBOX,
    payload: payload
});
export const fetchUserInboxSuccess = (payload)=>({
    type: ACTION.FETCH_USER_INBOX_SUCCESS,
    payload: payload
});
export const fetchUserInboxFail = (payload)=>({
    type: ACTION.FETCH_USER_INBOX_FAIL,
    payload: payload
});

export const fetchSidebarNotification = (payload)=>({
    type: ACTION.FETCH_SIDEBAR_NOTIFICATION,
    payload: payload
});

export const fetchSidebarNotificationSuccess = (payload)=>({
    type: ACTION.FETCH_SIDEBAR_NOTIFICATION_SUCCESS,
    payload: payload
});

export const fetchSidebarNotificationFail = (payload)=>({
    type: ACTION.FETCH_SIDEBAR_NOTIFICATION_FAIL,
    payload: payload
});