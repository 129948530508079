import React from "react";

const UnreadIcon = () => {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2626 9.90237C14.5962 8.96059 15.4091 8.02636 15.4431 7.98704L15.7029 7.68631L15.4431 7.38558C15.4092 7.34626 14.5962 6.41203 13.2626 5.47025C11.4797 4.21124 9.60821 3.54574 7.8504 3.54574C6.90031 3.54574 5.91712 3.74056 4.93271 4.118L0.814684 0L0.164062 0.650622L14.8861 15.3727L15.5367 14.722L11.6718 10.8571C12.2063 10.5913 12.7382 10.2726 13.2626 9.90237ZM7.8504 5.38599C9.1188 5.38599 10.1507 6.41791 10.1507 7.68631C10.1507 8.15447 10.0098 8.59018 9.76862 8.95394L9.0957 8.28102C9.1821 8.10083 9.23059 7.89914 9.23059 7.68631C9.23059 6.92527 8.61144 6.30612 7.8504 6.30612C7.63758 6.30612 7.43592 6.35461 7.25572 6.44101L6.58281 5.76809C6.9465 5.52689 7.38224 5.38599 7.8504 5.38599Z"
        fill="#133159"
      />
      <path
        d="M7.85067 9.98649C6.58228 9.98649 5.55036 8.95457 5.55036 7.68618C5.55036 7.57582 5.55864 7.4674 5.57376 7.36112L3.19066 4.97803C2.93885 5.13049 2.68818 5.29434 2.43926 5.47014C1.10605 6.41195 0.29349 7.34622 0.259537 7.38554L0 7.68618L0.259537 7.98681C0.29349 8.02613 1.10605 8.9604 2.43926 9.90218C4.22157 11.1612 6.09283 11.8267 7.85067 11.8267C8.48338 11.8267 9.13085 11.7401 9.78358 11.571L8.17572 9.96312C8.06945 9.97821 7.96103 9.98649 7.85067 9.98649Z"
        fill="#133159"
      />
    </svg>
  );
};

export default UnreadIcon;











