import React from "react";

const PersonIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.31579 3.31579C3.31579 5.14389 4.80347 6.63158 6.63158 6.63158C8.45968 6.63158 9.94737 5.14389 9.94737 3.31579C9.94737 1.48768 8.45968 0 6.63158 0C4.80347 0 3.31579 1.48768 3.31579 3.31579ZM12.5263 14H13.2632V13.2632C13.2632 10.4197 10.9487 8.10526 8.10526 8.10526H5.15789C2.31368 8.10526 0 10.4197 0 13.2632V14H12.5263Z"
        fill="#133159"
      />
    </svg>
  );
};

export default PersonIcon;
