import { withStyles } from "@material-ui/core/styles";
import React, {  useState, useEffect } from "react";
import { Box, Button, FormControl, InputBase, MenuItem, Select, Tooltip, Typography } from "@material-ui/core";
import BootstrapTooltip from "../../../../globals/BootstrapTooltip";
import {
  updateContactLeadType
} from "../../../../../api/contactApi";
import { connect } from "react-redux";
import Styles from "./leadstatus.module.css";
import {LEAD_TYPES} from "../../../../../constants/CoreConstants";


const BasicInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "&.MuiInputBase-multiline": {
      padding: "0 !important",
    },
  },
  input: {
    position: "relative",
    fontSize: "14px !important",
    margin: "0 !important",
    marginTop: "9px ",
    height: "2rem !important",
    padding: "0px 24px 4px 10px !important",
    borderRadius: "4px !important",
    background: "white !important",
    border: "1px solid #ced4da !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&.MuiSelect-select:focus": {
      background: "white !important",
    },
    "&.MuiSelect-selectMenu .subtitle": {
      display: "none",
    },
    "&.MuiSelect-selectMenu .selectedTitle": {
      overflow: "hidden",
      marginTop:'9px',
      textOverflow: "ellipsis",
      "@media(minWidth: 1300px)": {
        width: "100%",
      },
    },

    "&:focus": {
      borderRadius: 4,
      background: "white !important",
      boxShadow: "unset !important",
    },
  },
}))(InputBase);

const LeadType = (props) => {

  const initialLeadType = props?.contact?.contact_additional_informations?.lead_type;
  const [leadType, setLeadType] = useState(initialLeadType || "default");

  useEffect(() => {
    setLeadType(initialLeadType ? initialLeadType : "default");
  }, [props.contact?.id, initialLeadType]);

  const handleSubmit = (event) => {
    updateContactLeadType({ contactId: props.contact?.id, leadType: event.target.value })
        .then((res) => {
          if (res.success) {
            window.showNotification("SUCCESS", res.message);
            setLeadType(event.target.value);
          }
        })
        .catch((error) => {
          window.showNotification("Error", error);
        });
  };

  return (
    <Box className={Styles.leadOwnerBoxWidth}>
          <FormControl fullWidth >
            <Select
              value={leadType}
              onChange={handleSubmit}
              displayEmpty
              sx={{mt:1}}
              input={<BasicInput className='basicinputbackground' />}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
               <MenuItem className='dropdownhelper-menuitem-class' value='default' disabled>
                <Typography variant='body1' color='textSecondary' style={{ lineHeight: 2,marginTop:'9px !important' }}>
                  Lead Type
                </Typography>
              </MenuItem>
              {LEAD_TYPES.map((leadType) => (
                <MenuItem key={leadType.value} className='dropdownhelper-menuitem-class' value={leadType.value}>
                  <BootstrapTooltip title= {leadType.label}>
                    <Typography
                      className={`${Styles.leadOwnerTextWidth} selectedTitle`}
                      variant='body1'
                      color='textSecondary'
                      style={{ lineHeight: 1.2 }}
                    >
                      {" "}
                      {leadType.label}
                    </Typography>
                  </BootstrapTooltip>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
    </Box>
  );
};


const mapStateToProps = (state) => {
  return {
    allContactList: state.addContactReducer.allContactList,
    userInfo: state.addContactReducer.userInfo,
    subUserList: state.addContactReducer.subUserList,
    contact: state.addContactReducer.contact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadType);
