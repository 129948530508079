import { Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, InputBase, withStyles } from "@material-ui/core";
import React from "react";
import A2pLearnMore from "../a2pLearnMore";
import SampleMessagesLearnMore from "../a2pLearnMore/SampleMessagesLearnMore";
import Styles from "./Styles.module.css";

const CustomInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
            color: "black !important",
            fontSize: "20px!important"
        },
    },
    input: {
        fontSize: "14px !important",
        height: "auto !important",
        minHeight: "200px !important",
        color: "#707070 !important",
        borderRadius: "4px !important",
        position: "relative",
        border: "1px solid #ced4da!important",
        padding: "10px 12px !important",
        marginBottom: "0!important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            color: "#707070 !important",
            boxShadow: "none!important",
            borderColor: "black !important",
        },
    },
}))(InputBase);

const CustomInputTwo = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
            color: "black !important",
            fontSize: "20px!important"
        },
    },
    input: {
        fontSize: "14px !important",
        height: "auto !important",
        color: "#707070 !important",
        borderRadius: "4px !important",
        position: "relative",
        border: "1px solid #ced4da!important",
        padding: "10px 12px !important",
        marginBottom: "0!important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            color: "#707070 !important",
            boxShadow: "none!important",
            borderColor: "black !important",
        },
    },
}))(InputBase);

const SampleMessages = ({ inputScrollToViewRef, formData, callback, errorCallback, a2pDlcError,showPreview, setIsPreviewed, isPreviewed }) => {
    const handleFormDataChange = (name, value) => {
        if (isPreviewed) {
            setIsPreviewed(false);
        }

        if (name === "senderIdentification1"){
            if (value.trim() === ""){
                errorCallback("senderIdentification1","Sender Identification is required!");
            }
        }

        if (name === "senderIdentification2"){
            if (value.trim() === ""){
                errorCallback("senderIdentification2","Sender Identification is required!");
                
            }
        }
        if (name === "sampleMessage1"){
            if (value.trim() === ""){
                errorCallback("sampleMessage1","Sample Message 1 is required!");
            }else if(value.length < 20 || value.length > 1024){
                errorCallback("sampleMessage1","Sample Message 1 can not be less than 20 characters and more than 1024 characters!");
            }else {
                errorCallback("sampleMessage1","");
            }
        }

        if (name === "sampleMessage2"){
            if (value.trim() === ""){
                errorCallback("sampleMessage2","Sample Message 2 is required!");
            }else if(value.length < 20 || value.length > 1024){
                errorCallback("sampleMessage2","Sample Message 2 can not be less than 20 characters and more than 1024 characters!");
            }else {
                errorCallback("sampleMessage2","");
            }
        }

        if (name === "optOutLanguage1"){
            if (value.trim() === ""){
                errorCallback("optOutLanguage1","Opt-out Language is required!");
            }
        }


        if (name === "optOutLanguage2"){
            if (value.trim() === ""){
                errorCallback("optOutLanguage2","Opt-out Language is required!");
            }
        }

        if (name === "hasEmbeddedLinks"){
            if (value){
                value = "TRUE";
            }else {
                value = "FALSE";
            }
        }

        if (name === "hasEmbeddedPhone"){
            if (value){
                value = "TRUE";
            }else {
                value = "FALSE";
            }
        }

        callback(name, value);
    };

    const renderErrorMessage = (fieldName) => {
        return <FormHelperText error>{a2pDlcError[fieldName]}</FormHelperText>;
    };

    return (
        <div className={Styles.contentWrapper}>
            <div className={Styles.contentHeader}>
                <h1 className={Styles.contentTitle}>Read before answering questions:</h1>
                <p className={Styles.contentDescription}>Before the phone companies are willing to approve your messaging campaigns they need to review some examples of the types of messages you intend to send. Complete the info below.</p>
            </div>
            <div className={Styles.contentBody}>
                <div className={Styles.contentBodyLeft}>
                    <FormControl ref={el => inputScrollToViewRef.current[0] = el} className={Styles.formControl}>
                      <span className={`${Styles.label} ${Styles.dFlex} ${Styles.justifySpaceBetween}`}>
                        <span>
                            <b>Sample Message #1: (provide an example below)</b>
                            {/* <span className={Styles.required}> *</span> */}
                        </span>
                          {
                              formData.useCase &&
                              <span onClick={(event)=> {
                                  // A2pSuggestion({
                                  //     suggestionTitle: "Sample Message Suggestions",
                                  //     suggestions: CODE_WISE_DATA.SAMPLE_MESSAGE,
                                  //     onChange: (value) => {
                                  //         handleFormDataChange("sampleMessage1", value)
                                  //     }
                                  // });

                                  A2pLearnMore({
                                      title: "Sample messages",
                                      innerBody: <SampleMessagesLearnMore/>
                                  });
                              }} className={Styles.smallButton}>Suggestions</span>
                          }
                      </span>

                      <span className={`${Styles.label} ${Styles.dFlex} `}>
                            Sender Identification (this section is pre-populated and will be added to the beginning of your message)
                            <span className={Styles.required}> *</span>
                        </span>
                         <CustomInputTwo
                            placeholder="Hi this is {first_name} from {mycompany}"
                            name="senderIdentification1"
                            value={formData.senderIdentification1}
                            onChange={(e)=>{handleFormDataChange("senderIdentification1", e.target.value)}}
                        />
                        {renderErrorMessage("senderIdentification1")}

                        <br></br>
                        <CustomInput
                            placeholder={"Example: \n" +
                                "Hi Joe this is Jeff at XYZ Insurance. I received your request for a quote and I'd be happy to help out. Reply STOP to unsubscribe.\n" +
                                "\n" +
                                "Example: \n" +
                                "Your one time passcode is 12345 and will be valid for 30 minutes.\n" +
                                "\n" +
                                "Example: \n" +
                                "Hi Julie this is Jeff at ABC Family Practice reaching out to confirm your appointment on Tuesday, July 3 at 8:30 am."}
                            name="sampleMessage1"
                            value={formData.sampleMessage1}
                            onChange={(e)=>{handleFormDataChange("sampleMessage1", e.target.value)}}
                            multiline
                        />
                        {renderErrorMessage("sampleMessage1")}
                        <span className={Styles.multilineInputLimit}>Min length: 20 characters. Max length: 1024 characters.</span>

                        <br></br>
                        <span className={`${Styles.label} ${Styles.dFlex}`}>
                            Opt-out Language(this section is pre-populated and will be added to the end of your message)
                            <span className={Styles.required}> *</span>
                        </span>

                        <CustomInputTwo
                           
                            name="optOutLanguage1"
                            value={formData.optOutLanguage1}
                            // onChange={(e)=>{handleFormDataChange("optOutLanguage1", e.target.value)}}
                            readOnly
                        />
                    </FormControl>
                    <FormControl ref={el => inputScrollToViewRef.current[1] = el} className={Styles.formControl}>
                      <span className={`${Styles.label} ${Styles.dFlex} ${Styles.justifySpaceBetween}`}>
                        <span>
                            Sample Message #2:
                            {/* <span className={Styles.required}> *</span> */}
                        </span>
                          {
                              formData.useCase &&
                              <span onClick={(event)=> {
                                  // A2pSuggestion({
                                  //     suggestionTitle: "Sample Message Suggestions",
                                  //     suggestions: CODE_WISE_DATA.SAMPLE_MESSAGE,
                                  //     onChange: (value) => {
                                  //         handleFormDataChange("sampleMessage2", value)
                                  //     }
                                  // });

                                  A2pLearnMore({
                                      title: "Sample messages",
                                      innerBody: <SampleMessagesLearnMore/>
                                  });
                              }} className={Styles.smallButton}>Suggestions</span>
                          }
                      </span>

                      <span className={`${Styles.label} ${Styles.dFlex}`}>
                            Sender Identification (this section is pre-populated and will be added to the beginning of your message)
                            <span className={Styles.required}> *</span>
                        </span>
                        <CustomInputTwo
                            name="senderIdentification2"
                            value={formData.senderIdentification2}
                            onChange={(e)=>{handleFormDataChange("senderIdentification2", e.target.value)}}
                            
                        />
                        {renderErrorMessage("senderIdentification2")}

                        <br></br>

                        <span className={`${Styles.label} ${Styles.dFlex}`}>
                            Add your Sample message below:
                            <span className={Styles.required}> *</span>
                        </span>
                        <CustomInput
                            placeholder={"Example: \n" +
                                "Hi Joe this is Jeff at XYZ Insurance. I received your request for a quote and I'd be happy to help out. Reply STOP to unsubscribe.\n" +
                                "\n" +
                                "Example: \n" +
                                "Your one time passcode is 12345 and will be valid for 30 minutes.\n" +
                                "\n" +
                                "Example: \n" +
                                "Hi Julie this is Jeff at ABC Family Practice reaching out to confirm your appointment on Tuesday, July 3 at 8:30 am."}
                            name="sampleMessage2"
                            value={formData.sampleMessage2}
                            onChange={(e)=>{handleFormDataChange("sampleMessage2", e.target.value)}}
                            multiline
                        />
                        {renderErrorMessage("sampleMessage2")}
                        <span className={Styles.multilineInputLimit}>Min length: 20 characters. Max length: 1024 characters.</span>

                        <br></br>
                        <span className={`${Styles.label} ${Styles.dFlex}`}>
                            Opt-out Language(this section is pre-populated and will be added to the end of your message)
                            <span className={Styles.required}> *</span>
                        </span>

                        <CustomInputTwo
                           
                            name="optOutLanguage2"
                            value={formData.optOutLanguage2}
                            // onChange={(e)=>{handleFormDataChange("optOutLanguage2", e.target.value)}}
                            readOnly
                        />
                    </FormControl>
                    <div className={Styles.dFlex}>
                        <FormControl className={Styles.formControl}>
                        <FormControlLabel
                            className={Styles.embedCheckbox}
                            control={
                                <Checkbox
                                    name="hasEmbeddedLinks"
                                    id="hasEmbeddedLinks"
                                    color="primary"
                                    onChange={(e)=>{handleFormDataChange("hasEmbeddedLinks", e.target.checked)}}
                                    checked={formData.hasEmbeddedLinks === "TRUE"}
                                />
                            }
                            label="My text message includes an embedded link (website, calendar link, etc.)"
                        />
                        <FormControlLabel
                            className={Styles.embedCheckbox}
                            control={
                                <Checkbox
                                    name="hasEmbeddedPhone"
                                    id="hasEmbeddedPhone"
                                    color="primary"
                                    onChange={(e)=>{handleFormDataChange("hasEmbeddedPhone", e.target.checked)}}
                                    checked={formData.hasEmbeddedPhone === "TRUE"}
                                />
                            }
                            label="My text message includes a phone number"
                        />
                        <span className={Styles.italicMessage}>*If there is any chance your messages will include links or phone numbers we recommend you check these boxes.</span>
                    </FormControl>
                    <Button variant="contained" size="large" color="primary" className={Styles.previewButton} onClick={showPreview} > Preview Your Messages</Button>
                    </div>
                    
                </div>
                <div className={Styles.contentBodyRight}>
                        <Box className={Styles.notes}><b>Notes:</b>
                            <p>To be in compliance with A2P/10DLC the first message you send a contact must include <b>sender identifcation</b> and <b>opt-out language</b>.</p>

                            <p> Sender identification identifies who you are and the name of your company. This will appear at the beginning of your message.</p>

                            <p>Opt-out language is text you add to the end of your message that tells contacts how to unsubscribe from further texts. </p>

                            <p><b>If this is missing your registration will get rejected! 
                                To ensure compliance we'll automatically add these required elements to the sample messages you provide.</b></p>

                            <p> **If your messages include an embedded link or phone number be sure to check the appropriate boxes. If you check these boxes make sure your sample messages include a link and/or phone number as well. (DO NOT USE LINK SHORTENERS)</p>
                            
                        </Box>
                </div>
            </div>
        </div>
    );
}

export default SampleMessages;