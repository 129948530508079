import React from "react";
import './App.css';
import Routes from "./routes/Routes";
import { BrowserRouter as Router } from 'react-router-dom';
import { ToasterProvider } from "./CustomToaster/ToasterProvider";
import { createGenerateClassName, StylesProvider } from "@material-ui/styles";
import { init as initApm } from '@elastic/apm-rum'

const generateClassName = createGenerateClassName({
  productionPrefix: 'prnt',
});

if (process.env.NODE_ENV !== 'development') {
  // initApm({

  //   // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  //   serviceName: process.env.REACT_APP_APM_SERVICENAME, 

  //   // Set custom APM Server URL (default: http://localhost:8200)
  //   serverUrl: process.env.REACT_APP_APM_SERVERURL,

  //   // Set service version (required for sourcemap feature)
  //   serviceVersion: process.env.REACT_APP_APM_SERVICEVERSION,

  //     // Set the service environment
  //   environment: process.env.REACT_APP_APM_ENVIRONMENT,

  //   Type: ['page-load','history', 'xmlhttprequest','fetch','error'],
  //   disableInstrumentations: ['eventtarget','click']
  // })
}

function App() {

  return (
    <Router>
      <StylesProvider generateClassName={generateClassName}>
        <ToasterProvider>
          <Routes />
        </ToasterProvider>
      </StylesProvider>
    </Router>
  );
}

export default App;




