import { IconButton, styled } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { AddIcon } from "../../GlobalAddDealModalV3/components/Icons";
import { HEADER_COLORS } from "./constants";

const AddPopoverMenuInsuranceWrapper = styled("div")(({ theme }) => ({
    backgroundColor: "var(--white)",
    borderRadius: "10px",
    boxShadow: "0 0 17px 1px #bbb",
    right: "-105px",
    overflow: "visible",
    position: "absolute",
    top: "60px",
    width: "250px",
    zIndex: "999",

    "&:before": {
        background: "url(/static/media/arrow.20587642.svg) no-repeat 50% / 34px 24px",
        backgroundSize: "cover",
        content: "''",
        display: "block",
        height: "23px",
        position: "absolute",
        right: "44%",
        top: "-12px",
        width: "34px",
        zIndex: "-1"
    },

    "& .menuUl": {
        "& .menuLi": {
            borderBottom: "1px solid var(--gray_dark)",
            padding: "14px 16px",
            userSelect: "none",
            cursor: "pointer",

            "& h4": {
                color: "#133159",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "24px",
                margin: "0",
            },

            "& span": {
                color: "var(--gray)",
                fontSize: "14px",
                lineHeight: "22px",
                paddingBottom: "9px"
            },

            "&:hover": {
                background: "rgba(60, 126, 243, .1)",

                "& h4": {
                    color: "#3c7ef3"
                }
            }
        }
    }
}));

const HeaderAddPopover = () => {
    const [openSearchMenu, setOpenSearchMenu] = useState(false);
    const [menuOptions, setMenuOptions] = useState([
        {
            menu: "new-deal",
            label: "New Deal",
            description: "Add a new deal to your sales pipeline"
        },
        {
            menu: "new-contact",
            label: "New Contact",
            description: "Add a new contact to the system"
        },
        {
            menu: "new-activity",
            label: "New Activity",
            description: "Schedule meetings, task and follow up activities"
        },
        {
            menu: "new-campaign",
            label: "New Campaign",
            description: "Create a new automated drip marketing campaign"
        }
    ]);
    const buttonRef = useRef(null);
    const popoverRef = useRef(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (buttonRef.current && !buttonRef.current.contains(event.target)
            && popoverRef.current && !popoverRef.current.contains(event.target)) {
            setOpenSearchMenu(false);
        }
    };

    const handleClick = (item) => {
      if (item.menu === "new-deal"){
          window.globalAddDeal(true, null);
      }else if (item.menu === "new-contact"){
          window.showAddContactModal();
      }else if (item.menu === "new-activity"){
          window.openGlobalActivityModal({});
      }else if (item.menu === "new-campaign"){
          window.globalAddCampaignModal(true);
      }

      setOpenSearchMenu(false);
    };

    return(
        <div style={{ position: "relative" }}>
            <IconButton buttonRef={buttonRef} className="actionButton" onClick={()=>{ setOpenSearchMenu((prevState)=>(!prevState)); }}>
                <AddIcon color={HEADER_COLORS.ADD_ICON}/>{/*AddIcon icon color Blue*/}
            </IconButton>

            <AddPopoverMenuInsuranceWrapper ref={popoverRef} style={{ visibility: openSearchMenu ? "visible" : "hidden" }}>
                <div className="menuUl">
                    {
                        menuOptions.map((item)=> (
                            <div className="menuLi" onClick={()=>{ handleClick(item); }} key={item.menu}>
                                <h4>{item.label}</h4>
                                <span>{item.description}</span>
                            </div>
                        ))
                    }
                </div>
            </AddPopoverMenuInsuranceWrapper>
        </div>
    );
};

export default HeaderAddPopover;