import React, { useState } from "react";
import { connect } from 'react-redux';
import { _updateStateField } from "../redux/contactDetailAction";
import { deleteContactFromStageApi } from "../redux/contactDetailApi";
import Campaign from "./ContactMoreOption/Campaign";
import CustomField from "./ContactMoreOption/CustomField";
import Source from "./ContactMoreOption/Source";
import Tags from "./ContactMoreOption/Tags";

const mapStateToProps = state => ({
    contactDetailReducer: state.contactDetailReducer
});
const mapDispatchToProps = dispatch => ({
    _updateStateField: params => dispatch(_updateStateField(params))
})

const MoreInfoApp = (props) => {

    const {contactId} = props.contactDetailReducer
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [deleting, setDeleting] = useState(false)

    const deleteContact = () => {
        if(deleting) return;
        if(!confirmDelete){
            setConfirmDelete(true)
            setTimeout(() => {
                if(confirmDelete !== undefined){
                    setConfirmDelete(false)
                }
            }, 3000);
            return;
        }else{
            setConfirmDelete(false)
            setDeleting(true)
            deleteContactFromStageApi({id: contactId}).then(res => {
                setDeleting(false)
                props.closeModal()
            })
        }
    }

    return(
        <>
        <div className="outcomeWrapper d-flex">
            <CustomField />
            <Tags />
        </div>

        <div className="outcomeWrapper d-flex">
            <Campaign />
            <Source />
        </div>

        <div className="outcomeWrapper d-flex">
            {/*<Appointment />*/}
            {/*<Task />*/}
        </div>

        <div className="outcomeWrapper footerBtn">
            <div onClick={deleteContact} className="left__column columnItem" style={{padding : 10, backgroundColor: 'red', color: 'white', cursor:'pointer'}}>
                {confirmDelete ? 'Sure to DELETE ? ' : (deleting ? 'Deleting' : 'DELETE CONTACT')}
            </div>
        </div>
        </>
      )
}
const MoreInfo = connect(mapStateToProps, mapDispatchToProps)(MoreInfoApp);
export default MoreInfo;