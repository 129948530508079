import HttpRequest from "./HttpRequest";
import Api from "../constants/Api";

export const getInsuranceCompanies = (payload) => {
  return HttpRequest.get(Api.insuranceCompanies, payload);
};

export const lostDealFormData = (payload) => {
  return HttpRequest.get(Api.lostDealFormData, payload);
};

export const lostDealRecycle = (payload) => {
  return HttpRequest.post(Api.lostDealRecycle, payload);
};

export const searchVirtualNumberList = (payload) => {
  return HttpRequest.post(Api.searchVirtualNumberList, payload);
};

export const buyVirtualNumber = (payload) => {
  return HttpRequest.post(Api.buyVirtualNumberTwilio, payload);
};

export const getVirtualNumberListV2 = (payload) => {
  return HttpRequest.get(Api.getVirtualNumberListV2, payload);
};

export const connectGoogleProcessTokenTenant = (payload) => {
  return HttpRequest.post(Api.connectGoogleProcessTokenTenant, payload);
};