import HttpRequest from "../../../GlobalAddDealModalV3/helpers/HttpRequest";


const tenantUrl=`${process.env.REACT_APP_PYPEPRO_TENANT_BACKEND_SERVER}/api/v1`;

const endpoints = {
    userActivities: `${tenantUrl}/dashboard/user-activities`,
    userInboxData: `${tenantUrl}/dashboard/inbox-data`,
    userNotifications: `${tenantUrl}/dashboard/notifications`,
};

export const getUserActivities = (payload) => {
    return HttpRequest.get(endpoints.userActivities, payload);
}
export const getUserInbox = (payload) => {
    return HttpRequest.get(endpoints.userInboxData, payload);
}

export const getUserNotifications = (payload) => {
    return HttpRequest.get(endpoints.userNotifications, payload);
}