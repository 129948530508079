export const LAYOUT_SETTINGS = {
  SHORT_MENU: 19,
  TOPBAR_BACKGROUND: 20,
  TOPBAR_TEXT_COLOR: 21,
  SIDEBAR_BACKGROUND: 22,
  SIDEBAR_TEXT_COLOR: 23,
  SIDEBAR_ICON_COLOR: 30,
  SIDEBAR_HOVER_COLOR: 31,
  ACCENT_COLOR: 26,
  ACCENT_TEXT_COLOR: 32,
}

export const ActivityType = {
  CALL: '1',
  APOINTMENT: '2',
  TASK: '3',
  DEADLINE: '4',
  EMAIL: '5',
  FOLLOWUP: '6',
  OTHERS: '7'

}
export const TASK_ICON = {
  call: 'phone',
  meeting: 'event_note',
  task: 'assignment_turned_in',
  deadline: 'query_builder',
  email: 'mail_outline',
  followup: 'person_add',
  others: 'more_horiz',
}
export const SITE_NOTIFICATION = {
  SMS: 0,
  EMAIL: 1,
  CALL: 2,
  CAMPAIGN_PAUSE: 5
}
export const CODE_SNIPPET = {
  AFTER_LOGIN: 1,
  REGISTRATION: 2,
  POSITION_HEADER: 1,
  POSITION_FOOTER: 2,
}

export const MESSAGE_TYPE = {
  SMS: 1,
  MMS: 2,
  VOICE: 3,
  EMAIL: 4,
  CALL: 5,
  VIDEO_EMAIL: 14,
  FORM_RESPONSE: 26,
}

export const NOTIFICATION_MESSAGE_TYPE = {
  SMS: 0,
  EMAIL: 1,
  CALL: 2,
  FORM_RESPONSE: 4,
}

export const NOTIFICATION_OUTCOME_TYPE = {
  ANSWERED: 1,
  MISSED_CALL: 2,
  VOICEMAIL: 3
}

export const AUTO_DIALER = {
  STATUS: {
    NOT_STARTED: 0,
    RUNNING: 1,
    PAUSED: 2,
    COMPLETED: 3
  },
  ACTION_IF_NOT_ANSWER: {
    DO_NOTHING: 0,
    DROP_VOICEMAIL: 1,
    SEND_SMS: 2,
    BOTH: 3
  }
}

export const CALL_TYPE = {
  INCOMING: 1,
  GLOBAL_DIALER: 2,
  AUTO_DIALER: 3,
}

export const LEAD_TYPES = [
  {
    value: "CLIENT",
    label: "Client"
  },
  {
    value: 'VENDOR',
    label: "Vendor"
  },
  {
    value: 'LEAD',
    label: "Lead"
  }
];
export const PHONE_STATE = {
  mobile: "mobile",
  landline: "landline",
  voip: "voip",
  unknown: "unknown"
};
export const PHONE_TYPES = [
  {
    value: PHONE_STATE.mobile,
    label: "Mobile"
  },
  {
    value: PHONE_STATE.landline,
    label: "Landline"
  },
  {
    value: PHONE_STATE.voip,
    label: "Voip"
  },
  {
    value: PHONE_STATE.unknown,
    label: "Unknown"
  }
];

export const leadPermission = {
  REQUEST_TYPE_ADD_COLLABORATOR: 1,
  REQUEST_TYPE_CHANGE_OWNER: 2
}

export const US_STATE_LIST = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "DC", label: "District Of Columbia" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];


export const emailProvider = {
  DEFAULT: 0,
  NYLAS_V1: 1,
  NYLAS_V3: 2
}

export const NYLAS_V3_AGENCIES = [1, 125, 53, 82, 57, 106, 97, 16, 30, 62, 40, 87, 92, 103, 129, 38, 67, 85, 159, 193, 157, 45, 59, 190, 102, 145, 51, 201, 225, 73, 173, 42, 32, 150, 124, 44, 234, 226, 215, 214, 213, 46, 141, 218, 216, 219, 235];

export const ADD_DEAL_TYPE = {
  NEW: 1,
  EXISTING: 2
};

export const APPOINTMENT_SCHEDULE_PAST = 'PAST';
export const APPOINTMENT_SCHEDULE_TODAY = 'TODAY';
export const APPOINTMENT_SCHEDULE_UPCOMING = 'UPCOMING';
export const FREE_MAP_ACCESS_AGENCIES = [1, 236];
