import { Button, Grid } from "@material-ui/core";
import { Fragment } from "react";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const JoinGroup = (props) => {
    return (
      <Fragment>
        <Grid container>
          <Grid item xs={12} className="onboarding__setp__btn__group">
            <div className="onboarding__setp__btn__group__inner">
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="mr-2"
                startIcon={<ArrowBackIcon />}
                onClick={() => props.goToStep(props.activeStep - 1)}
                disabled={props.loading}
              >
                Previous Step
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                endIcon={<ArrowForwardIcon />}
                onClick={() => props.goToStep(props.activeStep + 1)}
                disabled={props.loading}
              >
                Next Step
              </Button>
            </div>
            <p
              className="onboarding__step__one__title_log_out"
              onClick={props.logout}
            >
              Log Out
            </p>
          </Grid>
          <Grid item xs={12} className="mt-5">
            <h5 className="m-0">Join The Salespype Users Group on Facebook</h5>

            <div>
              <p>
                In this free community you'll learn tips, tricks and best
                practices to get the most out of your <strong>Salespype</strong>{" "}
                membership. Click the link below to join.
              </p>
              <div class="pt-3 pb-3 text-center">
                <a
                  href="https://www.facebook.com/groups/salespype"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={
                      "https://alpha.pypepro.com/assets/images/onboard/facebook-group-button.jpeg"
                    }
                    alt="Join the Salespype Users Group on Facebook"
                    class="img-fluid onboarding_group__inner_img_width"
                  />
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
      </Fragment>
    );
}

export default JoinGroup;