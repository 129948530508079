import React from "react";
import {Helmet} from "react-helmet";
import ContactEmailUnsubscribeStyle from "./ContactEmailUnsubscribeStyle";

const Unsubscribed = () => {
    const classes = ContactEmailUnsubscribeStyle();

    return (
      <div className={classes.page}>
        <Helmet>
          <title>Unsubscribe</title>
        </Helmet>
        <div className={classes.container}>
          <div className={classes.innerContainer}>
            <img
              src="https://d24gujj67p1uc1.cloudfront.net/assets/bulk-email/images/releases/1.0.1/message-00909.svg"
              alt="Message icon"
            />

            <div className={classes.bottom}>
              <h2 className={classes.title} style={{ fontWeight: 600 }}>
                Thank you for being part of our community
              </h2>
              <p style={{margin:'2px'}}>We appreciate your time and consideration. 😊</p>
              <p style={{margin:'2px'}}>Wishing you all the best! ❤️</p>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Unsubscribed;