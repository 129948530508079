import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ThankYou from "./ThankYou";
import Unsubscribe from "./Unsubscribe";
import YouLost from "./YouLost";
import Loading from "./Loading";
import Unsubscribed from "./Unsubscribed";

const BulkEmailUnsubscribe = () => {
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const recipientId = params.get("recipient_id");
    const emailId = params.get("email_id");
    const showThankYou = params.get("thank_you");
    const unsubscribed = params.get("unsubscribed");

    const [tab, setTab] = useState(-1);

    useEffect(() => {
        if (emailId && recipientId) {
            setTab(0);
        } else if (!emailId && !recipientId && showThankYou) {
            setTab(1);
        }
        else if (!emailId && !recipientId && unsubscribed) {
            setTab(2);
        } else {
            setTab(3);
        }
    }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

    if (tab === -1) {
        return <Loading/>;
    } else if (tab === 0) {
        return <Unsubscribe />;
    } else if (tab === 1) {
        return <ThankYou />;
    } else if (tab === 2) {
        return <Unsubscribed />;
    }
    else {
        return <YouLost/>;
    }
};

export default BulkEmailUnsubscribe;
