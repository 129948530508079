import { sendRequest, sendRequestBackEnd, sendRequestWithHighTimeout } from './rootApi';
import Api from "../constants/Api";
import HttpRequest from './HttpRequest';

export const fetchContactDetailsNew = async (payload) => {
    return sendRequest("post", Api.detailsNew, payload);
}

export const changeLeadOwner = async (payload) => {
    return sendRequest("post", Api.changeLeadOwner, payload);
}

export const getSubUserListForContactlist = async (payload) => {
    return sendRequest("post", Api.subUserListForContactDropdown, payload);
}

export const receiveBulkContactPermissionRequest = async (payload) => {
    return sendRequestBackEnd("post", Api.receiveBulkContactPermissionRequest, payload);
}

export const updateContactLeadType = (payload) => {
    return HttpRequest.post(Api.updateContactLeadTypeApi, payload);
}

export const updateContactDetailsV2 = async (payload) => {
    return HttpRequest.post(Api.updateContactDetailsV2, payload);
}

export const createAdditionalContactApi = (payload) => {
    return sendRequest("POST", Api.createAdditionalContactApi, payload);
}
export const getAllAdditionalContactApi = (payload) => {
    return sendRequest("POST", Api.getAllAdditionalContactApi, payload);
}
export const updateAdditionalContactApi = (payload) => {
    return sendRequest("POST", Api.updateAdditionalContactApi, payload);
}
export const deleteAdditionalContactApi = (payload) => {
    return sendRequest("POST", Api.deleteAdditionalContactApi, payload);
}

export const deleteAdditionalContactNumberApi = (payload) => {
    return HttpRequest.post(Api.deleteAdditionalContactNumberApi, payload);
}

export const deleteAdditionalContactEmailApi = (payload) => {
    return HttpRequest.post(Api.deleteAdditionalContactEmailApi, payload);
}

export const makeDefaultAdditionalContactNumberApi = (payload) => {
    return HttpRequest.post(Api.makeDefaultAdditionalContactNumberApi, payload);
}

export const deleteContactEmailApi = (payload) => {
    return HttpRequest.post(Api.deleteContactEmailApi, payload);
}

export const makeDefaultAdditionalContactEmailApi = (payload) => {
    return HttpRequest.post(Api.makeDefaultAdditionalContactEmailApi, payload);
}

export const getZipCodeByPlace = async (payload) => {
    return sendRequest("post", Api.getZipCodeByPlace, payload);
}

export const updateContactInfo = async (payload) => {
    return sendRequest("post", Api.updateContactInfo, payload);
}

// Email Type List Api
export const getEmailTypeApi = () => {
    return sendRequest("post", Api.emailTypeListApi);
};

// Extra Email Add Api
export const addExtraEmailApi = (payload) => {
    return sendRequest("post", Api.addExtraEmailApi, payload);
};

// Extra Email List Apii
export const getExtraEmailApi = (payload) => {
    return sendRequest("post", Api.extraEmailListApi, payload);
};

// Extra Email List Apii
export const editExtraEmailApi = (payload) => {
    return sendRequest("post", Api.editExtraEmailApi, payload);
};

export const deleteContactNumberApi = (payload) => {
    return HttpRequest.post(Api.deleteContactNumberApi, payload);
}

export const deleteContactTag = async (payload) => {
    return sendRequest("post", Api.deleteContactTag, payload);
}

export const getContactTagList = async (payload) => {
    return sendRequest("post", Api.getContactTagList, payload);
}

export const updateContactCustomFieldV2 = (payload) => {
    return HttpRequest.post(Api.updateContactCustomFieldV2, payload);
}

export const updateImportantDates = (payload) => {
    return HttpRequest.post(Api.updateImportantDates, payload);
}

export const updateImportantLinks = (payload) => {
    return HttpRequest.post(Api.updateImportantLinks, payload);
}

export const updateContactDetailsApi = (payload) => {
    return HttpRequest.post(Api.updateContactDetailsApi, payload);
}

export const fetchContactStages = async (payload) => {
    return sendRequest("post", Api.getContactStages, payload);
}

export const changeDealStatus = async (payload) => {
    return sendRequest("post", Api.changeDealStatus, payload);
}

export const updateDeal = async (payload) => {
    return sendRequest("post", Api.updateDeal, payload);
}

export const getStageList = async (payload) => {
    return sendRequest("post", Api.stageList, payload);
}

export const deleteCollaborator = async (payload) => {
    return sendRequest("post", Api.deleteCollaborator, payload);
}

export const removeBulkContact = async (payload) => {
    return sendRequest("post", Api.removeBulkContact, payload);
}

export const getUserCampaignRegistrationStatusApi = async (payload) => {
    return sendRequest("POST", Api.getUserCampaignRegistrationStatus, payload);
}

export const getActivityListContactDetails = async (payload) => {
    return sendRequest("post", Api.getActivityListContactDetails, payload);
}

export const getContactSmartFormEntries = async (contactId) => {
    return sendRequestBackEnd("get", Api.getContactSmartFormEntries + `?contactId=${contactId}`);
}

export const formBuilderAWSPreSignedUrl = async (payload) => {
    return sendRequest("post", Api.formBuilderAWSPreSignedUrl, payload);
}

export const changeContactSource = async (payload) => {
    return sendRequest("post", Api.changeContactSource, payload);
}

export const getSourceListDropdown = async (payload) => {
    return sendRequest("post", Api.userSourceList, payload);
}
export const getUserInfo = async (payload) => {
    return sendRequest('post', Api.getUserInfo, payload)
}

export const updateNumberDncStatusApi = async (payload) => {
    return HttpRequest.post(Api.updateNumberDncStatus, payload)
}

export const sendMessage = async (payload) => {
    return sendRequestWithHighTimeout("post", Api.sendMessage, payload);
}

export const addNoteSubmit = async (payload) => {
    return sendRequest("post", Api.addNote, payload);
}

export const getAllTeamUsers = async (payload) => {
    return sendRequest("post", Api.getAllTeamUsers, payload);
}

export const fetchEmails = async (payload) => {
    return sendRequest("post", Api.emails, payload);
}

export const getContactStagesProduct = async (payload) => {
    return sendRequest("GET", Api.getContactStagesProduct, payload);
}

export const getUserProductsList = async (payload) => {
    return sendRequest("get", Api.getUserProductList, payload);
}

export const getContactRelationshipList = async (payload) => {
    return HttpRequest.get(Api.contactRelationshipApi, payload);
};

export const callEnvironment = async (payload) => {
  return HttpRequest.get(Api.callEnvironment, payload);
};

export const contactUnsubscribedApi = (payload) => {
    return sendRequest('POST', Api.checkUnsubscried, payload)
  }