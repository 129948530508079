import React, { useContext, useState, useEffect } from "react";
import CustomAccordion, {
  CustomAccordionBody,
  CustomAccordionHeader,
} from "../../components/Accordion/CustomAccordion";
import { ContactDetailContext } from "../../reducer/GlobalReduers";
import AddIcon from "@material-ui/icons/Add";
import { icons } from "../../icons";
import ApiList from "../../ApiList";
import { List } from "./List";
import ListAltIcon from "@material-ui/icons/ListAlt";
import TaskSkeleton from "./TaskSkeleton";

const Task = (props) => {
  const { detailStates } = useContext(ContactDetailContext);
  const [expand, setExpand] = useState(false);
  const [activity, setActivity] = useState([]);
  const [doneActivity, setDoneActivity] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDoneTask, setShowDoneTask] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setActivity(props.activity);
  }, [props.activity]);

  useEffect(() => {
    if (expand) {
      getPeopleActivity();
    }
  }, [expand]);

  const refresh = () => {
    getPeopleActivity();
  };

  const getPeopleActivity = () => {
    setLoading(true);
    ApiList.getPeopleActivity({
      contact_id: props.contactDetails?.id,
    }).then((res) => {
      let response = res.data;
      if (response.success) {
        let filter_data = response.data.filter(
          (item) => parseInt(item.task_id) !== 2 && parseInt(item.status) === 1
        );
        setActivity(filter_data);
        let filter_done_data = response.data.filter(
          (item) => parseInt(item.task_id) !== 2 && parseInt(item.status) === 2
        );
        setDoneActivity(filter_done_data);
      }
      setLoading(false);
    });
  };

  const editActivity = (id, data) => {
    window.openGlobalActivityModal({
      contactDetails: props.contactDetails,
      editData: data,
      callBack: getPeopleActivity,
    });
  };

  const handleAddAppointment = (event, status) => {
    event.stopPropagation();
    window.openGlobalActivityModal({
      subUserList: props.subUserList,
      contactDetails: props.contactDetails,
      callBack: getPeopleActivity,
    });
  };

  const RenderList = ({ list }) => {
    const taskCount = list.reduce((count, item) => {
      if (props.from === "Task" && item.task_id !== 2 && item.status !== 2) {
        return count + 1;
      }
      return count;
    }, 0);

    useEffect(() => {
      setCount(taskCount);
    }, [list]);

    return list.map((item, index) => {
      if (props.from === "Task" && item.task_id !== 2 && item.status !== 2) {
        return (
          <List
            makeDone={""}
            data={item}
            key={index}
            index={index}
            status={1}
            expand={() => setExpand(false)}
            refresh={refresh}
            editActivity={editActivity}
          />
        );
      }
      return null;
    });
  };

  const renderDoneList = (list) => {
    return list.map((item, index) => (
      <List
        data={item}
        key={item.id}
        index={item.id}
        status={2}
        makeDone={null}
      />
    ));
  };

  return (
    <div className="" style={{ marginTop: "10px" }}>
      <CustomAccordion
        expand={expand}
        className="g-contact-details-collapsible"
      >
        <CustomAccordionHeader
          onClick={() => setExpand(!expand)}
          className={`${expand ? "g-collapsible-header-for-deal" : "g-collapsible-header"}  ${expand ? "expand" : ""}`}
        >
          <span className={`${expand ? "g-header-title-for-deal" : "g-header-title"}`}>
            
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill={expand?"#fff":"#181f48"}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M19.2322 3.72473L17.1766 1.66917C17 1.50071 16.7652 1.40674 16.5211 1.40674C16.277 1.40674 16.0422 1.50071 15.8655 1.66917L14.0378 3.50251H4.2322C3.93752 3.50251 3.6549 3.61957 3.44653 3.82794C3.23816 4.03632 3.12109 4.31893 3.12109 4.61362V16.8358C3.12109 17.1305 3.23816 17.4131 3.44653 17.6215C3.6549 17.8299 3.93752 17.947 4.2322 17.947H16.4544C16.7491 17.947 17.0317 17.8299 17.2401 17.6215C17.4485 17.4131 17.5655 17.1305 17.5655 16.8358V6.70251L19.2322 5.03584C19.4059 4.86189 19.5035 4.62611 19.5035 4.38028C19.5035 4.13446 19.4059 3.89868 19.2322 3.72473ZM11.36 11.3525L9.0322 11.8692L9.58776 9.56362L14.8933 4.24695L16.6878 6.04139L11.36 11.3525ZM17.2878 5.40806L15.4933 3.61362L16.5211 2.58584L18.3155 4.38028L17.2878 5.40806Z"></path>
              </svg>
            
            Tasks <span className="g-header-count" style={{color:'black'}}>{count}</span>
          </span>

          <div className="g-header-action-content">
            <span
              className="g-add-task-button"
              style={{background:'white',height:'26px',width:'26px',display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'50%'}}
              onClick={(event) => handleAddAppointment(event, true)}
            >
              <AddIcon
                style={{
                  fontSize:'14px',
                  color: "var(--light_blue)",
                }}
              />
            </span>
            <span className={`${expand?"g-custom-caret-for-deal":"g-custom-caret"}`}>{icons.caretIcon}</span>
          </div>
        </CustomAccordionHeader>
        <CustomAccordionBody className="g-collapsible-body" expand={expand}>
          <div className="g-contact-appointment-content">
            {loading && <TaskSkeleton/>}
            {!loading && (
              <>
                <div
                  className="g-contact-appointment-list awesome__scroll_bar"
                  style={{ paddingBottom: "8px" }}
                >
                  {activity && 
                  // (
                  //   <p className="g-no-appointment" style={{display:"flex",justifyContent:"center"}}>
                  //     No active task found
                  //   </p>
                  // ) : (
                    
                    <RenderList list={activity} />
                  }
                </div>

                {/*{doneActivity.length > 0 &&*/}
                {/*<div className="g-contact-appointment-list awesome__scroll_bar">*/}
                {/*    <span className="g-done_activity_title">Mark as done task list : <span onClick={() => setShowDoneTask(!showDoneTask)} style={{ textDecoration: 'underline' }}>{showDoneTask ? 'Hide' : 'Show'}</span></span>*/}
                {/*    {showDoneTask && renderDoneList(doneActivity)}*/}
                {/*</div>*/}
                {/*}*/}
              </>
            )}
          </div>
        </CustomAccordionBody>
      </CustomAccordion>
    </div>
  );
};

export default Task;
