import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Button, makeStyles, Modal, Paper, } from "@material-ui/core";
import Styles from "./a2pCampaignModalStyles.module.css";
import CampaignDetails from "./CampaignDetails";
import SampleMessages from "./SampleMessages";
import EndUserConsent from "./EndUserConsent";
import {
    closeA2pModalApi,
    createCampaignUseCaseUpdateBrandV2Api,
    createCampaignUseCaseV2Api,
    form10DlcLink,
    getTwilioCampaignUseCaseApi
} from "../../api/globalApi";
import Processing from "./Processing";
import Utils from "../../helpers/Utils";
import ResponseV2 from "./ResponseV2";
import Icons from "./Icons";
import ConfirmPreview from "./ConfirmPreview";
import PreviewSampleMessage from "./PreviewSampleMessage";

const steps = [
    {
        label: "Campaign Details",
        title: "A2P/10DLC Registration - Campaign Details",
    },
    {
        label: "Sample Messages",
        title: "A2P/10DLC Registration - Sample Messages",
    },
    {
        label: "End User Consent",
        title: "A2P/10DLC Registration - End User Consent",
    }
];

const useStyles = makeStyles((theme) => {
    return {
        root: {
            "&.MuiButton-root": {
                backgroundColor: "#9670ff",
                padding: "5px 32px",
                borderRadius: "4px",
                fontWeight: 700,
            },
            "&.MuiButton-text": {
                color: "#9670ff"
            },
            "&.MuiButton-contained": {
                color: "white",
                padding: "7px 32px",
            },
            "&.MuiButton-outlined": {
                backgroundColor: "#ffffff",
                color: "#9670ff",
                borderColor: "#9670ff",
                border: "2px solid !important",
            }
        }
    };
});

const getOrPut = (needle, hey, emptyReplacer = "") => {
    try {
        if (hey[needle]) {
            return hey[needle] ? hey[needle] : emptyReplacer;
        } else {
            return emptyReplacer;
        }
    } catch (err) {
        return emptyReplacer;
    }
}

const generateFeedBack = (query, info) => {
    try {
        const parsedInfo = JSON.parse(info);
        const mango = parsedInfo.find((field) => field.fields[0] === query);

        return <span>{Icons.alert} {mango.description} <a className={Styles.url} href={mango.url} target="_blank" rel="noreferrer">Learn more {Icons.url}</a></span>;
    } catch (err) {
        return "";
    }
}

const MultiStepperModal = ({ isOpen, onClose, data }) => {
    const inputScrollToViewRef = useRef([]);
    const width = window.innerWidth - 288;
    const height = window.innerHeight - 264;

    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [submitting, setSubmitting] = useState(false);
    const [hasError, setHasError] = useState("");
    const [hasSuccess, setHasSuccess] = useState("");
    const [response, setResponse] = useState(null);
    const [isFinal, setIsFinal] = useState(false);
    const [a2pDlcData, setA2pDlcData] = useState({
        useCase: "",
        useCaseDescription: "",

        sampleMessage1: "",
        sampleMessage2: "",

        messageFlow: "",
        optOutKeywords: "CANCEL,END,QUIT,UNSUBSCRIBE,STOP,STOPALL",
        optOutMessage: "You have successfully been unsubscribed. You will not receive any more messages from this number. Reply START to resubscribe.",
        helpKeywords: "HELP,INFO",
        helpMessage: "Reply STOP to unsubscribe. Msg&Data Rates May Apply.",

        hasEmbeddedLinks: "False",
        hasEmbeddedPhone: "False",
        optOutLanguage1: "Reply stop to unsubscribe",
        optOutLanguage2: "Reply stop to unsubscribe",
        senderIdentification1: "Hi this is {first-name} from {my-company}",
        senderIdentification2: "Hi this is {first-name} from {my-company}",
    });
    const [a2pDlcError, setA2pDlcError] = useState({
        useCase: "",
        useCaseDescription: "",
        messageFlow: "",
        optOutKeywords: "",
        optOutMessage: "",
        senderIdentification1: "",
        senderIdentification2: "",
        sampleMessage1: "",
        sampleMessage2: "",
        optOutLanguage1: "",
        optOutLanguage2: ""
    });
    const [a2pDlcFeedBacks, setA2pDlcFeedBacks] = useState({
        useCase: "",
        useCaseDescription: "",
        messageFlow: "",
        optOutKeywords: "",
        optOutMessage: "",
        senderIdentification1: "",
        senderIdentification2: "",
        sampleMessage1: "",
        sampleMessage2: "",
        optOutLanguage1: "",
        optOutLanguage2: ""

    });
    const [campaignUseCase, setCampaignUseCase] = useState([]);
    const [isFeedBackAvailable, setIsFeedBackAvailable] = useState(false);
    const [isPreviewed, setIsPreviewed] = useState(false);
    const [dlcLink, setDlcLink] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let newData = { ...a2pDlcData };

        if (Utils.getAccountData("companyName")) {
            newData.senderIdentification1 = newData.senderIdentification1.replace("{my-company}", Utils.getAccountData("companyName"))
            newData.senderIdentification2 = newData.senderIdentification2.replace("{my-company}", Utils.getAccountData("companyName"))
        }

        if (Utils.getAccountData("fullName")) {
            newData.senderIdentification1 = newData.senderIdentification1.replace("{first-name}", Utils.getAccountData("fullName"))
            newData.senderIdentification2 = newData.senderIdentification2.replace("{first-name}", Utils.getAccountData("fullName"))
        }
        setA2pDlcData(newData);

        form10DlcLink()
            .then((res) => {
                if (res && res.data && res.data.link) {
                    setDlcLink(res.data.link);
                }
            })
            .catch((err) => {
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        if (data && data.messaging_service_info) {
            let sampleMessage1 = getOrPut("sample_message_1", data.messaging_service_info);

            if (sampleMessage1) {
                sampleMessage1 = sampleMessage1.replace(/Reply stop to unsubscribe/g, "");
            }

            let sampleMessage2 = getOrPut("sample_message_2", data.messaging_service_info);

            if (sampleMessage2) {
                sampleMessage2 = sampleMessage2.replace(/Reply stop to unsubscribe/g, "");
            }

            let senderIdentification1 = a2pDlcData.senderIdentification1;
            let senderIdentification2 = a2pDlcData.senderIdentification2;

            if (sampleMessage1) {
                senderIdentification1 = sampleMessage1.split("\n", 2)[0];
            }

            if (sampleMessage2) {
                senderIdentification2 = sampleMessage2.split("\n", 2)[0];
            }

            if (sampleMessage1) {
                const splitData1 = sampleMessage1.split("\n");
                sampleMessage1 = splitData1.slice(1).join("\n");
            }

            if (sampleMessage2) {
                const splitData2 = sampleMessage2.split("\n");
                sampleMessage2 = splitData2.slice(1).join("\n");
            }

            let messageFlow = "End users opt-in to receive messages from us by completing a form on our landing page. Before submitting the form, contacts are presented with a notice that by checking a box and submitting the form they are agreeing to be contacted by our business via text message and other communication mediums.\n" +
                "\n" +
                "You can view our landing page with the opt-in form here:\n" +
                "{your-form-link}\n\n" +
                "Our privacy policy and terms of use are located in links at the bottom of the landing page.";

            if (getOrPut("message_flow", data.messaging_service_info)) {
                messageFlow = getOrPut("message_flow", data.messaging_service_info);
            }

            if (dlcLink) {
                messageFlow = messageFlow.replace("{your-form-link}", dlcLink);
            }

            setA2pDlcData({
                ...a2pDlcData,
                useCase: getOrPut("campaign_use_cases", data.messaging_service_info),
                useCaseDescription: getOrPut("campaign_use_case_description", data.messaging_service_info),
                sampleMessage1: sampleMessage1,
                sampleMessage2: sampleMessage2,
                messageFlow: messageFlow,
                senderIdentification1: senderIdentification1,
                senderIdentification2: senderIdentification2,
            });

            if (data.messaging_service_info.campaign_registration_failure_reason_raw) {
                const rawInfo = data.messaging_service_info.campaign_registration_failure_reason_raw

                setA2pDlcFeedBacks({
                    ...a2pDlcFeedBacks,
                    useCaseDescription: generateFeedBack("USE_CASE_DESCRIPTION", rawInfo),
                    sampleMessage1: generateFeedBack("SAMPLE_MESSAGE_1", rawInfo),
                    sampleMessage2: generateFeedBack("SAMPLE_MESSAGE_2", rawInfo),
                    messageFlow: generateFeedBack("MESSAGE_FLOW", rawInfo)
                });

                setIsFeedBackAvailable(true);
            }
        }

    }, [data, dlcLink]); // eslint-disable-line react-hooks/exhaustive-deps

    const getTwilioCampaignUseCase = useCallback(() => {
        if (campaignUseCase && !campaignUseCase[0]) {
            setLoading(true);
            let payload = {};

            if (data && data.from === "UPDATE_BRAND") {
                payload.reason = "UPDATE";
            } else {
                payload.reason = "NEW";
            }
            getTwilioCampaignUseCaseApi(payload)
                .then(res => {
                    let response = res.data;
                    if (response.success) {
                        let rsData = response.data;
                        const removeCode = [
                            "AGENTS_FRANCHISES",
                            "EMERGENCY",
                            "K12_EDUCATION",
                            "PROXY",
                            "SOCIAL",
                            "SWEEPSTAKE"
                        ];
                        const cleanedUseCases = rsData.filter((obj) => !removeCode.includes(obj.code));

                        setCampaignUseCase(cleanedUseCases);
                    }
                }).catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getTwilioCampaignUseCase();
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    const validate = () => {
        if (a2pDlcData) {
            if (activeStep === 0) {
                let message = {
                    useCase: "",
                    useCaseDescription: "",
                };

                Object.entries(a2pDlcData).forEach(([key, value]) => {
                    if (key === "useCase" && value.trim() === "") {
                        message.useCase = "Campaign Use Case is required!";
                    } else if (key === "useCaseDescription" && value.trim() === "") {
                        message.useCaseDescription = "Campaign Use Case Description is required!";
                    } else if (key === "useCaseDescription" && (value.length < 40 || value.length > 4096)) {
                        message.useCaseDescription = "Campaign Use Case Description can not be less than 40 characters and more than 4096 characters!";
                    }
                });

                if (message && !Object.values(message).every(x => x === "")) {
                    setA2pDlcError({ ...a2pDlcError, ...message });

                    if (inputScrollToViewRef.current && inputScrollToViewRef.current.length > 0) {
                        let index = null;
                        let messages = Object.values(message)

                        for (let i = messages.length; i >= 0; i--) {
                            if (messages[i] !== "") {
                                index = i;
                            }
                        }

                        if (index !== null && index !== undefined && inputScrollToViewRef.current[index]) {
                            inputScrollToViewRef.current[index].scrollIntoView();
                        }
                    }

                    return false;
                }
                return true;
            }

            if (activeStep === 1) {
                let message = {
                    senderIdentification1: "",
                    senderIdentification2: "",
                    sampleMessage1: "",
                    sampleMessage2: "",
                };

                Object.entries(a2pDlcData).forEach(([key, value]) => {
                    if (key === "senderIdentification1" && value.trim() === "") {
                        message.senderIdentification1 = "Sender Identification 1 is required!";
                    } else if (key === "senderIdentification2" && value.trim() === "") {
                        message.senderIdentification2 = "Sender Identification 2 is required!";
                    } else if (key === "sampleMessage1" && value.trim() === "") {
                        message.sampleMessage1 = "Sample Message 1 is required!";
                    } else if (key === "sampleMessage1" && (value.length < 20 || value.length > 1024)) {
                        message.sampleMessage1 = "Sample Message 1 can not be less than 20 characters and more than 1024 characters!";
                    } else if (key === "sampleMessage2" && value.trim() === "") {
                        message.sampleMessage2 = "Sample Message 2 is required!";
                    } else if (key === "sampleMessage2" && (value.length < 20 || value.length > 1024)) {
                        message.sampleMessage2 = "Sample Message 2 can not be less than 20 characters and more than 1024 characters!";
                    }
                });

                if (message && !Object.values(message).every(x => x === "")) {
                    setA2pDlcError({ ...a2pDlcError, ...message });

                    if (inputScrollToViewRef.current && inputScrollToViewRef.current.length > 0) {
                        let index = null;
                        let messages = Object.values(message)

                        for (let i = messages.length; i >= 0; i--) {
                            if (messages[i] !== "") {
                                index = i;
                            }
                        }

                        if (index !== null && index !== undefined && inputScrollToViewRef.current[index]) {
                            inputScrollToViewRef.current[index].scrollIntoView();
                        }
                    }

                    return false;
                }
                return true;
            }

            if (activeStep === 2) {
                let message = {
                    messageFlow: "",
                    optOutKeywords: "",
                    optOutMessage: "",
                    helpKeywords: "",
                    helpMessage: "",
                };

                Object.entries(a2pDlcData).forEach(([key, value]) => {
                    if (key === "messageFlow" && value.trim() === "") {
                        message.messageFlow = "Consent Message Sample is required!";
                    } else if (key === "messageFlow" && (value.length < 40 || value.length > 2084)) {
                        message.messageFlow = "Message Flow can not be less than 40 characters and more than 2084 characters!";
                    } else if (key === "optOutKeywords" && value.trim() === "") {
                        message.optOutKeywords = "Opt Out Keywords is required!";
                    } else if (key === "optOutKeywords" && value.split(",").length > 0) {
                        const helperKeywordArray = value.split(",");

                        helperKeywordArray.forEach((keyword) => {
                            let index = keyword.indexOf(" ");
                            if (keyword === "") {
                                message.optOutKeywords = "Remove trailing comma from the Opt-out keywords!";
                            } else if (keyword.length > 255) {
                                message.optOutKeywords = "Each Opt-out keyword must be less then 255!";
                            } else if (index >= 0) {
                                message.optOutKeywords = "Opt-out Keyword cannot contain white-space!";
                            } else if (keyword.includes('_') || keyword.includes('-')) {
                                message.optOutKeywords = "Opt-out Keyword must contain either alphanumeric or accented characters!";
                            }
                        });
                    } else if (key === "optOutMessage" && value.trim() === "") {
                        message.optOutMessage = "Opt-out Message is required!";
                    } else if (key === "optOutMessage" && (value.length < 20 || value.length > 320)) {
                        message.optOutMessage = "Opt-out Message  can not be less than 20 characters and more than 320 characters!";
                    } else if (key === "helpKeywords" && value.trim() === "") {
                        message.helpKeywords = "Help Keywords is required!";
                    } else if (key === "helpKeywords" && value.split(",").length > 0) {
                        const helperKeywordArray = value.split(",");

                        helperKeywordArray.forEach((keyword) => {
                            let index = keyword.indexOf(" ");
                            if (keyword === "") {
                                message.helpKeywords = "Remove trailing comma from the Help keywords!";
                            } else if (keyword.length > 255) {
                                message.helpKeywords = "Each Help keyword must be less then 255!";
                            } else if (index >= 0) {
                                message.helpKeywords = "Help Keyword cannot contain white-space!";
                            } else if (keyword.includes('_') || keyword.includes('-')) {
                                message.helpKeywords = "Help Keyword must contain either alphanumeric or accented characters!";
                            }
                        });
                    } else if (key === "helpMessage" && value.trim() === "") {
                        message.helpMessage = "Help Message is required!";
                    } else if (key === "helpMessage" && (value.length < 20 || value.length > 320)) {
                        message.helpMessage = "Help Message  can not be less than 20 characters and more than 320 characters!";
                    }
                });

                if (message && !Object.values(message).every(x => x === "")) {
                    setA2pDlcError({ ...a2pDlcError, ...message });

                    if (inputScrollToViewRef.current && inputScrollToViewRef.current.length > 0) {
                        let index = null;
                        let messages = Object.values(message)

                        for (let i = messages.length; i >= 0; i--) {
                            if (messages[i] !== "") {
                                index = i;
                            }
                        }

                        if (index !== null && index !== undefined && inputScrollToViewRef.current[index]) {
                            inputScrollToViewRef.current[index].scrollIntoView();
                        }
                    }

                    return false;
                }
                return true;
            }
        }
    };

    const showPreview = () => {
        PreviewSampleMessage({
            formData: a2pDlcData,
            setIsPreviewed: setIsPreviewed,
        });
    };

    const showConfirmPreview = () => {
        ConfirmPreview({
            onSubmit: () => {
                showPreview();
            }
        });
    };

    const handleNext = () => {

        if (!validate()) {
            window.showNotification("Error", "Please fill the form correctly. For more details check the form.");
            return;
        }

        if (activeStep === 1 && !isPreviewed) {
            showConfirmPreview();
            return;
        }

        if (activeStep === (steps.length - 1)) {
            return;
        }
        if (activeStep === (steps.length - 2)) {
            setIsFinal(true);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 0) {
            return;
        }
        if (isFinal) {
            setIsFinal(false);
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleCloseForOneDay = async () => {
        onClose();
        await closeA2pModalApi();
    };

    const handleChangeA2pDlcData = (name, value) => {
        setA2pDlcData({ ...a2pDlcData, [name]: value });
    };

    const handleErrorCallback = (name, message) => {
        setA2pDlcError({ ...a2pDlcError, [name]: message });
    };

    const handleSubmitInformation = () => {

        if (!validate()) {
            return;
        }

        if (submitting) {
            return;
        }
        setSubmitting(true);

        let sampleMessage1 = `${a2pDlcData.senderIdentification1}\n${a2pDlcData.sampleMessage1}\n${a2pDlcData.optOutLanguage1}`;
        sampleMessage1 = sampleMessage1.replace(/\n+/g, "\n");

        let sampleMessage2 = `${a2pDlcData.senderIdentification2}\n${a2pDlcData.sampleMessage2}\n${a2pDlcData.optOutLanguage2}`;
        sampleMessage2 = sampleMessage2.replace(/\n+/g, "\n");

        let form_data = {
            description: a2pDlcData.useCaseDescription,
            messageFlow: a2pDlcData.messageFlow,
            optOutKeywords: a2pDlcData.optOutKeywords,
            optOutMessage: a2pDlcData.optOutMessage,
            helpKeywords: a2pDlcData.helpKeywords,
            helpMessage: a2pDlcData.helpMessage,
            sampleMessage1: sampleMessage1,
            sampleMessage2: sampleMessage2,
            usAppToPersonUsecase: a2pDlcData.useCase,
            hasEmbeddedLinks: a2pDlcData.hasEmbeddedLinks,
            hasEmbeddedPhone: a2pDlcData.hasEmbeddedPhone,
            from: "campaign_registration_only_from_modal"
        }

        if (data && data.from === "UPDATE_BRAND") {
            createCampaignUseCaseUpdateBrandV2Api(form_data).then(res => {
                let response = res.data;
                if (response.success) {
                    setHasSuccess("Successfully submitted the form.");
                    setResponse(response);
                    if (data.callback) {
                        data.callback();
                    }
                } else {
                    if (response.message) {
                        setHasError(response.message);
                    } else {
                        setHasError("Can not register 10DLC information!");
                    }
                }
                setSubmitting(false);
            }).catch(error => {
                setSubmitting(false);
                console.log(error);
            });
        } else {
            createCampaignUseCaseV2Api(form_data).then(res => {
                let response = res.data;
                if (response.success) {
                    setHasSuccess("Successfully submitted the form.");
                    setResponse(response);
                    if (data.callback) {
                        data.callback();
                    }
                } else {
                    if (response.message) {
                        setHasError(response.message);
                    } else {
                        setHasError("Can not register 10DLC information!");
                    }
                }
                setSubmitting(false);
            }).catch(error => {
                setSubmitting(false);
                console.log(error);
            });
        }
    };

    const handleRedirectHelp = () => {

        if (Utils.getAccountData('agencyId') === 1) {
            return `https://softwarehelp.freshdesk.com/support/solutions/articles/66000517994-understanding-a2p-10dlc-registration`;
        }
        return `${process.env.REACT_APP_CDN_LINK}asset/files/releases/1.0.0/10dlc-help.pdf`;
    }

    const getStepBody = () => {
        switch (activeStep) {
            case 0:
                return <CampaignDetails
                    inputScrollToViewRef={inputScrollToViewRef}
                    formData={a2pDlcData}
                    a2pDlcError={a2pDlcError}
                    callback={handleChangeA2pDlcData}
                    errorCallback={handleErrorCallback}
                    campaignUseCase={campaignUseCase}
                    setCampaignUseCase={setCampaignUseCase}
                    a2pDlcFeedBacks={a2pDlcFeedBacks}
                    loading={loading}
                />;
            case 1:
                return <SampleMessages
                    inputScrollToViewRef={inputScrollToViewRef}
                    formData={a2pDlcData}
                    a2pDlcError={a2pDlcError}
                    callback={handleChangeA2pDlcData}
                    errorCallback={handleErrorCallback}
                    a2pDlcFeedBacks={a2pDlcFeedBacks}
                    setIsPreviewed={setIsPreviewed}
                    isPreviewed={isPreviewed}
                    showPreview={showPreview}
                />;
            case 2:
                return <EndUserConsent
                    inputScrollToViewRef={inputScrollToViewRef}
                    formData={a2pDlcData}
                    a2pDlcError={a2pDlcError}
                    callback={handleChangeA2pDlcData}
                    errorCallback={handleErrorCallback}
                    a2pDlcFeedBacks={a2pDlcFeedBacks}
                />
            default:
                return;
        }
    };

    const getStepTitle = (index) => {
        return steps[index]?.title;
    };

    const handleAfterResponseClose = ({ onlyClose = true, redirect = false }) => {
        if (onlyClose && redirect) {
            if (response.success) {
                window.location.reload();
            }
        } else if (onlyClose) {
            setSubmitting(false);
            setHasError("");
            setHasSuccess("");
            setActiveStep(0);
            setIsFinal(false);
        }
    };

    return (
        <Modal
            open={isOpen}
            onClose={() => { }}
            aria-labelledby="multi-stepper-modal-title"
            aria-describedby="multi-stepper-modal-description"
            className={`${Styles.modalWrapper} global-xs-modal add_list_modal`}
            disableEnforceFocus
        >
            <Paper className={Styles.modalInner}>
                {
                    submitting ?
                        <Processing
                            style={{ width: width, height: (height + 222) }}
                        /> :
                        (
                            (!submitting && (hasError || hasSuccess || response)) ?
                                <ResponseV2
                                    hasError={hasError}
                                    hasSuccess={hasSuccess}
                                    response={response}
                                    handleClose={handleAfterResponseClose}
                                /> :
                                <Fragment>
                                    <div className={Styles.headerWrapper}>
                                        {
                                            steps.map((step, index) => (
                                                <div className={`${Styles.tabStepper} ${activeStep === index ? Styles.tabStepperActive : ""}`}>
                                                    <span className={Styles.tabStepperLabel}>{(index + 1)}. {step.label}</span>
                                                </div>
                                            ))
                                        }
                                    </div>

                                    <div className={Styles.modalBody}>
                                        <div className={Styles.modalBodyHeader}>
                                            <div className={Styles.modalBodyTitleWrapper}>
                                                <h1 className={Styles.modalBodyTitle}>{getStepTitle(activeStep)}</h1>
                                            </div>
                                            <div className={Styles.modalBodyTools}>
                                                <Button size="small" target="_blank" href={handleRedirectHelp()} className={`${classes.root} ${Styles.mr1}`} variant="outlined">
                                                    ACCESS HELP GUIDE
                                                </Button>
                                                <Button size="small" onClick={handleCloseForOneDay} className={`${classes.root} ${Styles.redButton}`} variant="outlined">
                                                    EXIT
                                                </Button>
                                            </div>
                                        </div>
                                        <div style={{ width: width, height: height }} className={Styles.bodyContainer}>
                                            {
                                                isFeedBackAvailable &&
                                                <div style={{ fontSize: "14px", color: "red" }} className={Styles.baseAlert}>
                                                    {Icons.alert} This campaign was rejected upon review
                                                </div>
                                            }
                                            {/*{*/}
                                            {/*    isFeedBackAvailable &&*/}
                                            {/*    <ul>*/}
                                            {/*        {generateOtherFeedBacks(otherFeedBacks)}*/}
                                            {/*    </ul>*/}
                                            {/*}*/}
                                            {getStepBody()}
                                        </div>
                                    </div>

                                    <div className={Styles.modalFooter}>
                                        {
                                            activeStep !== 0 &&
                                            (
                                                <Button size="large" onClick={handleBack} className={`${classes.root} ${Styles.mr1}`} variant="outlined">
                                                    Back
                                                </Button>
                                            )
                                        }
                                        {/* <Button size="large"  disabled={submitting || (activeStep === 1 && (!isPreviewed || edited))} onClick={isFinal ? handleSubmitInformation : handleNext} className={`${classes.root} ${Styles.overwriteButton}`} variant="contained">
                                            {isFinal ? (submitting ? "Submitting Information..." : "Submit Information") : "Next"}
                                        </Button> */}

                                        <Button size="large" disabled={submitting} onClick={isFinal ? handleSubmitInformation : handleNext} className={`${classes.root} ${Styles.overwriteButton}`} variant="contained">
                                            {isFinal ? (submitting ? "Submitting Information..." : "Submit Information") : "Next"}
                                        </Button>
                                    </div>
                                </Fragment>
                        )

                }
            </Paper>
        </Modal>
    );
};

export default MultiStepperModal;