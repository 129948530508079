import React from 'react';
import NotificationsIcon from "@material-ui/icons/Notifications";
import { ChatIcon , EmailIcon , VideoEmailIcon , VoiceMailIcon , CallIcon , NotecardIcon,LetterIcon ,GiftIcon,SmsIcon,AppointmentIcon,AppointmentReminderIcon,FormIcon} from './InboxDrawerIcon';

const InboxContentTypeIcon = ({ contentType }) => {
  const CONTENT_TYPE_SMS = 1;
  const CONTENT_TYPE_MMS = 2;
  const CONTENT_TYPE_VOICE = 3;
  const CONTENT_TYPE_EMAIL = 4;
  const CONTENT_TYPE_CALL = 5;
  const CONTENT_TYPE_APPOINTMENT = 12;
  const CONTENT_TYPE_VIDEO_EMAIL = 14;
  const CONTENT_TYPE_CHAT = 15;
  const CONTENT_TYPE_POST_CARD = 21;
  const CONTENT_TYPE_GREETINGS_CARD = 22;
  const CONTENT_TYPE_GIFT = 23;
  const CONTENT_TYPE_LETTER = 24;
  const CONTENT_TYPE_CALL_RECORD = 25;
  const CONTENT_TYPE_FORM_RESPONSE = 26;
  const CONTENT_TYPE_THANKSIO_GREETINGCARD = 27;
  const CONTENT_TYPE_APPOINTMENT_REMINDER = 28;
  const CONTENT_TYPE_THANKSIO_GIFT = 29;
  const CONTENT_TYPE_SMART_FORM_RESPONSE = 30;

  const getIcon = () => {
    switch (contentType) {
      case CONTENT_TYPE_SMS:
        return <ChatIcon />;
      case CONTENT_TYPE_MMS:
        return <EmailIcon/>;
      case CONTENT_TYPE_VOICE:
        return <VoiceMailIcon/>;
      case CONTENT_TYPE_EMAIL:
        return <EmailIcon />;
      case CONTENT_TYPE_CALL:
        return <CallIcon />;
      case CONTENT_TYPE_APPOINTMENT:
        return <AppointmentIcon />;
      case CONTENT_TYPE_VIDEO_EMAIL:
        return <VideoEmailIcon />;
      case CONTENT_TYPE_CHAT:
        return < SmsIcon/>;
      case CONTENT_TYPE_POST_CARD:
        return <NotecardIcon />;
      case CONTENT_TYPE_GREETINGS_CARD:
        return <NotecardIcon />;
      case CONTENT_TYPE_GIFT:
        return <GiftIcon />;
      case CONTENT_TYPE_LETTER:
        return <LetterIcon />;
      case CONTENT_TYPE_CALL_RECORD:
        return <CallIcon/>;
      case CONTENT_TYPE_FORM_RESPONSE:
        return <FormIcon />;
      case CONTENT_TYPE_THANKSIO_GREETINGCARD:
        return <NotecardIcon />;
      case CONTENT_TYPE_APPOINTMENT_REMINDER:
        return <AppointmentReminderIcon />;
      case CONTENT_TYPE_THANKSIO_GIFT:
        return <GiftIcon />;
      case CONTENT_TYPE_SMART_FORM_RESPONSE:
        return <FormIcon />;
      default:
        return <NotificationsIcon />;
    }
  };

  return (
    <div
      style={{
        padding: "10px",
        backgroundColor: "#f0f0f5", // Light gray color
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "40px",
        height: "40px",
      }}
    >
      {getIcon()}
    </div>
  );
};

export default InboxContentTypeIcon;