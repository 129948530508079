import React, { useEffect, useRef, useState } from "react";
import {
    ContactAvatar,
    ContactInfo,
    ContactLine,
    ContactName,
    SearchResult,
    SearchResults,
    SearchResultWrapper,
    SearchWrapper
} from "../GlobalAddDealModalStyles";
import { InputAdornment, InputBase, withStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import PersonIcon from "@material-ui/icons/Person";
import EmptyScreen from "./EmptyScreen";
import addDealUseDelayCallback from "../../GlobalAddDeal/assets/_hooks/_useDelayCallbackAddDeal";
import { getElasticContacts } from "../helpers/Api";
import { useIsMounted } from "../../../hooks/useIsMounted";
export const BasicInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
            fontFamily: 'Poppins !impotant',
        },
        "&.MuiInputBase-multiline": {
            padding: "0 !important",
        },
        "& .MuiInputAdornment-positionEnd": {
            position: "absolute",
            right: "12px"
        },
    },

    input: {
        color: "#36454F !important",
        position: "relative",
        backgroundColor: "white !important",
        fontSize: "15px !important",
        margin: "0 !important",
        height: "2.5rem !important",
        padding: "10px 12px 10px  !important",
        borderRadius: "4px !important",
        border: "1px solid #ced4da !important",
        fontWeight: '500',
        transition: theme.transitions.create(["border-color", "box-shadow"]),

        "&:focus": {
            borderRadius: 4,
            boxShadow: "unset !important",
            color: "#36454F !important",
        },

        "&.MuiSelect-select": {
            height: "1rem !important",
        },
        "&::placeholder": {
            color: "#082852 !important",
            fontSize: "15px !important",
            fontWeight: '500 !important'

        },
        "&::-webkit-input-placeholder": {
            color: "#082852 !important",
            fontWeight: '500 !important',
        },
        "&::-moz-placeholder": {
            color: "#082852 !important",
            fontWeight: '500 !important',
        },
        "&::-ms-input-placeholder": {
            color: "#082852 !important",
            fontWeight: '500 !important',
        }
    },
}))(InputBase);

const ContactSearch = ({ callback }) => {
    const isMounted = useIsMounted();
    const searchBoxRef = useRef(null);
    const [searchText, setSearchText] = useState("");
    const [showSearchResult, setShowSearchResult] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [emptyScreenText, setEmptyScreenText] = useState("No contact found");
    const [results, setResults] = useState([]);

    useEffect(() => {
        if (showSearchResult) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showSearchResult]);

    addDealUseDelayCallback(() => {
        getElastic();
    }, [searchText]);

    const getElastic = async () => {
        if (isMounted) {
            await handleGetElasticContacts();
        }
    }

    const handleSearch = (e) => {
        setLoadingData(true);
        setShowSearchResult(!!e.target.value);
        setEmptyScreenText("Please wait...");
        setSearchText(e.target.value);
    };

    const handleClickOutside = (event) => {
        if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {
            setShowSearchResult(false);
            setSearchText("");
        }
    };

    const handleGetElasticContacts = async () => {
        try {
            const resp = await getElasticContacts({
                page: 1,
                size: 10,
                search: searchText,
            });

            if (resp?.status === "SUCCESS" && resp?.data?.contactInfos[0]) {
                setResults(resp.data.contactInfos);
                setEmptyScreenText("");
            } else {
                setResults([]);
                setEmptyScreenText("No contact found");
            }
        } catch (err) {
            console.log(err);
            setResults([]);
            setEmptyScreenText("No contact found");
        } finally {
            setLoadingData(false);
        }
    };

    return (
        <SearchWrapper ref={searchBoxRef}>
            <BasicInput
                fullWidth
                value={searchText}
                placeholder="Search for existing contact"
                endAdornment={
                    <InputAdornment position="end">
                        <SearchIcon style={{ color: "#656565" }} />
                    </InputAdornment>
                }
                onChange={handleSearch}
                onFocus={() => {
                    if (searchText !== "") {
                        setShowSearchResult(true);
                    }
                }}
            />
            {
                showSearchResult &&
                <SearchResultWrapper>
                    {
                        !loadingData &&
                        <SearchResults>
                            {
                                results.map((result) => {
                                    let name = "";
                                    let avatar = "";

                                    if (result["firstName"]) {
                                        name = result["firstName"];
                                        avatar = result["firstName"].substring(0, 1);
                                    }

                                    if (name) {
                                        name += " ";
                                        name += result["lastName"];
                                        avatar += result["lastName"].substring(0, 1);
                                    }

                                    return (
                                        <SearchResult onClick={() => {
                                            callback(result);
                                            setShowSearchResult(false);
                                            setSearchText("");
                                        }}>
                                            <ContactAvatar>
                                                {avatar || <PersonIcon />}
                                            </ContactAvatar>
                                            <ContactInfo>
                                                <ContactName>
                                                    {name}
                                                </ContactName>
                                                <ContactLine>
                                                    {result["email"]}
                                                </ContactLine>
                                                <ContactLine>
                                                    {result["number"]}
                                                </ContactLine>
                                            </ContactInfo>
                                        </SearchResult>
                                    );
                                })
                            }
                        </SearchResults>
                    }
                    {
                        (loadingData || emptyScreenText) &&
                        <EmptyScreen text={emptyScreenText} />
                    }
                </SearchResultWrapper>
            }
        </SearchWrapper>
    );
}

export default ContactSearch;