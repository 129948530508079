const CONTACT_EMAIL = 'CONTACT EMAIL';
const FIRST_NAME = 'FIRST NAME';
const LAST_NAME = 'LAST NAME';
const MY_VIRTUAL_NUMBER = 'My virtual number';
const MY_COMPANY = 'My Company';
const MY_NAME = 'My Name';
const MY_FIRST_NAME = 'My First Name';
const MY_LAST_NAME = 'My Last Name';
const MY_PHONE = 'My Phone';
const MY_SIGNATURE = 'My Signature';
const MY_APPOINTMENT_URL = 'My Appointment URL';
const TODAY = 'Today Date';
const TOMORROW = 'Tomorrow Date';
const CONTACT_COUNTRY = 'Property Country';
const CONTACT_STATE = 'Property State';
const CONTACT_STREET_ADDRESS = 'Property Street Address';
const CONTACT_URL = 'Property URL';
const CONTACT_ZIP_CODE = 'Property Zip Code';
const CONTACT_PHONE_NUMBER = 'Contact Phone Number';
const CONTACT_CITY = 'Contact City';
const CONTACT_DEAL_VALUE = 'Contact Deal Value';
const CONTACT_BIRTH_DATE = 'Contact Birth Date';
const CONTACT_ANNIVERSARY_DATE = 'Contact Anniversary Date';
const CONTACT_COMPANY_NAME = 'Contact Company Name';
const AGENCY_FULL_NAME_PERSONALIZE = 'Agency Full Name';
const MY_EMAIL = 'My Email';

export const DefaultFieldGroup = [
    {
        group_name: 'Contact',
        list: [
            {
                field_label: FIRST_NAME,
                value: '[[first_name]]',
                order: 1
            },
            {
                field_label: LAST_NAME,
                value: '[[last_name]]',
                order: 2
            },
            {
                field_label: CONTACT_EMAIL,
                value: '[[email]]',
                order: 3
            },
            {
                field_label: CONTACT_PHONE_NUMBER,
                value: '[[contact_phone_number]]',
                order: 4
            },
            {
                field_label: CONTACT_COUNTRY,
                value: '[[contact_country]]',
                order: 5
            },
            {
                field_label: CONTACT_STATE,
                value: '[[contact_state]]',
                order: 6
            },
            {
                field_label: CONTACT_CITY,
                value: '[[contact_city]]',
                order: 7
            },
            {
                field_label: CONTACT_STREET_ADDRESS,
                value: '[[contact_street_address]]',
                order: 8
            },
            {
                field_label: CONTACT_COMPANY_NAME,
                value: '[[contact_company_name]]',
                order: 9
            },
            {
                field_label: CONTACT_URL,
                value: '[[contact_url]]',
                order: 10
            },
            {
                field_label: CONTACT_ZIP_CODE,
                value: '[[contact_zip_code]]',
                order: 11
            },
            {
                field_label: CONTACT_DEAL_VALUE,
                value: '[[contact_deal_value]]',
                order: 12
            },
            {
                field_label: CONTACT_BIRTH_DATE,
                value: '[[contact_birth_date]]',
                order: 13
            },
            {
                field_label: CONTACT_ANNIVERSARY_DATE,
                value: '[[contact_anniversary_date]]',
                order: 14
            }
        ],
        total: 14,
        group_value: 'pype_default_contact'
    },
    {
        group_name: 'User',
        list: [
            {
                field_label: MY_VIRTUAL_NUMBER,
                value: '[[virtual_number]]',
                order: 1
            },
            {
                field_label: MY_COMPANY,
                value: '[[company]]',
                order: 2
            },
            {
                field_label: MY_NAME,
                value: '[[my_name]]',
                order: 3
            },
            {
                field_label: MY_FIRST_NAME,
                value: '[[my_first_name]]',
                order: 4
            },
            {
                field_label: MY_LAST_NAME,
                value: '[[my_last_name]]',
                order: 5
            },
            {
                field_label: MY_PHONE,
                value: '[[my_phone]]',
                order: 6
            },
            {
                field_label: MY_SIGNATURE,
                value: '[[my_signature]]',
                order: 7
            },
            {
                field_label: MY_APPOINTMENT_URL,
                value: '[[appointment_url]]',
                order: 8
            },
            {
                field_label: MY_EMAIL,
                value: '[[my_email]]',
                order: 9
            }
        ],
        total: 9,
        group_value: 'pype_default_user'
    },
    {
        group_name: 'Date',
        list: [
            {
                field_label: TODAY,
                value: '[[today]]',
                order: 1
            },
            {
                field_label: TOMORROW,
                value: '[[tomorrow]]',
                order: 2
            }
        ],
        total: 2,
        group_value: 'pype_date'
    },
    {
        group_name: 'Agency',
        list: [
            {
                field_label: AGENCY_FULL_NAME_PERSONALIZE,
                value: '[[agency_full_name]]',
                order: 1
            }
        ],
        total: 1,
        group_value: 'pype_agency'
    }
];