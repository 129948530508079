import React from "react";
import { Button, styled, Typography } from "@material-ui/core";

const Wrapper = styled("div")(({ theme }) => ({
  maxWidth: 625,
  minHeight: 250,
   color:'var(--dark_blue)',

  "& .modalHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    borderBottom: "1px solid #d3d3d3",

    "& .titleWrapper": {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },

    "& .titleHeader": {
      fontSize: "20px",
      fontWeight:600,
    },
  },

  "& .modalBody": {
    padding: "16px 24px 0",
    textAlign: "center",

    "& .bodyContent": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
"& .closeButton": {
      minWidth: 130,
      border: "1px solid var(--red)",
      color: "var(--red) !important",
      "&:hover, &:focus": {
      backgroundColor: "transparent",
    }
  },

  "& .modalFooter": {
    padding: 16,
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const NumberAdded = ({ onClose, data }) => {

  const handleClose = () => {
    onClose();
    if (data && data.onClose){
      data.onClose();
    }
  };

  return (
    <Wrapper className="contentWrapper">
      <div className="modalHeader">
        <div className="titleWrapper">
          <img
            src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/1.0.0/phone-number-popup.png`} 
            height="50px"
            alt="logo"
          />
          <Typography className={"titleHeader"}>Phone Settings</Typography>
        </div>
      </div>

      <div className="modalBody">
        <div className="bodyContent">
          <div>
            <h5 style={{ fontSize: 20, fontWeight: 600 }}>
              The phone number has been added to your account.
            </h5>
            <p>
              Close this popup to continue configuring your phone number. You
              can add a voicemail message, turn on call recording, forward
              calls, and more.
            </p>
          </div>
        </div>
      </div>

      <div className="modalFooter">
        <Button
          variant="outlined"
          className={"closeButton"}
          disableElevation
          onClick={handleClose}
        >
          Close
        </Button>
      </div>
    </Wrapper>
  );
};

export default NumberAdded;
