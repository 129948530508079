/* eslint-disable react-hooks/exhaustive-deps */
import { Drawer, IconButton, styled } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  APPOINTMENT_SCHEDULE_PAST,
  APPOINTMENT_SCHEDULE_TODAY,
  APPOINTMENT_SCHEDULE_UPCOMING,
} from "../../../../constants/CoreConstants";
import Utils from "../../../../helpers/Utils";
import CardSectionTab from "../CardSectionTab";
import { CustomScrollbar } from "../common/CustomScrollbar";
import { ActivityIcon } from "../common/Icons";
import RightListSkeleton from "../common/RightListSkeleton";
import SubTitle from "../common/SubTitle";
import Title from "../common/Title";
import { fetchUserActivity, resetSidebarPage, setSidebarPage, setSidebarScheduleType } from "../../../../actions/floatingSidebarAction";

export const DrawerWrapper = styled(Drawer)(({ theme }) => ({
  "& .wrapper": {
    width: 500,
  },

  "& .headerWrapper": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "18px 22px",
    borderBottom: "1px solid #E9EAEC",

    "& .headerTitle": {
      fontSize: 20,
      margin: 0,
      fontWeight: 600,
    },
  },

  "& .bodyWrapper": {
    padding: "12px 16px",
  },

  "& .taskTab": {
    marginBottom: 16,
  },

  "& .activities": {
    display: "flex",
    flexDirection: "column",
    gap: 12,
  },

  "& .activityItem": {
    padding: "8px 14px",
    borderRadius: 12,
    border: "1px solid #E9EAEC",

    display: "flex",
    justifyContent: "",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px !important",

    "& .activityText": {
      "& .title": {
        fontSize: "16px",
        cursor: "pointer",
        lineHeight: "21px",
        fontWeight: 400,

        width: 290,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",

        "&:hover": {
          color: "#346FEF",
        },
      },
    },

    "& .dateTime": {
      color: "#7E869A",
      fontSize: 12,
      lineHeight: "18px",
    },
  },
}));


const ActivityDrawer = ({ open, onClose }) => {
  const { isLoading, scheduleType, activityList, page, hasMore } = useSelector((state) => state.floatingSidebarReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchUserActivity({
        schedule: scheduleType,
        page: 1,
      })
    );
  }, [scheduleType]);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollHeight - scrollTop <= clientHeight + 50 && hasMore) {
      dispatch(setSidebarPage(page + 1));
    }
  };

  useEffect(() => {
    if (page > 1) {
      dispatch(
        fetchUserActivity({
          schedule: scheduleType,
          page: page,
        })
      );
    }
  }, [page]);

  const renderActivities = () => {
    if (isLoading && page === 1) {
      return <RightListSkeleton itemCount={4} />;
    } else if (!activityList.length) {
      return <p className="d-flex justify-content-center">NO ACTIVITIES</p>;
    }

    return activityList.map((activity, index) => {
      const fullName = activity.contact_id
        ? activity.first_name + " " + activity.last_name
        : "";

      return (
        <div className="activityItem" key={index}>
          <span style={{ marginTop: "7px", paddingRight: "10px" }}>
            <ActivityIcon />
          </span>
          <div className="activityText">
            <span /* onClick={(e) => openActivity(e)} */>
              <Title
                className="title"
                text={Utils.getContactDisplayName(activity.task_title)}
              />
            </span>
            <SubTitle
              text={window.globalTimezoneConversionToDifferentTimezone(
                activity.date_time,
                "UTC",
                "",
                "MM-DD-YYYY ,ddd LT"
              )}
              className="dateTime"
            />
          </div>

          {activity.contact_id && (
            <div>
              <Link
                to={`/contacts/${activity.contact_id}`}
                onClick={(e) => {
                  e.preventDefault();
                  // history.push(`/contacts/${activity.contact_id}`);
                  onClose();
                }}
              >
                <SubTitle
                  text={`With ${
                    Utils.getContactDisplayName(
                      fullName,
                      activity.number,
                      activity.email
                    ) || ""
                  }`}
                  className="subTitle"
                />
              </Link>
            </div>
          )}
        </div>
      );
    });
  };

  const closeDrawer = () => {
    onClose();
    dispatch(resetSidebarPage());
  };

  return (
    <DrawerWrapper anchor={"right"} open={open} onClose={closeDrawer}>
      <div className="wrapper">
        <div className="headerWrapper">
          <h6 className="headerTitle">Activity</h6>

          <IconButton
            className="closeButton"
            size="small"
            onClick={closeDrawer}
          >
            <HighlightOffIcon style={{ color: "#ff0055" }} />
          </IconButton>
        </div>

        <div className="bodyWrapper">
          <CardSectionTab
            list={[
              { id: APPOINTMENT_SCHEDULE_PAST, title: "Past" },
              { id: APPOINTMENT_SCHEDULE_TODAY, title: "Today" },
              { id: APPOINTMENT_SCHEDULE_UPCOMING, title: "Future" },
            ]}
            activeTab={scheduleType}
            onActiveTab={(id) => dispatch(setSidebarScheduleType(id))}
            className="taskTab"
          />

          <CustomScrollbar
            style={{ maxHeight: "calc(100vh - 125px)", overflow: "auto" }}
            onScroll={handleScroll}
          >
            <div className="activities">{renderActivities()}</div>
            {isLoading && <RightListSkeleton itemCount={3} />}
          </CustomScrollbar>
        </div>
      </div>
    </DrawerWrapper>
  );
};

export default ActivityDrawer;
