import {InputBase, Button, styled, withStyles, Typography, Modal} from "@material-ui/core";
import MaskedInput from "react-text-mask";

export const ContentWrapper = styled("div")(({ theme }) => ({
    position: "relative",
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),

    "& .textCenter": {
        textAlign: "center",
    },

    "& .productItem:not(:last-child)": {
        marginBottom: "8px",
    },

    "& .productValueWrapper": {
        display: "flex",
        alignItems: "center",
        gap: 8,
    },
    "& .productValueInputWrapper": {
        position: "relative"
    },
    "& .productValueSign": {
        position: "absolute",
        top: "9px",
        left: "10px",
        zIndex: 1,
        fontSize: "14px"
    },
    "& .productValueInputWrapper input": {
        paddingLeft: "20px !important"
    }
}));

export const SearchAndAddWrapper =styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: 16,
}));

export const FooterWrapper = styled("div")(({ theme }) => ({
    height: 70,
    color: "#FFF",
    paddingInline: "24px",
    borderEndStartRadius: "8px",
    borderEndEndRadius: "8px",
    backgroundColor: "rgb(24, 31, 72)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& h5": {
        color: "#FFF !important",
        fontSize:'28px',
        fontWeight:'500'
    }
}));

export const SearchWrapper = styled("div")(({ theme }) => ({
    position: "relative",
    flex: "1 auto"
}));

export const SearchResultWrapper = styled("div")(({ theme }) => ({
    position: "absolute",
    backgroundColor: "var(--white)",
    boxShadow: "0 0 2px #000",
    width: "100%",
    borderRadius: "4px",
    zIndex: 1
}));

export const SearchResults = styled("div")(({ theme }) => ({
    maxHeight: "300px",
    overflowY: "auto"
}));

export const SearchResult = styled("div")(({ theme }) => ({
    padding: "6px 15px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
        backgroundColor: "#dadada"
    }
}));

export const ContactAvatar = styled("span")(({ theme })=>({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "16px",
    textTransform: "uppercase",
    marginRight: "10px",
    background: "var(--gray_dark)",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    fontSize: "12px"
}));

export const ContactInfo = styled("div")(({ theme }) => ({
    padding: "10px 10px 10px 2px",
}));

export const ContactName = styled("div")(({ theme }) => ({
    fontSize: "14px",
    lineHeight: "22px"
}));

export const ContactLine = styled("div")(({ theme }) => ({
    fontSize: "12px",
    color: "#7b7e8a",
    lineHeight: "22px"
}));

export const EmptyScreenWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "130px",
    fontSize: "12px",
    fontStyle: "italic",
    color: "#745e5e"
}));

export const ContactAddModal = styled(Modal)(({ theme }) => ({
    "& .modalWrapper": {
        overflow: "hidden",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 800,
        backgroundColor: "white",
        boxShadow: "24px",
        borderRadius: 8,
    },

    "& .modalHeader": {
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "20px",
        backgroundColor: "var(--light_blue)",
    },

    "& .formLabel": {
        color: "var(--dark_blue)",
        fontSize: "14px",
        fontWeight: "500",
        marginBottom: "4px",
    },

    "& .textCenter": {
        textAlign: "center",
    },

    "& .modalBody": {
        padding: "20px",
        maxHeight: "calc(100vh - 300px)",
    },

    "& .modalFooter": {
        background: "#00042e",
        padding: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    "& .saveButton": {
        width: "350px",
        fontWeight: 600,
        backgroundColor: "#3C7EF3",
        color: "white",
        padding: "10px 20px",
        borderRadius: "5px",
        "&:hover, &:focus": {
            backgroundColor: "#3C7EF3",
            color: "white",
        },
    },
}));

export const ContactAddModalWrapper = styled("div")(({ theme }) => ({
    overflow: "hidden",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    backgroundColor: "white",
    boxShadow: "24px",
    borderRadius: 8,
}));

export const ContactAddModalHeader = styled("div")(({ theme }) => ({
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px",
    backgroundColor: "var(--light_blue)",
}));

export const ContactAddModalBody = styled("div")(({ theme }) => ({
    padding: "20px",
    maxHeight: "calc(100vh - 300px)",
}));

export const BasicCloseButton = styled("div")(({ theme }) => ({
    background: "var(--red)",
    width: "26px",
    height: "26px",
    borderRadius: "50%",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}));

export const BasicInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
        "&.MuiInputBase-multiline": {
            padding: "0 !important",
        },
        "& .MuiInputAdornment-positionEnd": {
            position: "absolute",
            right: "12px"
        },
    },


    input: {
        position: "relative",
        // color: "var(--dark_blue)",
        backgroundColor: "white !important",
        fontSize: "15px !important",
        margin: "0 !important",
        height: "2.5rem !important",
        padding: "10px 12px 10px  !important",
        borderRadius: "4px !important",
        border: "1px solid #ced4da !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),

        "&:focus": {
            borderRadius: 4,
            boxShadow: "unset !important",
        },

        "&.MuiSelect-select": {
            height: "1rem !important",
        },
        "&::placeholder": {
            color: "#082852 !important",       
        },


    },
}))(InputBase);

export const BasicInputSelect = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        backgroundColor: "white !important",
        position: "relative",
        color: "var(--dark_blue)",
        fontSize: "13px",
        margin: "0 !important",

        padding: " 9px  !important",
        borderRadius: "4px !important",
        border: "1px solid #ced4da !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),

        "&:focus": {
            borderRadius: 4,
            boxShadow: "unset !important",
            backgroundColor: "#fff !important"
        },
    },
}))(InputBase);

export const BasicButton = withStyles((theme) => ({
    root: {
        backgroundColor: "#3C7EF3 !important",
        fontWeight: "600 !important",
        color: "white !important",
        minWidth: "fit-content !important",
        "&:hover, &:focus": {
            backgroundColor: "#3C7EF3 !important",
            color: "white !important",
        },
        '& .MuiSvgIcon-root': {
            color: 'white',
        },
    }
}))(Button);

export const BasicFormLabel = withStyles((theme) => ({
    root: {
        color: "var(--dark_blue) !important",
        fontSize: "14px !important",
        fontWeight: "600 !important",
        marginBottom: "4px !important",
        height: "unset !important"
    }
}))(Typography);

export const BasicFormHelperText = withStyles((theme) => ({
    root: {
        color: "var(--red) !important",
        fontSize: "10px !important",
        fontWeight: "500 !important",
        marginTop: "2px !important",
        height: "unset !important"
    }
}))(Typography);

export const BasicAddButton = withStyles((theme) => ({
    root: {
        color: "#3C7EF3 !important",
        fontWeight: "600 !important",
        "&:focus": { backgroundColor: "transparent !important"}
    }
}))(Button);

export const BasicMaskedInput = styled(MaskedInput)(({ theme }) => ({
    position: "relative",
    color: "var(--dark_blue)",
    backgroundColor: "white !important",
    fontSize: "15px !important",
    margin: "0 !important",
    height: "2.5rem !important",
    padding: "10px 12px 10px  !important",
    borderRadius: "4px !important",
    border: "1px solid #ced4da !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),

    "&:focus": {
        borderRadius: 4,
        boxShadow: "unset !important",
    },
    "&::placeholder": {
        color: "#082852 !important",       
      },
}));

export const BasicAddButton2 = styled("div")(({ theme }) => ({
    backgroundColor: "#00ff91",
    borderRadius: "4px",
    height: "37px",
    width: "37px",
    display: "grid",
    placeItems: "center",
    cursor: "pointer",
}));

export const DynamicFieldWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    gap: "10px",
}));

export const AddressSearchToggleWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "2px",
}));

export const AddressSearchButton = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: "6px 14px",
        textTransform: "none",
        backgroundColor: "#316aff !important",
        color: "white !important",
        marginBottom: "10px",
        fontWeight: "700px",
        "&:hover,&:focus": {
            backgroundColor: "#316aff !important",
            color: "white !important",
        },
    }
}))(Button);

export const AddressManualButton = withStyles((theme) => ({
    root: {
        whiteSpace: "nowrap",
        paddingLeft: "14px",
        paddingRight: "14px",
        textTransform: "none",
        backgroundColor: "#316aff !important",
        color: "white !important",
        marginBottom: "10px",
        fontWeight: "700px",
        "&:hover,&:focus": {
            backgroundColor: "#316aff !important",
            color: "white !important",
        },
    }
}))(Button);

export const GooglePlacesAutocompleteWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    paddingTop: "4px",
}));

export const GooglePlacesAutocompleteContainer = styled("div")(({ theme }) => ({
    width: "100%",
    margin: 0,
    "& .my-google-places-autocomplete__value-container": {
        padding: "0px 8px",
    },
    "& .my-google-places-autocomplete__input": {
        height: "16px !important",
        textAlign: "left !important",
        margin: "0 !important",
        border: "none !important",
        outline: "none !important",
        boxShadow: "none !important",
    },
    "& .my-google-places-autocomplete__input:focus": {
        border: "none !important",
        outline: "none !important",
        boxShadow: "none !important",
    },
    "& .my-google-places-autocomplete__control": {
        fontSize: "15px !important",
    },
    "& .my-google-places-autocomplete__input input": {
        height: "unset !important",
    },
}));

export const GooglePlacesAutocompleteInput = styled("div")(({ theme }) => ({
    width: "100%"
}));

export const GooglePlacesAutocompleteIconWrapper = styled("div")(({ theme }) => ({
    width: "2px",
    paddingTop: "8px",
}));

export const GooglePlacesAutocompleteIcon = styled("span")(({ theme }) => ({
    position: "relative",
    marginLeft: "-80px",
}));

export const HeadlineWrapper = styled("div")(({theme }) => ({
    color: "white",
    backgroundColor: "#316aff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 16,
    padding: "10px 16px",
    borderRadius: "5px",
    marginBottom: "4px",

    "& .MuiDivider-root": {
        color: "white",
        backgroundColor: "white",
    },
}));

export const AddContactFooter = styled("div")(({ theme }) => ({
    background: "#00042e",
    padding: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

export const AddContactSave = withStyles((theme) => ({
    root: {
        width: "350px",
        fontWeight: 600,
        backgroundColor: "#3C7EF3 !important",
        color: "white !important",
        padding: "10px 20px",
        borderRadius: "5px",
        "&:hover, &:focus": {
            backgroundColor: "#3C7EF3 !important",
            color: "white !important",
        },
    }
}))(Button);

export const ContactCustomFieldGrid = styled("div")(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: 20,
}));