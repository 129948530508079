import React, { useState, useEffect } from "react";
import If, { Else } from 'if-else-react'
import Timer from "react-compound-timer";
import Utils from "../../../helpers/Utils";
import { enableWarmTransfer, destroySecondCall, storeMultipleReducers } from "../../../actions/globalDialerAction";
import { connect } from "react-redux";
import { callHangUp, holdCurrentCall } from "../../../api/globalDialerApi";
import HangedUpState from "./HangedUpState";
import ExtensionNumberPad from "./ExtensionNumberPad";
import ReplayIcon from '@material-ui/icons/Replay';
import BootstrapTooltip from "../../globals/BootstrapTooltip";

const CallingState = props => {

	const [isMute, setIsMute] = useState(false);
	const [isHold, setIsHold] = useState(props.isFirstCallOnHold);
	const [activeContact, setActiveContact] = useState(props.activeContact);
	const [isEnableExtensionDial, setIsEnableExtensionDial] = useState(false);

	useEffect(() => {
		setIsHold(props.isFirstCallOnHold);
	}, [props.isFirstCallOnHold]);

	useEffect(() => {

		setActiveContact(props.activeContact);
	}, [props.activeContact]);

	const hangup = () => {

		if (props.isCallReceived) {
			props.rejectCall();
		} else {
			callHangUp({ participant: 'all', conferenceSid: props.callInfo.conferenceSid, isSecondCallInProgress: props.isSecondCallInProgress, callSid: props.callInfo.callSid }).then(response => {
				if (response.data.status) {
					console.log('Participant removed.');
				}
			})
		}
	}

	const activateGotoNextCall = () => {
		props.storeMultipleReducers({ goToNextInList: true })
	}


	const muteMyself = () => {
		try {
			if (!isMute) {
				props.muteMyself(true);
			} else {
				props.muteMyself(false);
			}
			setIsMute(!isMute);
		} catch (e) {
			Utils.showNotification('Opps! something went wrong. Please reload the page', 'error');
		}
	}

	const holdCall = () => {
		holdCurrentCall({ isHold: isHold, participant: 'participant1', conferenceSid: props.callInfo.conferenceSid }).then(response => {
			if (response.data.status) {
				setIsHold(!isHold);
			} else {
				Utils.showNotification(response.data.message, 'error');
			}
		})
	}

	const getName = (activeContact) => {

		let firstName = '';
		let lastName = '';
		if (activeContact.firstName && activeContact.firstName.trim() !== '') {
			firstName = activeContact.firstName + ' '
		} else if (activeContact.first_name && activeContact.first_name.trim() !== '') {
			firstName = activeContact.first_name + ' '
		}

		if (activeContact.lastName && activeContact.lastName.trim() !== '') {
			lastName = activeContact.lastName
		} else if (activeContact.last_name && activeContact.last_name.trim() !== '') {
			lastName = activeContact.last_name
		}

		return firstName + lastName
	}

	return (
		<div style={{ position: 'relative' }}>
			<If condition={props.callStatus === 'Hanged Up'}>
				<HangedUpState />
				<Else />
				<div className="call_option">
					<div className="call__option_topbar d-flex align-items-center flex-column">

						<If condition={props.isCallReceived}>
							<p className="name">{props.callInfo.callerName}</p>
							<span className="phn_no">{Utils.formatNumber(props.callInfo.callerNumber)}</span>
							<Else />
							{typeof activeContact.contact_id === 'undefined' &&
								<>
									<p className="name">Unknown</p>
									<span className="phn_no">{Utils.formatNumber(props.dialNumber)}</span>
								</>
							}
							{typeof activeContact.contact_id !== 'undefined' &&
								<>
									<p className="name">{getName(activeContact)}</p>
									<span className="phn_no">{Utils.formatNumber(props.dialNumber)}</span>
								</>
							}
						</If>


						<If condition={props.callStatus === 'Received'}>
							<div className="call_timer">
								<span className="rcd_icon">
									<svg width="10" height="10" viewBox="0 0 10 10"
										fill="none" xmlns="http://www.w3.org/2000/svg">
										<circle cx="5" cy="5" r="5" fill="#FF264A" />
										<circle cx="5" cy="5" r="3.125" fill="#FF264A" stroke="white" strokeWidth="1.25" />
									</svg>
								</span>
								<span className="rcd_timer">
									<Timer>
										<Timer.Hours formatValue={value => `${(value < 10 ? `0${value}` : value)}:`} />
										<Timer.Minutes formatValue={value => `${(value < 10 ? `0${value}` : value)}:`} />
										<Timer.Seconds formatValue={value => `${(value < 10 ? `0${value}` : value)}`} />
									</Timer>
								</span>
							</div>
							<Else />
							<div className="call__status text-center">
								<p>{props.callStatus + ' ...'}</p>
							</div>
						</If>

					</div>
					{props.callStatus === "Received" && !props.isCallReceived &&
						<div className="option_btn d-flex flex-wrap justify-content-center">
							<button disabled={props.isCallReceived} onClick={() => muteMyself()} type="button">
								<span className={`btn_icon ${(isMute ? "active" : '')}`}>
									<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M21.4482 14.2767C21.4482 13.7627 21.0552 13.3696 20.5411 13.3696C20.0271 13.3696 19.6341 13.7627 19.6341 14.2767C19.6341 17.8143 16.7617 20.6867 13.2241 20.6867C9.68653 20.6867 6.81414 17.8143 6.81414 14.2767C6.81414 13.7627 6.42108 13.3696 5.90707 13.3696C5.39306 13.3696 5 13.7627 5 14.2767C5 18.4795 8.14451 22.017 12.317 22.4706V24.8894H9.02135C8.50734 24.8894 8.11428 25.2825 8.11428 25.7965C8.11428 26.3105 8.50734 26.7036 9.02135 26.7036H17.4269C17.9409 26.7036 18.3339 26.3105 18.3339 25.7965C18.3339 25.2825 17.9409 24.8894 17.4269 24.8894H14.1312V22.4706C18.3037 22.017 21.4482 18.4795 21.4482 14.2767Z" fill={(isMute ? "white" : '#546376')} />
										<path d="M13.2247 1C10.443 1 8.17529 3.26768 8.17529 6.04936V14.2432C8.17529 17.0551 10.443 19.2926 13.2247 19.3228C16.0063 19.3228 18.274 17.0552 18.274 14.2735V6.04936C18.274 3.26768 16.0063 1 13.2247 1Z" fill={(isMute ? "white" : '#546376')} />
										<path d="M1 1L26 26" stroke={(isMute ? "white" : '#546376')} />
									</svg>
								</span>
								<span className="btn_text">Mute</span>
							</button>
							<button type='button' disabled={props.isCallReceived} onClick={() => setIsEnableExtensionDial(true)}>
								<span className="btn_icon">
									<svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M4.63699 0H1.54566C0.693471 0 0 0.693471 0 1.54566V3.60656C0 4.45875 0.693471 5.15223 1.54566 5.15223H4.63699C5.48918 5.15223 6.18265 4.45875 6.18265 3.60656V1.54566C6.18265 0.693471 5.48918 0 4.63699 0Z" fill="#546376" />
										<path d="M11.8196 0H8.72828C7.87609 0 7.18262 0.693471 7.18262 1.54566V3.60656C7.18262 4.45875 7.87609 5.15223 8.72828 5.15223H11.8196C12.6718 5.15223 13.3653 4.45875 13.3653 3.60656V1.54566C13.3653 0.693471 12.6718 0 11.8196 0Z" fill="#546376" />
										<path d="M19.0022 0H15.9109C15.0587 0 14.3652 0.693471 14.3652 1.54566V3.60656C14.3652 4.45875 15.0587 5.15223 15.9109 5.15223H19.0022C19.8544 5.15223 20.5479 4.45875 20.5479 3.60656V1.54566C20.5479 0.693471 19.8544 0 19.0022 0Z" fill="#546376" />
										<path d="M4.63699 6.18262H1.54566C0.693471 6.18262 0 6.87609 0 7.72828V9.78918C0 10.6414 0.693471 11.3348 1.54566 11.3348H4.63699C5.48918 11.3348 6.18265 10.6414 6.18265 9.78918V7.72828C6.18265 6.87609 5.48918 6.18262 4.63699 6.18262Z" fill="#546376" />
										<path d="M11.8196 6.18262H8.72828C7.87609 6.18262 7.18262 6.87609 7.18262 7.72828V9.78918C7.18262 10.6414 7.87609 11.3348 8.72828 11.3348H11.8196C12.6718 11.3348 13.3653 10.6414 13.3653 9.78918V7.72828C13.3653 6.87609 12.6718 6.18262 11.8196 6.18262Z" fill="#546376" />
										<path d="M19.0022 6.18262H15.9109C15.0587 6.18262 14.3652 6.87609 14.3652 7.72828V9.78918C14.3652 10.6414 15.0587 11.3348 15.9109 11.3348H19.0022C19.8544 11.3348 20.5479 10.6414 20.5479 9.78918V7.72828C20.5479 6.87609 19.8544 6.18262 19.0022 6.18262Z" fill="#546376" />
										<path d="M4.63699 12.3647H1.54566C0.693471 12.3647 0 13.0582 0 13.9104V15.9713C0 16.8235 0.693471 17.517 1.54566 17.517H4.63699C5.48918 17.517 6.18265 16.8235 6.18265 15.9713V13.9104C6.18265 13.0582 5.48918 12.3647 4.63699 12.3647Z" fill="#546376" />
										<path d="M11.8196 12.3647H8.72828C7.87609 12.3647 7.18262 13.0582 7.18262 13.9104V15.9713C7.18262 16.8235 7.87609 17.517 8.72828 17.517H11.8196C12.6718 17.517 13.3653 16.8235 13.3653 15.9713V13.9104C13.3653 13.0582 12.6718 12.3647 11.8196 12.3647Z" fill="#546376" />
										<path d="M19.0022 12.3647H15.9109C15.0587 12.3647 14.3652 13.0582 14.3652 13.9104V15.9713C14.3652 16.8235 15.0587 17.517 15.9109 17.517H19.0022C19.8544 17.517 20.5479 16.8235 20.5479 15.9713V13.9104C20.5479 13.0582 19.8544 12.3647 19.0022 12.3647Z" fill="#546376" />
										<path d="M11.8196 18.5483H8.72828C7.87609 18.5483 7.18262 19.2418 7.18262 20.094V22.1549C7.18262 23.0071 7.87609 23.7006 8.72828 23.7006H11.8196C12.6718 23.7006 13.3653 23.0071 13.3653 22.1549V20.094C13.3653 19.2418 12.6718 18.5483 11.8196 18.5483Z" fill="#546376" />
									</svg>
								</span>
								<span className="btn_text">Dial</span>
							</button>
							<button type='button' disabled={props.isCallReceived} onClick={() => holdCall()}>
								<span className={`btn_icon ${(isHold ? 'active' : '')}`}>
									<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M5.51088 17.0271C5.51088 18.5489 4.27715 19.7826 2.75544 19.7826C1.23374 19.7826 0 18.5489 0 17.0271V2.75544C0 1.23374 1.23374 0 2.75544 0C4.27715 0 5.51088 1.23374 5.51088 2.75544V17.0271Z" fill={(isHold ? "white" : '#546376')} />
										<path d="M15.8522 17.0271C15.8522 18.5489 14.6185 19.7826 13.0968 19.7826C11.575 19.7826 10.3413 18.5489 10.3413 17.0271V2.75544C10.3417 1.23374 11.5755 0 13.0968 0C14.6185 0 15.8522 1.23374 15.8522 2.75544V17.0271Z" fill={(isHold ? "white" : '#546376')} />
									</svg>
								</span>
								<span className="btn_text">Hold</span>
							</button>
							<button type='button' disabled={props.isCallReceived} onClick={() => props.enableWarmTransfer('blind')}>
								<span className="btn_icon">
									<svg width="30" height="30" viewBox="0 0 30 30"
										fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M6.87101 10.7C5.90327 13.4 5.8065 16.2 6.48392 19L9.19359 19.1C9.58069 19.1 9.77424 19.3 9.96779 19.6C10.3549 20.6 10.9355 21.5 11.7097 22.3C11.9033 22.5 11.9033 22.7 11.9033 22.9C11.9033 23.1 11.8065 23.3 11.6129 23.5L9.38714 25.6C9.19359 25.9 8.90327 26 8.70972 25.9C8.51617 25.9 8.32263 25.8 8.12908 25.6C5.4194 22.5 4.16134 18.9 4.25811 14.7C4.45166 10.5 6.00005 7.00001 9.00005 4.20002C9.19359 4.10002 9.48392 4.00002 9.67746 4.10002C9.87101 4.10002 10.0646 4.20002 10.2581 4.40002L12.2904 6.70002C12.4839 6.80002 12.5807 7.00002 12.4839 7.30002C12.4839 7.50002 12.3871 7.70002 12.1936 7.90002C11.4194 8.60002 10.742 9.50001 10.2581 10.4C10.0646 10.7 9.77424 10.9 9.48392 10.9L6.87101 10.7Z"
											fill="#546376" />
										<path
											d="M23.1289 10.7001C24.0966 13.4001 24.1934 16.2001 23.516 19.0001L20.8063 19.1001C20.4192 19.1001 20.2257 19.3001 20.0321 19.6001C19.645 20.6001 19.0644 21.5001 18.2902 22.3001C18.0966 22.5001 18.0966 22.7001 18.0966 22.9001C18.0966 23.1001 18.1934 23.3001 18.387 23.5001L20.6128 25.6001C20.8063 25.8001 20.9999 25.8001 21.1934 25.8001C21.387 25.8001 21.5805 25.7001 21.7741 25.5001C24.4837 22.4001 25.7418 18.8001 25.645 14.6001C25.4515 10.4001 23.9031 6.90011 20.9031 4.10011C20.8063 4.10011 20.516 4.00011 20.3224 4.10011C20.1289 4.10011 19.9353 4.20011 19.7418 4.40011L17.7095 6.70011C17.516 6.80011 17.4192 7.00011 17.516 7.30011C17.516 7.50011 17.6128 7.70011 17.8063 7.90011C18.5805 8.60011 19.2579 9.50011 19.7418 10.4001C19.9353 10.7001 20.2257 10.9001 20.516 10.9001L23.1289 10.7001Z"
											fill="#546376" />
										<path
											d="M17.5162 15.5002L15.3872 13.3002C15.2904 13.2002 15.2904 13.2002 15.1936 13.2002C15.0969 13.2002 15.0001 13.2002 15.0001 13.3002L14.8065 13.5002C14.7098 13.6002 14.7098 13.6002 14.7098 13.7002C14.7098 13.8002 14.7098 13.9002 14.8065 13.9002L16.0646 15.2002H12.1936C12.0001 15.2002 11.9033 15.3002 11.9033 15.5002V15.8002C11.9033 16.0002 12.0001 16.1002 12.1936 16.1002H16.0646L14.8065 17.4002C14.7098 17.5002 14.7098 17.5002 14.7098 17.6002C14.7098 17.7002 14.7098 17.8002 14.8065 17.8002L15.0001 18.0002C15.0969 18.1002 15.0969 18.1002 15.1936 18.1002C15.2904 18.1002 15.3872 18.1002 15.3872 18.0002L17.5162 15.8002C17.613 15.7002 17.613 15.7002 17.613 15.6002C17.613 15.6002 17.613 15.5002 17.5162 15.5002Z"
											fill="#546376" />
									</svg>
								</span>
								<span className="btn_text">Blind Transfer</span>
							</button>
							<button type='button' disabled={props.isCallReceived} onClick={() => props.enableWarmTransfer('warm')}>
								<span className="btn_icon">
									<svg width="30" height="30" viewBox="0 0 30 30"
										fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M5.59976 10.6998C4.59976 13.3998 4.49976 16.1998 5.19976 18.9998L7.99976 19.1998C8.39976 19.1998 8.59976 19.3998 8.79976 19.6998C9.19976 20.6998 9.79976 21.5998 10.5998 22.3998C10.7998 22.5998 10.7998 22.7998 10.7998 22.9998C10.7998 23.1998 10.6998 23.3998 10.4998 23.5998L8.19976 25.6998C7.99976 25.8998 7.79976 25.9998 7.59976 25.8998C7.39976 25.8998 7.19976 25.7998 6.99976 25.5998C4.19976 22.5998 2.79976 18.9998 2.99976 14.7998C3.19976 10.5998 4.79976 7.09978 7.89976 4.29978C8.09976 4.09978 8.29976 3.99978 8.59976 4.09978C8.79976 4.09978 8.99976 4.19978 9.19976 4.39978L11.2998 6.69978C11.4998 6.89978 11.4998 7.09978 11.4998 7.29978C11.4998 7.49978 11.3998 7.69978 11.1998 7.89978C10.3998 8.59978 9.69976 9.49978 9.19976 10.3998C8.99976 10.6998 8.69976 10.8998 8.39976 10.8998L5.59976 10.6998Z"
											fill="#546376" />
										<path
											d="M14.4 21.8V22.3L14.8 22L18.8 19.2H24.8C26 19.2 27 18.2 27 17V10.7C27 9.5 26 8.5 24.8 8.5H14.7C13.5 8.5 12.5 9.5 12.5 10.7V17C12.5 18.1 13.3 19.1 14.4 19.2V21.8ZM18.5 17.9H18.4L15.8 19.7V18.1V17.8H15.5H14.7C14.2 17.8 13.8 17.4 13.8 16.9V10.6C13.8 10.1 14.2 9.7 14.7 9.7H24.9C25.4 9.7 25.8 10.1 25.8 10.6V17C25.8 17.5 25.4 17.9 24.9 17.9H18.5Z"
											fill="#546376" stroke="#546376"
											strokeWidth="0.5" />
									</svg>
								</span>
								<span className="btn_text">Warm Transfer</span>
							</button>
						</div>
					}

					{props.callStatus === "Received" && props.isCallReceived &&
						<div className="option_btn d-flex flex-wrap justify-content-center">
							<button onClick={() => muteMyself()} type="button">
								<span className={`btn_icon ${(isMute ? "active" : '')}`}>
									<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M21.4482 14.2767C21.4482 13.7627 21.0552 13.3696 20.5411 13.3696C20.0271 13.3696 19.6341 13.7627 19.6341 14.2767C19.6341 17.8143 16.7617 20.6867 13.2241 20.6867C9.68653 20.6867 6.81414 17.8143 6.81414 14.2767C6.81414 13.7627 6.42108 13.3696 5.90707 13.3696C5.39306 13.3696 5 13.7627 5 14.2767C5 18.4795 8.14451 22.017 12.317 22.4706V24.8894H9.02135C8.50734 24.8894 8.11428 25.2825 8.11428 25.7965C8.11428 26.3105 8.50734 26.7036 9.02135 26.7036H17.4269C17.9409 26.7036 18.3339 26.3105 18.3339 25.7965C18.3339 25.2825 17.9409 24.8894 17.4269 24.8894H14.1312V22.4706C18.3037 22.017 21.4482 18.4795 21.4482 14.2767Z" fill={(isMute ? "white" : '#546376')} />
										<path d="M13.2247 1C10.443 1 8.17529 3.26768 8.17529 6.04936V14.2432C8.17529 17.0551 10.443 19.2926 13.2247 19.3228C16.0063 19.3228 18.274 17.0552 18.274 14.2735V6.04936C18.274 3.26768 16.0063 1 13.2247 1Z" fill={(isMute ? "white" : '#546376')} />
										<path d="M1 1L26 26" stroke={(isMute ? "white" : '#546376')} />
									</svg>
								</span>
								<span className="btn_text">Mute</span>
							</button>
							<button type='button' onClick={() => setIsEnableExtensionDial(true)}>
								<span className="btn_icon">
									<svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M4.63699 0H1.54566C0.693471 0 0 0.693471 0 1.54566V3.60656C0 4.45875 0.693471 5.15223 1.54566 5.15223H4.63699C5.48918 5.15223 6.18265 4.45875 6.18265 3.60656V1.54566C6.18265 0.693471 5.48918 0 4.63699 0Z" fill="#546376" />
										<path d="M11.8196 0H8.72828C7.87609 0 7.18262 0.693471 7.18262 1.54566V3.60656C7.18262 4.45875 7.87609 5.15223 8.72828 5.15223H11.8196C12.6718 5.15223 13.3653 4.45875 13.3653 3.60656V1.54566C13.3653 0.693471 12.6718 0 11.8196 0Z" fill="#546376" />
										<path d="M19.0022 0H15.9109C15.0587 0 14.3652 0.693471 14.3652 1.54566V3.60656C14.3652 4.45875 15.0587 5.15223 15.9109 5.15223H19.0022C19.8544 5.15223 20.5479 4.45875 20.5479 3.60656V1.54566C20.5479 0.693471 19.8544 0 19.0022 0Z" fill="#546376" />
										<path d="M4.63699 6.18262H1.54566C0.693471 6.18262 0 6.87609 0 7.72828V9.78918C0 10.6414 0.693471 11.3348 1.54566 11.3348H4.63699C5.48918 11.3348 6.18265 10.6414 6.18265 9.78918V7.72828C6.18265 6.87609 5.48918 6.18262 4.63699 6.18262Z" fill="#546376" />
										<path d="M11.8196 6.18262H8.72828C7.87609 6.18262 7.18262 6.87609 7.18262 7.72828V9.78918C7.18262 10.6414 7.87609 11.3348 8.72828 11.3348H11.8196C12.6718 11.3348 13.3653 10.6414 13.3653 9.78918V7.72828C13.3653 6.87609 12.6718 6.18262 11.8196 6.18262Z" fill="#546376" />
										<path d="M19.0022 6.18262H15.9109C15.0587 6.18262 14.3652 6.87609 14.3652 7.72828V9.78918C14.3652 10.6414 15.0587 11.3348 15.9109 11.3348H19.0022C19.8544 11.3348 20.5479 10.6414 20.5479 9.78918V7.72828C20.5479 6.87609 19.8544 6.18262 19.0022 6.18262Z" fill="#546376" />
										<path d="M4.63699 12.3647H1.54566C0.693471 12.3647 0 13.0582 0 13.9104V15.9713C0 16.8235 0.693471 17.517 1.54566 17.517H4.63699C5.48918 17.517 6.18265 16.8235 6.18265 15.9713V13.9104C6.18265 13.0582 5.48918 12.3647 4.63699 12.3647Z" fill="#546376" />
										<path d="M11.8196 12.3647H8.72828C7.87609 12.3647 7.18262 13.0582 7.18262 13.9104V15.9713C7.18262 16.8235 7.87609 17.517 8.72828 17.517H11.8196C12.6718 17.517 13.3653 16.8235 13.3653 15.9713V13.9104C13.3653 13.0582 12.6718 12.3647 11.8196 12.3647Z" fill="#546376" />
										<path d="M19.0022 12.3647H15.9109C15.0587 12.3647 14.3652 13.0582 14.3652 13.9104V15.9713C14.3652 16.8235 15.0587 17.517 15.9109 17.517H19.0022C19.8544 17.517 20.5479 16.8235 20.5479 15.9713V13.9104C20.5479 13.0582 19.8544 12.3647 19.0022 12.3647Z" fill="#546376" />
										<path d="M11.8196 18.5483H8.72828C7.87609 18.5483 7.18262 19.2418 7.18262 20.094V22.1549C7.18262 23.0071 7.87609 23.7006 8.72828 23.7006H11.8196C12.6718 23.7006 13.3653 23.0071 13.3653 22.1549V20.094C13.3653 19.2418 12.6718 18.5483 11.8196 18.5483Z" fill="#546376" />
									</svg>
								</span>
								<span className="btn_text">Dial</span>
							</button>
							<button type='button' onClick={() => window.showNotification("ERROR", "This option is only available for outgoing calls")}>
								<span className={`btn_icon ${(isHold ? 'active' : '')}`}>
									<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M5.51088 17.0271C5.51088 18.5489 4.27715 19.7826 2.75544 19.7826C1.23374 19.7826 0 18.5489 0 17.0271V2.75544C0 1.23374 1.23374 0 2.75544 0C4.27715 0 5.51088 1.23374 5.51088 2.75544V17.0271Z" fill={(isHold ? "white" : '#546376')} />
										<path d="M15.8522 17.0271C15.8522 18.5489 14.6185 19.7826 13.0968 19.7826C11.575 19.7826 10.3413 18.5489 10.3413 17.0271V2.75544C10.3417 1.23374 11.5755 0 13.0968 0C14.6185 0 15.8522 1.23374 15.8522 2.75544V17.0271Z" fill={(isHold ? "white" : '#546376')} />
									</svg>
								</span>
								<span className="btn_text">Hold</span>
							</button>
							<button type='button'
								onClick={() => {
									window.showNotification("ERROR", "This option is only available for outgoing calls")

								}}
							>
								<span className="btn_icon">
									<svg width="30" height="30" viewBox="0 0 30 30"
										fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M6.87101 10.7C5.90327 13.4 5.8065 16.2 6.48392 19L9.19359 19.1C9.58069 19.1 9.77424 19.3 9.96779 19.6C10.3549 20.6 10.9355 21.5 11.7097 22.3C11.9033 22.5 11.9033 22.7 11.9033 22.9C11.9033 23.1 11.8065 23.3 11.6129 23.5L9.38714 25.6C9.19359 25.9 8.90327 26 8.70972 25.9C8.51617 25.9 8.32263 25.8 8.12908 25.6C5.4194 22.5 4.16134 18.9 4.25811 14.7C4.45166 10.5 6.00005 7.00001 9.00005 4.20002C9.19359 4.10002 9.48392 4.00002 9.67746 4.10002C9.87101 4.10002 10.0646 4.20002 10.2581 4.40002L12.2904 6.70002C12.4839 6.80002 12.5807 7.00002 12.4839 7.30002C12.4839 7.50002 12.3871 7.70002 12.1936 7.90002C11.4194 8.60002 10.742 9.50001 10.2581 10.4C10.0646 10.7 9.77424 10.9 9.48392 10.9L6.87101 10.7Z"
											fill="#546376" />
										<path
											d="M23.1289 10.7001C24.0966 13.4001 24.1934 16.2001 23.516 19.0001L20.8063 19.1001C20.4192 19.1001 20.2257 19.3001 20.0321 19.6001C19.645 20.6001 19.0644 21.5001 18.2902 22.3001C18.0966 22.5001 18.0966 22.7001 18.0966 22.9001C18.0966 23.1001 18.1934 23.3001 18.387 23.5001L20.6128 25.6001C20.8063 25.8001 20.9999 25.8001 21.1934 25.8001C21.387 25.8001 21.5805 25.7001 21.7741 25.5001C24.4837 22.4001 25.7418 18.8001 25.645 14.6001C25.4515 10.4001 23.9031 6.90011 20.9031 4.10011C20.8063 4.10011 20.516 4.00011 20.3224 4.10011C20.1289 4.10011 19.9353 4.20011 19.7418 4.40011L17.7095 6.70011C17.516 6.80011 17.4192 7.00011 17.516 7.30011C17.516 7.50011 17.6128 7.70011 17.8063 7.90011C18.5805 8.60011 19.2579 9.50011 19.7418 10.4001C19.9353 10.7001 20.2257 10.9001 20.516 10.9001L23.1289 10.7001Z"
											fill="#546376" />
										<path
											d="M17.5162 15.5002L15.3872 13.3002C15.2904 13.2002 15.2904 13.2002 15.1936 13.2002C15.0969 13.2002 15.0001 13.2002 15.0001 13.3002L14.8065 13.5002C14.7098 13.6002 14.7098 13.6002 14.7098 13.7002C14.7098 13.8002 14.7098 13.9002 14.8065 13.9002L16.0646 15.2002H12.1936C12.0001 15.2002 11.9033 15.3002 11.9033 15.5002V15.8002C11.9033 16.0002 12.0001 16.1002 12.1936 16.1002H16.0646L14.8065 17.4002C14.7098 17.5002 14.7098 17.5002 14.7098 17.6002C14.7098 17.7002 14.7098 17.8002 14.8065 17.8002L15.0001 18.0002C15.0969 18.1002 15.0969 18.1002 15.1936 18.1002C15.2904 18.1002 15.3872 18.1002 15.3872 18.0002L17.5162 15.8002C17.613 15.7002 17.613 15.7002 17.613 15.6002C17.613 15.6002 17.613 15.5002 17.5162 15.5002Z"
											fill="#546376" />
									</svg>
								</span>
								<span className="btn_text">Blind Transfer</span>
							</button>
							<button type='button'
								onClick={() => {
									window.showNotification("ERROR", "This option is only available for outgoing calls")

								}}
							>

								<span className="btn_icon">
									<svg width="30" height="30" viewBox="0 0 30 30"
										fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M5.59976 10.6998C4.59976 13.3998 4.49976 16.1998 5.19976 18.9998L7.99976 19.1998C8.39976 19.1998 8.59976 19.3998 8.79976 19.6998C9.19976 20.6998 9.79976 21.5998 10.5998 22.3998C10.7998 22.5998 10.7998 22.7998 10.7998 22.9998C10.7998 23.1998 10.6998 23.3998 10.4998 23.5998L8.19976 25.6998C7.99976 25.8998 7.79976 25.9998 7.59976 25.8998C7.39976 25.8998 7.19976 25.7998 6.99976 25.5998C4.19976 22.5998 2.79976 18.9998 2.99976 14.7998C3.19976 10.5998 4.79976 7.09978 7.89976 4.29978C8.09976 4.09978 8.29976 3.99978 8.59976 4.09978C8.79976 4.09978 8.99976 4.19978 9.19976 4.39978L11.2998 6.69978C11.4998 6.89978 11.4998 7.09978 11.4998 7.29978C11.4998 7.49978 11.3998 7.69978 11.1998 7.89978C10.3998 8.59978 9.69976 9.49978 9.19976 10.3998C8.99976 10.6998 8.69976 10.8998 8.39976 10.8998L5.59976 10.6998Z"
											fill="#546376" />
										<path
											d="M14.4 21.8V22.3L14.8 22L18.8 19.2H24.8C26 19.2 27 18.2 27 17V10.7C27 9.5 26 8.5 24.8 8.5H14.7C13.5 8.5 12.5 9.5 12.5 10.7V17C12.5 18.1 13.3 19.1 14.4 19.2V21.8ZM18.5 17.9H18.4L15.8 19.7V18.1V17.8H15.5H14.7C14.2 17.8 13.8 17.4 13.8 16.9V10.6C13.8 10.1 14.2 9.7 14.7 9.7H24.9C25.4 9.7 25.8 10.1 25.8 10.6V17C25.8 17.5 25.4 17.9 24.9 17.9H18.5Z"
											fill="#546376" stroke="#546376"
											strokeWidth="0.5" />
									</svg>
								</span>
								<span className="btn_text">Warm Transfer</span>
							</button>
						</div>
					}

					{props.callStatus === "Incoming" &&
						<BootstrapTooltip title="Answer" arrow placement="top">
							<div className="call-button d-flex justify-content-center align-items-center m-2" onClick={(e) => { e.preventDefault(); e.stopPropagation(); props.acceptCall() }}>
								<svg width="52" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 10.999h2C22 5.869 18.127 2 12.99 2v2C17.052 4 20 6.943 20 10.999Z" fill="#fff" /><path d="M13 8c2.103 0 3 .897 3 3h2c0-3.225-1.775-5-5-5v2Zm3.422 5.443a1.001 1.001 0 0 0-1.39.043l-2.394 2.461c-.576-.11-1.734-.47-2.926-1.66-1.192-1.193-1.553-2.354-1.66-2.926l2.46-2.394a1 1 0 0 0 .042-1.39L6.86 3.512a1 1 0 0 0-1.39-.087l-2.17 1.861a1 1 0 0 0-.29.65c-.016.25-.302 6.171 4.29 10.765C11.305 20.707 16.323 21 17.705 21c.202 0 .326-.006.36-.008a.991.991 0 0 0 .647-.29l1.86-2.172a1 1 0 0 0-.086-1.39l-4.064-3.697Z" fill="#fff" /></svg>
							</div>
						</BootstrapTooltip>
					}
					{(props.stage === 'contact' && props.recentContacts.length > 1 && props.callStatus === "Received" && !props.isCallReceived &&
						!props.goToNextInList
					) ?

						<span className="hangup_call_histoy_wrpper__new">
							<BootstrapTooltip title="Hangup" arrow placement="bottom">
								<div className="hangup_call_histoy_wrpper__new_end call-button d-flex justify-content-center align-items-center end-call m-2" onClick={(e) => { e.preventDefault(); e.stopPropagation(); hangup() }}>
									<svg width="48" height="20" viewBox="0 0 48 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M9.59628 18.5844C11.1269 17.0538 12.8691 15.7903 14.7639 14.8368C15.6998 14.377 16.3393 13.3007 16.3278 12.4374L17.0168 7.50435C22.0169 5.98456 26.0324 5.98391 30.9843 7.50299L31.6625 12.3003C31.6618 13.3698 32.2505 14.3423 33.2129 14.8448C35.1172 15.8038 36.8594 17.0658 38.3907 18.5971C39.4968 19.7033 41.2967 19.7033 42.4028 18.5972L46.9939 14.0061C48.1001 12.8999 48.1001 11.1001 46.9939 9.99394C34.3149 -2.68512 13.685 -2.68505 1.0059 9.99401C-0.10022 11.1001 -0.100223 12.9 1.0059 14.0061L5.58423 18.5845C6.69035 19.6904 8.49022 19.6904 9.59628 18.5844Z" fill="white" />
									</svg>
								</div>
							</BootstrapTooltip>
							<BootstrapTooltip title="Hangup" arrow placement="bottom">
								<div className="hangup_call_histoy_wrpper__new_next call-button d-flex justify-content-center align-items-center end-call-blue m-2" onClick={(e) => { e.preventDefault(); e.stopPropagation(); activateGotoNextCall() }}>
									<span><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby="nextAltIconTitle" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" color="#000"><path d="m16 4 4 4-4 4" /><path d="M20 8H9.5A5.5 5.5 0 0 0 4 13.5v0A5.5 5.5 0 0 0 9.5 19H19" /></svg></span>
									<span>go to next call after call ends</span>
								</div>
							</BootstrapTooltip>
						</span>
						:
						<span>
							{!isEnableExtensionDial &&
								<BootstrapTooltip title="Hangup" arrow placement="bottom">
									<div className="call-button d-flex justify-content-center align-items-center end-call m-2" onClick={(e) => { e.preventDefault(); e.stopPropagation(); hangup() }}>
										<svg width="48" height="20" viewBox="0 0 48 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M9.59628 18.5844C11.1269 17.0538 12.8691 15.7903 14.7639 14.8368C15.6998 14.377 16.3393 13.3007 16.3278 12.4374L17.0168 7.50435C22.0169 5.98456 26.0324 5.98391 30.9843 7.50299L31.6625 12.3003C31.6618 13.3698 32.2505 14.3423 33.2129 14.8448C35.1172 15.8038 36.8594 17.0658 38.3907 18.5971C39.4968 19.7033 41.2967 19.7033 42.4028 18.5972L46.9939 14.0061C48.1001 12.8999 48.1001 11.1001 46.9939 9.99394C34.3149 -2.68512 13.685 -2.68505 1.0059 9.99401C-0.10022 11.1001 -0.100223 12.9 1.0059 14.0061L5.58423 18.5845C6.69035 19.6904 8.49022 19.6904 9.59628 18.5844Z" fill="white" />
										</svg>
									</div>
								</BootstrapTooltip>
							}
						</span>
					}
				</div>

				{isEnableExtensionDial &&
					<div className="extension_dialer">
						<ExtensionNumberPad sendDigit={props.sendDigit} />
						<div className="row">
							<div className="secondDialTrigger">
								<button className="btn btn-outline-secondary btn__dial px-4" onClick={() => setIsEnableExtensionDial(false)}>
									<ReplayIcon fontSize={'large'} />
								</button>
								<button className="call-button d-flex justify-content-center align-items-center" onClick={(e) => { e.preventDefault(); e.stopPropagation(); hangup() }}>
									<svg width="35" height="20" viewBox="0 0 48 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M9.59628 18.5844C11.1269 17.0538 12.8691 15.7903 14.7639 14.8368C15.6998 14.377 16.3393 13.3007 16.3278 12.4374L17.0168 7.50435C22.0169 5.98456 26.0324 5.98391 30.9843 7.50299L31.6625 12.3003C31.6618 13.3698 32.2505 14.3423 33.2129 14.8448C35.1172 15.8038 36.8594 17.0658 38.3907 18.5971C39.4968 19.7033 41.2967 19.7033 42.4028 18.5972L46.9939 14.0061C48.1001 12.8999 48.1001 11.1001 46.9939 9.99394C34.3149 -2.68512 13.685 -2.68505 1.0059 9.99401C-0.10022 11.1001 -0.100223 12.9 1.0059 14.0061L5.58423 18.5845C6.69035 19.6904 8.49022 19.6904 9.59628 18.5844Z" fill="white" />
									</svg>
								</button>
							</div>
						</div>
					</div>
				}
			</If>
		</div>
	);
}

const mapStateToProps = state => {
	return {
		stage: state.globalDialerReducer.stage,
		activeContact: state.globalDialerReducer.activeContact,
		dialNumber: state.globalDialerReducer.dialNumber,
		callStatus: state.globalDialerReducer.callStatus,
		callInfo: state.globalDialerReducer.callInfo,
		isFirstCallOnHold: state.globalDialerReducer.isFirstCallOnHold,
		isSecondCallInProgress: state.globalDialerReducer.isSecondCallInProgress,
		isCallReceived: state.globalDialerReducer.isCallReceived,
		twilioToken: state.globalDialerReducer.twilioToken,
		recentContacts: state.globalDialerReducer.recentContacts,
		goToNextInList: state.globalDialerReducer.goToNextInList,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		enableWarmTransfer: (params) => dispatch(enableWarmTransfer(params)),
		destroySecondCall: () => dispatch(destroySecondCall()),
		storeMultipleReducers: (params) => dispatch(storeMultipleReducers(params)),

	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CallingState);