import {Icon, ListItem, ListItemIcon, ListItemText, makeStyles} from "@material-ui/core";
import React, {Fragment, useEffect, useState} from "react";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import { getCustomMenuData } from "../../addContact/contactApi";
import {useHistory} from "react-router-dom";

const SidebarCustomMenuInsurance = ({ menuCollapsed }) => {
    const [customMenu, setCustomMenu] = useState([]);

    useEffect(() => {
        getCustomMenuData().then(res => {
            if(res.data.status && res.data.data){
                setCustomMenu(res.data.data)
            }
        }).catch((e) => {
            console.log(e);
        });
    },[]);

    return (
        <Fragment>
           {
               customMenu.map((elem, index) => (
                <MenuItem
                    menuCollapsed={menuCollapsed}
                    elem={elem}
                    key={index}
                />
            ))
           }
        </Fragment>
    )
}

export default SidebarCustomMenuInsurance;

const useStyles = makeStyles({
    item: (props) => ({
        color : `${props.elem.text_color} !important`,
        backgroundColor : `${props.elem.background_color} !important`,
        borderRadius: "8px !important",
    }),
    itemText: (props) => ({
        "&.MuiTypography-root": {
            color: `${props.elem.text_color} !important`
        }
    }),
    itemIcon: (props) => ({
        fontSize : "20px !important",
        color: `${props.elem.text_color} !important`
    })
});

const MenuItem = ({ elem, menuCollapsed }) => {
    const history = useHistory();
    const classes = useStyles({ elem });

    const gotoUrl = (elem) => {
        if (elem.link !== window.location.pathname) {
            if (elem.is_iframe === "NO"){
                window.open(elem.link, elem.link_target === "NEW_TAB" ? "_blank" : "_self");
            }else {
                history.push("/user/custom-page?url=" + elem.link + "&title=" + elem.title);
            }
        }
    };

    return (
        <BootstrapTooltip
            title={menuCollapsed ? elem.title : ""}
            arrow
            placement="right"
        >
            <ListItem
                button
                onClick={() => { gotoUrl(elem) }}
                className={classes.item}
            >
                <ListItemIcon>
                    <Icon className={classes.itemIcon}>{elem.icon}</Icon>
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ className: classes.itemText }} primary={elem.title} />
            </ListItem>
        </BootstrapTooltip>
    );
};
