import React from "react";
import {
    Backdrop,
    Button,
    Fade,
    makeStyles,
    Modal,
    Typography,
} from "@material-ui/core";

const modalStyles = makeStyles((theme) => ({
    wrapperMain: {
        marginLeft: "10px",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        width: (props) => props.modalWidth,
        backgroundColor: theme.palette.background.paper,
        borderRadius: "8px",
        boxShadow: theme.shadows[5],
    },
    modalHeader: {
        padding: "6px 15px",
        color: "#fff",
        borderTopRightRadius: "8px",
        borderTopLeftRadius: "8px",
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #d3d3d3",
    },
    modalBody: {
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    modalFooter: {
        borderEndEndRadius: "8px",
        borderEndStartRadius: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        paddingRight: "16px",
        height: "70px",
    },
    closeButton: {
        color: "#fff !important",
        width: "100px !important",
        padding: "6px 16px !important",
        borderRadius: "4px !important",
        fontWeight: 600,
        textTransform: "capitalize !important",
        backgroundColor: "#3C7EF3 !important",
        "&:hover, &:focus": {
            backgroundColor: "#3E50F7 !important",
        },
        "&.Mui-disabled": {
            backgroundColor: "#e0e0e0 !important",
            color: "#bdbdbd !important",
        },
    },
    typographyStyle: {
        height: "unset !important",
        color: "#133159",
        fontWeight: 500,
        fontSize: "15px",
        marginBottom: "20px",
        textAlign: "center",
    },
    alertMessages: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: "20px"
    },
    helperMessages: {
        width: "460px"
    }
}));

const VideoValidationAlert = ({
        modalWidth = 550,
        open,
        onClose,
        data: {
            alertTitle,
            alertSubTitle,
            alertMessage,
            alertSubMessage,
            helperMessage,
            helperSubMessage
        },
    }) => {
    const classes = modalStyles({ modalWidth });

    return (
        <div className={classes.wrapperMain}>
            <Modal
                open={open}
                onClose={()=>{}}
                className={classes.modal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={classes.modalHeader}>

                            <div style={{width: "50px", height: "50px"}}>
                                <img
                                    src={"https://d24gujj67p1uc1.cloudfront.net/assets/images/releases/video/1.0.0/video_alert_icon.png"}
                                    alt="logo"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                    }}
                                />
                            </div>

                            <div style={{marginLeft: "16px"}}>
                                <Typography
                                    style={{
                                        height: "unset !important",
                                        color: "#133159",
                                        fontWeight: 600,
                                    }}
                                    variant="h5"
                                >
                                    {alertTitle}
                                </Typography>
                                <Typography
                                    style={{
                                        height: "unset !important",
                                        color: "#133159",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                    }}
                                    variant="h5"
                                >
                                    {alertSubTitle}
                                </Typography>
                            </div>
                        </div>

                        <div className={classes.modalBody}>
                            <div className={classes.alertMessages}>
                                <Typography
                                    style={{
                                        height: "unset !important",
                                        color: "#133159",
                                        fontWeight: 600,
                                    }}
                                    variant="h5"
                                >
                                    {alertMessage}
                                </Typography>
                                <Typography
                                    style={{
                                        height: "unset !important",
                                        color: "#133159",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                    }}
                                    variant="h5"
                                >
                                    {alertSubMessage}
                                </Typography>
                            </div>

                            <div className={classes.helperMessages}>
                                <Typography
                                    style={{
                                        height: "unset !important",
                                        color: "#133159",
                                        fontWeight: 500,
                                        fontSize: "16px",
                                        textAlign: "center",
                                    }}
                                    variant="h5"
                                >
                                    {helperMessage}
                                </Typography>
                                <Typography
                                    style={{
                                        height: "unset !important",
                                        color: "#133159",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        textAlign: "center",
                                        fontStyle: "italic"
                                    }}
                                    variant="h5"
                                >
                                    {helperSubMessage}
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.modalFooter}>
                            <Button
                                disableElevation
                                variant="contained"
                                onClick={onClose}
                                className={classes.closeButton}
                            >
                                Close
                            </Button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

export default VideoValidationAlert;