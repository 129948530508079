import React from "react";
import {
  Checkbox,
  Link,
  makeStyles,
  Typography
} from "@material-ui/core";
import {formatPhoneNumber} from "../../helpers/Utils";

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'rgba(0, 0, 0, 0.54) !important',
    '&.Mui-checked': {
      color: '#343fef !important',
    }
  },
}));

const generateName = (contact) => {
  let name = "";

  if (contact.firstName) {
    name += contact.firstName;
  }

  if (contact.lastName) {
    if (name) {
      name += " ";
    }
    name += contact.lastName;
  }

  if (!name && contact.email){
    name = contact.email;
  }

  if (!name && contact.number){
    name = formatPhoneNumber(contact.number);
  }

  return name;
};

const EachRelatedItem = (props) => {
  const { relatedContact, checked, handleCheckbox, index } = props;
  const classes = useStyles(checked);

  return (
    <div className="item">
      <div className="itemContent">
        <div className="itemText">
          <Link href={`/contacts/${relatedContact.relatedContactId}`} variant="overline" color="primary" className="linkUnderline">
            {generateName(relatedContact)}
          </Link>

          <Typography variant="body1" className="relationship">
            {relatedContact.relationship}
          </Typography>
        </div>

        <div className="iconButton">
          <Checkbox
              checked={checked(relatedContact.relatedContactId)}
              onClick={()=>{
                handleCheckbox(relatedContact.relatedContactId);
              }}
              inputProps={{
                "aria-label": "primary checkbox"
              }}
              size="medium"
              classes={classes}
          />
        </div>
      </div>
    </div>
  );
};

export default EachRelatedItem;
