import React, { Fragment, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Utils, { isDefaultAgency } from '../../helpers/Utils';
import { fetchOnboardData, onboardGoToStep } from '../../api/onboardApi';
import Welcome from './Welcome';
import Loading from '../common/Loading';
import { Grid, Stepper, Step, StepLabel } from '@material-ui/core';
import JoinGroup from './JoinGroup';
import Number from './Number';
import CanSpam from './canSpam/CanSpam';
import Timezone from './timezone/Timezone';
import Congratulation from './Congratulation';
import './onBoarding.css'
import { deleteCookie } from '../../helpers/Cookie';
import NylasConnectEmail from "./nylasConnectEmail/NylasConnectEmail";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    maxWidth: 800,
    width:"100%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    "@media (max-width: 1024px)": {
        maxWidth: 600,
    },
    "@media (max-width: 620px)": {
        maxWidth: 400,
    },
    "@media (max-width: 400px)": {
        maxWidth: 300,
    }
  },
  modalWrapper: {
      overflow:'hidden',
        height:'100%',
  }
}));

function getStepContent(step) {
  switch (step) {
    case 'welcome':
      return 'Welcome';
    case 'join-group':
      return 'Join Group';
    case 'number':
      return 'Phone Number';
    case 'can-spam':
      return 'Can-Spam';
    case 'timezone':
      return 'Time Zone';
    case 'mail':
      return 'Email Settings';
    default:
      return 'Unknown Step';
  }
}

const Onboard = () => {
    let history = useHistory();
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [steps, setSteps] = React.useState(null);
    const [activeStep, setActiveStep] = React.useState(null);
    const [isCompleted, setIsCompleted] = React.useState(false);
    const [componentLoading, setComponentLoading] = React.useState(true);
    const [buttonLoading, setButtonLoading] = React.useState(false);
    const [agencyId, setAgencyId] = React.useState(null);
    const [onBoardWelcomeVideo, setOnBoardWelcomeVideo] = React.useState("");
    const [data, setData] = React.useState(null);

    useEffect(() => {
        setAgencyId(Utils.getAccountData('agencyId'));

        if (isDefaultAgency(Utils.getAccountData('agencyId'))) {
            let rules = [
                'welcome',
                'join-group',
                'number',
                'can-spam',
                'timezone'
            ];

            if (Utils.getAccountData('userId') === 1 || Utils.getAccountData('userId') === 446){
                rules.push('mail');
            }

            setSteps(rules);
        } else {
            let rules = [
                'welcome',
                'number',
                'can-spam',
                'timezone'
            ];

            if (Utils.getAccountData('agencyId') === 8){
                rules.push('mail');
            }

            setSteps(rules);
        }
        loadData();
    }, [])

    const goToStep = (_step) => {
        setButtonLoading(true);

        onboardGoToStep({
            step: _step
        })
        .then((response) => {
            loadData();
            setActiveStep(_step);
            setButtonLoading(false);
        })
        .catch(e => {
            if(window.showNotification !== undefined){
                window.showNotification('ERROR','Something went wrong')
            }
        });
    }

    const loadData = () => {


        fetchOnboardData({})
        .then(response => {
            if (response.data.data.completed) {
                setIsCompleted(true);
            }
            setActiveStep(parseInt(response.data.data.currentStep));
            setData(response.data.data);
            if (response.data.data.user.onboard_welcome_video){
                setOnBoardWelcomeVideo(response.data.data.user.onboard_welcome_video);
            }
            setComponentLoading(false);

        })
        .catch(e=>{
            if(window.showNotification !== undefined){
                window.showNotification('ERROR','Something went wrong')
            }
        })
    }

    const logout = () => {
        deleteCookie(process.env.REACT_APP_ACCESS_TOKEN);
        deleteCookie(process.env.REACT_APP_ACCOUNT_DATA);
        if(localStorage.getItem("global__custom__field__data")){
            localStorage.removeItem("global__custom__field__data");
        }
        history.push('/login');
        window.location.reload();
    }
    
    return (
        <Fragment>
            <Modal
            open={true}
            style={{ overflow: 'scroll' }}
            onClose={() => {}}
            >
                <div style={modalStyle} className={`${classes.paper} onboarding__modal__wr__v2__update fdfdsd awesome__scroll_bar`}>
                    {
                        (componentLoading || !steps || !data) ? (
                            <div className="onboarding__modal__loading__v2">
                                <Loading/>
                            </div>
                        ) : (
                            <Fragment>
                                {
                                    isCompleted ? (
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Congratulation completed={true} agencyId={agencyId} logout={logout} />
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid container>
                                            <Grid item xs={12}>
                                                {
                                                    (activeStep !==0 && activeStep === (steps.indexOf('welcome') + 1) && agencyId) && (
                                                        <Welcome agencyId={agencyId} welcomeVideo={onBoardWelcomeVideo} goToStep={goToStep} loading={buttonLoading} activeStep={activeStep} logout={logout} />
                                                    )
                                                }
                                                {
                                                    (activeStep !==0 && activeStep === (steps.indexOf('join-group') + 1)) && (
                                                        <JoinGroup goToStep={goToStep} loading={buttonLoading} activeStep={activeStep} logout={logout} />
                                                    )
                                                }
                                                {
                                                    (activeStep === (steps.indexOf('number') + 1) && agencyId) && (
                                                        <Number data={data} goToStep={goToStep} loading={buttonLoading} agencyId={agencyId} activeStep={activeStep} logout={logout} />
                                                    )
                                                }
                                                {
                                                    (activeStep === (steps.indexOf('can-spam') + 1) && agencyId) && (
                                                        <CanSpam data={data} goToStep={goToStep} loading={buttonLoading} agencyId={agencyId} activeStep={activeStep} logout={logout} />
                                                    )
                                                }
                                                {
                                                    (activeStep === (steps.indexOf('timezone') + 1) && agencyId) && (
                                                        <Timezone data={data} goToStep={goToStep} loading={buttonLoading} agencyId={agencyId} activeStep={activeStep} logout={logout} />
                                                    )
                                                }
                                                {
                                                    (activeStep === (steps.indexOf('mail') + 1) && agencyId) && (
                                                        <NylasConnectEmail data={data} goToStep={goToStep} loading={buttonLoading} agencyId={agencyId} activeStep={activeStep} logout={logout} />
                                                    )
                                                }
                                                {
                                                    (activeStep === 0 || activeStep === -1 || (steps != null && activeStep > steps.length)) && (
                                                        <Congratulation completed={false} data={data} goToStep={goToStep} agencyId={agencyId} logout={logout} />
                                                    )
                                                }
                                            </Grid>
                                            <Grid item xs={12} className="onboard_steper_label_container">
                                                {
                                                   ( activeStep !== 0) && (
                                                        <Stepper activeStep={activeStep - 1} alternativeLabel>
                                                            {steps.map((label) => (
                                                                <Step key={label}>
                                                                    <StepLabel>{getStepContent(label)}</StepLabel>
                                                                </Step>
                                                            ))}
                                                        </Stepper>
                                                    )
                                                }
                                                
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            </Fragment>
                        )
                    }
                </div>
            </Modal>
        </Fragment>
    )
}

export default React.memo(Onboard);