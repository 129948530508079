import React from "react";
import {EmptyScreenWrapper} from "../GlobalAddDealModalStyles";

const EmptyScreen = ({ text }) => {
    return(
        <EmptyScreenWrapper>
            <span>{ text }</span>
        </EmptyScreenWrapper>
    );
}

export default EmptyScreen;
