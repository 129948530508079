import { Card } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import "./SkeletonUI.css";

const SingleSkeleton = () => {
    return (
        <Card className="stage-distribution-card p-4">
            <div className="d-flex justify-content-between mb-4">
                <Skeleton width={50} />
                <Skeleton width={50} />
            </div>
            <div className='d-flex justify-content-center' >
                <Skeleton variant="circle" width={120} height={120} />
            </div>

            <div className="d-flex justify-content-between mb-4">
                <div>
                    <Skeleton width={50} />
                </div>
                
                <Skeleton width={50} />

            </div><div className="d-flex justify-content-between mb-4">
                <div>
                    <Skeleton width={50} />
                </div>
               
                <Skeleton width={50} />

            </div><div className="d-flex justify-content-between mb-4">
                <div>
                    <Skeleton width={50} />
                </div>
                <Skeleton width={50} />

            </div>
        </Card>

    )
}

export const DashboardModuleSkeleton = () => {
    return (
        <div className='dashboard-loader-parent d-flex mt-4 gap-15'>
            <div className="dashboard-loader-left ">
                {/* Deal */}
                <div className='d-flex gap'>
                    <div className='deal-item'>
                        <SingleSkeleton />
                    </div>
                    <div className='deal-item'>
                        <SingleSkeleton />
                    </div>
                    <div className='deal-item  mb-4'>
                        <SingleSkeleton />
                    </div>
                </div>

                {/* Funnel */}

                <div className='d-flex gap'>
                    <div className='funnel-left p-4'>
                        <div className="d-flex justify-content-between">
                            <Skeleton width={50} />
                            <Skeleton width={150} />
                            <Skeleton width={70} />
                        </div>
                        <div className='p-5'>
                            <div className='funnel-structure mt-3'>
                            </div>
                        </div>


                    </div>

                    <div className='funnel-right p-4'>
                        <div className="d-flex justify-content-between">
                            <Skeleton width={200} />
                            <Skeleton width={90} />

                        </div>
                        <div className="d-flex justify-content-between mt-3">
                            <Skeleton variant='rect' width={450} height={70} />
                        </div>
                        <div className="d-flex justify-content-between mt-3">
                            <Skeleton variant='rect' width={450} height={70} />
                        </div>
                        <div className="d-flex justify-content-between mt-3">
                            <Skeleton variant='rect' width={450} height={70} />
                        </div>
                        <div className="d-flex justify-content-between mt-3">
                            <Skeleton variant='rect' width={450} height={70} />
                        </div>
                    </div>

                </div>

            </div>

            <div className="  dashboard-loader-right p-4">
                <div className="d-flex mb-4 gap-5">
                    <div className=" right-sub-item  ">
                        <Skeleton variant='rect'  />
                    </div>
                    <div className=" right-sub-item ">
                        <Skeleton variant='rect'  />
                    </div>
                    <div className=" right-sub-item  ">
                        <Skeleton variant='rect'  />
                    </div>
                </div>
                <div className="d-flex gap-5">
                   
                    {Array.from(Array(7)).map((_, i) =>
                        <div className=" right-sub-item " key={i}>
                            <Skeleton variant='rect'  />
                        </div>
                    )}
                </div>
                <div className='d-flex mt-4'>
                    <div className="mt-3">
                        <Skeleton variant='circle' width={50} height={50}/>   
                    </div>  
                    <div className="pl-2 mt-3" style={{width:"60%"}} >
                        <Skeleton variant='rect' className='mb-2' />
                        <Skeleton variant='rect' />
                    </div>     
                    <div className="pl-2 mt-3" style={{width:"30%"}} >
                        <Skeleton variant='rect' />
                    </div>             
                </div>
                <div className='d-flex mt-4'>
                    <div className="mt-3">
                        <Skeleton variant='circle' width={50} height={50}/>   
                    </div>  
                    <div className="pl-2 mt-3" style={{width:"60%"}} >
                        <Skeleton variant='rect' className='mb-2' />
                        <Skeleton variant='rect' />
                    </div>     
                    <div className="pl-2 mt-3" style={{width:"30%"}} >
                        <Skeleton variant='rect' />
                    </div>             
                </div>
                <div className='d-flex mt-4'>
                    <div className="mt-3">
                        <Skeleton variant='circle' width={50} height={50}/>   
                    </div>  
                    <div className="pl-2 mt-3" style={{width:"60%"}} >
                        <Skeleton variant='rect' className='mb-2' />
                        <Skeleton variant='rect' />
                    </div>     
                    <div className="pl-2 mt-3" style={{width:"30%"}} >
                        <Skeleton variant='rect' />
                    </div>             
                </div>
            </div>
        </div>

    )
}
