import ActionType from "./ActionType";

export const storeProps = (payload) => ({
    type: ActionType.STORE_PROPS,
    payload: payload
});

export const handleLoading = (payload) => ({
    type: ActionType.HANDLE_LOADING,
    payload: payload
});

export const storeVnNumber = (payload) => ({
    type: ActionType.STORE_VIRTUAL_NUMBERS,
    payload: payload
});

export const updateVirtualNumber = (payload) => ({
    type: ActionType.UPDATE_SELECTED_VN,
    payload: payload
});

export const updateIsShowQuickReplyFlag = (payload) => ({
    type: ActionType.UPDATE_IS_SHOW_TEMPLATE,
    payload: payload
});

export const updateFileTypeFlag = (payload) => ({
    type: ActionType.UPDATE_FILE_TYPE,
    payload: payload
});

export const updateTemplateData = (payload) => ({
    type: ActionType.UPDATE_TEMPLATE_ID,
    payload: payload
});

export const updateFileData = (payload) => ({
    type: ActionType.UPDATE_FILE_DATA,
    payload: payload
});

export const updateSendingFlag = (payload) => ({
    type: ActionType.UPDATE_SENDING_FLAG,
    payload: payload
});

export const updateSaveAsTemplateFlag = (payload) => ({
    type: ActionType.UPDATE_SAVE_AS_TEMPLATE_FLAG,
    payload: payload
});

export const updateQuickReplyTitle = (payload) => ({
    type: ActionType.UPDATE_TITLE,
    payload: payload
});