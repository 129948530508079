const ActionType = {
    STORE_PROPS: 'STORE_PROPS',
    HANDLE_LOADING: 'HANDLE_LOADING',
    STORE_PERSONALIZED: 'STORE_PERSONALIZED',
    UPDATE_LAST_CONNECTED_VN: 'UPDATE_LAST_CONNECTED_VN',
    UPDATE_VN_LIST: 'UPDATE_VN_LIST',
    UPDATE_SELECTED_VN: 'UPDATE_SELECTED_VN',
    UPDATE_MMS_FILE: 'UPDATE_MMS_FILE',
    UPDATE_MESSAGE: 'UPDATE_MESSAGE',
    HANDLE_SENDING: 'HANDLE_SENDING',
    UPDATE_SAVE_AS_TEMPLATE_FLAG: 'UPDATE_SAVE_AS_TEMPLATE_FLAG',
    UPDATE_TITLE: 'UPDATE_TITLE',
    UPDATE_IS_SHOW_TEMPLATE: 'UPDATE_IS_SHOW_TEMPLATE',
    UPDATE_UNSUB_TEXT: 'UPDATE_UNSUB_TEXT',
}
export default ActionType;