import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { getSignUpPageData, selectPackage } from "../../actions/signupAction";
import { NewPackageBlock } from "./NewPackageBlock";
import Payment from "./Payment";

const App = (props) => {
    const [agencyThemeColor, setAgencyThemeColor] = useState(props.agencyThemeColor);
    const [agencyTextColor, setAgencyTextColor] = useState(props.agencyTextColor);
    const [packages, setPackages] = useState(props.packages);
    const [selectedPackage, setSelectedPackage] = useState(props.selectedPackage);
    const [isTrialPage, setIsTrialPage] = useState(false);

    useEffect(() => {
        setPackages(props.packages);
        if(props.type){
            if(props.type === 'trial'){
                setIsTrialPage(true);
            }
        }
    }, [props.packages]);

    useEffect(() => {
        setSelectedPackage(props.selectedPackage);
    }, [props.selectedPackage]);


    const renderPackages = () => {
        if (packages.length) {
            return packages.map(function (row, index) {
                return <NewPackageBlock
                        key={index} 
                        package={row} 
                        isTrialPage={isTrialPage}
                        agencyId={props.agencyId}
                        selectPackage={props.selectPackage}
                    />
            });
        }

        return [];
    }

    return (
        <React.Fragment>
            {!selectedPackage &&
            <div className="packages_content center-align" style={{ background: agencyThemeColor }}>
                <h1 style={{ color: agencyTextColor }}>Choose Your Package</h1>
                <h6 style={{ color: agencyTextColor }}>Select an option below. {isTrialPage ? 'You will not be charged at this time.' : ''}</h6>
                <Grid container className={"packages_content__wrapper__alt"}>
                    { renderPackages() }
                </Grid>
                {
                    isTrialPage?
                        <small style={{ color: agencyTextColor }}>*If you don't cancel within the free trial period, your account will convert to the paid plan chosen above.</small>
                        :
                        ''
                }

            </div>
            }
            {(selectedPackage && props.agencyStripe && props.agencyStripe.value) &&
            <Payment
                stripePublicKey={props.agencyStripe.value}
                privacyPolicy={props.privacyPolicy}
                termsAndConditions={props.termsAndConditions}
                agencyId={props.agencyId}
            />
            }
        </React.Fragment>

    );
}

const mapStateToProps = state => {
    return {
        validationCheck: state.signupReducer.validationCheck,
        packages: state.signupReducer.packages,
        selectedPackage: state.signupReducer.selectedPackage,
        agencyStripe: state.signupReducer.agencyStripe,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getSignUpPageData: (params) => dispatch(getSignUpPageData(params)),
        selectPackage: (params) => dispatch(selectPackage(params)),
    };
};

const SelectPackage = connect(mapStateToProps, mapDispatchToProps)(App);

export default SelectPackage;