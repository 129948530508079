import { all, call, put, takeEvery } from 'redux-saga/effects';
import * as CONTACT_API from "../api/contactApi";
import * as ACTION from '../constants/contactActionTypes';


function* updateContactWatcher() {
    yield takeEvery(ACTION.UPDATE_CONTACT, attemptUpdateContact)
    yield takeEvery(ACTION.ADD_EXTRA_EMAIL, attemptAddExtraEmail)
    yield takeEvery(ACTION.FETCH_CONTACT_ADDITIONAL_CONTACTS, fetchContactAdditionalContacts)
}

function* attemptUpdateContact(action) {
    try {
        let response = null
        if(action.payload.callback !== undefined){
            response = yield call(CONTACT_API.updateContactInfo, action.payload.payload);
            action.payload.callback(response?.data);
        }
        else{
            response = yield call(CONTACT_API.updateContactInfo, action.payload);
        }
        let responseData = response.data.original;
        if (responseData.success) {
            yield put({type: ACTION.UPDATE_CONTACT_COLUMN, payload: responseData.data});
        } else {
        }

    } catch (error) {
        console.log(error);
    }

}

function* attemptAddExtraEmail(action) {
    try {
        let response = null
        if(action.payload.callback !== undefined){
            response = yield call(CONTACT_API.addExtraEmailApi, action.payload.payload);
            action.payload.callback(response?.data);
        }
        else{
            console.log(action.payload,"ok")
            response = yield call(CONTACT_API.addExtraEmailApi, action.payload);
        }
        let responseData = response.data.original;
        if (responseData.success) {
            yield put({type: ACTION.UPDATE_CONTACT_COLUMN, payload: responseData.data});
        } else {
        }

    } catch (error) {
        console.log(error);
    }

}

function* getNoteModelOpenWatcher() {
    yield takeEvery(ACTION.SHOW_NOTE_MODAL, attemptNoteModelOpen)
}

function* attemptNoteModelOpen(action) {
    yield put({type: ACTION.SET_SHOW_NOTE_MODAL, payload:action.payload.status });
}
function* getTaskModelOpenWatcher() {
    yield takeEvery(ACTION.SHOW_TASK_MODAL, attemptTaskModelOpen)
}

function* attemptTaskModelOpen(action) {
    yield put({type: ACTION.SET_SHOW_TASK_MODAL, payload:action.payload.status });
}

function* fetchContactAdditionalContacts(action){
    const { payload } = action;

    try{
        const response = yield call(CONTACT_API.getAllAdditionalContactApi, payload);

        if (response.data.success === true) {
            if (response.data.data) {
                if (Array.isArray(response.data.data)) {
                    yield put({ type: ACTION.UPDATE_MULTIPLE_DATA, payload: { contactAdditionalContacts: response.data.data } })
                }
            }
        }else {
            yield put({ type: ACTION.UPDATE_MULTIPLE_DATA, payload: { contactAdditionalContacts: [] } })
        }
    }catch (err){
        yield put({ type: ACTION.UPDATE_MULTIPLE_DATA, payload: { contactAdditionalContacts: [] } })
    }
}


export default function* contactMiddleware() {
    yield all([
        updateContactWatcher(),getNoteModelOpenWatcher(),getTaskModelOpenWatcher()])
}