
import axios from "axios";
import ContactHelper from "./Helper";
import LinkFile from "../SendComponents/Video/LinkFile";
import Api from "../SendComponents/Video/redux/Api";

const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/container`
const dealRootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/deal`
const contactRootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/contacts`
const inboxRootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/inbox`

const apiKey = ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN);//for production

/* get contact details by id */
const getContactDetailsById = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: contactRootUrl + '/get-contact-details-v2',
        data: data
    });
}
/* update contact field */
const updateContactField = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/contact/update/field',
        data: data
    });
}
/* get user custom field */
const getUserCustomField = () => {
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/get-custom-fields',
        data: {
            'api-key': apiKey,
            'for_custom_field_update': true
        },
    });
}
/* update custom fields */
const updateCustomField = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/contact/single/custom-field/update',
        data: data
    });
}
/* get contact tags */
const getContactTags = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: contactRootUrl + '/get-contact-tag-list',
        data: data
    });
}
/* get all user tags */
const getAllTags = (params) => {

    let key = ContactHelper.getCookie(process.env.REACT_APP_ACCESS_TOKEN);//for production

    let data = {
        ...params,
        ...{ 'api-key': key }
    }
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/get/tag/list',
        data: data,
    });
}
/* remove tag from contant */
const removeTagFromContact = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/contact/remove-tags',
        data: data
    });
}
/* add tag to contact-new */
const addTagToContact = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/contact/add-tags',
        data: data
    });
}
/* get all contact campaign */
const getAllContactCampaigns = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/campaign/list/contact',
        data: data
    });
}
/* get all eligible campaign by contact*/
const getEligibleCampaigns = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/campaign/not-in-current-contact/list',
        data: data
    });
}
/* campaign single contact run */
const campaignSingleContactRun = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/campaign/single-contact/run',
        data: data
    });
}
/* campaign single contact pause */
const campaignSingleContactPause = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/campaign/single-contact/pause',
        data: data
    });
}
/* campaign single contact unsubscribed */
const campaignSingleContactUnsubscribed = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/campaign/contacts/unsubscribe',
        data: data
    });
}
/* move campaign contact */
const moveCampaignContact = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/campaign/contact/move',
        data: data
    });
}
/* get all lead sources */
const getLeadSource = () => {
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/lead-sources',
        data: {
            'api-key': apiKey
        },
    });
}
/* move campaign contact */
const updateContactSource = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/contact-source-type/change/source',
        data: data
    });
}
/* get contact activity */
const getPeopleActivity = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        // url: dealRootUrl+'/get/people/activity',
        url: contactRootUrl + '/activity-list-contact-details',
        data: data
    });
}
/* activity mark as done */
const activityMarkAsDone = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/contact-activity-mark-as-done',
        data: data
    });
}
/* get contact deal */
const getContactDeals = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/contact-stages',
        data: data
    });
}
/* delete deal */
const deleteContact = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/contact/delete/from/stage',
        data: data
    });
}
/* add note */
const addNote = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/note-add-submit',
        data: data
    });
}
/* update note */
const updateNote = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/update-timeline-note',
        data: data
    });
}
/* get notes */
const getNotes = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/get/people/conversation',
        data: data
    });
}
const getAllTeamMembers = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: contactRootUrl + '/get-all-team-users',
        data: data
    });
}
/* update deal won lost */
const updateDealStatus = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/status-change-deals',
        data: data
    });
}
/* update deal info */
const updateDealInfo = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/contact/update/field',
        data: data
    });
}
/* direct mail send */
const addCardBroadcastToContact = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: inboxRootUrl + '/add-card-broadcast-to-contact',
        data: data
    });
}

export const sendVideoEmail = async (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/send/bulk-email',
        data: data
    });
}




































const getVittualNumbers = () => {
    return axios.request({
        method: 'POST',
        url: rootUrl + '/get-virtual-number-by-user',
        data: {
            'api-key': apiKey
        },
    });
}

const getPersonalizedTag = () => {
    return axios.request({
        method: 'POST',
        url: rootUrl + '/get-custom-fields',
        data: {
            'api-key': apiKey
        },
    });
}

const getTemplates = () => {
    return axios.request({
        method: 'POST',
        url: rootUrl + '/get/sms/template',
        data: {
            'api-key': apiKey
        },
    });
}

const submitSendFromMap = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: rootUrl + '/send-broadcast-for-map',
        data: data
    });
}

const submitSendDefault = (params, formData = false) => {
    // let data = {
    //     ...params,
    //     ...{ 'api-key': apiKey }
    // }
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    if (formData) {
        params.append('api-key', apiKey)
        data = params;
    }
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/campaign/contacts/conversation',
        data: data
    });
}

/* for email module */
const getOtherEmailForAContact = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/get-other-email-by-contact',
        data: data
    });
}

const getProfileData = () => {
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/single/profile',
        data: {
            'api-key': apiKey
        },
    });
}

const submitSendEmailDefault = (params) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/send/bulk-email',
        data: data
    });
}

const getEmailTemplates = () => {
    return axios.request({
        method: 'POST',
        url: rootUrl + '/get/email/template',
        data: {
            'api-key': apiKey
        },
    });
}

/* voice send module */
const getVoiceTemplates = () => {
    return axios.request({
        method: 'POST',
        url: rootUrl + '/campaign/voice-template',
        data: {
            'api-key': apiKey
        },
    });
}

const submitSendVoiceDefault = (params, formData = false) => {
    let data = {
        ...params,
        ...{ 'api-key': apiKey }
    }
    if (formData) {
        params.append('api-key', apiKey)
        data = params;
    }

    return axios.request({
        method: 'POST',
        url: dealRootUrl + '/campaign/contacts/conversation',
        data: data
    });
}

const ApiList = {
    getContactDetailsById,
    updateContactField,
    getUserCustomField,
    updateCustomField,
    getContactTags,
    getAllTags,
    removeTagFromContact,
    addTagToContact,
    getAllContactCampaigns,
    getEligibleCampaigns,
    campaignSingleContactRun,
    campaignSingleContactPause,
    campaignSingleContactUnsubscribed,
    moveCampaignContact,
    getLeadSource,
    updateContactSource,
    getPeopleActivity,
    activityMarkAsDone,
    getContactDeals,
    deleteContact,
    updateNote,
    addNote,
    getNotes,
    getAllTeamMembers,
    updateDealStatus,
    updateDealInfo,
    addCardBroadcastToContact,







    getVittualNumbers,
    getPersonalizedTag,
    getTemplates,
    submitSendFromMap,
    submitSendDefault,
    getOtherEmailForAContact,
    getProfileData,
    submitSendEmailDefault,
    getVoiceTemplates,
    submitSendVoiceDefault,
    getEmailTemplates
}
export default ApiList;