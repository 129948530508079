import { FormControl, FormHelperText, InputBase, withStyles } from "@material-ui/core";
import React from "react";
import A2pLearnMore from "../a2pLearnMore";
import CampaignUseCaseDescriptionLearnMore from "../a2pLearnMore/CampaignUseCaseDescriptionLearnMore";
import Styles from "./Styles.module.css";

const CustomInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
            color: "black !important",
            fontSize: "20px!important"
        },
    },
    input: {
        fontSize: "14px !important",
        height: "auto !important",
        minHeight: "200px !important",
        color: "#707070 !important",
        borderRadius: "4px !important",
        position: "relative",
        border: "1px solid #ced4da!important",
        padding: "10px 12px !important",
        marginBottom: "0!important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            color: "#707070 !important",
            boxShadow: "none!important",
            borderColor: "black !important",
        },
    },
}))(InputBase);

const CampaignDetails = ({ inputScrollToViewRef, formData, callback, errorCallback, a2pDlcError }) => {
    const handleFormDataChange = (name, value) => {
        if (name === "useCaseDescription"){
            if (value.trim() === ""){
                errorCallback("useCaseDescription","Campaign Use Case Description is required!");
            }else if(value.length < 40 || value.length > 4096){
                errorCallback("useCaseDescription","Campaign Use Case Description can not be less than 40 characters and more than 4096 characters!");
            }else {
                errorCallback("useCaseDescription","");
            }
        }
        callback(name, value);
    };

    const renderErrorMessage = (fieldName) => {
        return <FormHelperText error>{a2pDlcError[fieldName]}</FormHelperText>;
    };

    return (
        <div className={Styles.contentWrapper}>
            <div className={Styles.contentHeader}>
                <h1 className={Styles.contentTitle}>Read before answering questions:</h1>
                <p className={Styles.contentDescription}>Before the phone companies are willing to approve your messaging campaigns they need to review some examples of the types of messages you intend to send. Complete the info below.</p>
            </div>
            <div className={Styles.contentBody}>
                <div className={Styles.contentBodyLeft}>
                    <FormControl ref={el => inputScrollToViewRef.current[1] = el} className={Styles.formControl}>
                      <span className={`${Styles.label} ${Styles.dFlex} ${Styles.justifySpaceBetween}`}>
                        <span>
                            Campaign Use Case Description
                            <span className={Styles.required}> *</span>
                        </span>
                          {
                              formData.useCase &&
                              <span onClick={(event)=> {
                                  // A2pSuggestion({
                                  //     suggestionTitle: "Campaign Use Case Description Suggestions",
                                  //     suggestions: CODE_WISE_DATA.CAMPAIGN_USE_CASE_DESCRIPTION,
                                  //     onChange: (value) => {
                                  //         handleFormDataChange("useCaseDescription", value)
                                  //     }
                                  // });

                                  A2pLearnMore({
                                      title: "Campaign Use Case Description",
                                      innerBody: <CampaignUseCaseDescriptionLearnMore/>
                                  });
                              }} className={Styles.smallButton}>Suggestions</span>
                          }
                      </span>
                        <CustomInput
                            placeholder={"Example:\n" +
                                "We reach out to clients and prospects who have opted in to receive information about the products and services that we offer.\n" +
                                "\n" +
                                "Example:\n" +
                                "This campaign sends a one time passcode to end users when they try to log into our company's website.\n" +
                                "\n" +
                                "Example:\n" +
                                "We use this campaign to confirm appointments and send appointment reminders."}
                            name="useCaseDescription"
                            value={formData.useCaseDescription}
                            onChange={(e)=>{handleFormDataChange("useCaseDescription", e.target.value)}}
                            multiline
                        />
                        {renderErrorMessage("useCaseDescription")}
                        <span className={Styles.multilineInputLimit}>Min length: 40 characters. Max length: 4096 characters.</span>
                    </FormControl>
                </div>
            </div>
        </div>
    );
}

export default CampaignDetails;