import React from "react";
import Styles from "./Styles.module.css";
import {
    Checkbox,
    FormControl,
    InputBase,
    MenuItem,
    Select,
    withStyles,
    FormControlLabel,
    FormHelperText
} from "@material-ui/core";
import {Search} from "@material-ui/icons";
import Utils from "../../helpers/Utils";

const CustomInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
            color: "black !important",
            fontSize: "20px!important"
        },
    },
    input: {
        fontSize: "14px !important",
        height: "auto !important",
        color: "#707070 !important",
        borderRadius: "4px !important",
        position: "relative",
        border: "1px solid #ced4da!important",
        padding: "10px 12px !important",
        marginBottom: "0!important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            color: "#707070 !important",
            boxShadow: "none!important",
            borderColor: "black !important",
        },
        "&::placeholder": {
            fontSize: "11px !important"
        }
    },
}))(InputBase);

const BootstrapInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        fontSize: "14px !important",
        borderRadius: "4px !important",
        position: "relative",
        color: "#707070 !important",
        border: "1px solid #ced4da !important",
        padding: "10px 26px 10px 12px !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 4
        },
    },
}))(InputBase);

const businessTypeList = [
    {label: "Sole Proprietorship", value: "Sole Proprietorship"},
    {label: "Partnership", value: "Partnership"},
    {label: "Limited Liability Corporation", value: "Limited Liability Corporation"},
    {label: "Co-operative", value: "Co-operative"},
    {label: "Non-profit Corporation", value: "Non-profit Corporation"},
    {label: "Corporation", value: "Corporation"},
];

const businessIndustryList = [
    {label: "Automotive", value: "AUTOMOTIVE"},
    {label: "Agriculture", value: "AGRICULTURE"},
    {label: "Banking", value: "BANKING"},
    {label: "Consumer", value: "CONSUMER"},
    {label: "Education", value: "EDUCATION"},
    {label: "Engineering", value: "ENGINEERING"},
    {label: "Energy", value: "ENERGY"},
    {label: "Oil and Gas", value: "OIL_AND_GAS"},
    {label: "Fast Moving Consumer Goods", value: "FAST_MOVING_CONSUMER_GOODS"},
    {label: "Financial", value: "FINANCIAL"},
    {label: "Fintech", value: "FINTECH"},
    {label: "Food and Beverage", value: "FOOD_AND_BEVERAGE"},
    {label: "Government", value: "GOVERNMENT"},
    {label: "Health Care", value: "HEALTHCARE"},
    {label: "Hospitality", value: "HOSPITALITY"},
    {label: "Insurance", value: "INSURANCE"},
    {label: "Legal", value: "LEGAL"},
    {label: "Manufacturing", value: "MANUFACTURING"},
    {label: "Media", value: "MEDIA"},
    {label: "Online", value: "ONLINE"},
    {label: "Raw Materials", value: "RAW_MATERIALS"},
    {label: "Real Estate", value: "REAL_ESTATE"},
    {label: "Religion", value: "RELIGION"},
    {label: "Retail", value: "RETAIL"},
    {label: "Jewelry", value: "JEWELRY"},
    {label: "Technology", value: "TECHNOLOGY"},
    {label: "Telecommunications", value: "TELECOMMUNICATIONS"},
    {label: "Transportation", value: "TRANSPORTATION"},
    {label: "Travel", value: "TRAVEL"},
    {label: "Electronics", value: "ELECTRONICS"},
    {label: "Not for Profit", value: "NOT_FOR_PROFIT"}
];

const typeList = [
    {label: "Individual", value: "individual"},
    {label: "Business", value: "business"},
];

const BusinessInformation = ({ inputScrollToViewRef, formData, callback, errorCallback, a2pDlcError }) => {
    const handleFormDataChange = (name, value) => {
        if (name === "business_type"){
            if (value.trim() === ""){
                errorCallback("business_type","Business Type is required!");
            }else{
                errorCallback("business_type","");
            }
        }else if (name === "type"){
            if (value.trim() === ""){
                errorCallback("type","Customer Business Profile Type is required!");
            }else{
                errorCallback("type","");
            }
        }else if (name === "business_industry"){
            if (value.trim() === ""){
                errorCallback("business_industry","Business Industry is required!");
            }else {
                errorCallback("business_industry","");
            }
        }else if (name === "business_registration_id_type"){
            if (value.trim() === ""){
                errorCallback("business_registration_id_type","Business Registration ID Type is required!");
            }else {
                errorCallback("business_registration_id_type","");
            }
        }else if (name === "business_registration_id"){
            if (value.trim() === ""){
                errorCallback("business_registration_id","Business Registration Number (EIN#) is required!");
            }else {
                errorCallback("business_registration_id","");
            }
        }else if (name === "website_url"){
            if (value.trim() === ""){
                errorCallback("website_url","Business Website is required!");
            }else if (!Utils.isValidURL(value)){
                errorCallback("website_url","Business Website must be a valid url!");
            }else {
                errorCallback("website_url","");
            }
        }else if(name === "iso_country"){
            if (value !== "US"){
                window.showNotification("ERROR", "Sorry, we are currently not operating outside of the Canada and USA regions!");
                return;
            }
        }
        callback(name, value);
    };

    const renderErrorMessage = (fieldName) => {
        return <FormHelperText error>{a2pDlcError[fieldName]}</FormHelperText>;
    };

    const renderBusinessTypeListOptions = () => {
        const item = [];
        for(let i=0;i<businessTypeList.length;i++){
            item.push(<MenuItem className="dropdownhelper-menuitem-class" value={businessTypeList[i].value}>{businessTypeList[i].label}</MenuItem>);
        }
        return item;
    }

    const renderBusinessIndustryListOptions = () => {
        const item = [];
        for(let i=0;i<businessIndustryList.length;i++){
            item.push(<MenuItem className="dropdownhelper-menuitem-class" value={businessIndustryList[i].value}>{businessIndustryList[i].label}</MenuItem>);
        }
        return item;
    }

    const renderCustomerBusinessProfileTypeListOptions = () => {
        const item = [];
        for(let i=0;i<typeList.length;i++){
            item.push(<MenuItem className="dropdownhelper-menuitem-class" value={typeList[i].value}>{typeList[i].label}</MenuItem>);
        }
        return item;
    }

    return (
        <div className={Styles.contentWrapper}>
            <div className={Styles.contentHeader}>
                <h1 className={Styles.contentTitle}>Read before answering questions:</h1>
                <p className={Styles.contentDescription}>When completing this page please reference your official IRS documentation. (EIN documents, W2, W9, or CP575). Answer the questions carefully to ensure the info you provide matches your official IRS documents <u>EXACTLY</u>. Inaccuracies can lead to manual reviews and long delays in getting your business registered.</p>
            </div>
            <div className={Styles.contentBody}>
                <div className={Styles.contentBodyLeft}>
                    <FormControl ref={el => inputScrollToViewRef.current[0] = el} className={Styles.formControl}>
                      <span className={Styles.label}>
                        Customer Business Profile Type
                        <span className={Styles.required}> *</span>
                      </span>
                        <Select
                            name="type"
                            value={formData.type}
                            displayEmpty
                            onChange={(e)=>{ handleFormDataChange("type", e.target.value) }}
                            input={<BootstrapInput/>}
                            inputProps={{ "aria-label": "Without label" }}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                        >
                            <MenuItem className="dropdownhelper-menuitem-class" value="" disabled>Select your customer business profile type.</MenuItem>
                            {renderCustomerBusinessProfileTypeListOptions()}
                        </Select>
                        {renderErrorMessage("type")}
                    </FormControl>
                    <FormControl ref={el => inputScrollToViewRef.current[1] = el} className={Styles.formControl}>
                      <span className={Styles.label}>
                        Business Type
                        <span className={Styles.required}> *</span>
                      </span>
                        <Select
                            name="business_type"
                            value={formData.business_type}
                            displayEmpty
                            onChange={(e)=>{ handleFormDataChange("business_type", e.target.value) }}
                            input={<BootstrapInput/>}
                            inputProps={{ "aria-label": "Without label" }}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                        >
                            <MenuItem className="dropdownhelper-menuitem-class" value="">Select an option (reference your IRS documents to confirm the correct option)</MenuItem>
                            {renderBusinessTypeListOptions()}
                        </Select>
                        {renderErrorMessage("business_type")}
                    </FormControl>
                    <FormControl ref={el => inputScrollToViewRef.current[2] = el} className={Styles.formControl}>
                      <span className={Styles.label}>
                        Business Industry
                        <span className={Styles.required}> *</span>
                      </span>
                        <Select
                            name="business_industry"
                            value={formData.business_industry}
                            displayEmpty
                            onChange={(e)=>{ handleFormDataChange("business_industry", e.target.value) }}
                            input={<BootstrapInput/>}
                            inputProps={{ "aria-label": "Without label" }}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                        >
                            <MenuItem className="dropdownhelper-menuitem-class" value="" disabled>Select your industry</MenuItem>
                            {renderBusinessIndustryListOptions()}
                        </Select>
                        {renderErrorMessage("business_industry")}
                    </FormControl>
                    <FormControl ref={el => inputScrollToViewRef.current[4] = el} className={Styles.formControl}>
                      <span className={Styles.label}>
                        Business Registration Number (EIN#)
                        <span className={Styles.required}> *</span>
                      </span>
                        <CustomInput
                            placeholder="Enter your EIN Number (Must be a 9-digit number ex. 12-3456789. This can be found on W2, W9, or CP575)."
                            name="business_registration_id"
                            value={formData.business_registration_id}
                            onChange={(e)=>{handleFormDataChange("business_registration_id", e.target.value)}}
                        />
                        {renderErrorMessage("business_registration_id")}
                    </FormControl>
                    <FormControl ref={el => inputScrollToViewRef.current[5] = el} className={Styles.formControl}>
                      <span className={Styles.label}>
                        Business Website 
                            <span style={{color:"#9670ff",marginLeft:"5px",fontSize:"14px"}}>
                               (It must be your actual business domain)
                            </span>
                        <span className={Styles.required}> *</span>
                      </span>
                        <CustomInput
                            placeholder="Add your business website url here (ex. www.businesswebsite.com)."
                            name="website_url"
                            value={formData.website_url}
                            onChange={(e)=>{handleFormDataChange("website_url", e.target.value)}}
                        />
                        {renderErrorMessage("website_url")}
                    </FormControl>
                    <FormControl ref={el => inputScrollToViewRef.current[6] = el} className={Styles.formControl}>
                      <span className={Styles.label}>
                        Business Regions of Operations
                        <span className={Styles.required}> *</span>
                      </span>
                        <div className={Styles.checkboxGrid}>
                            <FormControlLabel
                                className={Styles.einDescCheckbox}
                                control={
                                    <Checkbox
                                        name="AFRICA"
                                        id="iso_country"
                                        color="primary"
                                        onChange={(e)=>{handleFormDataChange("iso_country", e.target.name)}}
                                        checked={formData.iso_country === "AFRICA"}
                                    />
                                }
                                label="Africa"
                            />
                            <FormControlLabel
                                className={Styles.einDescCheckbox}
                                control={
                                    <Checkbox
                                        name="ASIA"
                                        id="iso_country"
                                        color="primary"
                                        onChange={(e)=>{handleFormDataChange("iso_country", e.target.name)}}
                                        checked={formData.iso_country === "ASIA"}
                                    />
                                }
                                label="Asia"
                            />
                            <FormControlLabel
                                className={Styles.einDescCheckbox}
                                control={
                                    <Checkbox
                                        name="LATIN-AMERICA"
                                        id="iso_country"
                                        color="primary"
                                        onChange={(e)=>{handleFormDataChange("iso_country", e.target.name)}}
                                        checked={formData.iso_country === "LATIN-AMERICA"}
                                    />
                                }
                                label="Latin America"
                            />
                            <FormControlLabel
                                className={Styles.einDescCheckbox}
                                control={
                                    <Checkbox
                                        name="US"
                                        id="iso_country"
                                        color="primary"
                                        onChange={(e)=>{handleFormDataChange("iso_country", e.target.name)}}
                                        checked={formData.iso_country === "US"}
                                    />
                                }
                                label="USA and Canada"
                            />
                            <FormControlLabel
                                className={Styles.einDescCheckbox}
                                control={
                                    <Checkbox
                                        name="EUROPE"
                                        id="iso_country"
                                        color="primary"
                                        onChange={(e)=>{handleFormDataChange("iso_country", e.target.name)}}
                                        checked={formData.iso_country === "EUROPE"}
                                    />
                                }
                                label="Europe"
                            />
                        </div>
                        {renderErrorMessage("iso_country")}
                    </FormControl>
                </div>
                <div className={Styles.contentBodyRight}>
                    <div className={Styles.einDesc}>
                        <div><span className={Styles.einDescStrong}>What is an EIN?</span> (Employer Identification Number)</div>
                        <div>Your 9-digit US EIN is also referred to as your Tax ID.</div>
                        <div>To get the highest possible messaging limits, create your Customer Profile with the details that match those in your US EIN listing.</div>
                        <div>If there are any differences - for example in the business name or address, your messaging limits will be lower.</div>
                        <div className={Styles.einDescLinkIcon}> <Search/> <a href="https://www.hipaaspace.com/irs/irs-tin-check" target="_blank"  rel="noreferrer noopener">SEARCH FOR YOUR EIN HERE</a></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BusinessInformation;