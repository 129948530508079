import React, {useEffect, useState} from "react";
import { Dialog, styled } from "@material-ui/core";
import GetStarted from "./GetStarted";
import SelectNumber from "./SelectNumber";
import NumberAdded from "./NumberAdded";
import {STEP_TABS} from "./constants";

const ModalWrapper = styled(Dialog)(({theme}) => ({
  "& .MuiDialog-paperWidthSm": {
    maxWidth: 900,
  },
  "& .MuiButton-root":{
    padding: "6px 16px !important",
    borderRadius: "4px"
  }
}));

const PhoneSettingsModal = ({ open, onClose, data }) => {
  const [step, setStep] = useState(STEP_TABS.GET_STARTED);

  useEffect(()=>{
    if (data?.skipGetStarted){
      setStep(STEP_TABS.SELECT_NUMBER);
    }
  },[data]);

  const renderStep = () => {
    switch (step){
      case STEP_TABS.GET_STARTED:
        return <GetStarted setStep={setStep} onClose={onClose}/>;
      case STEP_TABS.SELECT_NUMBER:
        return <SelectNumber setStep={setStep} data={data} onClose={onClose}/>;
      case STEP_TABS.NUMBER_ADDED:
        return <NumberAdded onClose={onClose} data={data}/>;
      default:
      return <GetStarted/>;
    }
  };

  return (
    <ModalWrapper open={open} onClose={()=>{}}>
      {renderStep()}
    </ModalWrapper>
  );
};

export default PhoneSettingsModal;
