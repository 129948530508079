import React, {useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {
    getCustomFieldData, updateArrSlotTimeHour1, updateArrSlotTimeMin1,
    updateDay, updateGreetingOption, updateGreetingsOptionTimeHour1, updateGreetingsOptionTimeMin1,
    updateHour, updateLaterHour, updateLaterMin, updateMin,
    updateRecurringDay, updateRecurringTimeHour1, updateRecurringTimeMin1, updateStartDate,
    updateTabValue, updateTime
} from "../../../actions/SendModulesAction";
import {connect} from "react-redux";
import DatePicker from "react-datepicker";
import moment from 'moment';
import GlobalCustomSelect from "../../globals/FormComponents/GlobalCustomSelect";

const hours = [
    0,1,2,3,4,5,6,7,8,9,10,11,12
];
const minutes = [
    0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,35,40,45,50,55
];

const TabPanel = props => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const App = props => {
    //const [campaignDetails, setCampaignDetails] = React.useState(props.campaignDetails);

    const [tabValue, setTabValue] = useState(props.tabValue);
    const [hour, setHour] = useState(props.hour);
    const [min, setMin] = useState(props.min);
    const [day, setDay] = useState(props.day);
    //const [recurringDay, setRecurringDay] = useState(props.recurringDay);
    const [laterHour, setLaterHour] = useState(props.laterHour);
    const [laterMin, setLaterMin] = useState(props.laterMin);
    //const [time, setTime] = useState(props.time);
    const [startDate, setStartDate] = useState(props.startDate);
    const [arrSlotTimeHour1, setArrSlotTimeHour1] = useState(props.arrSlotTimeHour1);
    const [arrSlotTimeMin1, setArrSlotTimeMin1] = useState(props.arrSlotTimeMin1);
    const [greetingOption, setGreetingOption] = useState(props.greetingOption);
    const [greetingsOptionTimeMin1, setGreetingsOptionTimeMin1] = useState(props.greetingsOptionTimeMin1);
    const [greetingsOptionTimeHour1, setGreetingsOptionTimeHour1] = useState(props.greetingsOptionTimeHour1);
    const [recurringTimeHour1, setRecurringTimeHour1] = useState(props.recurringTimeHour1);
    const [recurringTimeMin1, setRecurringTimeMin1] = useState(props.recurringTimeMin1);
    const [showAllTimeSetting, setShowAllTimeSetting] = useState(props.showAllTimeSetting);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        props.updateTabValue(newValue);
        if(props.tabChangeCallback !== undefined){
            props.tabChangeCallback()
        }
    };

    useEffect(() => {
        setTabValue(props.tabValue);
        setHour(props.hour);
        setMin(props.min);
        setDay(props.day);
    }, [props.tabValue, props.hour, props.min, props.day])

    // useEffect(() => {
    //     setTime(props.time)
    // }, [props.time])

    useEffect(() => {
        setRecurringTimeHour1(props.recurringTimeHour1);
        setRecurringTimeMin1(props.recurringTimeMin1);
    }, [props.recurringTimeHour1, props.recurringTimeMin1])

    useEffect(() => {
        setGreetingOption(props.greetingOption)
    }, [props.greetingOption])

    useEffect(() => {
        setGreetingsOptionTimeHour1(props.greetingsOptionTimeHour1);
        setGreetingsOptionTimeMin1(props.greetingsOptionTimeMin1);
    }, [props.greetingsOptionTimeHour1, props.greetingsOptionTimeMin1])

    useEffect(() => {
        setLaterHour(props.laterHour);
        setLaterMin(props.laterMin);
    }, [props.laterHour, props.laterMin])


    useEffect(() => {
        setStartDate(props.startDate)
    }, [props.startDate])

    useEffect(() => {
        setArrSlotTimeHour1(props.arrSlotTimeHour1);
        setArrSlotTimeMin1( props.arrSlotTimeMin1);
    }, [props.arrSlotTimeHour1, props.arrSlotTimeMin1])

    // const renderHour = () => {
    //     let hoursOption = [];
    //     hours.map(function(data,index){
    //         hoursOption.push(
    //             <option key={index} value={data} >{data}</option>
    //         );
    //     });
    //     return hoursOption
    // }

    const hourChangeHandler = (value) => {
        setHour(value);
        props.updateHour(value);
    }

    // const renderMin = () => {
    //     let minuteOption = [];
    //     minutes.map(function(data,index){
    //         minuteOption.push(
    //             <option key={index} value={data} >{data}</option>
    //         );
    //     });
    //     return minuteOption;
    // }

    const minChangeHandler = (value) => {
        setMin(value);
        props.updateMin(value);
    }

    // const renderDays = () => {
    //     let list = [];
    //     for (let i = 2; i <= 1000; i++) {
    //         list.push(<option key={i} value={i} selected={i===day} >{i}</option>);
    //         // list.push(i);
    //     }
    //     return list;
    // }

    const dayChangeHandler = (value) => {
        setDay(value);
        props.updateDay(value);
    }

    // const handleRecurringDayChange = value => {
    //     setRecurringDay(value);
    //     updateRecurringDay(value);
    // };

    const timeChangeHandler = value => {
        let selectedTime = value.split(':')
        let arrSlotTimeHour1 = ('0' + selectedTime[0]).slice(-2);
        let arrSlotTimeMin1 = ('0' + selectedTime[1]).slice(-2);

        setLaterHour(arrSlotTimeHour1);
        setLaterMin(arrSlotTimeMin1);

        props.updateTime(arrSlotTimeHour1+':'+arrSlotTimeMin1 + ":00");
    }

    const timeIntervalOptions = (interval) => {
      let option = [];
      for (let ap = 1; ap <= 2; ap++) {
        for (let i = 0; i < 12; i++) {
          for (let j = 0; j < 60 / interval; j++) {
            let hv = i;
            let h = i;
            if (i === 0) h = 12;
            if (ap !== 1) hv = hv + 12;
            // option.push(<option value={('0' + hv).slice(-2)+':'+('0' + interval*j).slice(-2)} data-content="<i className='fa fa-address-book-o' aria-hidden='true'></i>Option1">{('0' + h).slice(-2)+':'+('0' + interval*j).slice(-2)+' '+(ap==1?'AM':'PM')}</option>)
            option.push({
              label:
                ("0" + h).slice(-2) +
                ":" +
                ("0" + interval * j).slice(-2) +
                " " +
                (ap === 1 ? "AM" : "PM"),
              value:
                ("0" + hv).slice(-2) + ":" + ("0" + interval * j).slice(-2),
            });
          }
        }
      }
      return option;
    };

    const timeChangeFixed = value => {

        let selectedTime = value.split(':')
        var arrSlotTimeHour1 = ('0' + selectedTime[0]).slice(-2);
        var arrSlotTimeMin1 = ('0' + selectedTime[1]).slice(-2);

        setArrSlotTimeHour1(arrSlotTimeHour1);
        setArrSlotTimeMin1(arrSlotTimeMin1);

        props.updateTime(arrSlotTimeHour1+':'+arrSlotTimeMin1 + ":00");
    }

    const onGreetingsOptionChangeFixed = value => {

        let selectedTime = value.split(':')
        let arrSlotTimeHour1 = ('0' + selectedTime[0]).slice(-2);
        let arrSlotTimeMin1 = ('0' + selectedTime[1]).slice(-2);

        setGreetingsOptionTimeHour1(arrSlotTimeHour1);
        props.updateGreetingsOptionTimeHour1(arrSlotTimeHour1);

        setGreetingsOptionTimeMin1(arrSlotTimeMin1);
        props.updateGreetingsOptionTimeMin1(arrSlotTimeMin1);

        props.updateTime(arrSlotTimeHour1 + ":" + arrSlotTimeMin1 +":00")
    }

    const greetingOptionChangeHandler = value => {
        setGreetingOption(value);
        props.updateGreetingOption(value);
    }

    const startDateChangeHandler = value => {
        if(!moment(value).isValid()){
            setStartDate(null)
            props.updateStartDate(null)
            return
        }
        // setStartDate(value);
        setStartDate(moment(value).format('MM/DD/YYYY'));
        // props.updateStartDate(value);
        props.updateStartDate(moment(value).format('MM/DD/YYYY'));
    }

    const ongRecurringChangeFixed = value => {

        let selectedTime = value.split(':')
        let arrSlotTimeHour1 = ('0' + selectedTime[0]).slice(-2);
        let arrSlotTimeMin1 = ('0' + selectedTime[1]).slice(-2);

        setRecurringTimeHour1(arrSlotTimeHour1);
        updateRecurringTimeHour1(arrSlotTimeHour1);

        setRecurringTimeMin1(arrSlotTimeMin1);
        updateRecurringTimeMin1(arrSlotTimeMin1);
        props.updateTime(arrSlotTimeHour1 + ":" + arrSlotTimeMin1 +":00")
    }

    return (
        <div className="select_followup_buttons">
            {/* <h3 className="mb-3">Select when to send this followup</h3> */}
            <div className="massage_sending_button d-flex justify-content-center">
                <Paper square>
                    <Tabs
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChange}
                        aria-label="disabled tabs example"
                        className="campaign_module_schedule_tab"
                    >
                        <Tab label="Immediately" />
                        <Tab label="Later the same day" />
                        <Tab label="On a later day" />
                        <Tab label="Custom Date" disabled={ !showAllTimeSetting } />
                        <Tab label="Greetings" disabled={ !showAllTimeSetting } />
                        <Tab label="Recurring" disabled={ !showAllTimeSetting } />
                    </Tabs>

                    <TabPanel className="campaign_module_box_text" value={tabValue} index={0}>
                        Setting will be triggered immediately after receiving lead
                    </TabPanel>
                    <TabPanel className="campaign_module_box_text" value={tabValue} index={1}>
                        <div className="form-group d-flex justify-content-center align-items-center global__custom_select">
                            <div className='pr-2 d-flex align-items-center'>
                                <GlobalCustomSelect
                                    placeholder="Hours"
                                    data={hours}
                                    value={hour}
                                    handleOnChange={(event) => hourChangeHandler(event.target.value)}
                                    value_field="__FULL_DATA__"
                                    title_field="__FULL_DATA__"
                                />
                                hours
                            </div>
                            <div className="pr-2 d-flex align-items-center">
                                <GlobalCustomSelect
                                    placeholder="Mins"
                                    data={minutes}
                                    value={min}
                                    handleOnChange={(event) => minChangeHandler(event.target.value)}
                                    value_field="__FULL_DATA__"
                                    title_field="__FULL_DATA__"
                                />
                                minutes later
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel className="campaign_module_box_text" value={tabValue} index={2}>
                        <div className="form-group d-flex justify-content-center w-100">
                            <div className='pr-2 d-flex align-items-center position-relative'>
                                Day
                                <div className="input-field subject_input selects_fields">
                                    <input 
                                        id="email-setting-subject-input-field" 
                                        style={{marginBottom: 0}} 
                                        type="number" 
                                        min={1} 
                                        max={1000} 
                                        placeholder="Days" 
                                        value={day} 
                                        onChange={(event) => {
                                            if(/^\d+$/.test(event.target.value)){
                                                if(event.target.value > 1000){
                                                    dayChangeHandler(1000)
                                                }else if(event.target.value < 1){
                                                    dayChangeHandler(1)
                                                }
                                                else{
                                                    dayChangeHandler(event.target.value)
                                                }
                                            }
                                            else{
                                                dayChangeHandler(1)
                                            }                                            
                                        }} 
                                    />
                                </div>
                                at
                                <span className="day-select-message">Day range between 1 to 1000</span>
                            </div>
                            <div className="pl-2 d-flex align-items-center global__custom_select">
                                <div className={"pr-2 timepicker-wrapper"}>
                                    <GlobalCustomSelect
                                        placeholder="Time"
                                        data={timeIntervalOptions(15)}
                                        // value={min}
                                        value={laterHour + ':' + laterMin}
                                        handleOnChange={(event) => timeChangeHandler(event.target.value)}
                                        value_field={'value'}
                                        title_field={'label'}
                                    />
                                    {/* <select name="" className={'form-control'}
                                            onChange={(e) => timeChangeHandler(e.target.value)}
                                            value={laterHour + ':' + laterMin}
                                            style={{'height': '40px', 'fontSize': '18px'}}>
                                        {timeIntervalOptions(15)}
                                    </select> */}
                                </div>

                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel className="campaign_module_box_text" value={tabValue} index={3}>
                        <div className="form-group d-flex justify-content-center w-100">
                            <div className="pr-2 d-flex align-items-center date_input" >
                                Date
                                {/* <input type="date" className="form-control ml-2" name="date" value={startDate} onChange={(e) => startDateChangeHandler(e.target.value)} /> */}
                                <DatePicker placeholderText="MM/DD/YYYY" minDate={new Date()} className="trigger__modal_textField dealValue setFullWidth" selected={startDate != null ? new Date(startDate) : null} onChange={(date) => startDateChangeHandler(date)} />
                            </div>
                            <div className="pl-2 d-flex align-items-center global__custom_select">
                                <div className={"pr-2 timepicker-wrapper"}>
                                    {/* <select name="" className={'form-control'}
                                            onChange={(e) => timeChangeFixed(e.target.value)}
                                            value={arrSlotTimeHour1 + ':' + arrSlotTimeMin1}
                                            style={{'height': '40px', 'fontSize': '18px'}}>
                                        {timeIntervalOptions(15)}
                                    </select> */}
                                    <GlobalCustomSelect
                                        placeholder="Time"
                                        data={timeIntervalOptions(15)}
                                        // value={min}
                                        value={arrSlotTimeHour1 + ':' + arrSlotTimeMin1}
                                        handleOnChange={(event) => timeChangeFixed(event.target.value)}
                                        value_field={'value'}
                                        title_field={'label'}
                                    />
                                </div>

                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel className="campaign_module_box_text" value={tabValue} index={4}>
                        <div className="form-group d-flex justify-content-center w-100 global__custom_select">
                            <div className='pr-2 d-flex align-items-center'>
                                 <span style={{paddingRight:"10px"}}> Select Greetings</span>
                                <GlobalCustomSelect
                                    placeholder="Greetings"
                                    data={[
                                        {label : 'Birthday', value: 1},
                                        {label : 'Anniversary', value: 2}
                                    ]}
                                    value={greetingOption}
                                    handleOnChange={(event) => greetingOptionChangeHandler(event.target.value)}
                                    value_field={'value'}
                                    title_field={'label'}
                                />
                                {/* <select className=" form-control mx-3" value={greetingOption} onChange={(e) => greetingOptionChangeHandler(e.target.value)}>
                                    <option key={1} value={1} >Birthday</option>
                                    <option key={2} value={2} >Anniversary</option>
                                </select> */} at
                            </div>
                            <div className="pl-2 d-flex align-items-center global__custom_select">
                                <div className={"pr-2 timepicker-wrapper"}>
                                    <GlobalCustomSelect
                                        placeholder="Time"
                                        data={timeIntervalOptions(15)}
                                        // value={min}
                                        value={greetingsOptionTimeHour1 + ':' + greetingsOptionTimeMin1}
                                        handleOnChange={(event) => onGreetingsOptionChangeFixed(event.target.value)}
                                        value_field={'value'}
                                        title_field={'label'}
                                    />
                                    {/* <select name="" className={'form-control'}
                                            onChange={(e) => onGreetingsOptionChangeFixed(e.target.value)}
                                            value={greetingsOptionTimeHour1 + ':' + greetingsOptionTimeMin1}
                                            style={{'height': '40px', 'fontSize': '18px'}}>
                                        {timeIntervalOptions(15)}
                                    </select> */}
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel className="campaign_module_box_text" value={tabValue} index={5}>
                        <div className="form-group d-flex justify-content-center align-items-center w-100">
                            Followup Sequence Repeat Every
                            <div className='pr-2 d-flex align-items-center position-relative'>
                                {/* <select className={'form-control'}
                                        onChange={(e) => dayChangeHandler(e.target.value)}
                                        value={day}
                                        style={{'height': '40px', 'fontSize': '18px'}}>
                                    {renderDays()}
                                </select> */}
                                <div className="input-field subject_input selects_fields">
                                    <input 
                                        id="email-setting-subject-input-field" 
                                        style={{marginBottom: 0}} 
                                        type="number" 
                                        min={0} 
                                        max={1000} 
                                        placeholder="Days" 
                                        value={day} 
                                        onChange={(event) => {
                                            if(/^\d+$/.test(event.target.value)){
                                                if(event.target.value > 1000){
                                                    dayChangeHandler(1000)
                                                }else if(event.target.value < 0){
                                                    dayChangeHandler(0)
                                                }
                                                else{
                                                    dayChangeHandler(event.target.value)
                                                }
                                            }
                                            else{
                                                dayChangeHandler(0)
                                            }                                            
                                        }} 
                                    />
                                </div>
                                <span className="day-select-message left-6">Day range between 1 to 1000</span>
                                days at
                            </div>
                            <div className="pl-2 d-flex align-items-center global__custom_select">
                                <div className={"pr-2 timepicker-wrapper"}>
                                    <GlobalCustomSelect
                                        placeholder="Time"
                                        data={timeIntervalOptions(15)}
                                        // value={min}
                                        value={recurringTimeHour1 + ':' + recurringTimeMin1}
                                        handleOnChange={(event) => ongRecurringChangeFixed(event.target.value)}
                                        value_field={'value'}
                                        title_field={'label'}
                                    />
                                    {/* <select name="" className={'form-control'}
                                            onChange={(e) => ongRecurringChangeFixed(e.target.value)}
                                            value={recurringTimeHour1 + ':' + recurringTimeMin1}
                                            style={{'height': '40px', 'fontSize': '18px'}}>
                                        {timeIntervalOptions(15)}
                                    </select> */}
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </Paper>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        userInfo: state.videoEmailReducer.userInfo,
        campaignDetails: state.videoEmailReducer.campaignDetails,

        tabValue: state.videoEmailReducer.tabValue,
        hour: state.videoEmailReducer.hour,
        min: state.videoEmailReducer.min,
        day: state.videoEmailReducer.day,
        recurringDay: state.videoEmailReducer.recurringDay,
        laterHour: state.videoEmailReducer.laterHour,
        laterMin: state.videoEmailReducer.laterMin,
        time: state.videoEmailReducer.time,
        startDate: state.videoEmailReducer.startDate,
        arrSlotTimeHour1: state.videoEmailReducer.arrSlotTimeHour1,
        arrSlotTimeMin1: state.videoEmailReducer.arrSlotTimeMin1,
        greetingOption: state.videoEmailReducer.greetingOption,
        greetingsOptionTimeMin1: state.videoEmailReducer.greetingsOptionTimeMin1,
        greetingsOptionTimeHour1: state.videoEmailReducer.greetingsOptionTimeHour1,
        recurringTimeHour1: state.videoEmailReducer.recurringTimeHour1,
        recurringTimeMin1: state.videoEmailReducer.recurringTimeMin1,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateTabValue: (params) => dispatch(updateTabValue(params)),
        updateHour: (params) => dispatch(updateHour(params)),
        updateMin: (params) => dispatch(updateMin(params)),
        updateDay: (params) => dispatch(updateDay(params)),
        updateRecurringDay: (params) => dispatch(updateRecurringDay(params)),
        updateLaterHour: (params) => dispatch(updateLaterHour(params)),
        updateLaterMin: (params) => dispatch(updateLaterMin(params)),
        updateTime: (params) => dispatch(updateTime(params)),
        updateStartDate: (params) => dispatch(updateStartDate(params)),
        updateArrSlotTimeHour1: (params) => dispatch(updateArrSlotTimeHour1(params)),
        updateArrSlotTimeMin1: (params) => dispatch(updateArrSlotTimeMin1(params)),
        updateGreetingOption: (params) => dispatch(updateGreetingOption(params)),
        updateGreetingsOptionTimeMin1: (params) => dispatch(updateGreetingsOptionTimeMin1(params)),
        updateGreetingsOptionTimeHour1: (params) => dispatch(updateGreetingsOptionTimeHour1(params)),
        updateRecurringTimeHour1: (params) => dispatch(updateRecurringTimeHour1(params)),
        updateRecurringTimeMin1: (params) => dispatch(updateRecurringTimeMin1(params)),
    };
}

const ScheduleSetting = connect(mapStateToProps, mapDispatchToProps)(App);

export default ScheduleSetting;