import MicroFrontend from "../../MicroFrontend";
import React from "react";

const {
    REACT_APP_DASHBOARD_SERVER: dashboardHost,
    REACT_APP_INBOX_SERVER: inboxHost,
    REACT_APP_PROFILE_SERVER: profileHost,
    REACT_APP_MARKETING_SERVER: marketingHost,
    REACT_APP_CONTACT_SERVER: contactHost,
    REACT_APP_TRIGGER_SERVER: triggerHost,
    REACT_APP_LEAD_FLOW_SERVER: leadFlowHost,
    REACT_APP_MAPFINDER_SERVER: mapFinderHost,
    REACT_APP_CALENDAR_SERVER: calendarHost,
    REACT_APP_DEAL_SERVER: dealStagesHost,
    REACT_APP_BILLING_SERVER: billingHost,
    REACT_APP_FORM_BUILDER_SERVER: formBuilderHost,
    REACT_APP_FORM_BUILDER_V3_SERVER: formBuilderV3Host,
    REACT_APP_REPORT_SERVER: reportHost,
    REACT_APP_APP_STORE_SERVER: appStoreHost,
    REACT_APP_INBOX_V2_SERVER: inboxV2,
    REACT_APP_POWER_DIALER_SERVER: powerDialerHost,
    REACT_APP_INVOICE_BUILDER_SERVER: InvoiceBuilderHost,
    REACT_APP_WEBSITE_BUILDER_SERVER: WebsiteBuilderHost,
    REACT_APP_BULK_EMAIL_SERVER: bulkEmailHost,
} = process.env;

export function Dashboard({ history }) {
    return <MicroFrontend history={history} host={dashboardHost} name="DashboardModule" />;
}

export function Inbox({ history }) {
    return <MicroFrontend history={history} host={inboxHost} name="InboxModule" />;
}

export function Profile({ history }) {
    return <MicroFrontend history={history} host={profileHost} name="ProfileModule" />;
}

export function Marketing({ history }) {
    return <MicroFrontend history={history} host={marketingHost} name="MarketingModule" />;
}

export function Contact({ history }) {
    return <MicroFrontend history={history} host={contactHost} name="ContactModule" />;
}

export function Trigger({ history }) {
    return <MicroFrontend history={history} host={triggerHost} name="TriggerModule" />;
}

export function LeadFlow({ history }) {
    return <MicroFrontend history={history} host={leadFlowHost} name="LeadFlowModule" />;
}
export function MapFinder({ history }) {
    return <MicroFrontend history={history} host={mapFinderHost} name="MapFinderModule" />;
}

export function Calendar({ history }) {
    return <MicroFrontend history={history} host={calendarHost} name="CalendarModule" />;
}

export function DealStages({ history }) {
    return <MicroFrontend history={history} host={dealStagesHost} name="DealModule" />;
}

export function Billing({ history }) {
    return <MicroFrontend history={history} host={billingHost} name="BillingModule" />;
}

export function FormBuilder({ history }) {
    return <MicroFrontend history={history} host={formBuilderHost} name="FormBuilderModule" />;
}

export function FormBuilderV3({ history }) {
    return <MicroFrontend history={history} host={formBuilderV3Host} name="FormBuilderV3Module" />;
}

export function Report({ history }) {
    return <MicroFrontend history={history} host={reportHost} name="ReportModule" />;
}

export function AppStore({ history }) {
    return <MicroFrontend history={history} host={appStoreHost} name="AppStoreModule" />;
}

export function InboxV2({ history }) {
    return <MicroFrontend history={history} host={inboxV2} name="InboxV2Module" />;
}

export function PowerDialer({ history }) {
    return <MicroFrontend history={history} host={powerDialerHost} name="PowerDialerModule" />;
}

export function InvoiceBuilder({ history }) {
    return <MicroFrontend history={history} host={InvoiceBuilderHost} name="InvoiceBuilderModule" />;
}

export function WebsiteBuilder({ history }) {
    return <MicroFrontend history={history} host={WebsiteBuilderHost} name="WebsiteBuilderModule" />;
}

export function BulkEmailBuilder({ history }) {
    return <MicroFrontend history={history} host={bulkEmailHost} name="BulkEmailModule" />;
}




