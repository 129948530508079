import { sendRequest } from './rootApi';
import Api from "../constants/Api";
import httpRequest from "./HttpRequest";

export const getSiteNotifications = async (payload) => {
    return sendRequest("post", Api.siteNotifications, payload);
}

export const getLeadNotifications = async (payload) => {
    return sendRequest("post", Api.leadNotifications, payload);
}

export const setMarkAllAsSeen = async (payload) => {
    return sendRequest("post", Api.markAllAsSeen, payload);
}

export const setLeadMarkAllAsSeen = async (payload) => {
    return sendRequest("post", Api.leadMarkAllAsSeen, payload);
}

export const closeSiteNotification = async (payload) => {
    return sendRequest("post", Api.closeNotification, payload);
}

export const closeLeadNotification = async (payload) => {
    return sendRequest("post", Api.closeLeadNotification, payload);
}

export const getNotificationCount = async (payload) => {
    return sendRequest("post", Api.notificationCount, payload);
}

export const markAllNotificationsAsClosedApi = async (payload) => {
    return sendRequest("post", Api.markAllNotificationsAsClosed, payload);
}

export const updateSiteNotificationApi = async (payload) => {
    return sendRequest("post", Api.updateSiteNotificationStatus, payload);
}

export const updateLeadNotificationApi = async (payload) => {
    return sendRequest("post", Api.updateLeadNotificationStatus, payload);
}

export const nylasAccessTokenConfig = async (payload) => {
    return httpRequest.post(Api.nylasAccessTokenConfig, payload);
}

export const connectGoogleProcessTokenBulkEmail = async (payload) => {
    return httpRequest.post(Api.connectGoogleProcessToken, payload);
}