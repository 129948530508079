export const ADD_SELECTED_CONTACT = 'ADD_SELECTED_CONTACT';
export const STORE_CONVERSATION = 'STORE_CONVERSATION';
export const FETCH_VIRTUAL_NUMBERS = 'FETCH_VIRTUAL_NUMBERS';
export const STORE_VIRTUAL_NUMBERS = 'STORE_VIRTUAL_NUMBERS';
export const FETCH_CUSTOM_FIELD = 'FETCH_CUSTOM_FIELD';
export const STORE_CUSTOM_FIELD = 'STORE_CUSTOM_FIELD';
export const SET_USER = 'SET_USER';
export const MARK_AS_IMPORTANT = 'MARK_AS_IMPORTANT';
export const UPDATE_SELECTED_CONTACT = 'UPDATE_SELECTED_CONTACT';
export const MARK_AS_UNREAD = 'MARK_AS_UNREAD';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const ARCHIVE_CONTACT = 'ARCHIVE_CONTACT';
export const MOVE_FROM_ARCHIVE = 'MOVE_FROM_ARCHIVE';
export const FETCH_MESSAGE_TEMPLATES = 'FETCH_MESSAGE_TEMPLATES';
export const STORE_MESSAGE_TEMPLATE = 'STORE_MESSAGE_TEMPLATE';

//Card broadcast
export const GET_MAIL_CATEGORY = 'GET_MAIL_CATEGORY';
export const SET_MAIL_CATEGORY = 'SET_MAIL_CATEGORY';
export const GET_MAIL_TEMPLATES = 'GET_MAIL_TEMPLATES';
export const CREATE_POSTCARD_TEMPLATE = 'CREATE_POSTCARD_TEMPLATE';

//Bottom Box Visible
export const UPDATE_BOTTOM_BOX = 'UPDATE_BOTTOM_BOX'

//Right sidebar visibility
export const TOGGLE_RIGHT_SIDEBAR = 'TOGGLE_RIGHT_SIDEBAR';
export const CHANGED_TAB_IN_BOTTOM_BOX = 'CHANGED_TAB_IN_BOTTOM_BOX';

export const SELECTED_TAB_LEFT_SIDE = 'SELECTED_TAB_LEFT_SIDE'

export const UPDATE_INBOX_UNREAD_CONTACT = 'UPDATE_INBOX_UNREAD_CONTACT'

export const SET_CONTACT_ID_FROM_DASHBOARD = 'SET_CONTACT_ID_FROM_DASHBOARD'

/* for inbox middle part */
export const HANDLE_SEND_VIEW_FLAG = 'HANDLE_SEND_VIEW_FLAG'
export const UPDATE_ACTIVE_SEND_OPTION = 'UPDATE_ACTIVE_SEND_OPTION'


export const UPDATE_LAST_CONTACTED_NUMBER = 'UPDATE_LAST_CONTACTED_NUMBER'

export const UPDATE_MULTIPLE_DATA = 'UPDATE_MULTIPLE_DATA'

export const FETCH_CONTACT_ADDITIONAL_CONTACTS="FETCH_CONTACT_ADDITIONAL_CONTACTS"

export const GET_CONTACT_POLICIES="GET_CONTACT_POLICIES"
export const SET_CONTACT_POLICIES="SET_CONTACT_POLICIES"

export const GET_POLICY_COMPANY_AGENT="GET_POLICY_COMPANY_AGENT"
export const SET_POLICY_COMPANY_AGENT="SET_POLICY_COMPANY_AGENT"