import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
/* icons */
import { Person } from "@material-ui/icons";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';


import CustomAccordion, { CustomAccordionBody, CustomAccordionHeader } from "../../assets/CustomAccordion";
import icons from "../../assets/icons";
import { getContactDetails } from "../../redux/contactDetailAction";
import { addContactTagsApi, getAllTagsApi, getContactTagListApi, removeContactTagApi } from "../../redux/contactDetailApi";


const mapStateToProps = state => ({
    contactDetailReducer: state.contactDetailReducer
});
const mapDispatchToProps = dispatch => ({
    getContactDetails: params => dispatch(getContactDetails(params))  
})

const TagsOptionApp = (props) => {
    const {
        contactId, contactDetails
    } = props.contactDetailReducer

    const [assignTags, setAssignTags] = useState([])
    const [expand, setExpand] = useState(false)
    const [expandAddMore, setExpandAddMore] = useState(false)
    const [allTags, setAllTags] = useState([])
    const [allTagIds, setAllTagIds] = useState([])
    const [search, setSearch] = useState('')
    const [filterTags, setFilterTags] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedTags, setSelectedTags] = useState([])
    const [submit, setSubmit] = useState(false)
    const [render, setRender] = useState(false)

    useEffect(() => {
        if(expand) storeTags()
        setExpandAddMore(false)
    }, [expand]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(expand) {
            storeTags()
        }
    }, [contactDetails]) // eslint-disable-line react-hooks/exhaustive-deps

    const storeTags = () => {
        getContactTagListApi({
            contactId: contactId
        }).then(res => {
            let response = res.data
            setAssignTags(response.data)
            let _allTags = []
            response.data.forEach((item, index) => {
                _allTags.push(item.tag_id)
            })
            setAllTagIds(_allTags)
        })
        
    }

    const fetchAllTags = (onlyFetch = false) => {
        if(!expandAddMore){
            setLoading(true)
            getAllTagsApi().then(res => {
                let response = res.data
                setAllTags(response)
                setLoading(false)
            })
        }
        if(onlyFetch){
            setExpandAddMore(!expandAddMore)
            setSelectedTags([])
        }
    }

    const removeExistingTag = (id, index) => {
        removeContactTagApi({
            contact_id: contactId,
            tag_id: id
        }).then(res => {
            let tags = assignTags
            tags.splice(index, 1)
            setAssignTags(tags)
            setRender(!render)
            props.getContactDetails({
                id: contactId
            })
        })
    }

    const handleSearch = (event) => {
        let query = event.target.value
        query = query.toLowerCase()
        let filterList = allTags.filter(item => {
            let text = item.name.toLowerCase()
            if(text.includes(query)) return true
            return false
        })
        setSearch(query)
        setFilterTags(filterList)
    }

    const renderAvailableTags = () => {
        let tagList = allTags
        if(search !== ''){
            tagList = filterTags
        }
        if(tagList.length === 0){
            return <span className="ds__noItems_found">No tags created {search === '' ? '.' : 'with '+search.toUpperCase()}</span>
        }
        return tagList.map((item, index) => {
            if(allTagIds.includes(item.id))return null;
            return <EachTags item={item} selectedTags={selectedTags} onCheckChange={handleCheckChange} key={index} />
        })
    }

    const handleCheckChange = (id) => {
        let newSelectItem = selectedTags
        let itemIndex = selectedTags.indexOf(id)
        if(itemIndex >= 0){
            newSelectItem.splice(itemIndex, 1)
        }
        else{
            newSelectItem.push(id)
        }
        setSelectedTags(newSelectItem)
        setRender(!render)
    }

    const handleCreateAndAssign = () => {
        if(submit || search === ''){
            return
        }
        addContactTagsApi({
            assign: false,
            contact_id: [contactId],
            new: true,
            tags: search
        }).then(res => {
            props.getContactDetails({
                id: contactId
            })
            setSelectedTags([])
            fetchAllTags(true)
            setSearch('')
        })
    }

    const handleSubmit = () => {
        if(submit || selectedTags.length === 0){
            return
        }
        setSubmit(true)
        addContactTagsApi({
            assign: true,
            contact_id: [contactId],
            new: false,
            tags: selectedTags
        }).then(res => {
            props.getContactDetails({
                id: contactId
            })
            setSubmit(false)
            setSelectedTags([])
        })
    }

    return(
        <div className="left__column columnItem">
            <CustomAccordion expand={expand}  className={"collapsible defaultToggle trigger__agency tagsCustomAccordion"}>
                <CustomAccordionHeader onClick={() => setExpand(!expand)} className={`collapsible-header trigger__header border__bottom flex content-between items-center ${expand ? 'expand' : ''}`}>
                <span class="trigger__subtitle"><Person /> Tags </span>
                {icons.caretIcon}
                </CustomAccordionHeader>
                <CustomAccordionBody className="collapsible-body">
                <ul className="awesome__scroll_bar templateItems ds__tags_template">
                    {assignTags.length === 0 ?
                        <span className="ds__no_tags common__round_circle empty-tag-list-contact-details">No tag assign for this contact</span>    
                    :
                        assignTags.map((tag, index) => {
                            return(
                                <button>
                                    {tag?.tag?.name}
                                    <span className="cursor-pointer" style={{margin: 2}} onClick={() => removeExistingTag(tag.tag_id, index)}>&times;</span>
                                </button>
                            )
                        })
                    }
                </ul>

                <div style={{padding: 10, width: '100%'}}>
                    <div className="common__round_circle ds__add_tag_tem" onClick={fetchAllTags}>
                        <span>+ Add Tags</span>
                        <span>{expandAddMore ? <ArrowDropUpIcon fontSize={'large'}/> : <ArrowDropDownIcon fontSize={'large'}/> }</span>
                    </div>

                    {(expandAddMore && loading) &&
                        <div className="ds__loader">Loading...</div>
                    }

                    {(expandAddMore && !loading) &&
                    <div className="ds__type_and_search">
                        <input className="common__round_circle" type={'text'} placeholder={"Type and search"} value={search} onChange={handleSearch}/>
                        {search !== '' &&
                            <span className="common__round_circle ds__alter1" onClick={handleCreateAndAssign}>
                                Create and assign with this&nbsp;&nbsp;<strong> {search.toUpperCase()}</strong>
                            </span>
                        }
                        <div className="awesome__scroll_bar search__type_lists">
                            {renderAvailableTags()}
                        </div>
                    </div>  
                    }                  
                </div>
                
                {expandAddMore &&
                <div className="common__round_circle customMargin10px" onClick={handleSubmit}>
                    {/* {submit ? <Loading /> : ''} */}
                    {selectedTags.length === 0 ? 'Select Tag' : ((submit ? 'Assigning ' : 'Assign ') + (selectedTags.length === 1 ? 'Tag' : 'Tags')) }
                </div>
                }
                
                </CustomAccordionBody>
            </CustomAccordion>
        </div> 
    )
}
const Tags = connect(mapStateToProps, mapDispatchToProps)(TagsOptionApp);
export default Tags;

const EachTags = props => {
    let isChecked = props.selectedTags.includes(props.item.id) 
    return(
        <div className="ds__selectItem">
            <div className="d-inline-flex" onClick={() => props.onCheckChange(props.item.id)} style={{color: '#525252bd'}}>
                {isChecked ? <CheckBoxIcon color="secondary" /> : <CheckBoxOutlineBlankIcon />}
                <span style={{marginLeft: 5}}>{props.item.name}</span>
            </div>
        </div>
    )
}