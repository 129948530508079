import { InputBase, styled, withStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { searchIcon } from "../../GlobalAddDealModalV3/components/Icons";
import { getContactsFromTopbarNew, searchContactsFromElastic } from "../../../api/globalApi";
import { Link } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";

const BasicInput = withStyles((theme) => ({
	root: {
		"label + &": {
			marginTop: theme.spacing(3),
		},
		"&.MuiInputBase-multiline": {
			padding: "0 !important",
		},
		"& .MuiInputAdornment-positionEnd": {
			position: "absolute",
			right: "12px",
		},
	},
	input: {
		position: "relative",
		backgroundColor: "white !important",
		fontSize: "15px !important",
		fontWeight: "400",
		margin: "0 !important",
		height: "3rem !important",
		padding: "10px 12px 10px 36px !important",
		borderRadius: "8px !important",
		border: "1px solid #ced4da !important",
		fontFamily: "'Inter', sans-serif",

		transition: theme.transitions.create(["border-color", "box-shadow"]),
		"&:focus": {
			borderRadius: 4,
			boxShadow: "0 1px 2px rgba(0, 0, 0, 0.3) !important",
		},

		"&.MuiSelect-select": {
			height: "1rem !important",
		},
		"&::placeholder": {
			color: "#082852 !important",
		},
	},
}))(InputBase);

function useDebounce(value, delay) {
	const [debouncedValue, setDebouncedValue] = React.useState(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return debouncedValue;
}

const SearchWrapper = styled('div')(({ theme }) => ({
	position: 'relative',
	'& .slashIcon': {
		position: "absolute",
		right: "12px",
		top: "50%",
		transform: "translateY(-50%)",
	},
	'& .searchIcon': {
		position: "absolute",
		left: "12px",
		top: "56%",
		transform: "translateY(-50%)",
	},
}));

const SearchBarPopoverMenuInsuranceWrapper = styled("div")(({ theme }) => ({
	backgroundColor: "var(--white)",
	borderRadius: "10px",
	boxShadow: "0 0 17px 1px #bbb",
	right: "0px",
	overflow: "visible",
	padding: "20px",
	position: "absolute",
	top: "62px",
	width: "540px",
	zIndex: "999",

	"&:before": {
		background: "url(/static/media/arrow.20587642.svg) no-repeat 50% / 34px 24px",
		backgroundSize: "cover",
		content: "''",
		display: "block",
		height: "23px",
		position: "absolute",
		right: "6px",
		top: "-12px",
		width: "34px",
		zIndex: "9"
	},

	"& .header": {
		backgroundColor: "#fff",
		color: "var(--dark_blue)",
		height: "40px",
		marginTop: "-7px",
		display: "flex",
		alignItems: "center",

		"& p": {
			fontSize: "16px",
			lineHeight: "22px",
			color: "var(--dark_blue)",
			marginLeft: "14px"
		},
	},

	"& .tab-title": {
		"& header": {
			color: "rgba(0, 0, 0, 0.87)",
			backgroundColor: "#f5f5f5",
			position: "static",
			boxSizing: "border-box",
			display: "flex",
			flexDirection: "column",
			flexShrink: "0",
			width: "100%",
			zIndex: "1100",
		},

		"& .tab-root": {
			display: "flex",
			minHeight: "48px",
			overflow: "hidden",

			"& .tab-scroller": {
				width: "100%",
				display: "inline-block",
				flex: "1 1 auto",
				position: "relative",
				whiteSpace: "nowrap",

				"& .tab-indicator": {
					backgroundColor: "#3f51b5",
					position: "absolute",
					bottom: "0",
					width: "100%",
					height: "4px"
				}
			},

			"& .tab-flex-container": {
				borderBottom: "4px solid #e5e8ef",
				display: "flex",

				"& button": {
					boxSizing: "border-box",
					flexShrink: "0",
					fontSize: "0.875rem",
					fontWeight: "500",
					letterSpacing: "0.02857em",
					lineHeight: "1.75",
					//maxWidth: "264px",
					minHeight: "48px",
					minWidth: "72px",
					overflow: "hidden",
					padding: "6px 12px",
					position: "relative",
					textAlign: "center",
					textTransform: "uppercase",
					whiteSpace: "normal",
					alignItems: "center",
					backgroundColor: "transparent",
					border: "0",
					borderRadius: "0",
					cursor: "pointer",
					display: "inline-flex",
					justifyContent: "center",
					margin: "0",
					MozAppearance: "none",
					outline: "0",
					textDecoration: "none",
					userSelect: "none",
					verticalAlign: "middle",
					color: "#3f51b5",
					flex: "1 1",
					"-webkit-appearance": "none",
					"-webkit-tap-highlight-color": "transparent"
				}
			}
		}
	},

	"& .tab-content": {
		maxHeight: "300px",
		overflowY: "auto",
		paddingTop: "10px",

		"& .search-list-item": {
			borderBottom: "1px solid var(--gray_dark)",
			"& a": {
				marginLeft: "-16px",
				paddingRight: "0",
				padding: "10px",
				display: "flex",
				alignItems: "center",
				justifyContent: "flex-start"
			},
			"& .search-avatar": {
				alignItems: "center",
				background: "var(--gray_dark)",
				borderRadius: "50%",
				color: "var(--dark_blue)",
				display: "flex",
				fontSize: "14px",
				height: "30px",
				justifyContent: "center",
				lineHeight: "22px",
				marginLeft: "0.5rem",
				marginRight: "10px",
				textTransform: "uppercase",
				width: "30px"
			},
			"& .search-name": {
				color: "var(--dark_blue)",
				fontSize: "14px",
				lineHeight: "22px",
				margin: "0"
			},
			"& .search-email": {
				color: "#7b7e8a !important",
				fontSize: "12px !important",
				lineHeight: "22px",
				margin: "0"
			},
			"& .search-number": {
				color: "#7b7e8a !important",
				fontSize: "12px !important",
				lineHeight: "22px",
				margin: "0"
			}
		},

		"&::-webkit-scrollbar": {
			width: "6px",
			height: "6px",
			scrollbarWidth: "thin"
		},
		"&::-webkit-scrollbar-track": {
			borderRadius: "4px",
			background: "#f4f5f7",
			boxShadow: "inset 1px 0px 0px rgba(148, 157, 178, 0.12)",
		},
		"&::-webkit-scrollbar-thumb": {
			borderRadius: "10px",
			boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
			backgroundColor: "#949db2",
			boxSizing: "border-box",
		},
	},

	"& .skeleton-item-contacts": {
		"& .skeleton-search-topbar-details": {
			marginTop: "7px",
			display: "flex",
			flexDirection: "row"
		}
	},
}));

const HeaderSearchBarInsurance = () => {
	const [contacts, setContacts] = useState([]);
	const [openSearchMenu, setOpenSearchMenu] = useState(false);
	const [loading, setLoading] = useState(false);
	const [value, setValue] = useState("");
	const debouncedValue = useDebounce(value, 500);
	const inputRef = useRef(null);
	const popoverRef = useRef(null);

	const onSearch = async (query) => {
		if (process.env.REACT_APP_DIALER_CONTACT_SEARCH === "elastic") {
			await getContactsSearchElastic(query);
		} else {
			await getContactsSearch(query);
		}
	};

	useEffect(() => {
		onSearch(debouncedValue);
	}, [debouncedValue]);

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		// window.addEventListener("keydown", handleKeyPress);

		return () => {
			// window.removeEventListener("keydown", handleKeyPress);
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleClickOutside = (event) => {
		if (inputRef.current && !inputRef.current.contains(event.target)
			&& popoverRef.current && !popoverRef.current.contains(event.target)) {
			setOpenSearchMenu(false);
		}
	};

	const handleKeyPress = (event) => {
		if (event.key === "/") {
			event.preventDefault();
			inputRef.current.focus();
		}
	};

	const handleInputChange = (e) => {
		setValue(e.target.value);

		if (e.target.value !== "") {
			setOpenSearchMenu(true);
		} else {
			setOpenSearchMenu(false);
			setContacts([]);
		}
	};

	const handleOnFocus = () => {
		if (value !== "") {
			setOpenSearchMenu(true);
		}
	};

	const getContactsSearchElastic = async (query) => {
		setLoading(true);
		let resp = await searchContactsFromElastic("GET", {}, "?page=1&size=10&search=" + query);

		if (resp?.data?.contactInfos) {
			setContacts(resp.data.contactInfos);
		}
		setLoading(false);
	};

	const getContactsSearch = async (query) => {
		setLoading(true);
		let resp = await getContactsFromTopbarNew({ q: query, page: 1, perPage: 10 });

		if (resp?.data?.data?.pypepro_contacts) {
			setContacts(resp.data.data.pypepro_contacts);
		}
		setLoading(false);
	};

	const renderElasticContacts = () => {
		if (typeof contacts != "undefined") {
			return contacts.map((contact, index) => {
				let firstName = contact["firstName"] ? contact["firstName"] : "";
				let lastName = contact["lastName"] ? contact["lastName"] : "";
				let avatar = firstName?.substring(0, 1) + lastName?.substring(0, 1);

				return (
					<div className="search-list-item" key={index}>
						<Link to={"/contacts/" + contact.id} onClick={(e) => { e.stopPropagation(); }}>
							<span className="search-avatar">{avatar}</span>
							<div className="search-infos">
								<p className="search-name">{(firstName + " " + lastName).trim()}</p>
								<p className="search-email">{contact["email"]}</p>
								<p className="search-number">{contact["number"]}</p>
							</div>
						</Link>
					</div>
				);
			});
		}
		return "";
	};

	const renderContacts = () => {
		if (typeof contacts != "undefined") {
			return contacts.map((contact, index) => {
				let firstName = contact["first_name"] ? contact["first_name"] : "";
				let lastName = contact["last_name"] ? contact["last_name"] : "";
				let avatar = firstName?.substring(0, 1) + lastName?.substring(0, 1);

				return (
					<div className="search-list-item" key={index}>
						<Link to={"/contacts/" + contact.id} onClick={(e) => { e.stopPropagation(); }}>
							<span className="search-avatar">{avatar}</span>
							<div className="search-infos">
								<p className="search-name">{(firstName + " " + lastName).trim()}</p>
								<p className="search-email">{contact["email"]}</p>
								<p className="search-number">{contact["number"]}</p>
							</div>
						</Link>
					</div>
				);
			});
		}
		return "";
	};

	const loadingSkeleton = () => {
		let skeletons = [];
		for (let i = 0; i < 5; i++) {
			skeletons.push(
				<div key={i} className="skeleton-item-contacts">
					<div className="skeleton-search-topbar-details">
						<Skeleton variant="rect" width={730} height={80} marginTop={"10px"} />
					</div>
				</div>
			);
		}
		return skeletons;
	};

	return (
		<div style={{ position: "relative", width: "100%" }}>
			<SearchWrapper style={{ position: "relative", width: "100%" }}>
				<BasicInput
					type="text"
					placeholder={"Search Contacts..."}
					value={value}
					inputRef={inputRef}
					onChange={handleInputChange}
					onFocus={handleOnFocus}
					fullWidth
				// endAdornment={
				//   <InputAdornment position="end" className="slashIcon">
				//     {slashIcon}
				//   </InputAdornment>
				// }
				/>
				<span className="searchIcon">{searchIcon}</span>
			</SearchWrapper>

			<SearchBarPopoverMenuInsuranceWrapper
				ref={popoverRef}
				style={{ visibility: openSearchMenu ? "visible" : "hidden" }}
			>
				<div className="inner-wrapper">
					<div>
						<div className="header">
							<svg
								width="18"
								height="18"
								viewBox="0 0 18 18"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
									fill="#133159"
								/>
							</svg>
							<p>Search all results for “{value}”</p>
						</div>
						<div className="tab-title">
							<header>
								<div className="tab-root">
									<div className="tab-scroller">
										<div className="tab-flex-container">
											<button>People</button>
										</div>
										<span className="tab-indicator"></span>
									</div>
								</div>
							</header>
						</div>
						<div className="tab-content">
							{!loading &&
								process.env.REACT_APP_DIALER_CONTACT_SEARCH === "elastic" &&
								renderElasticContacts()}

							{!loading &&
								process.env.REACT_APP_DIALER_CONTACT_SEARCH !== "elastic" &&
								renderContacts()}

							{!loading && !contacts[0] && (
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										paddingTop: "50px",
										paddingBottom: "20px",
									}}
								>
									No contacts
								</div>
							)}

							{loading && loadingSkeleton()}
						</div>
					</div>
				</div>
			</SearchBarPopoverMenuInsuranceWrapper>
		</div>
	);
}

export default HeaderSearchBarInsurance;
