import React from "react";
import { Box, Button, Divider, IconButton, Slide, Typography, makeStyles } from "@material-ui/core";
import EditIcon from "../../common/icons/EditIcon";
import { RightSideStyle } from "../../RightSideStyle";
import DraggableIcon from "../../common/icons/DraggableIcon";

const customTitleStyles = makeStyles((theme) => ({
  titleStyle: {
    fontSize: "14px !important",
    fontWeight: "600 !important",
    color: "var(--dark_blue) !important",
    whiteSpace: "nowrap",
  },
}));

function CustomTitle({ title, actions, handleOpen, iconHide }) {
  const { flexCenterBetween, flexCenter, titleIcon } = RightSideStyle();
  const { titleStyle } = customTitleStyles();

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box className={flexCenterBetween} sx={{ flexWrap: "wrap", paddingRight: "10px" }}>
          <Typography variant='body2' className={titleStyle}>
            {title}
          </Typography>
        </Box>

        <Box flex={1}>
          <Divider />
        </Box>
        <Box
          className={`${flexCenter} ${iconHide}`}
          sx={{
            height: "20px !important",
            transition: "all .35s",
            overflow: "hidden",
          }}
        >
          <IconButton className={`${titleIcon}`} size='small'>
            <DraggableIcon fill='currentColor' />
          </IconButton>
          <IconButton onClick={handleOpen} className={titleIcon} size='small'>
            <EditIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

export default CustomTitle;
