import { Editor } from '@tinymce/tinymce-react';
import React, { useRef } from 'react';

const TextEditorTinyMCE = (props) => {


  const editorRef = useRef(null);

  const apiKeys = () => {
    return "f2zuqw07g06pnduwafiv7wehs2nc44j426n4nq0rjdhs8w1x";
  }
  return (
    <>
      <Editor
        apiKey={apiKeys()}
        onInit={(editor) => editorRef.current = editor}
        onEditorChange={props.changeTextInEditor}
        value={props.textEditorValue}
        init={{
          height: props.height !== undefined ? props.height : 500,
          browser_spellcheck: true,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          convert_urls: false,
          toolbar: 'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
      />
    </>
  );
}

export default TextEditorTinyMCE;