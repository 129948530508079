import React, { Fragment, useEffect, useState } from "react";
import {
    Backdrop, Button,
    Fade,
    makeStyles,
    Modal,
    styled,
    Typography
} from "@material-ui/core";
import EachRelatedItem from "./EachRelatedItem";
import { getContactRelationshipList } from "../../api/contactApi";
import Loading from "./Loading";
import Utils, { formatPhoneNumber } from "../../helpers/Utils";

const modalStyles = makeStyles((theme) => ({
    wrapperMain: {
        marginLeft: "10px",
        "& .openButton": {
            fontWeight: "600 !important",
            minWidth: "fit-content !important",
            textTransform: 'capitalize',
            "&:hover, &:focus": {
                backgroundColor: "var(--accent-color) !important",
                color: "white !important",
            },
        }
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        width: 500,
        backgroundColor: theme.palette.background.paper,
        borderRadius: "8px",
        boxShadow: theme.shadows[5],
    },
    modalHeader: {
        padding: "16px",
        color: "#fff",
        borderTopRightRadius: "8px",
        borderTopLeftRadius: "8px",
        display: "flex",
        alignItems: "center",
        borderBottom:'1px solid #d3d3d3',

        "& .closeIcon": {
            display: "flex",
            cursor: "pointer",
            "& svg": {
                color: "#fff",
                backgroundColor: "var(--red)",
                width: "25px",
                height: "25px",
                borderRadius: "50px"
            },
        },

        "& .title": {
            height: "unset !important",
            color: "white",
            fontWeight: 600,
            fontSize: 16
        }
    },
    modalBody: {
        padding: "16px",
        "& .list": {
            display: "flex",
            flexDirection: "column",
            gap: 12,
            "& .item": {
                padding: "10px 36px",
                borderRadius: 6,
                border:'1px solid #d3d3d3',
                marginBottom:'12px',
                "&:hover": {
                    backgroundColor: "rgba(60,126,243,.1)",
                },
                "& .linkUnderline": {
                    color: "var(--light_blue)",
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: 1,
                    textTransform:"capitalize"
                },
                "& .relationship": {
                    color: "#181F48",
                    height: "fit-content !important",
                    fontSize: "14px",
                    textTransform:"capitalize"
                },
                "& .itemContent": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                },
                "& .itemText": {
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                },
                "& .iconButton": {
                    color: "lightGray !important",
                    "& .MuiButtonBase-root": {
                        padding: "0 !important"
                    }
                },
                "& .actionListHover": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 8,
                    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                    marginTop: 8,
                    paddingTop: 16,
                    paddingBottom: 8,
                    "& .actionItem": {
                        backgroundColor: "#316aff",
                        "& svg": {
                            fill: "white",
                            color: "white",
                            height: 30,
                            width: 30,
                            "& path": {
                                fill: "white",
                            },
                        },
                    },
                },
            },
        },
        "& .save": {
            paddingLeft: "8px",
            paddingRight: "8px",
            marginTop: "15px",
            "& .saveButton": {
                backgroundColor: "#3C7EF3 !important",
                fontWeight: "600 !important",
                color: "white !important",
                minWidth: "fit-content !important",
                "&:hover, &:focus": {
                    backgroundColor: "#3C7EF3 !important",
                    color: "white !important",
                },
                width: "100%"
            }
        }
    },
    modalBodyInput: {
        display: "flex",
        alignItems: "center",
        gap: "20px",
    },
    modalFooter: {
        borderEndEndRadius: "8px",
        borderEndStartRadius: "8px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap:'8px',
        borderTop:'1px solid #d3d3d3',
        paddingRight:'16px',
        height:'70px',
        "& .title": {
          color: "#fff",
          fontWeight: "600",
        },
    
        "& .btnSubmit": {
          background: "#346fef !important",
            fontWeight: "600"
        },
      },
    errorText: {
        color: "red",
        marginTop: "4px",
        fontSize: "0.875rem",
    },
    contactTabs: {
        display: "flex",
        gap: "5px",
        "& .contactTab": {
            backgroundColor: "#6c6ce4",
            width: "fit-content",
            borderRadius: "12px",
            padding: "0px 10px",
            color: "white",
            marginTop: "10px",
        },
    },
    closeButton: {
        color: '#000 !important',
        width:'100px !important',
        border: '1px solid #ccc !important',
        fontWeight: 600,
        marginRight: '8px',
        textTransform: 'capitalize !important',
        backgroundColor: '#fff !important',
    },
    saveButton: {
        color: '#fff !important',
        width:'100px !important',
        fontWeight: 600,
        textTransform: 'capitalize !important',
        backgroundColor: '#3C7EF3 !important',
    },
    listBody:{
        
            maxHeight: '300px',
            overflowY: "auto",
            marginTop: "16px",
    }
}));

const CustomScrollbar = styled("div")({
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "#2c3e50",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#346fef",
      borderRadius: "10px",
    },
  });
const generateName = (contact) => {
    let name = "";

    if (contact.firstName) {
        name += contact.firstName;
    }

    if (contact.lastName) {
        if (name) {
            name += " ";
        }
        name += contact.lastName;
    }

    if (!name && contact.email) {
        name = contact.email;
    }

    if (!name && contact.number) {
        name = formatPhoneNumber(contact.number);
    }

    return name;
};

const GlobalContactRelatedContactSelectorInsurance = ({ data = null }) => {
    const classes = modalStyles();
    const [relatedContacts, setRelatedContacts] = useState([]);
    const [selectedRelatedContactIds, setSelectedRelatedContactIds] = useState([]);
    const [deSelectedRelatedContactIds, setDeSelectedRelatedContactIds] = useState([]);
    const [openContactRelatedContactSelector, setOpenContactRelatedContactSelector] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (data && data.contactId) {
            getRelationData()
        }
    }, []);

    useEffect(() => {
        if (data && data.removeRequest){
            if (data.removeRequest !== -1){
                let findRemoveRequest = null;

                if (data.type === "email"){
                    findRemoveRequest = relatedContacts.find((item)=>(item.email === data.removeRequest));
                }else if(data.type === "number"){
                    findRemoveRequest = relatedContacts.find((item)=>(item.email === data.removeRequest));
                }

                if (findRemoveRequest){
                    setSelectedRelatedContactIds((prevState)=>{
                        return prevState.filter((item) => (item !== findRemoveRequest.relatedContactId));
                    });
                }
                data.removeRequestCallback();
            }else if (data.removeRequest === -1) {
                setSelectedRelatedContactIds([]);
                data.removeRequestCallback();
            }
        }
    }, [data]);

    const getRelationData = async () => {
        try {
            const response = await getContactRelationshipList({ contactId: data.contactId });
            if (response && response.success && response.data[0]) {
                let contacts = response.data;
                if (data.type === "email") {
                    contacts = response.data.filter(contact => contact.email !== "" && contact.email !== null).map((contact) => ({ ...contact, number: null }));
                } else if (data.type === "number") {
                    contacts = response.data.filter(contact => contact.number !== "" && contact.number !== null).map((contact) => ({ ...contact, email: null }));
                }

                setRelatedContacts(contacts);
            } else {
                
                setRelatedContacts([]);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const handleCheckbox = (id) => {
        setSelectedRelatedContactIds((prevSelectedIds) => {
            if (prevSelectedIds.includes(id)) {
                setDeSelectedRelatedContactIds((prevDeselectedIds) => [...prevDeselectedIds, id]);
                return prevSelectedIds.filter((item) => item !== id);
            } else {
                setDeSelectedRelatedContactIds((prevDeselectedIds) =>
                    prevDeselectedIds.filter((item) => item !== id)
                );
                return [id, ...prevSelectedIds];
            }
        });
    };

    const checked = (id) => selectedRelatedContactIds.includes(id);

    const handleSave = () => {
        if (data?.callback) {
            const contacts = relatedContacts
                .filter(contact => selectedRelatedContactIds.includes(contact.relatedContactId))
                .map(contact => {
                    if (data.type === "email") {
                        return contact.email;
                    } else if (data.type === "number") {
                        return contact.number;
                    }
                    return null;
                })
                .filter(contact => contact !== null);

            const deContacts = relatedContacts
                .filter(contact => deSelectedRelatedContactIds.includes(contact.relatedContactId))
                .map(contact => {
                    if (data.type === "email") {
                        return contact.email;
                    } else if (data.type === "number") {
                        return contact.number;
                    }
                    return null;
                })
                .filter(contact => contact !== null);

            data.callback({ [`${data.type}s`]: contacts }, { [`${data.type}s`]: deContacts });
        }
        handleClose();
    };

    const handleClose = () => {
        setOpenContactRelatedContactSelector(false);
    };

    const handleOpen = () => {
        // if (data?.callback){
        //     const contacts = relatedContacts
        //         .map(contact => {
        //             if (data.type === "email") {
        //                 return contact.email;
        //             } else if (data.type === "number") {
        //                 return contact.number;
        //             }
        //             return null;
        //         })
        //         .filter(contact => contact !== null);

        //     const contactsIds = relatedContacts
        //         .map(contact => {
        //             if (data.type === "email") {
        //                 return contact.relatedContactId;
        //             } else if (data.type === "number") {
        //                 return contact.relatedContactId;
        //             }
        //             return null;
        //         })
        //         .filter(contact => contact !== null);

        //     data.callback({ [`${data.type}s`]: contacts }, { [`${data.type}s`]: [] });
        //     setSelectedRelatedContactIds(contactsIds);
        // }
        setOpenContactRelatedContactSelector(true);
    };

    return (
      <div className={classes.wrapperMain}>
        <Button className="openButton" onClick={handleOpen} disabled={!relatedContacts[0]}
        style={{ backgroundColor: !relatedContacts[0] ? 'var(--gray_dark) !important' : 'var(--accent-color) !important', color: !relatedContacts[0] ? '#00000042' : '#fff'}}
        >
          Related Contacts
        </Button>

        <Modal
          open={openContactRelatedContactSelector}
          onClose={handleClose}
          className={classes.modal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openContactRelatedContactSelector}>
            <div className={classes.paper}>
              <div className={classes.modalHeader}>
                <div style={{ width: "50px", height: "50px" }}>
                  <img
                    src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/1.0.0/related_contacts_icon.png`}
                    alt="logo"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div style={{ marginLeft: "16px" }}>
                  <Typography
                    style={{
                      height: "unset !important",
                      color: "#133159",
                      fontWeight: 600,
                    }}
                    variant="h5"
                  >
                    Related Contacts
                  </Typography>
                  <Typography
                    style={{
                      height: "unset !important",
                      color: "#133159",
                      fontWeight: 500,
                      fontSize: "15px",
                    }}
                    variant="h5"
                  >
                    Select the contacts you'd like to send your message to:
                  </Typography>
                </div>
              </div>

              <div className={classes.modalBody}>
                                {
                                    isLoading ?
                                        <Loading /> :
                  <Fragment>
                    <div className="list">
                      <CustomScrollbar className={classes.listBody}>
                        {relatedContacts.map((relatedContact, index) => (
                          <EachRelatedItem
                            index={index}
                            relatedContact={relatedContact}
                            checked={checked}
                            handleCheckbox={handleCheckbox}
                          />
                        ))}
                      </CustomScrollbar>
                    </div>
                   
                  </Fragment>
                                }
              </div>
              <div className={classes.modalFooter}>
                      <Button
                        onClick={handleClose}
                        className={classes.closeButton}
                      >
                        Close
                      </Button>
                      <Button
                        onClick={handleSave}
                        className={classes.saveButton}
                      >
                        Save
                      </Button>
                    </div>
            </div>
          </Fade>
        </Modal>
            </div> 
    );
};

export default GlobalContactRelatedContactSelectorInsurance;