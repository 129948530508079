import useDelayCallback from "../../hooks/useDelayCallback";
import { useIsMounted } from "../../hooks/useIsMounted";
import TextEditorTinyMCE from "../common/TextEditorTinyMCE";
import GlobalModalForDeal from "../GlobalModal/Modal/GlobalModalForDeal";


const LinkFileForDeal = {
    'modal' : GlobalModalForDeal,
    'TextEditorTinyMCE': TextEditorTinyMCE,
    'useDelayCallback': useDelayCallback,
    'isMounted': useIsMounted,
}
export default LinkFileForDeal;