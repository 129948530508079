import React, {useEffect, useState} from "react";
import {CircularProgress} from "@material-ui/core";
import {useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";
import Styles from "./Styles.module.css";
import {setupZenDirect} from "../../api/containerApi";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const ZendirectValidation=(props)=>{
    const codeObj = useLocation().search;
    const code = new URLSearchParams(codeObj).get("code");
    const errorReason = new URLSearchParams(codeObj).get("error_reason");
    const [verifying, setVerifying] = useState(true);
    const [verified, setVerified] = useState(false);
    const [message, setMessage] = useState("<h5>Please wait. While verifying your ZenDirect App</h5>");

    useEffect(()=>{
        try{
            setupZenDirect({ code: code })
                .then((res)=>(res.data))
                .then((data)=>{
                    if (data.status === "success"){
                        setVerified(true);
                        setMessage("<h5>"+data.message+"</h5>");
                        setTimeout(()=>{
                            window.location.replace(window.location.origin+"/agency/messaging-setup#postcard_letter_gateway_setup_section");
                        },1500);
                    }else {
                        if (errorReason === "wrong_redirect_uri"){
                            setMessage("<h5>The Redirect Uri Doesn't Matched with ZenDirect App</h5><h6>Please check the ZenDirect App redirect uri</h6>");
                        }else {
                            setMessage("<h5>"+data.message+"</h5>");
                        }

                        setVerified(false);
                    }
                }).finally(()=>{
                setVerifying(false);
            })
                .catch((err)=>{
                    setMessage("<h5>Sorry, Failed to verify your ZenDirect App</h5>");
                });
        }catch (err){
            setMessage("<h5>Sorry, Failed to verify your ZenDirect</h5>");
        }
    },[]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={Styles.share_page__wrapper}>
            <Helmet>
                <title>ZenDirect Verification</title>
                <meta name="description" content="All-In-One Sales & Marketing Platform" />
                <meta name="keywords" content="CRM, Automation, Sales, Marketing" />
            </Helmet>
            <div className={Styles.share_page__inner_wrapper}>
                {
                    (
                        verifying ? (
                            <CircularProgress className={Styles.progress_icon} size={80}/>
                        ) : (
                            verified ? (<CheckCircleOutlineIcon className={Styles.progress_icon} style={{ fontSize: 100 }}/>) : (<HighlightOffIcon className={Styles.progress_icon} style={{ fontSize: 100 }}/>)
                        )
                    )
                }
                <div dangerouslySetInnerHTML={{ __html: message }}></div>
            </div>
        </div>
    );
}

export default ZendirectValidation;